import React, { Component } from "react";
import { connect } from "react-redux";
import Search from "components/Search/Search";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Page } from "utils/constant";
import { Dropdown } from "semantic-ui-react";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { getUserId } from 'services/UserService';
import PhoenixAPI from "utils/PhoenixAPI";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import AlertBox from "components/AlertBox/AlertBox";
import { wrap } from "lodash";
import * as XLSX from "xlsx";
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const LAB_OPTIONS = [
  {
    key: "alborg",
    value: "alborg",
    text: "Al Borg",
  },
  {
    key: "neuberg",
    value: "neuberg",
    text: "Neuberg",
  },
  {
    key: "thyrocare",
    value: "thyrocare",
    text: "Thyrocare",
  },
  {
    key: "unilabs",
    value: "unilabs",
    text: "Unilabs",
  },
  {
    key: "vestacare",
    value: "vestacare",
    text: "Vestacare",
  },
  {
    key: "biosytech",
    value: "biosytech",
    text: "Biosytech"
  },
  {
    key: "farabi",
    value: "farabi",
    text: "Farabi"
  },
  {
    key: "taib",
    value: "taib",
    text: "Taib Lab"
  },
  {
    key: "wareedlab",
    value: "wareedlab",
    text: "Wareed Lab"
  },
  {
    key: "lifenitylab",
    value: "lifenitylab",
    text: "Lifenity Lab"
  },
  {
    key: "almushtaqbal",
    value: "almushtaqbal",
    text: "Al Mushtaqbal Lab"
  },
  {
    key: "lifenity_abudhabi",
    value: "lifenity_abudhabi",
    text: "Lifenity Lab Abu-Dhabi"
  },
  {
    key: "genalive",
    value: "genalive",
    text: "Genalive Lab"
  },
  {
    key: "alborg fox explorer",
    value: "alborg fox explorer",
    text: "Alborg Fox Explorer"

  },
  {
    key: "unilabs fox explorer",
    value: "unilabs fox explorer",
    text: "Unilabs Fox",
  },
  {
    key:"qlabs",
    value:"qlabs",
    text:"Q Labs"
  }
];

const NonBlood_Lab_Options=[
  {
    key: "alborg fox explorer",
    value: "alborg fox explorer",
    text: "Alborg Fox Explorer"
  },
  {
    key: "unilabs fox explorer",
    value: "unilabs fox explorer",
    text: "Unilabs Fox",
  }

];

const UnitOptions = [{
  key: "units",
  value: "units",
  text: "Units"
},
{
  key: "ratio",
  value: "ratio",
  text: "Ratio"
}
]

class OCRManagement extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      searchTerm: "",
      isEditable: true,
      labName: "",
      bloodBiomarkers: [],
      bloodBiomarker: {},
      isShowModal: false,
      labNameError: "",
      selectedUnit: "units",
      searchApplied: false,
      loadError: "",
      allBloodBiomarkers: [],
      uploadedData: [],
      uploadedFileDetails: {},
      errors: {},
      editables: {},
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      biomarkerType: "",
      selectedUnitError:"",
      isShowNonBloodModal: false,
      allRatioBiomarkers:[],
      isNewAddRatio:false,
      nonDerivedBiomarkers:[],
      newRatioBiomarker:{derivedTestId:"",numeratorTestId:"",denominatorTestId:"",operationType:"ratio"},
      allDerivedTests:[]
    };
    this.columns = [
      {
        name: "Id",
        selector: "id",
        sortable: true,
        wrap: true,

        maxWidth: "100px"
      },
      {
        name: "Biomarker : Valeo",
        selector: "valeoTestName",
        wrap: true,
      },
      {
        name: "Unit : Valeo",
        selector: "valeoTestUnit",
        wrap: true,
        maxWidth: "100px"
      },
      {
        name: "Biomarker : Lab",
        selector: "labTestName",
        wrap: true,
      },
      {
        name: "Unit : Lab",
        selector: "labTestUnit",
        center: true,
        wrap: true,
        maxWidth: "100px"
      },
      {
        name: "Conversion Factor",
        selector: "defaultConversionFactor",
        wrap: true,

        maxWidth: "150px"
      },

      {
        name: "Edit",
        wrap: true,
        minWidth: "200px",
        center: true,
        cell: (row) => this.renderEditButton(row.id),
      },

    ];
    this.nonBloodColumns = [
      {
        name: "Id",
        selector: "id",
        sortable: true,
        wrap: true,

        maxWidth: "100px"
      },
      {
        name: "Biomarker : Valeo",
        selector: "valeoFoodName",
        wrap: true,
        format: (row) => row.valeoFoodName ? row.valeoFoodName : ""
      },
      {
        name: "Unit : Valeo",
        selector: "valeoFood",
        wrap: true,
        format: (row) => row.valeoFoodUnit ? row.valeoFoodUnit : ""
      },

      {
        name: "Biomarker : Lab",
        selector: "labFoodName",
        wrap: true,
        format: (row) => row.labFoodName ? row.labFoodName : ""
      },
      {
        name: "Unit : Lab",
        selector: "labFoodUnit",
        wrap: true,
        format: (row) => row.labFoodUnit ? row.labFoodUnit : ""
      },
      {
        name: "Edit",
        wrap: true,
        minWidth: "200px",
        center: true,
        cell: (row) => this.renderEditButton(row.id),
      }
    ];
    this.ratioColumns=[
    
    {
      name:"valeoBiomarker Name",
      selector: "derivedTestName",
      wrap: true,
      minWidth: "150px"
    },
    {
      name:"valeoBiomarker Ratio1",
      selector:"numeratorTestName",
      wrap: true,
      minWidth: "150px"
    },
    {
      name:"valeoBiomarker Ratio2",
      selector:"denominatorTestName",
      wrap: true,
      minWidth: "150px"
    }
    ]
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  componentDidMount() {
    this.fetchAclUser();
  }

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.OCR_Management;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  ratioHideModal=()=>{
    this.setState({isNewAddRatio:false});
    this.resetForm();
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  

  handleAddNew = () =>{
    this.setState({isNewAddRatio:true})
  }

  renderEditButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => { this.renderEditBloodBiomarker(id); }}
      >
        Edit
      </button>
    </div>
  );

  renderEditBloodBiomarker = async (labId) => {
    const { biomarkerType, isShowNonBloodModal } = this.state;
    try {

      const response = biomarkerType == "bloodBiomarkers" ? await PhoenixAPI.get(`/biomarker-tests/${labId}`) : await PhoenixAPI.get(`/admin/food-intolerance-tests-by-id/${labId}`);
      if (biomarkerType == "bloodBiomarkers") {
        this.setState({ bloodBiomarker: response.data, isShowModal: true });
      }
      else {
        this.setState({ bloodBiomarker: response.data, isShowNonBloodModal: true });
      }
    } catch (error) {
      console.log("Error fetching blood biomarkers", error)
      this.showErrorNotification("Error fetching blood biomarkers");
    }
    finally {
      this.hideLoading();
    }
  }

  handleViewBloodBiomarkers = async (biomarkerType) => {
    const { labName, labNameError, selectedUnit,allRatioBiomarkers } = this.state;
    this.showLoading();
    try {

      if (labName == "") {
        const labNameError = "please select a lab ";
        this.setState({ labNameError });
       // this.showErrorNotification("Please select a lab");
        return;
      }
      const response = await (biomarkerType === "bloodBiomarkers"
        ? PhoenixAPI.get(`/biomarker-tests?lab=${labName}`)
        : PhoenixAPI.get(`/admin/food-intolerance-tests?lab=${labName}`));;
      console.log("response is", response);
        const allDerivedBiomarkers= await this.handleDerivedTests();
        
      const ratioBiomarkerResponse =await this.handleRatioBiomarkers();
      const ratioBiomarkers=ratioBiomarkerResponse?.data?.filter((biomarker)=>biomarker.isDerivedTest);
      const nonDerivedBiomarkerTests=ratioBiomarkerResponse?.data?.filter((biomarker)=>!biomarker.isDerivedTest);
      
      console.log("ratioBiomarkers", ratioBiomarkers)
      const selectedBiomarkers = biomarkerType == "bloodBiomarkers" ? response.data : response.data;
      
      console.log("selectedBiomarkers", selectedBiomarkers)
      this.setState({ bloodBiomarkers: selectedBiomarkers,allRatioBiomarkers:ratioBiomarkers, allBloodBiomarkers: response.data,nonDerivedBiomarkers:nonDerivedBiomarkerTests,allDerivedTests:allDerivedBiomarkers.data });
      console.log("blood biomarkers", this.state.bloodBiomarkers);
    } catch (error) {
      console.log("Error fetching blood biomarkers", error.message)
      this.showErrorNotification("Error fetching blood biomarkers");
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
    finally {
      this.hideLoading();
    }

  }

   convertJSONToCSV = (jsonData) => {
    // Convert JSON to a worksheet
    const array = Array.isArray(jsonData) ? jsonData : [jsonData];

    // Extract headers
    const headers = Object.keys(array[0]);
    const csvRows = [];

    // Add headers to CSV
    csvRows.push(headers.join(","));

    // Add rows to CSV
    array.forEach(obj => {
        const values = headers.map(header => JSON.stringify(obj[header] || ""));
        csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  
    
  };


  UploadOCRFile = async () => {
    const {
      uploadedData,
      uploadedFileDetails,
      biomarkerType,
      labName
    } = this.state;
    console.log("Upload adasdasd", uploadedData);
    if (uploadedData && uploadedData.length === 0) {
      this.showErrorNotification("Please choose File to upload!");
      return;
    }

   

  // Attach the CSV to FormData
  const formData = new FormData();
  if(biomarkerType=="bloodBiomarkers"){
    const processedData = uploadedData.map((biomarker) => ({
      ...biomarker,
      defaultConversionFactor: biomarker.defaultConversionFactor ?? "",
    }));
    
    // Convert processed data to CSV
    const csv = this.convertJSONToCSV(processedData);
    const blob = new Blob([csv], { type: "text/csv" });
    const file = new File([blob], "data.csv", { type: "text/csv" });
    
    console.log("CSV data:", csv);
    console.log("Blob object:", blob);
    console.log("File object:", file);
  
    formData.append("file", file);
    formData.append("labName",labName)

  }
  
  else{
 
   formData.append("file", uploadedFileDetails.fileContent);
   formData.append("labName",labName)
  }
    
    const config = {
      method: "POST",
      url: biomarkerType == "bloodBiomarkers" ? "/biomarker-tests/save-lab-tests" : "/admin/load-food-intolerance-tests",
      data: formData,
      headers: {
        accept: "application/json",
        "Accept-Language": "en-US,en;q=0.8",
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
      },
    };


    try {
      await PhoenixAPI.request(config);
      this.showSuccessNotification("lab units save successfully");
      if (biomarkerType == "bloodBiomarkers")
        this.handleViewBloodBiomarkers("bloodBiomarkers");
      else {
        this.handleViewBloodBiomarkers("nonBloodBiomarkers");
      }
      this.handleReset();
    } catch (error) {
      console.log("Error in Saving biomarkers", error.message);
      this.showErrorNotification("Error in Saving biomarkers");
    }
  };


  handleRatioBiomarkers=async()=>{
    
    const url="/tests";
    const response=  await PhoenixAPI.get(url);
      
    console.log("RatioBiomarkers", response);

    return response;
  }

  handleReset = () => {
    // Reset the file input value
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
  
    }
    this.setState({uploadedData:[]});
  };
  handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    console.log("upload file",this.state.labName);
    const uploadedFileDetails = {
      labName: this.state.labName,
      fileContent: file,
    };
    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        
      });
      const formattedData = jsonData.map((biomarker) => {
        const isBloodBiomarker = this.state.biomarkerType === "bloodBiomarkers";
        console.log("biomarker", biomarker);
        return{
        

         
          Name_in_your_Lab: isBloodBiomarker?biomarker.Name_in_your_Lab:biomarker.labFoodName,
          Unit_in_your_lab:isBloodBiomarker? biomarker.Unit_in_your_lab:biomarker.labFoodUnit,
          Name_in_Valeo:isBloodBiomarker?biomarker.Name_in_Valeo: biomarker.valeoFoodName,
          Unit_in_Valeo: isBloodBiomarker?biomarker.Unit_in_Valeo: biomarker.valeoFoodUnit,
        ...(isBloodBiomarker && { defaultConversionFactor: biomarker.defaultConversionFactor }),
        // Convert Excel date to proper date stri
      };});

      this.setState({ uploadedData: formattedData ?? [], });
      console.log("formattedData: " + formattedData);
    };
    this.setState({ uploadedFileDetails });
    reader.readAsArrayBuffer(file);
  };

  mappingPackages = (biomarkers) => {
    const mappingBiomarkers = biomarkers.map((biomarker) => {
      const item = {
        "Name in your Lab": biomarker.labTestName,
        "Unit in your lab": biomarker.labTestUnit,

        "Name in Valeo": biomarker.valeoTestName,
        "Unit in Valeo": biomarker.valeoTestUnit,

        defaultConversionFactor: biomarker.defaultConversionFactor,
      };
      return item;
    });
    return mappingBiomarkers;
  };

  mappingNonBloodPackages = (biomarkers) => {
    const mappingBiomarkers = biomarkers.map((biomarker) => {
      const item = {
        "Name in your Lab": biomarker.labFoodName,
        "Unit in your lab": biomarker.labFoodUnit,

        "Name in Valeo": biomarker.valeoFoodName,
        "Unit in Valeo": biomarker.valeoFoodUnit,


      };
      return item;
    });
    return mappingBiomarkers;

  }

  jsonToCsv = (json) => {
    const csvRows = [];
    // Get the headers
    const headers = Object.keys(json[0]).map(header => header.replace(/\s+/g, "_"));
    csvRows.push(headers.join(',')); // Add headers to CSV

    // Map over each row
    json.forEach(row => {
        const values = headers.map(header => {
            // Ensure the original key from JSON is accessed properly
            const key = Object.keys(row).find(k => k.replace(/\s+/g, "_") === header);
            const escaped = ('' + (row[key] || "")).replace(/"/g, '\\"'); // Escape quotes
            return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
    });

    // Join rows with newline character
    return csvRows.join('\n');
  };


  downloadBiomarkerTests = async() => {
    const { bloodBiomarkers = [], biomarkerType,labName } = this.state;
    

    const downloadUrl=biomarkerType=="bloodBiomarkers"?`biomarker-tests/download-template/${labName}`:`admin/food-intolerance/download-template/${labName}`;
    const response= await PhoenixAPI.get(downloadUrl);

    const selectedItems = response.data;
    
    if (selectedItems.length > 0) {
      const mappedPackages = biomarkerType == "bloodBiomarkers" ? this.mappingPackages(selectedItems) : this.mappingNonBloodPackages(selectedItems);
      if (mappedPackages.length > 0) {
        const csvContent = this.jsonToCsv(mappedPackages);
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        this.showSuccessNotification("File is Downloaded successfully")
      
      }
    } else {
        return;
      }

    }
  


  handleBiomarkerChange = ({ currentTarget: input }) => {
    const fieldName = (input && input.name) || "";
    let bloodBiomarker = { ...this.state.bloodBiomarker };
    bloodBiomarker[fieldName] = input.value;
    this.setState({ bloodBiomarker });
  };

  handleRatioBiomarker = ({ currentTarget: input }) => {
    const fieldName = (input && input.name) || "";
    let bloodBiomarker = { ...this.state.bloodBiomarker };


    const currentLabTestNameParts = bloodBiomarker.labTestName ? bloodBiomarker.labTestName.split("/") : ["", ""];

    if (fieldName === "labTestNameRatio1") {

      currentLabTestNameParts[0] = input.value;
    } else if (fieldName === "labTestNameRatio2") {

      currentLabTestNameParts[1] = input.value;
    }

    // Join the parts back to form the updated labTestName
    bloodBiomarker.labTestName = currentLabTestNameParts.join("/");

    // Update the state
    this.setState({ bloodBiomarker });
  };

  onhideModal = () => {
    this.setState({ isShowModal: false });
    this.resetForm();
  }
  onhideNonBloodModal = () => {
    this.setState({ isShowNonBloodModal: false });
    this.resetForm();
  }


  validate = () => {
    const errors = {};
    const { bloodBiomarker, selectedUnit, biomarkerType } = this.state;
    const { labTestUnit, labTestName, defaultConversionFactor, labFoodName, labFoodUnit } = bloodBiomarker ?? {};
    if (biomarkerType == "bloodBiomarkers") {
      if ( !(labTestName.includes("/")|| labTestName.includes("ratio"))) {
        console.log("lab test unit", labTestUnit);
        if (!labTestUnit || labTestUnit.trim() === "") {
          errors.labTestUnit = "please enter lab test unit";

        }
         if (!labTestName || labTestName.trim() === "") {
          errors.labTestName = "please enter lab test name";
        }
        
      }
      else {
        const labRatio1 = labTestName.split("/")[0];
        const labRatio2 = labTestName.split("/")[1];
        
          if (!labRatio1 || labRatio1.trim() === "") {
            errors.labTestNameRatio1 = "Biomarker Name Ratio 1 is required";
          }
         if (!labRatio2 || labRatio2.trim() === "") {
            errors.labTestNameRatio2 = "Biomarker Name Ratio 2 is required";
          }
        
      }
    }
    else {
      if (!labFoodUnit || labFoodUnit.trim() === "") {
        errors.labTestUnit = "please enter lab test unit";

      }
       if (!labFoodName || labFoodName.trim() === "") {
        errors.labTestName = "please enter lab test name";
      }

    }

    return Object.keys(errors).length === 0 ? null : errors;
  }
  handleSaveBiomarker = async (event) => {
    console.log("check event");
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({ isSaving: false });
      return;
    }
    const { bloodBiomarker, biomarkerType } = this.state;
    this.showLoading();
    try {
      await PhoenixAPI.patch(`/biomarker-tests/${bloodBiomarker.id}`, bloodBiomarker);
      this.showSuccessNotification("Biomarker updated successfully");
      this.onhideModal();
      const prevSelectedUnit = this.state.selectedUnit;
      this.handleViewBloodBiomarkers("bloodBiomarkers");
      this.setState({ selectedUnit: prevSelectedUnit })
    } catch (error) {
      console.log("Error updating blood biomarker", error.message)
      this.showErrorNotification("Error updating blood biomarker");
    }
    finally {
      this.hideLoading();
    }

  }
  handleSaveNonBloodBiomarker = async (event) => {
    event.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      this.setState({ isSaving: false });
      return;
    }
    const { bloodBiomarker, biomarkerType } = this.state;
    this.showLoading();
    try {
      await PhoenixAPI.patch(`/admin/food-intolerance-tests/${bloodBiomarker.id}`, bloodBiomarker);
      this.showSuccessNotification("Biomarker updated successfully");
      this.onhideNonBloodModal();

      this.handleViewBloodBiomarkers("nonBloodBiomarkers");

    } catch (error) {
      console.log("Error updating non blood biomarker", error.message)
      this.showErrorNotification("Error updating non blood biomarker");
    }
    finally {
      this.hideLoading();
    }
  }


  getFilteredBloodBiomarkers = () => {
    const { bloodBiomarkers, allDerivedTests,selectedUnit } = this.state;
    console.log("getFiltered",allDerivedTests);
    return selectedUnit=="ratio"?this.getSearchedDerivedBiomarkers(allDerivedTests):this.getSearchedBloodBiomarkers(bloodBiomarkers);
  }
  
  getSearchedDerivedBiomarkers=(derivedTests)=>{
    const { searchApplied, searchTerm } = this.state;
    if (!searchApplied || !searchTerm)
      return derivedTests;
    const newValue = searchTerm.slice(0).trim().toLowerCase();
    console.log("new value", searchTerm);
    return derivedTests.filter((derivedTest) => {
      const{id,derivedTestName,numeratorTestName,denominatorTestNmae}=derivedTest;

     return( (id && id.toString().includes(newValue)) ||
      
      (derivedTestName && derivedTestName.toLowerCase().indexOf(newValue) !== -1) ||
      (numeratorTestName && numeratorTestName.toLowerCase().indexOf(newValue) !== -1) ||
      (denominatorTestNmae && denominatorTestNmae.toLowerCase().indexOf(newValue) !== -1));
  });

}


  getSearchedBloodBiomarkers = (bloodBiomarkers) => {
    const { searchApplied, searchTerm, biomarkerType } = this.state;
    if (!searchApplied || !searchTerm)
      return bloodBiomarkers;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    console.log("new value", searchTerm);
    return bloodBiomarkers.filter((bloodBiomarker) => {

      const {
        id,
        labTestName,
        valeoTestName,
        labFoodName,
        valeoFoodName,
      } = bloodBiomarker;
      return (biomarkerType == "bloodBiomarkers" ?
        ((id && id.toString().includes(newValue)) ||
          (labTestName && labTestName.toLowerCase().indexOf(newValue) !== -1) ||
          (valeoTestName && valeoTestName.toLowerCase().indexOf(newValue) !== -1)) : ((id && id.toString().includes(newValue)) ||
            (labFoodName && labFoodName.toLowerCase().indexOf(newValue) !== -1) ||
            (valeoFoodName && valeoFoodName.toLowerCase().indexOf(newValue) !== -1))
      );
    });
  }

  handleBiomarkerType=(labName)=>{
   const bloodType= labName=="alborg fox explorer"|| labName=="unilabs fox explorer"?"nonBloodBiomarkers":"bloodBiomarkers";
    this.setState({biomarkerType:bloodType})
  }

  renderPencilIcon = (fieldName) => {
    const setEditable = (field) => {
      const editables = { ...this.state.editables };
      editables[field] = true;
      this.setState({ editables });
    };


    return (
      <div
        onClick={() => setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    );
  };

  handleSearch = (queryString) => {
    console.log("search term ", queryString);
    this.setState({
      searchApplied: true,
      searchTerm: queryString ?? "",
    });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };
  resetForm = () => {
    this.setState({
      errors: {},
      editables: {},
      newRatioBiomarker:{derivedTestId:"",numeratorTestId:"",denominatorTestId:"",operationType:"ratio"}
    })
    };
  
    
    validateRatio=()=>{
      const { newRatioBiomarker } = this.state;
      const { errors } = this.state;
      if (!newRatioBiomarker.derivedTestId || newRatioBiomarker.derivedTestId.trim() === "") {
        errors.derivedTestName = "please enter derived test name";
      }
      else if (!newRatioBiomarker.numeratorTestId|| newRatioBiomarker.numeratorTestId.trim() === "") {
        errors.numeratorTestName = "please enter numerator test name";
      }
      else if (!newRatioBiomarker.denominatorTestId || newRatioBiomarker.denominatorTestId.trim() === "") {
        errors.denominatorTestName = "please enter denominator test name";
      }
      return Object.keys(errors).length === 0 ? null : errors;
    }

    handleRatioSaveBiomarker=async(event)=>{
          event.preventDefault();
          const errors = this.validateRatio();
          this.setState({ errors: errors || {} });
          if (errors) {
            this.showErrorNotification("There are some invalid form fields!");
            this.setState({ isSaving: false });
            return;
          }
          const { newRatioBiomarker } = this.state;
          this.showLoading();
          try {
            await PhoenixAPI.post(`/derived-tests`, newRatioBiomarker);
          
            this.showSuccessNotification("Ratio Biomarker updated successfully");
            this.ratioHideModal();
            this.handleDerivedTests();
          } catch (error) {
            console.log("Error updating ratio biomarker", error.message)
            this.showErrorNotification("Error updating ratio biomarker");
          }
          finally {
            this.hideLoading();
          }
    }
    handleDerivedTests=async()=>{
      try{
             const response=await PhoenixAPI.get(`/derived-tests`);
             console.log("response",response.data);
             this.setState({allDerivedTests:response.data});
              return response;
          }catch(error){
            console.log("Error fetching derived tests", error.message);
    
      }
    }

    
    

  render() {
    const { labName, labNameError, bloodBiomarkers,newRatioBiomarker, selectedUnit, selectedUnitError,nonDerivedBiomarkers, errors, editables,isNewAddRatio,allRatioBiomarkers, isShowModal, bloodBiomarker, isEditable, searchTerm, rowsPerPage, isShowNonBloodModal } = this.state;

    const filteredBloodBiomarkers = this.getFilteredBloodBiomarkers();
    //console.log("Filtered bloodBiomarkers", filteredBloodBiomarkers.length);
    const newRatioForm=(
            <div className="container mt-4">
              <div className="row">
              <div className="form-group col-6">
                <label htmlFor="lab_food_name">Valeo RatioBioMarkers </label>
                <select
                id="valeo_ratio_biomarker"
                name="valeo_ratio_biomarker"
                className={`form-control user-data-input`}
                value={newRatioBiomarker?.derivedTestId  || ""}
              onChange={(event)=>{const selectedId=event.target.value;console.log("check",event.target.value,newRatioBiomarker);
                if(event.target){this.setState((prevState) => ({
                newRatioBiomarker: {
                  ...prevState.newRatioBiomarker, 
                  derivedTestId:  selectedId
                }
              }))};}}>
                  <option key={-1} value="" hidden={true}>Select</option>
                 {allRatioBiomarkers &&
                 
                    allRatioBiomarkers.map((biomarker, index) => (
                    <option key={biomarker.id} value={biomarker.id}>
                   {biomarker.name} 
                  </option>
    ))}

                </select>
                </div>
                <span className="help-block">{errors.derivedTestName}</span>
              </div>
              <div className="row">
                <div className="form-group col-6">
              <label htmlFor="lab_food_name">BioMarker Ratio 1</label>
                <select
                id="valeo_ratio_biomarker"
                name="valeo_ratio_biomarker"
                className={`form-control`}
              onChange={(event)=>{const selectedId=event.target.value;this.setState((prevState) => ({
                  newRatioBiomarker: {
                    ...prevState.newRatioBiomarker, 
                    numeratorTestId  : selectedId 
                  }
                }));}}>
                  <option key={-1} value="" hidden={true}>Select</option>
                 {nonDerivedBiomarkers &&
    nonDerivedBiomarkers.map((biomarker, index) => (
      <option key={biomarker.id} value={biomarker.id}>
        {biomarker.name} 
      </option>
    ))}

                </select>
                <span className="help-block">{errors.numeratorTestName}</span>
              </div>
              <div className="form-group col-6">
              <label htmlFor="lab_food_name">BioMarker Ratio 2</label>
                <select
                id="valeo_ratio_biomarker"
                name="valeo_ratio_biomarker"
                className={`form-control `}
                onChange={(event)=>{const selectedId=event.target.value;this.setState((prevState) => ({
                  newRatioBiomarker: {
                    ...prevState.newRatioBiomarker, 
                    denominatorTestId  :selectedId 
                  }
                }));}}>
                  <option key={-1} value="" hidden={true}>Select</option>
                 {nonDerivedBiomarkers &&
    nonDerivedBiomarkers.map((biomarker, index) => (
      <option key={biomarker.id} value={biomarker.id}>
        {biomarker.name} 
      </option>
    ))}

                </select>
                <span className="help-block">{errors.denominatorTestName}</span>
              </div>
            </div>
            </div>
    )
    const nonBloodbiomarkerForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="valeo_test_name">Valeo Test Name</label>

            <input
              type="text"
              className="form-control"
              id="valeo_test_name"
              placeholder="Valeo Test Name"
              value={bloodBiomarker.valeoFoodName}
              name="valeoFoodName"
              onChange={this.handleBiomarkerChange}
              disabled={true}
            />
          </div>
          <div className="form-group col-6">
            <label htmlFor="valeo_test_unit">Valeo Test Unit</label>
            <input
              type="text"
              className="form-control"
              id="valeo_test_unit"
              placeholder="Valeo Test Unit"
              value={bloodBiomarker.valeoFoodUnit}
              name="valeoFoodUnit"
              onChange={this.handleBiomarkerChange}
              disabled={true}
            />

          </div>
        </div>


        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="lab_test_name">Lab Test Name</label>
            <div className="input-group">
              <input
                type="text"

                id="lab_test_name"
                placeholder="Lab Test Name"
                value={bloodBiomarker.labFoodName}
                name="labFoodName"
                onChange={this.handleBiomarkerChange}
                disabled={!editables.labFoodName}
                className={
                  "form-control" + (errors.labTestName ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("labFoodName")}
            </div>
            <span className="help-block">{errors.labTestName}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="lab_test_unit">Lab Test Unit</label>
            <div className="input-group">
              <input
                type="text"

                id="lab_test_unit"
                placeholder="Lab Test Unit"
                value={bloodBiomarker.labFoodUnit}
                name="labFoodUnit"
                onChange={this.handleBiomarkerChange}
                disabled={!editables.labFoodUnit}
                className={
                  "form-control" + (errors.labTestUnit ? " has-error" : "")
                }


              />
              {this.renderPencilIcon("labFoodUnit")}
            </div>
            <span className="help-block">{errors.labTestUnit}</span>
          </div>
        </div>


      </div>

    )

    const biomarkerForm = (
      <div className="container mt-4">


        {(bloodBiomarker?.valeoTestName?.includes("/") ||bloodBiomarker?.valeoTestName?.includes("ratio"))  ? (<>
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="valeo_test_name">Valeo Test Name</label>
              <input
                type="text"
                className="form-control"
                id="valeo_test_name"
                placeholder="Valeo Test Name"
                value={bloodBiomarker.valeoTestName}
                name="valeoTestName"
                onChange={this.handleBiomarkerChange}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="valeo_test_unit">Biomarker Name for Lab Ratio 1</label>
              <div className="input-group">
                <input
                  type="text"

                  id="valeo_test_unit"
                  name="labTestNameRatio1"
                  placeholder="Biomarker Name for Lab Ratio 1"
                  value={bloodBiomarker.labTestName?.split("/")[0]}
                  onChange={this.handleRatioBiomarker}
                  disabled={!editables.labTestNameRatio1}
                  className={
                    "form-control" + (errors.labTestNameRatio1 ? " has-error" : "")
                  } />
                {this.renderPencilIcon("labTestNameRatio1")}
              </div>
              <span className="help-block">{errors.labTestNameRatio1}</span>
            </div>
            <div className="form-group col-6">
              <label htmlFor="valeo_test_unit">Biomarker Name for Lab Ratio 2</label>
              <div className="input-group">
                <input
                  type="text"

                  id="valeo_test_unit"
                  name="labTestNameRatio2"
                  placeholder="Biomarker Name for Lab Ratio 2"
                  value={bloodBiomarker.labTestName?.split("/")[1]}
                  onChange={this.handleRatioBiomarker}
                  disabled={!editables.labTestNameRatio2}
                  className={
                    "form-control" + (errors.labTestNameRatio2 ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("labTestNameRatio2")}
              </div>
              <span className="help-block">{errors.labTestNameRatio2}</span>
            </div>
          </div>
        </>) : (<>
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="valeo_test_name">Valeo Test Name</label>

              <input
                type="text"
                className="form-control"
                id="valeo_test_name"
                placeholder="Valeo Test Name"
                value={bloodBiomarker.valeoTestName}
                name="valeoTestName"
                onChange={this.handleBiomarkerChange}
                disabled={true}
              />
            </div>
            <div className="form-group col-6">
              <label htmlFor="valeo_test_unit">Valeo Test Unit</label>
              <input
                type="text"
                className="form-control"
                id="valeo_test_unit"
                placeholder="Valeo Test Unit"
                value={bloodBiomarker.valeoTestUnit}
                name="valeoTestUnit"
                onChange={this.handleBiomarkerChange}
                disabled={true}
              />

            </div>
          </div>


          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="lab_test_name">Lab Test Name</label>
              <div className="input-group">
                <input
                  type="text"

                  id="lab_test_name"
                  placeholder="Lab Test Name"
                  value={bloodBiomarker.labTestName}
                  name="labTestName"
                  onChange={this.handleBiomarkerChange}
                  disabled={!editables.labTestName}
                  className={
                    "form-control" + (errors.labTestName ? " has-error" : "")
                  }

                />
                {this.renderPencilIcon("labTestName")}
              </div>
              <span className="help-block">{errors.labTestName}</span>
            </div>
            <div className="form-group col-6">
              <label htmlFor="lab_test_unit">Lab Test Unit</label>
              <div className="input-group">
                <input
                  type="text"

                  id="lab_test_unit"
                  placeholder="Lab Test Unit"
                  value={bloodBiomarker.labTestUnit}
                  name="labTestUnit"
                  onChange={this.handleBiomarkerChange}
                  disabled={!editables.labTestUnit}
                  className={
                    "form-control" + (errors.labTestUnit ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("labTestUnit")}
              </div>
              <span className="help-block">{errors.labTestUnit}</span>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="conversion_factor">Conversion Factor</label>
              <div className="input-group">
                <input
                  type="number"

                  id="conversion_factor"
                  placeholder="Conversion Factor"
                  value={bloodBiomarker.defaultConversionFactor}
                  name="defaultConversionFactor"
                  onChange={this.handleBiomarkerChange}
                  disabled={!editables.defaultConversionFactor}
                  className={
                    "form-control" + (errors.defaultConversionFactor ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("defaultConversionFactor")}
              </div>
              <span className="help-block">{errors.defaultConversionFactor}</span>
            </div>
          </div></>)}

      </div>
    )
    return (<>

      <div className="row">
        <div className="form-group col-4">
          <label htmlFor="lab_name">Select Lab</label>
          <Dropdown
            value={labName}
            onChange={(event, value) => { const labName=value.value;this.setState({ labName: value.value, selectedUnit: "units",labNameError:"",bloodBiomarkers: [],allDerivedTests:[] },()=>{this.handleReset();this.handleBiomarkerType(labName)}) }}
            id="lab_name"
            name="lab_name"
            placeholder="Select Lab"
            search
            selection
            options={LAB_OPTIONS}
            disabled={!this.state.isEditable}
            className={"form-control" + (labNameError ? " has-error" : "")}
          />
          <span className="help-block">{labNameError ?? ""}</span>
        </div>
        {!(labName=="alborg fox explorer"|| labName=="unilabs fox explorer")&&
        <div className="form-group col">
          <div className={(labName=="alborg fox explorer"|| labName=="unilabs fox explorer")?"col":"col-6"} style={{ marginTop: "30px" }}>
            <button
              className="btn button button-green result-submit "
              style={{ 'padding': '.375rem .75rem' }}


              onClick={() => {
                this.setState({ biomarkerType: "bloodBiomarkers" }, () => {
                  this.handleViewBloodBiomarkers("bloodBiomarkers");
                });
              }}
            >
              View Blood Biomarkers
            </button>
          </div>
        </div>}
         {(labName=="alborg fox explorer"|| labName=="unilabs fox explorer")&&
        <div className="form-group col">
          <div className="col-6" style={{ marginTop: "30px" }}>
            <button
              className="btn button button-green result-submit "
              style={{ 'padding': '.375rem .75rem' }}
              onClick={() => 
                 this.handleViewBloodBiomarkers("nonBloodBiomatrkers")}
            >
              View NonBlood Biomarkers
            </button>
          </div>
        </div>}


      </div>



      {labName && <Card body>
        <div className="row">
          <div className="col-2">
            <label htmlFor="units">Lab Name :  </label>
            <span style={{ fontWeight: "bold" }}>{labName}</span>
          </div>

          {this.state.biomarkerType == "bloodBiomarkers" ? <div className="form-group col-3">
            <Dropdown
              value={selectedUnit}
              onChange={(event, value) => {
                const isRatio = value.value === "ratio";
                const filteredBiomarkers = isRatio?this.state.allDerivedTests:this.state.allBloodBiomarkers;
                console.log("allDer",this.state.allDerivedTests);
               isRatio?this.setState({
                selectedUnit: value.value,allDerivedTests:this.state.allDerivedTests}): this.setState({
                  selectedUnit: value.value,
                  bloodBiomarkers: filteredBiomarkers,
                  
                });
              }}
              id="Units"
              name="selectedUnit"
              placeholder="Select Unit"
              search
              selection
              options={UnitOptions}
              disabled={!this.state.isEditable}
              className={"form-control" + (selectedUnitError ? " has-error" : "")}
            />

          </div> : <></>}
          {selectedUnit && <div className="page-header" style={{ width: "50%" }}>
            <div className="actions">
              <div className="action-item">
                <Search
                  searchExpanded={true}
                  searchTerm={searchTerm}
                  handleSearch={this.handleSearch}
                  clearSearch={this.handleSearchClear}
                  handleSearchExit={this.handleSearchExit}
                  ref={(input) => {
                    this.search = input;
                  }}
                ></Search>
              </div>
             {this.state.selectedUnit=="ratio" &&  (<div className="action-item">
              <button
                onClick={this.handleAddNew}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
               
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
             )}
             </div>
          </div> }
        </div>
        <br />
         {selectedUnit=="units"&&<div className="row">
          <div
            className=""
            style={{ marginLeft: "20px", display: "flex", gap: "20px" }}
          >
            <button
              onClick={(e) => this.downloadBiomarkerTests()}
              id="show_orders"
              className="btn px-2"
              style={{ backgroundColor: "#0CA789", color: "white" }}
            >
              Download
            </button>

            <div className="" style={{ display: "flex", gap: "10px" }}>
              <button
                onClick={(e) => this.UploadOCRFile()}
                id="show_orders"
                className="btn px-2"
                style={{ backgroundColor: "#0CA789", color: "white" }}
              >
                Upload File
              </button>
              <input
                type="file"
                accept=".xlsx, .xls"
                placeholder="Upload File"
                onChange={(e) => this.handleFileUpload(e)}
                ref={this.fileInputRef}
              />
            </div>
          </div>
         
        </div>} 
        <DataTable
          highlightOnHover
          columns={this.state?.biomarkerType == "bloodBiomarkers" ? selectedUnit=="ratio"?this.ratioColumns:this.columns : this.nonBloodColumns}
          data={filteredBloodBiomarkers || []}
          pagination
          paginationPerPage={rowsPerPage ?? DEFAULT_ROWS_PER_PAGE}
          paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          responsive
          sortIcon={<ArrowDownward></ArrowDownward>}
          noDataComponent={
            this.state.loadError ? (
              <AlertBox
                message={this.state.loadError}
                error={true}
              ></AlertBox>
            ) : (
              <AlertBox message="There's nothing here."></AlertBox>
            )
          }
        />
      </Card>}
      <Modal size="lg" show={isShowNonBloodModal} onHide={this.onhideNonBloodModal}>
        <Modal.Header closeButton>
          <Modal.Title>Lab Name : {labName}</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          {nonBloodbiomarkerForm}
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={this.handleSaveNonBloodBiomarker}
            className="btn btn-lg btn-success rounded-pill px-4 py-2"
          >
            save
          </button>
        </Modal.Footer>

      </Modal>

      <Modal size="lg" show={isShowModal} onHide={this.onhideModal}>
        <Modal.Header closeButton>
          <Modal.Title>Lab Name : {labName}</Modal.Title>

        </Modal.Header>
        <Modal.Body>
          {biomarkerForm}
        </Modal.Body>

        <Modal.Footer>
          <button
            onClick={this.handleSaveBiomarker}
            className="btn btn-lg btn-success rounded-pill px-4 py-2"
          >
            save
          </button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={isNewAddRatio} onHide={this.ratioHideModal}>
        <Modal.Header closeButton>Lab Name : {labName}</Modal.Header>
    
      
        <Modal.Body>
          {newRatioForm}
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={this.handleRatioSaveBiomarker}
            className="btn btn-lg btn-success rounded-pill px-4 py-2"
          >
            save
          </button>
        </Modal.Footer>

      </Modal>

    </>


    )
  }
}
function mapStateToProps(state) {
  return {};
}


function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OCRManagement);
