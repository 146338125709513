import React, { Component } from "react";
import { connect } from "react-redux";
import FormValidator from "utils/FormValidator";
import Alert from "react-bootstrap/Alert";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";

class BioMarkForm extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "status_name",
        method: "isEmpty",
        validWhen: false,
        message: "Label is required.",
      },
      {
        field: "status_name_ar",
        method: "isEmpty",
        validWhen: false,
        message: "Label is required.",
      },
      {
        field: "status_color_code",
        method: "isEmpty",
        validWhen: false,
        message: "Color is required.",
      },
    ]);
    this.state = {
      range_start: this.setNextStartingPoint(),
      range_end: "",
      status_name: "",
      status_name_ar: "",
      status_color_code: "",
      gender: this.props.gender,
      country:null,
      arrBiomarkRange: this.props.arrBioMark,
      validation: this.validator.valid(),
      editIndex: null,
      alertMessage: "",
    };
    this.submitted = false;
    this.bioMarkColorOptions = [
      "#40C1AB",
      "#FFCC66",
      "#FF8966",
      "#FAACAC",
      "#F77077",
    ];
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addNewRange = this.addNewRange.bind(this);
    this.updateRange = this.updateRange.bind(this);
    this.validateRange = this.validateRange.bind(this);
    this.formReset = this.formReset.bind(this);
    this.editBiomark = this.editBiomark.bind(this);
    this.setNextStartingPoint = this.setNextStartingPoint.bind(this);
  }
 
  componentDidMount() {
    let country = this.props?.country?.id;
    this.setState({ country })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.country !== nextProps.country) {
      this.setState({
        country: nextProps.country.id,
      })
    }
  }

  handleChange(event) {
    const target = event.target;
    let value = target.value;
    if (target.type === "number") {
      value = parseFloat(target.value);
      value = Number.isNaN(value) ? null : value;
    }
    this.setState({ [target.name]: value });
  }
  handleCheckBoxChange(event) {
    this.setState({
      status_color_code: event.target.value,
    });
  }

  validateRange(startPoint, endPoint, markers, editIndex) {
    if (startPoint < 0 || endPoint < 0) return false;

    //new marker
    if (editIndex === undefined) {
      //this is the firt marker
      if (!markers.length) {
        if ((!endPoint && !startPoint) || endPoint <= startPoint) return false;
      } else {
        if (endPoint && endPoint <= startPoint) return false;
      }

      return true;
    }

    //edit marker
    //if this is the last item
    if (editIndex === markers.length - 1) {
      if (endPoint && endPoint <= startPoint) {
        return false;
      } else return true;
    } else {
      //if endpoint is empty
      if (!endPoint) return false;

      //if the updated endpoint is greater than the next one's endpoint
      if (
        markers[editIndex + 1].range_end &&
        endPoint >= markers[editIndex + 1].range_end
      )
        return false;
    }

    return true;
  }

  addNewRange() {
    const validation = this.validator.validate(this.state);
    this.submitted = true;
    this.setState({ validation });
    
    if (validation.isValid) {
      let {
        range_start,
        range_end,
        status_color_code,
        status_name,
        status_name_ar,
        gender,
        country,
      } = this.state;
      
      let arrBiomarkRange = this.props.arrBioMark;
      let valid = this.validateRange(range_start, range_end, arrBiomarkRange);

      if (valid) {
        if (this.bioMarkColorOptions.length === arrBiomarkRange.length) {
          this.setState({ alertMessage: "Marker limit reached." });
          return;
        }

        arrBiomarkRange.push({
          range_start,
          range_end,
          status_color_code,
          status_name,
          status_name_ar,
          gender,
          country,
        });

        this.props.setBioMark(arrBiomarkRange);
        this.formReset();
      } else {
        this.setState({ alertMessage: "Invalid range!" });
      }
    }
  }

  updateRange() {
    const validation = this.validator.validate(this.state);
    this.submitted = true;

    this.setState({ validation });
    if (validation.isValid) {
      let {
        range_start,
        range_end,
        status_color_code,
        status_name,
        status_name_ar,
        editIndex,
        gender,
        country,
      } = this.state;
      console.log(range_start, range_end);

      let arrBiomarkRange = this.props.arrBioMark;
      let id = this.props.arrBioMark[editIndex].id;

      let validate = this.validateRange(
        range_start,
        range_end,
        arrBiomarkRange,
        editIndex
      );

      if (validate) {
        if (editIndex < arrBiomarkRange.length - 1 && !range_end) {
          this.setState({ alertMessage: "Invalid range!" });
          return;
        }

        arrBiomarkRange[editIndex] = {
          range_start,
          range_end,
          status_color_code,
          status_name,
          status_name_ar,
          gender,
          id,
          country,
        };

        if (arrBiomarkRange[editIndex + 1]) {
          arrBiomarkRange[editIndex + 1].range_start =
            arrBiomarkRange[editIndex].range_end;
        }
        console.log(arrBiomarkRange);

        this.props.setBioMark(arrBiomarkRange);
        this.formReset();
      } else {
        this.setState({ alertMessage: "Invalid range!" });
      }
    }
  }

  formReset() {
    this.setState({
      range_start: this.setNextStartingPoint(),
      range_end: "",
      status_name: "",
      status_name_ar: "",
      status_color_code: "",
      validation: this.validator.valid(),
      editIndex: null,
      alertMessage: "",
      // country:null,
    });
    this.props.showErrorMessage({
      notificationMessage: "",
      successMessage: true,
      showNotification: false,
    });
    this.submitted = false;
  }

  /*
  editBiomark(index) {
    this.setState({ alertMessage: "" });

    let arrBiomarkRange = this.props.arrBioMark;

    this.setState({
      range_start:
      index==0 && arrBiomarkRange[index].range_start == null ? 
      0 : index==0 && arrBiomarkRange[index].range_start >= 0?
      arrBiomarkRange[index].range_start:
       arrBiomarkRange[index].range_start >= 0 ? 
       this.incrementOne(arrBiomarkRange[index].range_start) :
       "",
      range_end:
      (arrBiomarkRange[index].range_end && (arrBiomarkRange[index].range_end >= 0))
          ? arrBiomarkRange[index].range_end
          : "",
      status_name: arrBiomarkRange[index].status_name,
      status_name_ar: arrBiomarkRange[index].status_name_ar,
      status_color_code: arrBiomarkRange[index].status_color_code,
      country:arrBiomarkRange[index].country,
      editIndex: index,
      validation: this.validator.valid(),
    });
  }
  */

  editBiomark(index) {
    this.setState({ alertMessage: "" });
    const arrBiomarkRange = this.props.arrBioMark;
    if (!arrBiomarkRange || arrBiomarkRange.length <= index) {
      console.log("editBiomark: index is greater then array length", {index, arrBiomarkRange});
      return;
    }

    const biomarkerRange = arrBiomarkRange[index];
    const previousBiomarkerRange = index > 0 ? arrBiomarkRange[index - 1] : null;
    const { range_start, range_end, status_name, status_name_ar, status_color_code } = biomarkerRange;

    const updatedRangeStart = index === 0 && range_start == null
      ? 0
      : index === 0 && range_start >= 0
        ? range_start
        : range_start >= 0 && range_start < previousBiomarkerRange?.range_end
          ? this.incrementOne(arrBiomarkRange[index].range_start)
          : range_start;

    const updatedRangeEnd = range_end && range_end >= 0 ? range_end : "";

    this.setState({
      range_start: updatedRangeStart,
      range_end: updatedRangeEnd,
      status_name,
      status_name_ar,
      status_color_code,
      editIndex: index,
      validation: this.validator.valid(),
    });
  }

  handleDeleteData=(range)=>{
    let arrBiomarkRange = this.props.arrBioMark;
    const filterArrBiomarkRange=arrBiomarkRange.length>0 ? arrBiomarkRange.filter((item)=>item.id!==range.id):[]
    this.props.setBioMark(filterArrBiomarkRange);
    this.formReset();
  }

  setNextStartingPoint() {
    let { arrBioMark } = this.props;
    if (arrBioMark.length > 0) {
      return Math.max.apply(
        Math,
        arrBioMark.map(function (value) {
          return value.range_end;
        })
      );
    } else {
      return "";
    }
  }

  colorAvailable(color) {
    const editIndex = this.state.editIndex;
    const markers = this.props.arrBioMark;

    if (
      editIndex !== null &&
      markers[editIndex].status_color_code &&
      markers[editIndex].status_color_code === color
    )
      return true;

    for (let marker of markers) {
      if (marker.status_color_code === color) {
        return false;
      }
    }

    return true;
  }

  incrementOne = (value) => {
    let current = Number(value);
    let changeValue = 0;
    let toFix = 0;
    if (!current || (current < 0)) changeValue = 1;
    else if (!current.toString().includes('.')) changeValue = 1;
    else {
      let c = current.toString().split('.');
      let length = c[1].length;
      toFix = length;
      changeValue = 1 / (10 ** length);
    }
    changeValue = Number(changeValue);
    return (current + changeValue).toFixed(toFix);
  }

  render() {
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;
    let { editIndex } = this.state;

    const { arrBiomarkRange } = this.state;
    const markerLength = arrBiomarkRange.length;
    const viewMode = this.props.viewMode;

    return (
      <div>
        {!viewMode ? (
          <div>
            <div className="form-row">
              <div className="form-group col">
                <label>Label (English) *</label>
                <input
                  type="text"
                  name="status_name"
                  className={
                    "form-control " +
                    (validation.status_name.isInvalid && "has-error")
                  }
                  placeholder="Label in English"
                  onChange={this.handleChange}
                  value={this.state.status_name}
                ></input>
                <span className="help-block">
                  {validation.status_name.message}
                </span>
              </div>
              <div className="form-group col">
                <label>Label (Arabic) *</label>
                <input
                  type="text"
                  name="status_name_ar"
                  className={
                    "form-control input-arabic " +
                    (validation.status_name_ar.isInvalid && "has-error")
                  }
                  placeholder="Label in Arabic"
                  onChange={this.handleChange}
                  value={this.state.status_name_ar}
                ></input>
                <span className="help-block">
                  {validation.status_name_ar.message}
                </span>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col">
                <label>Starting Point</label>
                <input
                  type="number"
                  name="range_start"
                  className="form-control"
                  placeholder={
                    this.props.unit ? this.props.unit : "Starting Point"
                  }
                  onChange={this.handleChange}
                  value={this.state.range_start}
                  disabled={
                    editIndex === 0
                      ? false
                      : this.props.arrBioMark.length
                        ? true
                        : false
                  }
                ></input>
              </div>
              <div className="form-group col">
                <label>Ending Point</label>
                <input
                  type="number"
                  name="range_end"
                  className="form-control"
                  placeholder={
                    this.props.unit ? this.props.unit : "Ending Point"
                  }
                  onChange={this.handleChange}
                  value={this.state.range_end}
                ></input>
              </div>
            </div>
            {this.state.alertMessage ? (
              <Alert
                dismissible
                onClose={() => {
                  this.setState({ alertMessage: "" });
                }}
                variant="danger"
              >
                {this.state.alertMessage}
              </Alert>
            ) : null}
            <div className="form-row">
              <div className="form-group col">
                <label>Color *</label>
                <div className="custom-check-box bio-mark-color-picker">
                  {this.bioMarkColorOptions.map((value, index) => {
                    return (
                      <div key={index} className="color-container">
                        <input
                          type="checkbox"
                          name="bioMarkerForFemale"
                          onChange={this.handleCheckBoxChange}
                          value={value}
                          disabled={!this.colorAvailable(value)}
                          checked={this.state.status_color_code === value}
                        />
                        <label style={{ backgroundColor: value }}></label>
                      </div>
                    );
                  })}
                </div>
                <span className="help-block">
                  {validation.status_color_code.message}
                </span>
              </div>
              <div className="form-group col button-col">
                <button
                  className="btn cancel-button btn-outline-danger"
                  onClick={this.formReset}
                >
                  Reset
                </button>
                {editIndex !== null ? (
                  <button
                    className="btn btn-outline-primary"
                    onClick={this.updateRange}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="btn btn-outline-primary"
                    disabled={
                      this.bioMarkColorOptions.length === markerLength ||
                      (arrBiomarkRange[markerLength - 1]
                        ? arrBiomarkRange[markerLength - 1].range_end
                          ? false
                          : true
                        : false)
                    }
                    onClick={this.addNewRange}
                  >
                    Add
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.props.arrBioMark.length ? (
          <div className="row table-row">
            <table className="table table-hover table-bordered table-striped biomarker-table mt-3">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Label</th>
                  <th scope="col">Color</th>
                  <th scope="col">Starting Point</th>
                  <th scope="col">Ending Point</th>
                  <th scope="col" className="text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.arrBioMark.map((value, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{value.status_name}</td>
                      <td>
                        <div
                          className="colorBox"
                          style={{ backgroundColor: value.status_color_code }}
                        ></div>
                      </td>
                      <td>
                        {value.range_start ? (
                          index === 0 ? value.range_start : this.incrementOne(value.range_start) + " " + this.props.unit
                        ) : (0 + " " + this.props.unit
                          // <>-&#8734;</>
                        )}
                      </td>
                      <td>
                        {value.range_end || value.range_end === 0 ? (
                          value.range_end + " " + this.props.unit
                        ) : (
                          <>&#8734;</>
                        )}
                      </td>
                      <td className="text-center">
                        <ActionBar
                          alignRight
                          className="action-menu valeo-dropdown"
                        >
                          <ActionBar.Toggle variant="">
                            <MoreHorizOutlinedIcon></MoreHorizOutlinedIcon>
                          </ActionBar.Toggle>
                          <ActionBar.Menu>
                            <ActionBar.Item
                              disabled={viewMode ? true : false}
                              onClick={() => this.editBiomark(index)}
                            >
                              <EditIcon className="icon-small"></EditIcon>Edit 
                            </ActionBar.Item>
                            <ActionBar.Item
                                className="danger"
                                onClick={() => this.handleDeleteData(value)}
                            >
                                <DeleteIcon></DeleteIcon>
                                Delete
                            </ActionBar.Item>
                          </ActionBar.Menu>
                        </ActionBar>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showErrorMessage: (value) => dispatch({ type: "SHOW_NOTIFICATION", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BioMarkForm);
