import React, { Component } from "react";
import API from "utils/API";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import moment from "moment";
import { getUserType } from "services/UserService";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { Dropdown } from "semantic-ui-react";
import $ from "jquery";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { formatDateTime } from "utils/commons";
import WalletTransactions from "components/Common/WalletTransactions";
import PhoenixAPI from "utils/PhoenixAPI";
import ClientTransactions from "components/Common/ClientTransactions";
import ClientSubscriptions from "./ClientSubscriptions";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import FamilyMembers from "./FamilyMembers";
import PdfPreview from "components/Common/PdfPreview";
import SelectCity from "components/Common/SelectCity";
import SelectCountry from "components/Common/SelectCountry";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const HEIGHT_METRICS = ["cm", "cms"];
const WEIGHT_METRICS = ["kg", "kgs", "lb", "lbs"]
const USER_CREATED_BY = "ADMIN_PANEL";
const TWO_MEGA_BYTE = 2_097_152;
const TEN_MEGA_BYTE = TWO_MEGA_BYTE * 5;

const COMMUNICATION_OPTIONS = [
  {
    key: true,
    value: true,
    text: "Yes",
  },
  {
    key: false,
    value: false,
    text: "No",
  },
];

const NATIONALITY_OPTIONS = [
  {
    key: "saudi",
    value: "saudi",
    text: "Saudi",
  },
  {
    key: "expats",
    value: "expats",
    text: "Expats",
  },
]

class Clients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      users: null,
      isLoaded: false,
      loadError: "",
      searchExpanded: true,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      userType: getUserType(),
      showModal: false,
      newUser: false,
      modalData: {},
      key: "personal",
      userTransactions: [],
      userEmail: "",
      userName: "",
      userPassword: "",
      label: "",
      address_line1: "",
      address_line2: "",
      city: "",
      building_apt: "",
      country: "",
      firstname: "",
      editFn: false,
      editLn: false,
      editEmail: false,
      editPhoneNo: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      cities: [],
      countries: [],
      clientNoteErrorMessage: "",
      fnErrorMessage: "",
      lnErrorMessage: "",
      emailErrorMessage: "",
      contactNoErrorMessage: "",
      passwordErrorMsg: "",
      heightErrorMessage: "",
      weightErrorMessage: "",
      genderErrorMessage: "",
      addressErrorMessages: [],
      heightMetric: "cm",
      weightMetric: "kg",
      editables: {},
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "id",
      sortDirection: "desc",
      front_id:null,
      back_id:null,
      fullNameIdErrorMessage:"",
      frontIdImageErrorMessage: "",
      backIdImageErrorMessage: "",
      whatsAppNoErrorMessage:"",
      isShowNewAddressModal: false,
      errors:{},
      newAddress:{},
      selectedCountry: null,
      selectedCity: null,
    };

    this.columns = [
      {
        name: "User ID",
        selector: "id",
        sortable: true,
        sortField: 'id',
        width: "10%"
        // width: "120px"
      },
      {
        name: "Name",
        selector: "username",
        sortable: true,
        wrap: true,
        width: "20%",
        // width: "250px",
        sortField: 'username',
        format: (client) => this.getName(client),
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
        width: "20%",
        // width: "250px",
        sortField: 'email',
      },
      {
        name: "Contact No",
        selector: "phone_no",
        sortable: false,
        width: "20%",
        // width: "150px"
      },
      // {
      //   name: "Height",
      //   selector: "user_profile.height",
      //   format: row => row.user_profile?.height !== null ? row.user_profile?.height : ""

      // }, {
      //   name: "Weight",
      //   selector: "user_profile.weight",
      //   format: row => row.user_profile?.weight !== null ? row.user_profile?.weight : ""
      // },
      {
        name: "Joining Date",
        selector: "date_joined",
        sortable: true,
        sortField: 'date_joined',
        width: "20%",
        // width: "150px",
        format: (row) => row.date_joined ? formatDateTime(row.date_joined) : "-",
      },
      // {
      //   name: "Last Login",
      //   selector: "last_login",
      //   sortable: true,
      //   format: row => row.last_login != null ? moment(row.last_login).format("DD-MM-YYYY, hh:MM A") : "-"
      // },
      {
        name: "View",
        selector: "id",
        sortable: false,
        width: "10%",
        format: (row) => (
          <button
            className="btn button-blue btn-md"
            onClick={(e) => {
              this.showModal(row)
            }}
          >
            View
          </button>
        ),
      },
    ];

    this.transactionColumns = [
      {
        name: "Date",
        selector: "transaction_date",
        sortable: true,
        width: "180px",
      },
      {
        name: "Order ID",
        selector: "order",
        sortable: true,
        width: "120px",
      },
      {
        name: "Package",
        selector: "package",
        sortable: true,
        width: "300px",
      },
      {
        name: "Price",
        selector: "final_amount",
        sortable: true,
        width: "120px",
      },
    ];

    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.handleSearchEnter = this.handleSearchEnter.bind(this);
    this.onUserPageChange = this.onUserPageChange.bind(this);
    // this.customSort = this.customSort.bind(this);
    this.getHeightMetric = this.getHeightMetric.bind(this);
    this.getWeightMetric = this.getWeightMetric.bind(this);
    this.getHeight = this.getHeight.bind(this);
    this.getWeight = this.getWeight.bind(this);
  }

  componentDidMount() {
    // this.loadClients();
    this.fetchAclUser();
    this.fetchClients();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
   if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.CLIENTS;
      const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
      const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
      const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.state !== undefined &&
      this.props.location.state !== null
    ) {
      if (
        prevProps.location.state.length !== this.props.location.state.length
      ) {
        // this.loadClients();
        this.fetchClients();
      }
    }
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }

  getName = (client) => {
    // console.log("Clients:: getName:", client);
    if (!client) return "";
    const { username, first_name, last_name } = client;
    return username ? username : `${first_name ?? ""} ${last_name ?? ""}`.trim();
  }

  onUserPageChange() {
    if (this.state.nextURL) {
      let url = this.state.nextURL;
      let nextURL = "";
      let orderResonse = [];

      this.props.toggleLoading({
        isLoading: true,
      });
      API.get(url)
        .then((response) => {
          nextURL =
            response.data && response.data.next ? response.data.next : "";
          orderResonse =
            response.data && response.data.results ? response.data.results : [];
          let allClient = [...this.state.users, ...orderResonse];
          let totalPage = response.data && response.data.count ? response.data.count : 0;
          this.setState({
            users: allClient,
            nextURL,
            nextURL,
            isLoaded: true,
            totalPage: totalPage,
          });
          this.props.toggleLoading({
            isLoading: false,
          });
        })
        .catch((error) => {
          console.log("Error on fetching data", error);
          this.setState({
            loadError: "Some error has occured. Please try again",
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        });
    }
  }


  // Not needed, now we are using fetchClients method.
  // loadClients() {
  //   this.props.toggleLoading({
  //     isLoading: true,
  //   });

  //   let url;
  //   let orderResonse = [];
  //   let nextURL;
  //   const userType = this.state.userType;
  //   // if (this.state.currentURL) {
  //   //   url = this.state.currentURL
  //   // } else {
  //   if (userType === "nutritionist") url = "list-active-coach-clients/";
  //   else url = "list-active-user/";
  //   API.get(url)
  //     .then((response) => {
  //       nextURL = response.data && response.data.next ? response.data.next : "";
  //       orderResonse =
  //         response.data && response.data.results ? response.data.results : [];
  //       let totalPage = response.data && response.data.count ? response.data.count : 0;
  //       this.setState({
  //         users: orderResonse,
  //         nextURL,
  //         nextURL,
  //         isLoaded: true,
  //         totalPage: totalPage,
  //       });
  //       this.props.toggleLoading({
  //         isLoading: false,
  //       });
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         loadError: "Some error has occured. Please try again",
  //       });
  //       this.props.showNotificationMessage({
  //         notificationMessage: "Some error has occured. Please try again.",
  //         successMessage: false,
  //         showNotification: true,
  //       });
  //     });
  // }

  // loadOrders() {
  //   // let url = "list-active-user/"
  //   let url;
  //   if (this.state.userType === "nutritionist") url = "list-active-coach-clients/";
  //   else url = "list-active-user/";
  //   let orders = [];
  //   let orderResonse = [];
  //   let nextURL;

  //   if (this.state.sortField) {
  //     let fieldOrder = this.state.sortDirection == 'asc' ? this.state.sortField : ('-' + this.state.sortField);
  //     if (this.state.sortField == 'id,username,email,phone_no' && this.state.sortDirection == 'desc') {
  //       fieldOrder = "-id,-username,-email,-phone_no"
  //     }
  //     url = url + "?ordering=" + fieldOrder
  //   }
  //   if (this.state.searchValue && this.state.sortField) {
  //     url = url + "&search=" + this.state.searchValue
  //   }
  //   if (this.state.searchValue && !this.state.sortField) {
  //     url = url + "?search=" + this.state.searchValue
  //   }
  //   this.props.toggleLoading({
  //     isLoading: true,
  //   });

  //   API.get(url)
  //     .then((response) => {
  //       nextURL = response.data && response.data.next ? response.data.next : "";
  //       orderResonse =
  //         response.data && response.data.results ? response.data.results : [];
  //       for (let order of orderResonse) {
  //         orders.push(order);
  //       }

  //       let totalPage = response.data && response.data.count ? response.data.count : 0;
  //       // if (this.state.searchApplied) {
  //       //   this.setState({ results: orders, isLoaded: true, nextURL: nextURL, currentURL: url, totalPage: totalPage });
  //       // } else {
  //       this.setState({ results: orders, resultCount: orders.length, isLoaded: true, nextURL: nextURL, currentURL: url, totalPage: totalPage });
  //       // }

  //       this.props.toggleLoading({
  //         isLoading: false,
  //       });
  //     })
  //     .catch((error) => {
  //       this.setState({
  //         loadError: "Some error has occured. Please try again",
  //       });
  //       this.props.showNotificationMessage({
  //         notificationMessage: "Some error has occured. Please try again.",
  //         successMessage: false,
  //         showNotification: true,
  //       });
  //     });
  // }

  handleSearch = (value) => {
    let newValue, list = [];

    this.setState({ searchTerm: value });

    newValue = value.slice(0).trim().toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        // searchExpanded: false
      });
      return;
    }

    list = this.state.users;
    if (!list) return;

    let newList = [];
    this.setState({
      searchValue: newValue,
    });
  }

  handleSearchEnter() {
    this.setState({
      searchExpanded: true,
      searchApplied: true,
    }, () => {
      // this.loadOrders();
      this.fetchClients();
    });
  }

  // Not needed, now we are using handleSort method.
  // customSort = (column, sortDirection) => {
  //   let sortField = column.sortField;
  //   this.setState(
  //     { sortField: sortField, sortDirection: sortDirection },
  //     () => {
  //       this.loadOrders();
  //     }
  //   );
  // };

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchValue: '',
      searchApplied: false,
    }, () => {
      // this.loadOrders();
      this.fetchClients();
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        // searchExpanded: false,
        searchApplied: false,
        searchValue: '',
      });

      return;
    } else {
      this.setState({
        // searchExpanded: true
      });
    }
  }

  findCountryByName = (name) => {
    const { countries = [] } = this.state;
    return countries.find(country => country.country_name === name || country.country_name_ar === name);
  }

  findCityByName = (countryName,cityName) => {
    const { countries=[] } = this.state;
    const selectedCountry= countries.find(country => country.country_name === countryName || country.country_name_ar === countryName)
    const cities = selectedCountry.cities;
    return cities.find(city => city.city_name === cityName || city.city_name_ar === cityName);
  }

  createNewUser = () => {
    const addressErrorMessages = [this.createEmptyAddressErrorMessageObject()];
    this.setState({ showModal: true, newUser: true, addressErrorMessages, modalData: { unsubscribe_communication: false } }, () => {
      this.showModal({});
    });
  };

  hideModal = () => {
    this.setState({
      showModal: false,
      editFn: false,
      editLn: false,
      editEmail: false,
      editPhoneNo: false,
      editGender: false,
      editDob: false,
      editHeight: false,
      editWeight: false,
      key: "personal",
      newUser: false,
      modalData: {},
      clientNoteErrorMessage: "",
      fnErrorMessage: "",
      lnErrorMessage: "",
      emailErrorMessage: "",
      passwordErrorMsg: "",
      genderErrorMessage: "",
      contactNoErrorMessage: "",
      heightErrorMessage: "",
      weightErrorMessage: "",
      country: "",
      city: "",
      addressErrorMessages: [],
      editables: {},
      label: "",
      address_line1: "",
      address_line2: "",
      building_apt: "",
      city: "",
      country: "",
      address_phone_no: "",
      front_id:null,
      back_id:null,
      fullNameIdErrorMessage:"",
      frontIdImageErrorMessage: "",
      backIdImageErrorMessage: "",
      userName:"",
      userEmail:"",
      userPassword:"",
      userProfileData:{},
      cities:[],
      whatsAppNoErrorMessage:"",
      selectNationalityErrorMessage:""
    });
    // this.clearForm();
  };

  showModal = (row) => {
    // console.log("Selected Row: ", row);
    API.get("list-countries-cities/")
      .then((response) => {
        // console.log("Countries Response", response)
        this.setState({ countries: response.data })
      })
      .catch((error) => {
        console.log("Error on fetching list countries cities", error);
      })

    let tempRow = { ...row }
    if (!this.state.newUser) {
      if (tempRow.user_address && tempRow.user_address.length === 0) {
        tempRow["user_address"] = [{ label: "", address_line1: "", address_line2: "", city: "", country: "", building_apt: "", map_url: "", lat: "", lng: "", }]
        this.setState({ modalData: tempRow })
      } else {
        this.setState({ modalData: tempRow })
      }
      if (row.user_address) {
        row.user_address.map(addr => {
          let cID = this.state.countries.filter(country => country.country_name === addr.country);
          if (cID.length) {
            let res = cID[0];
            this.setState({ cities: res.cities })
          }
        })
      }
      if (tempRow.front_id || tempRow.front_id_s3_url) {
        this.setState({
          front_id: tempRow.front_id_s3_url ?? tempRow.front_id
        })
      }
      if (tempRow.back_id || tempRow.back_id_s3_url) {
        this.setState({
          back_id: tempRow.back_id_s3_url ?? tempRow.back_id
        })
      }
    } else {
      tempRow["user_address"] = [{ label: "", address_line1: "", address_line2: "", city: "", country: "", building_apt: "", map_url: "", lat: "", lng: "", }]
      this.setState({ modalData: tempRow })
    }

    if (this.state.newUser) {
      if ($(".modal-backdrop").length > 0 && this.state.showModal) {
        setTimeout(function () {
          $(".modal-backdrop").addClass("modal-backdrop-updated");
        });
      }
    } else {
      const name = row.username;
      const email = row.email;

      this.setState(
        {
          showModal: true,
          // modalData: row,
          userName: name,
          userEmail: email,
          userTransactions: row.user_transaction_details,
          userProfileData: row.userprofile,
        },
        () => {
          if (this.state.modalData.user_address) {
            if (this.state.modalData.user_address.length) {
              this.state.modalData.user_address.map((item, i) => {
                let keysInObject = Object.keys(item);
                keysInObject.map((val) => {
                  this.setState({
                    [val + i]: false,
                  });
                });
              });
            }
          }
          if (this.state.userProfileData && this.state.userProfileData !== null && this.state.userProfileData !== undefined) {
            if (this.state.userProfileData.height) {
              this.setState({ heightMetric: this.state.userProfileData.height.split(/(\s+)/)[2] })
            }
            if (this.state.userProfileData.weight) {
              this.setState({ weightMetric: this.state.userProfileData.weight.split(/(\s+)/)[2] })
            }
          }
          if ($(".modal-backdrop").length > 0 && this.state.showModal) {
            setTimeout(function () {
              $(".modal-backdrop").addClass("modal-backdrop-updated");
            });
          }
        }
      );
    }

    const addressErrorMessages = [];
    const userHasAddresses = row && row.user_address && row.user_address.length !== 0;
    if (userHasAddresses)
      row.user_address.forEach(address => addressErrorMessages.push(this.createEmptyAddressErrorMessageObject()))
    else
      addressErrorMessages.push(this.createEmptyAddressErrorMessageObject());

    this.setState({ addressErrorMessages });
  };

  makeEditable = (key) => {
    this.setState({
      [key]: true,
    });
  };

  numberCheck = async (value, data) => {
    if (data.dialCode !== undefined && data.dialCode !== null && value !== "") {
      if (value.slice(data.dialCode.length).length > 6) {
        this.setState({
          modalData: {
            ...this.state.modalData,
            phone_no: `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
          contactNoErrorMessage: "",
        });
      } else {
        this.setState({
          modalData: {
            ...this.state.modalData,
            phone_no: `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
        });
      }
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          phone_no: "",
        },
        contactNoErrorMessage: "Invalid Phone Number"
      })
    }
  };

  numberCheckForWhatsApp = async (value, data) => {
    if (data.dialCode !== undefined && data.dialCode !== null && value !== "") {
      if (value.slice(data.dialCode.length).length > 6) {
        this.setState({
          modalData: {
            ...this.state.modalData,
            whatsapp_number: `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
          whatsAppNoErrorMessage: "",
        });
      } else {
        this.setState({
          modalData: {
            ...this.state.modalData,
            whatsapp_number: `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
        });
      }
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          whatsapp_number: "",
        },
        whatsAppNoErrorMessage: "Invalid Phone Number"
      })
    }
  };

  numberCheckForAddress = async (value, data, index) => {
    const { addressErrorMessages = [] } = this.state;
    if (addressErrorMessages.length < index) return;

    const addressErrorMessage = addressErrorMessages[index];
    let phoneNumber;
    // console.log("Phone Number: value: ", value , " data: ", data);
    if (!value || value === "") {
      phoneNumber = null;
      addressErrorMessage["contactNoErrorMessage"] = "";
    } else if (data.dialCode !== undefined && data.dialCode !== null && value !== "") {
      if (value.slice(data.dialCode.length).length > 6) {
        addressErrorMessage["contactNoErrorMessage"] = "";
        phoneNumber = `+${data.dialCode}` + value.slice(data.dialCode.length);
      } else {
        phoneNumber = `+${data.dialCode}` + value.slice(data.dialCode.length);
      }
    } else {
      addressErrorMessage["contactNoErrorMessage"] = "Invalid Phone Number!";
      phoneNumber = "";
    }
    const newAddressErrorMessages = addressErrorMessages.map((it, i) => i === index ? addressErrorMessage : it);
    // addressErrorMessages.splice(index, 0, addressErrorMessage);
    this.setState({ addressErrorMessages: newAddressErrorMessages });

    if (this.state.newUser) {
      this.setState({ address_phone_no: phoneNumber });
      let modalData = { ...this.state.modalData };
      modalData["user_address"][index]["phone_no"] = phoneNumber;
      this.setState({ modalData });
    } else {
      const modalData = { ...this.state.modalData };
      if (modalData.user_address.length === 0) {
        modalData["user_address"] = [{ label: "", address_line1: "", address_line2: "", city: "", country: "", building_apt: "", phone_no: "", }];
        modalData["user_address"][index]["phone_no"] = phoneNumber;
        this.setState({ modalData });
      }
      else {
        modalData["user_address"][index]["phone_no"] = phoneNumber;
        this.setState({ modalData });
      }
    }
  };

  handleHeightandWeight = (e, data) => {
    if (data === "heightMetric") {
      if (e.target.textContent !== this.state.heightMetric) {
        this.setState({ [data]: e.target.textContent });
      }
    } else {
      if (e.target.textContent !== this.state.weightMetric) {
        this.setState({ [data]: e.target.textContent });
      }
    }
  };


  handleInput = (e, data, pVal) => {

    if (data === "dob") {
      this.setState({
        modalData: {
          ...this.state.modalData,
          userprofile: {
            ...this.state.modalData.userprofile,
            [data]: e.target.value,
          },
        },
        dobErrorMessage: ""
      });
    } else if (data === "height" || data === "weight") {
      let { value, min, max } = e.target;
      // console.log("Target:", e.target);
      // value = value && Math.max(Number(min), Math.min(Number(max), Number(value)));
      this.setState({
        modalData: {
          ...this.state.modalData,
          userprofile: {
            ...this.state.modalData.userprofile,
            [data]: value
          },
        },
        heightErrorMessage: "",
        weightErrorMessage: "",
      });
    } else if (data === "gender") {
      let val;
      if (e.target.textContent === "Male") {
        val = "M";
      } else {
        val = "F";
      }
      this.setState({
        modalData: {
          ...this.state.modalData,
          userprofile: {
            ...this.state.modalData.userprofile,
            [data]: val,
          },
        },
        genderErrorMessage: ""
      });
    } else if (data === "password") {
      this.setState({ passwordErrorMsg: "", userPassword: e.target.value });
    } else if (
      data.includes("label") ||
      data.includes("address_line2") ||
      data.includes("building_apt") ||
      data.includes("address_line1") ||
      data.includes("city") ||
      data.includes("country") ||
      data.includes("map_url") ||
      data.includes("lat") ||
      data.includes("lng")||
      data.includes("countryName")
    ) {
      let dataKey = data;
      const stringLength = dataKey.length;
      const patterns = [
        "label",
        "address_line2",
        "building_apt",
        "address_line1",
        "city",
        "country",
        "map_url",
        "lat",
        "lng",
        "countryName"
      ];
      console.log("check string length: " , stringLength,dataKey);
      let fetchIndex = null;
      patterns.forEach((pattern) => {
        if (data.startsWith(pattern)) {
          const stringLength = pattern.length;
          fetchIndex = data.substring(stringLength); // Extract digits following the matched pattern
          console.log(`Pattern "${pattern}" found. Number extracted: ${fetchIndex}`);
        }
      });
      //let fetchIndex = dataKey.charAt(stringLength - 1);
      
      let getOnlyTheKey = data.replace(fetchIndex, "");
      // let findCountryId;
      // if(getOnlyTheKey === "city") {
      //   findCountryId  = this.state.cities.filter(city => 
      //     city.city_name === pVal.value
      //   );
      // }
      // if(findCountryId) {
      //   let cID = this.state.countries.filter(country => country.id === findCountryId[0].country.id);
      //   let res = cID[0];
      //   this.setState({country : res.country_name})
      // }
      if (getOnlyTheKey === "country") {
        let cID = this.state.countries.filter(country => country.country_name === pVal.value);
        if (cID.length) {
          let res = cID[0];
          this.setState({ cities: res.cities })
        }
      }

      // if (getOnlyTheKey === "city" || getOnlyTheKey === "country") {
      //   e.target.value = e.target.textContent;
      // }

      if (getOnlyTheKey === "country") {
        e.target.value = e.target.textContent;
        let modalData = { ...this.state.modalData };
        modalData["user_address"][fetchIndex][getOnlyTheKey] = e.target.value;
        modalData["user_address"][fetchIndex]["city"] = null;
        this.setState({ modalData })
      }

      if (getOnlyTheKey === "city") {
        e.target.value = e.target.textContent;
        let modalData = { ...this.state.modalData };
       
        modalData["user_address"][fetchIndex][getOnlyTheKey] = pVal.value; /// changed  e.target.value; to pVal.value
        this.setState({ modalData})
      }
      if(getOnlyTheKey==="countryName"){
        getOnlyTheKey="country";
      }
      if(getOnlyTheKey==="cityName"){
        getOnlyTheKey="city";
      }

      if (this.state.newUser) {
        let modalData = { ...this.state.modalData };
        if(getOnlyTheKey === "city"){
         
          modalData["user_address"][fetchIndex][getOnlyTheKey] = pVal.value;
        }
        else{
          modalData["user_address"][fetchIndex][getOnlyTheKey] = e.target.value;
        }
        
        this.setState({
          modalData,
          [getOnlyTheKey]: e.target.value,
          buildingErrorMessage: "",
          labelErrorMessage: "",
          cityErrorMessage: "",
          countryErrorMessage: "",
        })
      } else {
        let modalData = { ...this.state.modalData };
        if (modalData.user_address.length === 0) {
          modalData["user_address"] = [{ label: "", address_line1: "", address_line2: "", city: "", country: "", building_apt: "" }];
          if(getOnlyTheKey === "city"){
         
            modalData["user_address"][fetchIndex][getOnlyTheKey] = pVal.value;
          }
          else{
            console.log("addresss is",e.target.value);
            modalData["user_address"][fetchIndex][getOnlyTheKey] = e.target.value;
          }
         
          this.setState({
            modalData
          })
        }
        else {
          if(getOnlyTheKey === "city"){
            modalData["user_address"][fetchIndex][getOnlyTheKey] = pVal.value;
          }
          else{
            console.log("addresss is",e.target.value,fetchIndex,getOnlyTheKey);
            modalData["user_address"][fetchIndex][getOnlyTheKey] = e.target.value;
          }
          console.log("user address is",modalData.user_address,fetchIndex) ;
          this.setState({
            modalData
          })
        }
      }
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [data]: e.target.value,
        },
        emailErrorMessage: "",
        // fnameErrorMessage: "",
        // lnameErrorMessage: "",
        fnErrorMessage: "",
        lnErrorMessage: "",
      });
    }
  };

  createEmptyAddressErrorMessageObject = () => ({
    labelErrorMessage: "",
    areaErrorMessage: "",
    buildingErrorMessage: "",
    streetErrorMessage: "",
    contactNoErrorMessage: "",
    cityErrorMessage: "",
    countryErrorMessage: "",
  });

  validateClientsNotes = (clientNotes) => {
    if (clientNotes && clientNotes.length > 10000) {
      this.setState({ clientNoteErrorMessage: "Invalid Client's Notes! (can not be longer than 10000 characters)" });
      return false;
    } else {
      this.setState({ clientNoteErrorMessage: "" });
      return true;
    }
  }

  validateFirstName = (firstName) => {
    const ARABIC_TEXT = /[\u0600-\u06FF\u0750-\u077F]/;

    if (!firstName || firstName.trim() === "") {
      this.setState({ fnErrorMessage: "Firstname is a required field!" });
      return false;
    } else if (ARABIC_TEXT.test(firstName)) {
      this.setState({ fnErrorMessage: "" });
      return true;
    } else if (!firstName.split(/[ .]+/).every(name => /^[a-zA-Z]*$/g.test(name))) {
      this.setState({ fnErrorMessage: "Invalid Firstname!" });
      return false;
    } else if (firstName.length > 50) {
      this.setState({ fnErrorMessage: "Invalid Firstname! (can not be longer than 50 characters)" });
      return false;
    } else {
      this.setState({ fnErrorMessage: "" });
      return true;
    }
  }

  validateLastName = (lastName) => {
    const ARABIC_TEXT = /[\u0600-\u06FF\u0750-\u077F]/;

    if (!lastName || lastName.trim() === "") {
      this.setState({ lnErrorMessage: "Lastname is a required field!" });
      return false;
    } else if (ARABIC_TEXT.test(lastName)) {
      this.setState({ lnErrorMessage: "" });
      return true;
    } else if (!lastName.split(/[ .]+/).every(name => /^[a-zA-Z]*$/g.test(name))) {
      this.setState({ lnErrorMessage: "Invalid Lastname!" });
      return false;
    } else if (lastName.length > 50) {
      this.setState({ lnErrorMessage: "Invalid Lastname! (can not be longer than 50 characters)" });
      return false;
    } else {
      this.setState({ lnErrorMessage: "" });
      return true;
    }
  }

  validateEmail = (email) => {
    const pattern = new RegExp(/^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/);
    if (!email || email.trim() === "") {
      this.setState({ emailErrorMessage: "Email is a required field!" });
      return false;
    }
    else if (!pattern.test(email)) {
      this.setState({ emailErrorMessage: "Invalid email address!" });
      return false;
    }
    else {
      this.setState({ emailErrorMessage: "" });
      return true;
    }
  }

  validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.trim() === "") {
      this.setState({ contactNoErrorMessage: "Phone Number is a required field" })
      return false
    }
    else if (phoneNumber && phoneNumber.length <= 11) {
      this.setState({ contactNoErrorMessage: "Invalid Phone number!" });
      return false;
    } else {
      this.setState({ contactNoErrorMessage: "" });
      return true;
    }
  }

  validateWhatsAppNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.trim() === "") {
      this.setState({ whatsAppNoErrorMessage: "WhatsApp Number is a required field" })
      return false
    }
    //  else if (phoneNumber && phoneNumber.length <= 11) {
    //     this.setState({ whatsAppNoErrorMessage: "Invalid WhatsApp number!" });
    //     return false;
    //   } 
    else {
      this.setState({ whatsAppNoErrorMessage: "" });
      return true;
    }
  }

  validatePassword = (password) => {
    if (!password || password.trim() === "") {
      this.setState({ passwordErrorMsg: "Password is a required field!" });
      return false;
    } else if (!/^[a-zA-Z0-9_@./#&+-]*$/g.test(password)) {
      this.setState({ passwordErrorMsg: "Invalid Password!" });
      return false;
    } else if (password.length < 8) {
      this.setState({ passwordErrorMsg: "Invalid Password! (can not be smaller than 8 characters)." });
      return false;
    } else {
      this.setState({ passwordErrorMsg: "" });
      return true;
    }
  }

  validateAddress = ({
    label,
    address_line1: area,
    building_apt: building,
    address_line2: street,
    city: city,
    country: country
  }, index) => {
    const ARABIC_TEXT = /[\u0600-\u06FF\u0750-\u077F]/;
    const ARABIC_TEXT_WITH_NUMBERS = /[\u0600-\u06FF\u0750-\u077F\u0660-\u0669]/;
    const ENGLISH_TEXT = /^[a-zA-Z\s]+$/g;
    const ENGLISH_TEXT_WITH_NUMBERS = /^[a-z\d\-_\s]+$/i;

    console.log("Validating Address", index,building);

    const addressErrorMessage = this.state.addressErrorMessages[index];
    let isValidLabel, isValidArea, isValidBuilding, isValidStreet, isValidCity, isValidCountry = false;

    // if (label && !ENGLISH_TEXT.test(label) && !ARABIC_TEXT.test(label))
    //   addressErrorMessage["labelErrorMessage"] = "Label can only contain text!";
    if (label && label.length > 500)
      addressErrorMessage["labelErrorMessage"] = "Invalid Label! (can not be longer than 500 characters).";
    else {
      addressErrorMessage["labelErrorMessage"] = "";
      isValidLabel = true;
    }

    // if (area && !ENGLISH_TEXT_WITH_NUMBERS.test(area) && !ARABIC_TEXT_WITH_NUMBERS.test(area))
    //   addressErrorMessage["areaErrorMessage"] = "Area can only contain text and numbers!";
    
      
    
    if (area && area.length > 500)
      addressErrorMessage["areaErrorMessage"] = "Invalid Area! (can not be longer than 500 characters).";
    else {
      addressErrorMessage["areaErrorMessage"] = "";
      isValidArea = true;
    }


    // if (building && !ENGLISH_TEXT_WITH_NUMBERS.test(building) && !ARABIC_TEXT_WITH_NUMBERS.test(building))
    //   addressErrorMessage["buildingErrorMessage"] = "Building can only contain text and numbers!";
   
      
    if (building && building.length > 500)
      addressErrorMessage["buildingErrorMessage"] = "Invalid Building! (can not be longer than 500 characters).";
    else {
      addressErrorMessage["buildingErrorMessage"] = "";
      isValidBuilding = true;
    }
  

    // if (street && !ENGLISH_TEXT_WITH_NUMBERS.test(street) && !ARABIC_TEXT_WITH_NUMBERS.test(street))
    //   addressErrorMessage["streetErrorMessage"] = "Street can only contain text and numbers!";
   
    if (street && street.length > 500)
      addressErrorMessage["streetErrorMessage"] = "Invalid Street! (can not be longer than 500 characters).";
    else {
      addressErrorMessage["streetErrorMessage"] = "";
      isValidStreet = true;
    }
  
    if (!country) {
      addressErrorMessage["countryErrorMessage"] = "Select Country "
    }
    else {
      addressErrorMessage["countryErrorMessage"] = "";
      isValidCountry = true;
    }

    if (!city) {
      addressErrorMessage["cityErrorMessage"] = "Select City"
    }
    else {
      addressErrorMessage["cityErrorMessage"] = "";
      isValidCity = true;
    }

    const addressErrorMessages = [...this.state.addressErrorMessages];
    addressErrorMessages.splice(index, 0, addressErrorMessage);
    this.setState({ addressErrorMessages });
    console.log("valid area",isValidArea,isValidBuilding);
    return isValidLabel && isValidArea && isValidBuilding && isValidStreet && isValidCity && isValidCountry;
  }

  validateNationality=({
    city: city,
    country: country
  }, index)=>{
    console.log("Validating Address", index, country);
    const modalData= this.state.modalData || {};
    const {nationality=null}=modalData ;
    let  isValidNationality = false;
    let selectNationalityErrorMessage="";
    if (country==="Kingdom of Saudi Arabia" && index===0) {
      if(!nationality || nationality=="null"){
        selectNationalityErrorMessage = "Select Nationality" 
      }
      else {
        selectNationalityErrorMessage = "";
        isValidNationality = true;
      }
    }
    else {
      selectNationalityErrorMessage = "";
      isValidNationality = true;
    }
    this.setState({selectNationalityErrorMessage})
    return isValidNationality; 
  }

  validateHeight = (height) => {
    if (!height) return;

    const MIN_HEIGHT = 0;
    const MAX_HEIGHT = 300;
    if (/^[\u0660-\u0669]/.test(height)) {
      this.setState({ heightErrorMessage: "" });
      return true;
    } else if (Number.isNaN(Number(height))) {
      this.setState({ heightErrorMessage: `Invalid Height! should be a valid number.` });
      return false;
    } else if (this.notInBetween(height, MIN_HEIGHT, MAX_HEIGHT)) {
      this.setState({ heightErrorMessage: `Invalid Height! should be between (${MIN_HEIGHT}-${MAX_HEIGHT})` });
      return false;
    } else {
      this.setState({ heightErrorMessage: "" });
      return true;
    }
  }

  validateWeight = (weight) => {
    if (!weight) return;

    const MIN_WEIGHT = 0;
    const MAX_WEIGHT = 999;

    if (/^[\u0660-\u0669]/.test(weight)) {
      this.setState({ weightErrorMessage: "" });
      return true;
    } else if (Number.isNaN(Number(weight))) {
      this.setState({ weightErrorMessage: `Invalid Weight! should be a valid number.` });
      return false;
    } else if (this.notInBetween(weight, MIN_WEIGHT, MAX_WEIGHT)) {
      this.setState({ weightErrorMessage: `Invalid Weight! should be between (${MIN_WEIGHT}-${MAX_WEIGHT})` });
      return false;
    } else {
      this.setState({ weightErrorMessage: "" });
      return true;
    }
  }

  validateGender = (gender) => {
    if (!gender) {
      this.setState({ genderErrorMessage: "Gender is a required field!" });
      return false;
    } else {
      this.setState({ genderErrorMessage: "" });
      return true;
    }
  }

  validateFullNameId = (full_name_id) => {
    if (full_name_id && full_name_id.length > 100) {
      this.setState({ fullNameIdErrorMessage: "Full Name as per ID can not be more than 100 characters" });
      return false
    }
    else {
      this.setState({ fullNameIdErrorMessage: "" });
      return true;
    }
  }

  validateFrontId = (front_id) => {
    if (front_id && front_id.size) {
      if (front_id.type === "application/pdf" && front_id.size > TEN_MEGA_BYTE) {
        this.setState({ frontIdImageErrorMessage: "PDF/File should note be more than 10MB" });
        return false;
      } else if (front_id.size > TWO_MEGA_BYTE) {
        this.setState({ frontIdImageErrorMessage: "Image/File should not be more than 2MB" });
        return false;
      }
    }
    this.setState({ frontIdImageErrorMessage: "" })
    return true;
  }

  validateBackId = (back_id) => {
    if (back_id && back_id.size) {
      if (back_id.type === "application/pdf" && back_id.size > TEN_MEGA_BYTE) {
        this.setState({ backIdImageErrorMessage: "PDF/File should note be more than 10MB" });
        return false;
      } else if (back_id.size > TWO_MEGA_BYTE) {
        this.setState({ backIdImageErrorMessage: "Image/File should not be more than 2MB" });
        return false;
      }
    }
    this.setState({ backIdImageErrorMessage: "" })
    return true;
  }

  notInBetween = (value, start, end) => !this.inBetween(value, start, end);

  inBetween = (value, start, end) => value >= start && value <= end;

  createCustomerReferralWallet = async (customerData) => {
    const { id, user_address } = customerData ?? {};
    if (id !== null && id !== undefined && user_address && user_address.length !== 0) {
      let customerId = id;
      let countryName = user_address[0]["country"]
      let country = this.findCountryByName(countryName);
      if (customerId && country && country.id) {
        let countryId = country.id;
        try {
          const response = await PhoenixAPI.post(`api/v1/referrals/generate-and-create-wallet/customer/${customerId}/country/${countryId}`)
          console.log("Generate Create referral-wallet customer", response)
        }
        catch (error) {
          const WalletErrorMessage = error?.response?.data?.message;
          this.showErrorNotification("Some error occurred: " + WalletErrorMessage);
        }
      }
      else {
        this.showErrorNotification("Invalid Customer Id or Country Id");
      }
    }
    else {
      return;
    }
  }

  creatingCleverTapProfileUser = (data) => {
    console.log("creatingCleverTapProfileUser", data)
    const { id: userId = "", email = "" ,unsubscribe_communication=false } = data || {}
    if(!unsubscribe_communication){
      return (window.clevertap.onUserLogin.push({
        "Site": {
          "Identity": userId,
          "Email": email,
          "MSG-email": true,
          "MSG-push": true,
          "MSG-sms": true,
          "MSG-whatsapp": true,
        },
        "cart_data": []
      }))
    }
    else{
      return (window.clevertap.onUserLogin.push({
        "Site": {
          "Identity": userId,
          "Email": email,
          "MSG-email": false,
          "MSG-push": false,
          "MSG-sms": false,
          "MSG-whatsapp": false,
        },
        "cart_data": []
      }))
    }
 
  }

  createNewClient = async () => {

    this.showLoading();

    const formData = this.createFormData();
    const apiOptions = this.createApiOptions(
      "post",
      "list-active-user/",
      formData
    );
    try {
      const response = await API.request(apiOptions);
      // console.log("New Client Response: ", response);

      const data = response && response.data;
      const { message, status } = data;
      if (status === 400) {
        this.setState({ emailErrorMessage: message })
        this.showErrorNotification(message)
      }
      else {
        this.hideModal();
        this.setState({ emailErrorMessage: "" })
        this.creatingCleverTapProfileUser(data)
        this.createCustomerReferralWallet(data)
        this.showSuccessNotification("User Created Successfully")
        this.fetchClients();
      }
    }
    catch (error) {
      console.log("Error on saving client data", error);
      if (error.response && error.response.status !== 200) {
        if (error.response.data) {
          const { email, password } = error.response.data;
          if (email && email.length !== 0)
            this.setState({ emailErrorMessage: email.join(", ") });
          if (password && password.length !== 0)
            this.setState({ passwordErrorMsg: password.join(", ") });
        }
        this.showErrorNotification("Some error has occured. Cause of the error is " + error)
      }
    }
  }

  updateClient = async () => {

    this.showLoading();
    const { modalData } = this.state;
    const updatedModalData = {
      ...modalData,
      userprofile: {
        ...modalData.userprofile,
      },
    }


    if (updatedModalData.userprofile) {
      delete updatedModalData.userprofile.height_metric;
      delete updatedModalData.userprofile.weight_metric;
    }
    if (this.state.userEmail === updatedModalData.email) {
      delete updatedModalData.email;
    } else {
      console.log("Email Updated!", updatedModalData);
    }
    const formData = this.createFormData();
    const apiOptions = this.createApiOptions(
      "patch",
      `list-active-user/${updatedModalData.id}/`,
      formData
    );
    try {
      const response = await API.request(apiOptions);
      const data = response && response.data;
      const { message, status } = data;
      if (status === 400) {
        this.setState({ emailErrorMessage: message })
        this.showErrorNotification(message)
      }
      else {
        this.setState({ emailErrorMessage: "" })
        this.hideModal();
        this.hideLoading();
        this.fetchClients();
        this.showSuccessNotification("User Updated Successfully")
      }
    }
    catch (error) {
      console.log("Error on updating user", error)
      this.setState({
        loadError: "Some error has occured. Please try again",
      });
      if (error.response) {
        console.log("Error on updating client data", error);
        if (error.response.data) {
          const { email, password } = error.response.data;
          if (email && email.length !== 0)
            this.setState({ emailErrorMessage: email.join(", ") });
          if (password && password.length !== 0)
            this.setState({ passwordErrorMsg: password.join(", ") });
        }
        this.showErrorNotification("Some error has occured. Cause of the error is " + error)
      }
    }
  }

  createFormData = () => {
    const { modalData, newUser } = this.state;
    const updatedModalData = {
      ...modalData,
      userprofile: {
        ...modalData.userprofile,
      },
    }

    let { userprofile, user_address } = updatedModalData;

    const formData = new FormData();
    if (newUser) {
      const { country: countryName, city:cityName } = this.state;
      if (countryName) {
        const country = this.findCountryByName(countryName);
        if (country && country.id) {
          formData.append("country_id", country.id);
        } else {
          console.log("createFormData:: Invalid country!", { countryName, country });
        }
      } else {
        console.log("createFormData:: New user don't have any country selected!");
      }
      if (cityName) {
        const city = this.findCityByName(countryName,cityName)
        if (city && city.id) {
          formData.append("city_id", city.id);
        } else {
          console.log("createFormData:: Invalid country!", { cityName, city });
        }
      } else {
        console.log("createFormData:: New user don't have any city selected!");
      }

      formData.append("first_name", updatedModalData.first_name);
      formData.append("last_name", updatedModalData.last_name);
      formData.append("is_email_need_update_later", updatedModalData.is_email_need_update_later == null || updatedModalData.is_email_need_update_later == undefined ? false : updatedModalData.is_email_need_update_later);
      formData.append("email", updatedModalData.email);
      formData.append("password", this.state.userPassword);
      formData.append("signup_from", USER_CREATED_BY)
      formData.append("unsubscribe_communication", updatedModalData.unsubscribe_communication ?? false);
      formData.append("nationality", updatedModalData.nationality)
      if (updatedModalData && updatedModalData.phone_no) {
        formData.append("phone_no", updatedModalData.phone_no);
      }
      if (updatedModalData && updatedModalData.client_note) {
        formData.append("client_note", updatedModalData.client_note);
      }
      if (updatedModalData && updatedModalData.full_name_id) {
        formData.append("full_name_id", updatedModalData.full_name_id)
      }

      if (updatedModalData && updatedModalData.front_id) {
        if (updatedModalData && typeof updatedModalData.front_id !== "string") {
          formData.append("front_id", updatedModalData.front_id);
          formData.append("front_id_s3", updatedModalData.front_id);
        }
      }

      if (updatedModalData && updatedModalData.back_id) {
        if (updatedModalData && typeof updatedModalData.back_id !== "string") {
          formData.append("back_id", updatedModalData.back_id);
          formData.append("back_id_s3", updatedModalData.back_id);
        }
      }

      if (userprofile) {
        formData.append("userprofile", JSON.stringify(userprofile))
      }

      if (user_address) {
        formData.append("user_address", JSON.stringify(user_address))
      }


      if (updatedModalData.is_test_user) {
        formData.append("is_test_user", updatedModalData.is_test_user)
      }

      if (updatedModalData && updatedModalData.whatsapp_number) {
        formData.append("whatsapp_number", updatedModalData.whatsapp_number);
      }

      return formData;
    }
    else {
      formData.append("first_name", updatedModalData.first_name);
      formData.append("last_name", updatedModalData.last_name);
      formData.append("email", updatedModalData.email);
      formData.append("is_email_need_update_later", updatedModalData.is_email_need_update_later);
      formData.append("unsubscribe_communication", updatedModalData.unsubscribe_communication ?? false);
      formData.append("nationality", updatedModalData.nationality)
      if (updatedModalData && updatedModalData.phone_no) {
        formData.append("phone_no", updatedModalData.phone_no);
      }

      formData.append("client_note", updatedModalData?.client_note || "");

      formData.append("full_name_id", updatedModalData?.full_name_id || "")

      if (updatedModalData && updatedModalData.front_id) {
        if (updatedModalData && typeof updatedModalData.front_id !== "string") {
          formData.append("front_id", updatedModalData.front_id);
          formData.append("front_id_s3", updatedModalData.front_id);
        }
      }

      if (updatedModalData && updatedModalData.back_id) {
        if (updatedModalData && typeof updatedModalData.back_id !== "string") {
          formData.append("back_id", updatedModalData.back_id);
          formData.append("back_id_s3", updatedModalData.back_id);
        }
      }

      if (userprofile) {
        formData.append("userprofile", JSON.stringify(userprofile))
      }

      if (user_address) {
        formData.append("user_address", JSON.stringify(user_address))
      }

      formData.append("is_test_user", updatedModalData.is_test_user)

      if (updatedModalData && updatedModalData.whatsapp_number) {
        formData.append("whatsapp_number", updatedModalData.whatsapp_number);
      }

      return formData;
    }
  }

  saveUserData = () => {
    const { modalData, newUser, userPassword, heightMetric, weightMetric } = this.state;
    const updatedModalData = {
      ...modalData,
      userprofile: {
        ...modalData.userprofile,
      },
    }

    updatedModalData.user_address.forEach((address, index) => {

      const { country: countryName, city: cityName, lat, lng } = address;
      const country = this.findCountryByName(countryName);
      const city = this.findCityByName(countryName,cityName);
      updatedModalData.user_address[index]["fk_country_id"] = country ? country.id : null;
      updatedModalData.user_address[index]["fk_city_id"] = city ? city.id : null;
      updatedModalData.user_address[index]["lat"] = lat ? Number(lat) : null;
      updatedModalData.user_address[index]["lng"] = lng ? Number(lng) : null;
    })

    const height = this.getHeight(updatedModalData.userprofile.height);
    const weight = this.getWeight(updatedModalData.userprofile.weight);

    if (height) {
      updatedModalData.userprofile["height"] = height + " " +
        ((heightMetric === null || heightMetric === undefined) ? "cm" : heightMetric);
    }
    if (weight) {
      updatedModalData.userprofile["weight"] = weight + " " +
        ((weightMetric === null || weightMetric === undefined) ? "kg" : weightMetric);
    }

    const allValidated =
      this.validateClientsNotes(updatedModalData.client_note) &
      this.validateFirstName(updatedModalData.first_name) &
      this.validateLastName(updatedModalData.last_name) &
      this.validateEmail(updatedModalData.email) &
      this.validatePhoneNumber(updatedModalData.phone_no) &
      this.validateWhatsAppNumber(updatedModalData.whatsapp_number) &
      this.validateGender(updatedModalData.userprofile.gender) &
      (height ? this.validateHeight(height) : true) &
      (weight ? this.validateWeight(weight) : true) &
      (this.state.newUser ? this.validatePassword(userPassword) : true) &
      (this.state.newUser ?
        this.validateAddress(this.state, 0) :
        (updatedModalData.user_address ?
          updatedModalData.user_address.every((address, index) => this.validateAddress(address, index)) : true)) &
      this.validateFullNameId(updatedModalData.full_name_id) &
      this.validateFrontId(updatedModalData.front_id) &
      this.validateBackId(updatedModalData.back_id) &
      (this.state.newUser ?
        this.validateNationality(this.state, 0) :
        (updatedModalData.user_address ?
          updatedModalData.user_address.every((address, index) => this.validateNationality(address, index)) : true)) 
    if (!allValidated) {
      this.props.showNotificationMessage({
        notificationMessage: "There are some invalid form fields!",
        successMessage: false,
        showNotification: true,
      });
    }

    if (newUser) {
      if (allValidated) {
        this.createNewClient()
      }
      else {
        return;
      }

    }
    else {
      if (allValidated) {
        this.updateClient()
      }
    }
  }

  createApiOptions = (method, url, formData) => ({
    method,
    url,
    data: formData,
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });

  // saveUserData = async () => {
  //   const { modalData, newUser, userPassword, heightMetric, weightMetric } = this.state;
  //   const updatedModalData = {
  //     ...modalData, 
  //     userprofile: { 
  //       ...modalData.userprofile, 
  //     },
  //   }

  //   const height = this.getHeight(updatedModalData.userprofile.height);
  //   const weight = this.getWeight(updatedModalData.userprofile.weight);

  //   if (height) {
  //     updatedModalData.userprofile["height"] = height + " " + 
  //       ((heightMetric === null || heightMetric === undefined) ? "cm" : heightMetric);
  //   }
  //   if (weight) {
  //     updatedModalData.userprofile["weight"] = weight + " " + 
  //       ((weightMetric === null || weightMetric === undefined) ? "kg" : weightMetric);
  //   }

  //   const allValidated =
  //       this.validateClientsNotes(updatedModalData.client_note) &
  //       this.validateFirstName(updatedModalData.first_name) &
  //       this.validateLastName(updatedModalData.last_name) &
  //       this.validateEmail(updatedModalData.email) &
  //       this.validatePhoneNumber(updatedModalData.phone_no) &
  //       // this.validateGender(updatedModalData.userprofile.gender) &
  //       (height ? this.validateHeight(height) : true) &
  //       (weight ? this.validateWeight(weight) : true) &
  //       (this.state.newUser ? this.validatePassword(userPassword) : true) &
  //       (this.state.newUser ?
  //       this.validateAddress(this.state, 0) :
  //       (updatedModalData.user_address ?
  //       updatedModalData.user_address.every((address, index) => this.validateAddress(address, index)) : true));

  //     if (!allValidated) {
  //       this.props.showNotificationMessage({
  //         notificationMessage: "There are some invalid form fields!",
  //         successMessage: false,
  //         showNotification: true,
  //       });
  //     }

  //   if (newUser) {
  //     let payload;
  //     if (allValidated) {
  //       payload = {
  //         first_name: updatedModalData.first_name,
  //         last_name: updatedModalData.last_name,
  //         email: updatedModalData.email,
  //         phone_no: updatedModalData.phone_no,
  //         password: this.state.userPassword,
  //         client_note: updatedModalData.client_note,
  //         signup_from: USER_CREATED_BY,
  //         userprofile: {
  //           gender: updatedModalData.userprofile
  //             ? updatedModalData.userprofile.gender
  //               ? updatedModalData.userprofile.gender
  //               : null
  //             : null,
  //           height: (updatedModalData.userprofile && updatedModalData.userprofile.height)
  //             ? updatedModalData.userprofile.height
  //             : ""
  //           ,
  //           weight: (updatedModalData.userprofile && updatedModalData.userprofile.weight)
  //             ? updatedModalData.userprofile.weight
  //             : "",
  //         },
  //         user_address: [
  //           {
  //             address_line1: this.state.address_line1,
  //             address_line2: this.state.address_line2,
  //             building_apt: this.state.building_apt,
  //             city: this.state.city,
  //             label: this.state.label,
  //             country: this.state.country,
  //             phone_no: this.state.address_phone_no || null,
  //           },
  //         ],
  //       };
  //       if (updatedModalData.userprofile && updatedModalData.userprofile.dob)
  //         payload.userprofile.dob = updatedModalData.userprofile.dob;

  //       if (updatedModalData.is_test_user)
  //         payload.is_test_user = updatedModalData.is_test_user;
  //     } else {
  //       return;
  //     }

  //     console.log({ payload });
  //     API.post("list-active-user/", payload)
  //       .then((response) => {
  //         // if (response.status === 200) {
  //         this.hideModal();
  //         // this.loadClients();
  //         this.fetchClients();
  //         this.props.showNotificationMessage({
  //           notificationMessage: "User Created Successfully",
  //           successMessage: true,
  //           showNotification: true,
  //         });
  //         // this.loadOrders();
  //         // }
  //       })
  //       .catch((error) => {
  //         console.log("Error on saving client data", error);
  //         if (error.response && error.response.status !== 200) {
  //           if (error.response.data) {
  //             const { email, password } = error.response.data;
  //             if (email && email.length !== 0)
  //               this.setState({ emailErrorMessage: email.join(", ") });
  //             if (password && password.length !== 0)
  //               this.setState({ passwordErrorMsg: password.join(", ") });
  //           }
  //           this.props.showNotificationMessage({
  //             notificationMessage: "Some error has occured. Cause of the error is " + error,
  //             successMessage: false,
  //             showNotification: true,
  //           });
  //         }
  //       });
  //   } else {
  //     if (allValidated) {
  //       console.log("UpdatedModalData: ", updatedModalData);
  //       if (updatedModalData.userprofile) {
  //         delete updatedModalData.userprofile.height_metric;
  //         delete updatedModalData.userprofile.weight_metric;
  //       }
  //       if (this.state.userEmail === updatedModalData.email) {
  //         delete updatedModalData.email;
  //       } else {
  //         console.log("Email Updated!", updatedModalData);
  //       }
  //       API.patch(
  //         "list-active-user/" + updatedModalData.id + "/", updatedModalData
  //       )
  //         .then((response) => {
  //           this.hideModal();
  //           // this.loadClients();
  //           this.fetchClients();
  //           this.props.showNotificationMessage({
  //             notificationMessage: "User Updated Successfully",
  //             successMessage: true,
  //             showNotification: true,
  //           });
  //           // this.loadOrders();
  //         })
  //         .catch((error) => {
  //           console.log("Error on updating user", error)
  //           this.setState({
  //             loadError: "Some error has occured. Please try again",
  //           });
  //           if (error.response) {
  //             console.log("Error on updating client data", error);
  //             if (error.response.data) {
  //               const { email, password } = error.response.data;
  //               if (email && email.length !== 0)
  //                 this.setState({ emailErrorMessage: email.join(", ") });
  //               if (password && password.length !== 0)
  //                 this.setState({ passwordErrorMsg: password.join(", ") });
  //             }
  //             this.props.showNotificationMessage({
  //               notificationMessage: "Some error has occured. Cause of the error is " + error,
  //               successMessage: false,
  //               showNotification: true,
  //             });
  //           }
  //         });
  //     }
  //   }
  // };

  getHeightMetric(height) {
    if (!new RegExp(HEIGHT_METRICS.join("|")).test(height)) {
      console.log("Unsupported Height Metric: ", height);
      return "";
    }

    const heightMetric = height.substring(height.indexOf("cm")).trim();
    return heightMetric.endsWith("s") ? heightMetric : heightMetric + "s";
  }

  getWeightMetric(weight) {
    if (!new RegExp(WEIGHT_METRICS.join("|")).test(weight)) {
      console.log("Unsupported Weight Metric: ", weight);
      return "";
    }

    const position = weight.indexOf("kg") !== -1 ? weight.indexOf("kg") : weight.indexOf("lb");
    const weightMetric = weight.substring(position).trim();
    return weightMetric.endsWith("s") ? weightMetric : weightMetric + "s";
  }

  getWeight(weightString) {
    if (weightString && (weightString.indexOf("undefined") !== -1 || weightString.indexOf("null") !== -1))
      return weightString.split(" ")[0];

    if (new RegExp(WEIGHT_METRICS.join("|")).test(weightString)) {
      const position = weightString.indexOf("kg") !== -1 ? weightString.indexOf("kg") : weightString.indexOf("lb");
      return weightString.substring(0, position).trim();
    }
    return weightString;
  }

  getHeight(heightString) {
    if (heightString && (heightString.indexOf("undefined") !== -1 || heightString.indexOf("null") !== -1))
      return heightString.split(" ")[0];

    return new RegExp(HEIGHT_METRICS.join("|")).test(heightString)
      ? heightString.substring(0, heightString.indexOf("cm")).trim()
      : heightString;
  }

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.newUser ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  getCitiesForAddress = (address) => {
    const { countries } = this.state;
    const country = address && countries
      ? countries.find(country => country.country_name === address.country)
      : null;
    return country ? country.cities : this.state.cities;
  }

  handlePageChange = (page) => this.fetchClients(page);

  handleRowsPerPageChange = (newRowsPerPage, page) =>
    this.setState({ rowsPerPage: newRowsPerPage }, () =>
      this.fetchClients(page)
    );

  handleSort = (column, sortDirection) => {
    // console.log(column, sortDirection);
    this.setState(
      { sortField: column.selector, sortDirection: sortDirection },
      () => this.fetchClients()
    );
  };

  fetchClients = (page = 1) => {
    const {
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm,
      userType = "",
    } = this.state;
    const baseUrl = userType === "nutritionist" ? "list-active-coach-clients" : "list-active-user";
    const sf = sortDirection === "desc" ? `-${sortField}` : sortField;
    if (baseUrl === "list-active-user") {
      let url = `v2/${baseUrl}/?page=${page}&page_size=${rowsPerPage}&ordering=${sf}`;;

      if (searchApplied) {
        url += `&search=${searchTerm ? encodeURIComponent(searchTerm) : searchTerm}`;
      }

      this.showLoading();
      PhoenixAPI.get(url)
        .then((response) => {
          // console.log("Response: ", response);
          if (response.data) {
            const { count: totalRows = 0, results: users = [] } = response.data;
            this.setState({ totalRows, users, isLoaded: true });
          }
        })
        .catch((error) => {
          console.log("Error on fetching data", error);
          this.setState({ loadError: "Some error has occured. Please try again" });
          this.showErrorNotification("Some error has occured. Please try again.");
        })
        .finally(() => this.hideLoading());

    }
    else {
      let url = `${baseUrl}/?page=${page}&page_size=${rowsPerPage}&ordering=${sf}`;;
      if (searchApplied) {
        url += `&search=${searchTerm ? encodeURIComponent(searchTerm) : searchTerm}`;
      }

      this.showLoading();
      API.get(url)
        .then((response) => {
          // console.log("Response: ", response);
          if (response.data) {
            const { count: totalRows = 0, results: users = [] } = response.data;
            this.setState({ totalRows, users, isLoaded: true });
          }
        })
        .catch((error) => {
          console.log("Error on fetching data", error);
          this.setState({ loadError: "Some error has occured. Please try again" });
          this.showErrorNotification("Some error has occured. Please try again.");
        })
        .finally(() => this.hideLoading());
    }
  };

  handleSelectFrontImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      const newModalData = { ...this.state.modalData, front_id: file }
      this.setState({ modalData: newModalData });

      const reader = new FileReader();
      reader.onloadend = function () {
        this.setState({ front_id: reader.result });
      }.bind(this);

      if (file?.type?.startsWith("image/")) {
        reader.readAsDataURL(file);
      } else if (file?.type === "application/pdf") {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  handleSelectBackImage = (e) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];
      const newModalData = { ...this.state.modalData, back_id: file }
      this.setState({ modalData: newModalData });
      const reader = new FileReader();
      reader.onloadend = function () {
        this.setState({ back_id: reader.result });
      }.bind(this);

      if (file?.type?.startsWith("image/")) {
        reader.readAsDataURL(file);
      } else if (file?.type === "application/pdf") {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderFrontIdPreview = () => {
    const { front_id: fileSource, modalData } = this.state;
    if (!fileSource) return null;
    const file = modalData.front_id;
    const isPdfFile = (file?.type === "application/pdf") ||
      (typeof (fileSource) === "string" && fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile
      ? <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource} />
      : (
        <div>
          <img
            id="selected_front_image"
            name="selected_front_image"
            src={fileSource}
            alt="Selected"
            className="img-thumbnail mx-auto"
            style={{
              width: "100%",
              maxHeight: "200px",
              objectFit: "cover",
            }}
          />
        </div>
      )

    return (
      <>
        <label htmlFor="selected_front_image">ID - Front Preview</label>
        {preview}
      </>
    )
  }

  renderBackIdPreview = () => {
    const { back_id: fileSource, modalData } = this.state;
    if (!fileSource) return null;

    const file = modalData.back_id;
    const isPdfFile = (file?.type === "application/pdf") ||
      (typeof (fileSource) === "string" && fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile
      ? <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource} />
      : (
        <div>
          <div>
            <img
              id="selected_back_image"
              name="selected_back_image"
              src={fileSource}
              alt="Selected"
              className="img-thumbnail mx-auto"
              style={{
                width: "100%",
                maxHeight: "200px",
                objectFit: "cover",
              }}
            />
          </div>
        </div>
      )

    return (
      <>
        <label htmlFor="selected_back_image">ID - Back Preview</label>
        {preview}
      </>
    )
  }
  handleNewAdress = () => {
    const { addressErrorMessages = [] } = this.state;
    console.log("addressErrorMessage")
    addressErrorMessages.push(this.createEmptyAddressErrorMessageObject())
    this.setState({ isShowNewAddressModal: true, addressErrorMessages })
  }
  hideNewAddressModal = () => {

    this.setState({ isShowNewAddressModal: false, selectedCity: null, selectedCountry: null, newAddress: {} })
  }
  handleChange = ({ currentTarget: input }) => {
    const newAddress = { ...this.state.newAddress };
    newAddress[input.name] = input.value;
    this.setState({ newAddress });
  }

  handleNewPhoneNumberChange = (value, data) => {
    const newAddress = { ...this.state.newAddress };
    let phoneNumber;

    if (!value || value === "") {
      phoneNumber = null;

    } else if (data.dialCode !== undefined && data.dialCode !== null && value !== "") {
      if (value.slice(data.dialCode.length).length > 6) {

        phoneNumber = `+${data.dialCode}` + value.slice(data.dialCode.length);
      } else {
        phoneNumber = `+${data.dialCode}` + value.slice(data.dialCode.length);
      }
    }
    newAddress["phone_no"] = phoneNumber;
    this.setState({ newAddress });
  }


  handleSubmitNewAdress = () => {
    const { selectedCountry, selectedCity, newAddress, modalData } = this.state
    if(!selectedCountry || !selectedCity){
      this.showErrorNotification("Please select country and city")
      return
    }
    if (selectedCountry && selectedCity && newAddress) {
      const address = {
        ...newAddress,
        country: selectedCountry.label,
        city: selectedCity.label
      }
      const newUpdatedData = {
        ...modalData,
        user_address: [...modalData.user_address, address]
      }
      this.setState({ modalData: newUpdatedData });

      this.hideNewAddressModal()
      this.showSuccessNotification("Address added successfully")
    }
  }

  render() {
    let finalList = [];
    let transactionList = [];
    let transactionListLength = 0;
    const {
      users,
      isLoaded,
      loadError,
      searchApplied,
      resultCount,
      results,
      modalData,
      userTransactions,
      newUser,
      sortField,
      editables,
      countries,
      cities,
      isEditable,
      errors, newAddress,
      selectedCountry,
      selectedCity
    } = this.state;

    transactionList = userTransactions;
    if (transactionList) {
      if (transactionList.length) {
        transactionListLength = transactionList.length
      }
    }
    if (isLoaded) {
      // if (searchApplied || sortField) {
      //   finalList = results;
      // } else {
      //   finalList = users;
      // }
      finalList = users;
      // console.log("Client with multiple addresses: ", finalList.filter(it => it.user_address && it.user_address.length > 1));
    }

    const languageDetails = modalData.language || {};
    const countryDetails = modalData.country || {};

    const personalForm = (
      <>
        <div className="form-group-container">
          <div className="form-group">
            <label htmlFor="first_name">First Name *</label>
            <div className="user-input-container">
              <div className="input-group">
                <input
                  type="text"
                  value={modalData.first_name || ""}
                  onChange={(e) => this.handleInput(e, "first_name")}
                  id="first_name"
                  name="first_name"
                  // readOnly={this.state.editFn || newUser ? false : true}
                  readOnly={!newUser && !editables.first_name}
                  autoComplete="off"
                  className={`form-control py-2 user-data-input ${editables.first_name || newUser ? `non-editable` : `editable`
                    } `}
                />
                {this.renderPencilIcon("first_name")}
              </div>
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editFn")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
              <span className="error">
                {this.state.fnameErrorMessage || this.state.fnErrorMessage}
              </span>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="last_name">Last Name *</label>
            <div className="user-input-container">
              <div className="input-group">
                <input
                  type="text"
                  value={modalData.last_name || ""}
                  onChange={(e) => this.handleInput(e, "last_name")}
                  id="last_name"
                  name="last_name"
                  // readOnly={this.state.editLn || newUser ? false : true}
                  readOnly={!newUser && !editables.last_name}
                  autoComplete="off"
                  // className={`form-control user-data-input ${this.state.editLn || newUser ? `non-editable` : `editable`
                  //   } `}
                  className={`form-control py-2 user-data-input ${editables.last_name || newUser ? `non-editable` : `editable`
                    } `}
                />
                {this.renderPencilIcon("last_name")}
              </div>
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editLn")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
              <span className="error">
                {this.state.lnameErrorMessage || this.state.lnErrorMessage}
              </span>
            </div>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label htmlFor="email">Email *</label>
            <div className="user-input-container">
              <div className="input-group">
                <input
                  type="text"
                  value={modalData.email || ""}
                  onChange={(e) => this.handleInput(e, "email")}
                  id="email"
                  name="email"
                  readOnly={!newUser && !editables.email}
                  // readOnly={this.state.editEmail || newUser ? false : true}
                  autoComplete="off"
                  className={`form-control py-2 user-data-input ${editables.email || newUser ? `non-editable` : `editable`} `}
                // className={`form-control user-data-input ${this.state.editEmail || newUser ? `non-editable` : `editable`} `}
                />
                {this.renderPencilIcon("email")}
              </div>
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  style={{height: "auto"}}
                  onClick={() => this.makeEditable("editEmail")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}

              <div className="row">

                <div className="mt-3 ml-2">

                  <input

                    checked={(modalData && modalData?.is_email_need_update_later) || false}
                    onChange={(event) => {
                      console.log("check", event.target.checked);
                      this.setState({
                        modalData: {
                          ...modalData, is_email_need_update_later: event.target.checked
                        }
                      });
                    }}
                    id="is_email_need_update_later"
                    name="is_email_need_update_later"
                    type="checkbox"

                  // disabled={!isEditable}
                  />
                  <label style={{ "textAlign": "center" }}>
                    Does this email need to be Updated later?
                  </label>
                </div>
              </div>

              <span className="error">{this.state.emailErrorMessage}</span>
            </div>
          </div>
          <div className="form-group">
            <label>Phone Number *</label>
            <div className="user-input-container">
              <div className={`${editables.phone_no || newUser
                ? `non-editable-phone`
                : `editable-phone`
                } `}>
                <PhoneInput
                  // country={"ae"}
                  autocompleteSearch
                  autoFormat
                  className={`form-cotrol user-data-input contact-no ${editables.phone_no || newUser ? `non-editable` : `editable`} `}
                  value={modalData.phone_no || ""}
                  onChange={(value, data) => {
                    this.numberCheck(value, data);
                  }}
                  disabled={!newUser && !editables.phone_no}
                  // disabled={this.state.editPhoneNo || newUser ? false : true}
                  inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                />
                {this.renderPencilIcon("phone_no")}
              </div>
              {/* {!newUser && (
                <div
                  className="user-data-edit-icon"
                  onClick={() => this.makeEditable("editPhoneNo")}
                >
                  <i className="fa fa-pencil"></i>
                </div>
              )} */}
              <span className="error">{this.state.contactNoErrorMessage}</span>
            </div>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label htmlFor="password">Password *</label>
            <input
              type="text"
              id="password"
              name="password"
              className={`form-control user-data-input py-2 ${newUser ? `non-editable` : `editable`
                } `}
              value={this.state.newUser ? modalData.password : ""}
              onChange={(e) => this.handleInput(e, "password")}
              readOnly={this.state.newUser ? false : true}
              autoComplete="off"
            ></input>
            <span className="error">{this.state.passwordErrorMsg}</span>
          </div>
          <div className="form-group">
            <label>WhatsApp Number *</label>
            <div className="user-input-container">
              <div className={`${editables.whatsapp_number || newUser
                ? `non-editable-phone`
                : `editable-phone`
                } `}>
                <PhoneInput
                  // country={"ae"}
                  autocompleteSearch
                  autoFormat
                  className={`form-cotrol user-data-input contact-no ${editables.whatsapp_number || newUser ? `non-editable` : `editable`} `}
                  value={modalData.whatsapp_number || ""}
                  onChange={(value, data) => {
                    this.numberCheckForWhatsApp(value, data);
                  }}
                  disabled={!newUser && !editables.whatsapp_number}
                  inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                />
                {this.renderPencilIcon("whatsapp_number")}
              </div>

              <span className="error">{this.state.whatsAppNoErrorMessage}</span>
            </div>
          </div>
        </div>
        {!newUser &&
          <div className="form-group-container">
            <div className="form-group">
              <label htmlFor="default_country">Default Country</label>
              <input
                value={countryDetails.country_name || ""}
                type="text"
                id="default_country"
                name="default_country"
                className="form-control user-data-input py-2 non-editable"
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="default_language">Default Language</label>
              <input
                value={languageDetails.language_name || ""}
                type="text"
                id="default_language"
                name="default_language"
                className="form-control user-data-input py-2 non-editable"
                readOnly
              />
            </div>
          </div>
        }
        <div className="form-group-container">
          <div className="form-group">
            <label htmlFor="user_created_by">User Created By</label>
            <div className="user-input-container">
              <input
                // value={ORDER_SOURCE}
                value={newUser ? USER_CREATED_BY : modalData?.signup_from ?? ""}
                id="user_created_by"
                name="user_created_by"
                type="text"
                readOnly={true}
                className={`form-control user-data-input`}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="unsubscribe_communication">Stop Communication</label>
            <div className="user-input-container">
              <div className="input-group">
                <Dropdown
                  value={modalData.unsubscribe_communication}
                  onChange={(event, value) => {
                    this.setState({
                      modalData: { ...modalData, unsubscribe_communication: value.value }
                    });
                  }}
                  id="unsubscribe_communication"
                  name="unsubscribe_communication"
                  search
                  selection
                  options={COMMUNICATION_OPTIONS}
                  disabled={!newUser && !editables.unsubscribe_communication}
                  className={`form-control user-data-input ${editables.unsubscribe_communication || newUser ? `non-editable` : `editable`} `}
                />
                {this.renderPencilIcon("unsubscribe_communication")}
              </div>
            </div>
          </div>
        </div>
        <div className="form-content">
          <span>Identification Information</span>
        </div>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col">
              <label htmlFor="fullName">Full Name as per ID</label>
              <div className="input-group">
                <input
                  value={modalData.full_name_id || ""}
                  onChange={(e) => this.handleInput(e, "full_name_id")}
                  id="full_name_id"
                  name="full_name_id"
                  type="text"
                  placeholder="Full Name"
                  className={
                    "form-control py-2"
                  }
                  disabled={!newUser && !editables.full_name_id}
                />
                {this.renderPencilIcon("full_name_id")}
              </div>
              <span className="error" style={{ color: "#E88385" }}>{this.state.fullNameIdErrorMessage}</span>
            </div>
            <div className="form-group col">
            <label htmlFor="nationality">Nationality {this.state.selectNationalityErrorMessage && <span>*</span>}</label>
            <div className="user-input-container">
              <div className="input-group">
                <Dropdown
                  value={modalData.nationality}
                  onChange={(event, value) => {
                    this.setState({
                      modalData: { ...modalData, nationality: value.value }
                    });
                  }}
                  id="nationality"
                  name="nationality"
                  search
                  selection
                  options={NATIONALITY_OPTIONS}
                  disabled={!newUser && !editables.nationality}
                  className={`form-control user-data-input ${editables.nationality || newUser ? `non-editable` : `editable`} `}
                />
                {this.renderPencilIcon("nationality")}
              </div>
              <span className="error" style={{ color: "#E88385" }}>{this.state.selectNationalityErrorMessage}</span>
            </div>
          </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="imageInput">ID-Front</label>
              <div className="input-group">
                <input
                  onChange={this.handleSelectFrontImage}
                  id="imageInput"
                  name="frontimage"
                  type="file"
                  accept="image/*, application/pdf"
                  disabled={!newUser && !editables.frontimage}
                  className={"form-control"}
                />
                {this.renderPencilIcon("frontimage")}
              </div>
              <span className="error" style={{ color: "#E88385" }}>{this.state.frontIdImageErrorMessage}</span>
            </div>
            <div className="form-group col-6">
              {this.renderFrontIdPreview()}
              {/* {this.state.front_id && (
                <>
                  <label htmlFor="selected_front_image">
                    ID - Front Preview
                  </label>
                  <div>
                    <img
                      id="selected_front_image"
                      name="selected_front_image"
                      src={this.state.front_id}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>
              )} */}
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="imageInput">ID-Back</label>
              <div className="input-group">
                <input
                  onChange={this.handleSelectBackImage}
                  id="imageInput"
                  name="image"
                  type="file"
                  accept="image/*, application/pdf"
                  disabled={!newUser && !editables.backimage}
                  className={"form-control"}
                />
                {this.renderPencilIcon("backimage")}
              </div>
              <span className="error" style={{ color: "#E88385" }}>{this.state.backIdImageErrorMessage}</span>
            </div>
            <div className="form-group col-6">
              {this.renderBackIdPreview()}
              {/* {this.state.back_id && (
                <>
                  <label htmlFor="selected_back_image">
                    ID - Back Preview
                  </label>
                  <div>
                    <img
                      id="selected_back_image"
                      name="selected_back_image"
                      src={this.state.back_id}
                      alt="Selected"
                      className="img-thumbnail mx-auto"
                      style={{
                        width: "100%",
                        maxHeight: "200px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </>
              )} */}
            </div>
          </div>
        </div>
        {this.state.modalData.user_address &&
          this.state.modalData.user_address.map((item, i) => {
            return (
              <>
                <div className="form-content">
                  <span>Address {i + 1}</span>
                </div>
                <div className="form-group-container">
                  <div className="form-group">
                    <label htmlFor={`label${i}`}>Label</label>
                    <div className="user-input-container">
                      <div className="input-group">
                        <textarea
                          name="label"
                          id={`label${i}`}
                          className={`form-control user-data-input py-2 ${editables[`label${i}`] || newUser
                            ? `non-editable`
                            : `editable`
                            } `}
                          value={!newUser ? item.label : this.state.label || ""}
                          onChange={(e) => this.handleInput(e, `label${i}`)}
                          readOnly={this.state.label + `${i}` || newUser ? false : true}
                          autoComplete="off"
                          disabled={!newUser && !editables[`label${i}`]}
                        ></textarea>
                        {this.renderPencilIcon("label" + i)}
                      </div>
                      {/* {!newUser && (
                        <div
                          className="user-data-edit-icon"
                          onClick={() => this.makeEditable(`label${i}`)}
                        >
                          <i className="fa fa-pencil"></i>
                        </div>
                      )} */}
                      <span className="error">
                        {this.state.addressErrorMessages[i].labelErrorMessage}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor={`street_name${i}`}>Street Name *</label>
                    <div className="user-input-container">
                      <div className="input-group">
                        <textarea
                          id={`street_name${i}`}
                          name={`street_name${i}`}
                          className={`form-control user-data-input py-2 ${editables[`street_name${i}`] || newUser
                            ? `non-editable`
                            : `editable`
                            } `}
                          value={!newUser ? item.address_line2 : this.state.address_line2}
                          onChange={(e) => this.handleInput(e, `address_line2${i}`)}
                          readOnly={
                            this.state.address_line2 + `${i}` || newUser
                              ? false
                              : true
                          }
                          autoComplete="off"
                          disabled={newUser || editables[`street_name${i}`] ? false : true}
                        ></textarea>
                        {this.renderPencilIcon("street_name" + i)}
                      </div>
                      {/* {!newUser && (
                        <div
                          className="user-data-edit-icon"
                          onClick={() => this.makeEditable(`address_line2${i}`)}
                        >
                          <i className="fa fa-pencil"></i>
                        </div>
                      )} */}
                      <span className="error">{this.state.addressErrorMessages[i].streetErrorMessage}</span>
                    </div>
                  </div>
                </div>
                <div className="form-group-container">
                  <div className="form-group">
                    <label htmlFor={`building_apt${i}`}>Building/Apt. *</label>
                    <div className="user-input-container">
                      <div className="input-group">
                        <textarea
                          id={`building_apt${i}`}
                          name={`building_apt${i}`}
                          className={`form-control user-data-input py-2 ${editables[`building_apt${i}`] || newUser
                            ? `non-editable`
                            : `editable`
                            } `}
                          value={
                            !newUser ? item.building_apt : this.state.building_apt
                          }
                          onChange={(e) =>
                            this.handleInput(e, `building_apt${i}`)
                          }
                          readOnly={
                            this.state.building_apt + `${i}` || newUser
                              ? false
                              : true
                          }
                          autoComplete="off"
                          disabled={newUser || editables[`building_apt${i}`] ? false : true}
                        ></textarea>
                        {this.renderPencilIcon("building_apt" + i)}
                      </div>
                      {/* {!newUser && (
                        <div
                          className="user-data-edit-icon"
                          onClick={() => this.makeEditable(`building_apt${i}`)}
                        >
                          <i className="fa fa-pencil"></i>
                        </div>
                      )} */}
                      <span className="error">
                        {this.state.addressErrorMessages[i].buildingErrorMessage}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor={`area_district${i}`}>Area/District *</label>
                    <div className="user-input-container">
                      <div className="input-group">
                        <textarea
                          type="text"
                          id={`area_district${i}`}
                          name={`area_district${i}`}
                          className={`form-control user-data-input py-2 ${editables[`area_district${i}`] || newUser
                            ? `non-editable`
                            : `editable`
                            } `}
                          value={
                            !newUser
                              ? item.address_line1
                              : this.state.address_line1
                          }
                          onChange={(e) =>{ console.log("e.target.value");
                            this.handleInput(e, `address_line1${i}`);
                          }
                          }
                          readOnly={
                            this.state.address_line1 + `${i}` || newUser
                              ? false
                              : true
                          }
                          autoComplete="off"
                          disabled={newUser || editables[`area_district${i}`] ? false : true}
                        ></textarea>
                        {this.renderPencilIcon("area_district" + i)}
                      </div>
                      {/* {!newUser && (
                        <div
                          className="user-data-edit-icon"
                          onClick={() => this.makeEditable(`address_line1${i}`)}
                        >
                          <i className="fa fa-pencil"></i>
                        </div>
                      )} */}
                      <span className="error">
                        {this.state.addressErrorMessages[i].areaErrorMessage}
                      </span>
                    </div>
                  </div>
                </div>
             {this.state.modalData && this.state.modalData.country && this.state.modalData.country.country_code === "Others"?<></>:(
              <div className="form-group-container">
                  <div className="form-group">
                    <label htmlFor={`country${i}`}>Country *</label>
                    <div className="user-input-container">
                      {/* <div
                        className={`ui dropdown flex ${editables[`country${i}`] || newUser ? `` : `disabled`
                          }`}
                        style={{ height: "40px" }}
                      > */}
                      <div className="input-group">
                        <Dropdown
                          id={`country${i}`}
                          name={`country${i}`}
                          className={`form-control user-data-input ${editables[`country${i}`] || newUser
                            ? `non-editable`
                            : `editable`
                            } `}
                          placeholder="Select Country"
                          onChange={(e, val) => this.handleInput(e, `country${i}`, val)}
                          value={
                            !newUser ? item.country : this.state.country
                          }
                          selection
                          options={this.state.countries.map((data) => ({
                            key: data.id,
                            text: data.country_name,
                            value: data.country_name,
                          }))}
                          disabled={(!newUser && item.city)?true:!newUser && !editables[`country${i}`]}
                          style={{ height: "40px" }}
                        />
                        {item.country?<></>:this.renderPencilIcon("country" + i)}
                      </div>
                      {/* </div> */}
                      {/* {!newUser && (
                        <div
                          className="user-data-edit-icon"
                          style={{ lineHeight: "40px" }}
                          onClick={() => this.makeEditable(`country${i}`)}
                        >
                          <i className="fa fa-pencil"></i>
                        </div>
                      )} */}
                      {/* <span className="error">
                        {this.state.countryErrorMessage}
                      </span> */}
                    </div>
                    <span className="error">
                      {this.state.addressErrorMessages[i].countryErrorMessage}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor={`city${i}`}>City *</label>
                    <div className="user-input-container">
                      {/* <div
                        className={`ui dropdown flex ${this.state[`city${i}`] || newUser ? `` : `disabled`
                          }`}
                        style={{ height: "40px" }}
                      > */}
                      <div className="input-group">
                        <Dropdown
                          id={`city${i}`}
                          name={`city${i}`}
                          className={`form-control user-data-input py-2 ${editables[`city${i}`] || newUser
                            ? `non-editable`
                            : `editable`
                            } `}
                          placeholder="Select City"
                          onChange={(e, value) => this.handleInput(e, `city${i}`, value)}
                          defaultValue={!newUser ? item.city : this.state.city}
                          selection
                          disabled={(!newUser && item.city)?true:!newUser && !editables[`city${i}`]}
                          style={{ height: "40px" }}
                          // options={this.state.cities.map((data) => ({
                          //   key: data.city_name,
                          //   text: data.city_name,
                          //   value: data.city_name,
                          // }))}
                          options={
                            this.getCitiesForAddress(item).map((data) => ({
                              key: data.city_name,
                              text: data.city_name,
                              value: data.city_name,
                            }))
                          }
                        />
                        {item.city?<></>:this.renderPencilIcon("city" + i)}
                      </div>
                      {/* </div> */}
                      {/* {!newUser && (
                        <div
                          className="user-data-edit-icon"
                          style={{ lineHeight: "40px" }}
                          onClick={() => this.makeEditable(`city${i}`)}
                        >
                          <i className="fa fa-pencil"></i>
                        </div>
                      )} */}
                    </div>
                    <span className="error">
                      {this.state.addressErrorMessages[i].cityErrorMessage}
                    </span>
                  </div>
                </div>
             )}  
                {this.state.modalData && this.state.modalData.country && this.state.modalData.country.country_code === "Others" ?
                  <div className="form-group-container">
                    <div className="form-group">
                      <label htmlFor={`countryName${i}`}>Country Name *</label>
                      <div className="user-input-container">
                        <div className="input-group">
                          <input
                            name="countryName"
                            id={`countryName${i}`}
                            className={`form-control user-data-input py-2 ${editables[`countryName${i}`] || newUser
                              ? `non-editable`
                              : `editable`
                              } `}
                            value={!newUser ? item.country : this.state.country || ""}
                            onChange={(e) => this.handleInput(e, `countryName${i}`)}
                            readOnly={this.state.country + `${i}` || newUser ? false : true}
                            // readOnly={true}
                            autoComplete="off"
                            disabled={item.country?true:!newUser && !editables[`countryName${i}`]}
                          ></input>
                          {this.renderPencilIcon("countryName" + i)}
                        </div>
                        {console.log("itemAddres", this.state.modalData)}
                        {/* {!newUser && (
                        <div
                          className="user-data-edit-icon"
                          onClick={() => this.makeEditable(`label${i}`)}
                        >
                          <i className="fa fa-pencil"></i>
                        </div>
                      )} */}
                        <span className="error">
                          {this.state.addressErrorMessages[i].countryErrorMessage}
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor={`cityName${i}`}>City Name *</label>
                      <div className="user-input-container">
                        <div className="input-group">
                          <input
                            id={`cityName${i}`}
                            name={`cityName${i}`}
                            className={`form-control user-data-input py-2 ${editables[`cityName${i}`] || newUser
                              ? `non-editable`
                              : `editable`
                              } `}
                            value={!newUser ? item.city : this.state.city}
                            onChange={(e) => this.handleInput(e, `cityName${i}`)}
                            readOnly={
                              this.state.city + `${i}` || newUser
                                ? false
                                : true
                            }
                            // readOnly={true}
                            autoComplete="off"
                            disabled={newUser || editables[`cityName${i}`] ? false : true}
                          ></input>
                          {this.renderPencilIcon("cityName" + i)}
                        </div>
                        {/* {!newUser && (
                        <div
                          className="user-data-edit-icon"
                          onClick={() => this.makeEditable(`address_line2${i}`)}
                        >
                          <i className="fa fa-pencil"></i>
                        </div>
                      )} */}
                        <span className="error">{this.state.addressErrorMessages[i].cityErrorMessage}</span>
                      </div>
                    </div>
                  </div>
                  : ""
                }
                <div className="form-group-container">
                  <div className="form-group col-6 mb-auto">
                    <label htmlFor={`phone_no${i}`}>Phone Number</label>
                    <PhoneInput
                      value={!newUser ? item.phone_no || "" : this.state.address_phone_no}
                      onChange={(value, data) => {
                        this.numberCheckForAddress(value, data, i);
                      }}
                      id={`phone_no${i}`}
                      inputStyle={
                        !newUser && !editables[`phone_no${i}`]
                          ? { width: "100%", backgroundColor: "#E9ECEF" }
                          : { width: "100%" }
                      }
                      disabled={!newUser && !editables[`phone_no${i}`]}
                      autocompleteSearch
                      autoFormat
                    />
                    {this.renderPencilIcon(`phone_no${i}`)}
                    <span className="error">
                      {this.state.addressErrorMessages[i].contactNoErrorMessage}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor={`map_url${i}`}>Pin Location</label>
                    <div className="user-input-container">
                      <div className="input-group">
                        <input
                          type="text"
                          name="map_url"
                          id={`map_url${i}`}
                          className={`form-control user-data-input py-2 ${editables[`map_url${i}`] || newUser
                            ? `non-editable`
                            : `editable`
                            } `}
                          value={!newUser ? item.map_url : this.state.map_url || ""}
                          onChange={(e) => this.handleInput(e, `map_url${i}`)}
                          readOnly={this.state.map_url + `${i}` || newUser ? false : true}
                          autoComplete="off"
                          disabled={!newUser && !editables[`map_url${i}`]}
                        />
                        {this.renderPencilIcon("map_url" + i)}
                      </div>
                      <span className="error">
                        {this.state.addressErrorMessages[i].pinLocationErrorMessage}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group-container">
                  <div className="form-group">
                    <label htmlFor={`lat${i}`}>Latitude</label>
                    <div className="user-input-container">
                      <div className="input-group">
                        <input
                          type="number"
                          name="lat"
                          id={`lat${i}`}
                          className={`form-control user-data-input py-2 ${editables[`lat${i}`] || newUser
                            ? `non-editable`
                            : `editable`
                            } `}
                          value={!newUser ? item.lat : this.state.lat || ""}
                          onChange={(e) => this.handleInput(e, `lat${i}`)}
                          readOnly={this.state.lat + `${i}` || newUser ? false : true}
                          autoComplete="off"
                          disabled={!newUser && !editables[`lat${i}`]}
                        />
                        {this.renderPencilIcon("lat" + i)}
                      </div>
                      <span className="error">
                        {this.state.addressErrorMessages[i].pinLocationErrorMessage}
                      </span>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor={`lng${i}`}>Longitude</label>
                    <div className="user-input-container">
                      <div className="input-group">
                        <input
                          type="number"
                          name="lng"
                          id={`lng${i}`}
                          className={`form-control user-data-input py-2 ${editables[`lng${i}`] || newUser
                            ? `non-editable`
                            : `editable`
                            } `}
                          value={!newUser ? item.lng : this.state.lng || ""}
                          onChange={(e) => this.handleInput(e, `lng${i}`)}
                          readOnly={this.state.lng + `${i}` || newUser ? false : true}
                          autoComplete="off"
                          disabled={!newUser && !editables[`lng${i}`]}
                        />
                        {this.renderPencilIcon("lng" + i)}
                      </div>
                      <span className="error">
                        {this.state.addressErrorMessages[i].pinLocationErrorMessage}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="container mt-4"> 
            <div className="row">
              <div className="form-group col">
              <button
                onClick={this.handleNewAdress}
              style={{ width: "100%" }}
                className="btn btn-success mt-1"
              >
                Add New
              </button>
              </div>
          
              </div> 
           </div>
      </>
    );

    const profileForm = (
      <>
        <div>
          <div className="form-group-container">
            <div className="form-group mb-4">
              <label htmlFor="gender">Gender *</label>
              <div className="user-input-container">
                {/* <div
                  className={`ui dropdown flex ${
                    this.state.editGender || newUser ? `` : `disabled`
                    }`}
                  style={{ height: '40px' }}
                > */}
                <div className="input-group">
                  <Dropdown
                    id="gender"
                    name="gender"
                    className={`form-control ${editables.gender || newUser
                      ? `non-editable`
                      : `editable`
                      } `}
                    placeholder="Select Gender"
                    onChange={(e) => this.handleInput(e, "gender")}
                    defaultValue={
                      (!newUser && modalData.userprofile) ?
                        modalData.userprofile.gender
                          ? modalData.userprofile.gender
                          : ""
                        : ""
                    }
                    selection
                    options={[
                      {
                        key: "M",
                        text: "Male",
                        value: "M",
                      },
                      {
                        key: "F",
                        text: "Female",
                        value: "F",
                      },
                    ]}
                    disabled={!newUser && !editables.gender}
                    style={{ height: "40px" }}
                  />
                  {this.renderPencilIcon("gender")}
                  {/* </div>        */}
                </div>
                {/* {!newUser && (
                  <div
                    className="user-data-edit-icon"
                    style={{ lineHeight: "40px" }}
                    onClick={() => this.makeEditable("editGender")}
                  >
                    <i className="fa fa-pencil"></i>
                  </div>
                )} */}
                <span className="error">{this.state.genderErrorMessage}</span>
              </div>
            </div>
            <div className="form-group mb-4">
              <label htmlFor="dateofbirth">Date of Birth</label>
              <div className="user-input-container">
                <div className="input-group">
                  <input
                    type="date"
                    id="dateofbirth"
                    name="dateofbirth"
                    className={`form-control py-2 ${editables.dateofbirth || newUser ? `non-editable` : `editable`
                      } `}
                    // style={!newUser ? { width: "93%" } : { width: "100%" }}
                    value={modalData.userprofile ? modalData.userprofile.dob ? modalData.userprofile.dob : "" : ""}
                    min={moment().subtract(110, "years").format("YYYY-MM-DD")}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                    onChange={(e) => this.handleInput(e, "dob")}
                    readOnly={editables.dateofbirth || newUser ? false : true}
                  />
                  {this.renderPencilIcon("dateofbirth")}
                </div>
                {/* {!newUser && (
                  <div
                    className="user-data-edit-icon"
                    style={{ lineHeight: "40px" }}
                    onClick={() => this.makeEditable("editDob")}
                  >
                    <i className="fa fa-pencil"></i>
                  </div>
                )} */}
                <span className="error">{this.state.dobErrorMessage}</span>
              </div>
            </div>
          </div>
          <div className="form-group-container">
            <div className="form-group">
              <label>Height</label>
              <div className="user-input-container number-field">
                <input
                  type="text"
                  // onKeyPress={(event) => {
                  //   if (!/[0-9]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                  id="height"
                  name="height"
                  className={`form-control user-data-input ${editables.height || newUser
                    ? `non-editable`
                    : `editable`
                    } `}
                  value={
                    (modalData.userprofile && modalData.userprofile.height)
                      ? this.getHeight(modalData.userprofile.height)
                      : ""
                  }
                  onChange={(e) => this.handleInput(e, "height")}
                  readOnly={editables.height || newUser ? false : true}
                  // min="10"
                  max="300"
                  style={!newUser ? { width: "69%" } : { width: "100%" }}
                  autoComplete="off"
                ></input>
                <div
                  className={`ui dropdown flex ${editables.height || newUser ? `` : ``
                    }`}
                  style={{ height: "40px", minWidth: "85px" }}
                >
                  <Dropdown
                    className={`form-control ${editables.height || newUser
                      ? `non-editable`
                      : `editable`
                      } `}
                    placeholder=""
                    onChange={(e) =>
                      this.handleHeightandWeight(e, "heightMetric")
                    }
                    defaultValue={
                      (modalData.userprofile && modalData.userprofile.height)
                        ? (this.getHeightMetric(modalData.userprofile.height) || "cms")
                        : "cms"
                    }
                    selection
                    options={[
                      {
                        key: "cms",
                        text: "cms",
                        value: "cms",
                      }
                    ]}
                    disabled={!newUser && !editables.height}
                  />
                  {this.renderPencilIcon("height")}
                </div>
                {/* {!newUser && (
                  <div
                    className="user-data-edit-icon"
                    style={{ lineHeight: "40px" }}
                    onClick={() => this.makeEditable("editHeight")}
                  >
                    <i className="fa fa-pencil"></i>
                  </div>
                )} */}
              </div>
              <span className="error">{this.state.heightErrorMessage}</span>
            </div>
            <div className="form-group">
              <label>Weight</label>
              <div className="user-input-container number-field">
                <input
                  type="text"
                  // onKeyPress={(event) => {
                  //   if (!/[0-9]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                  name="weight"
                  className={`form-control user-data-input ${editables.weight || newUser
                    ? `non-editable`
                    : `editable`
                    } `}
                  value={
                    (modalData.userprofile && modalData.userprofile.weight)
                      ? this.getWeight(modalData.userprofile.weight)
                      : ""
                  }
                  // value={modalData.userprofile ? modalData.userprofile.weight ? modalData.userprofile.weight : "" : ""}
                  onChange={(e) => this.handleInput(e, "weight")}
                  // readOnly={this.state.editWeight || newUser ? false : true}
                  disabled={!newUser && !editables.weight}
                  // min="0"
                  max="999"
                  style={!newUser ? { width: "71%" } : { width: "100%" }}
                  autoComplete="off"
                ></input>
                <div
                  className={`ui dropdown flex ${this.state.editWeight || newUser ? `` : ``
                    }`}
                  style={{ height: "40px", minWidth: "80px" }}
                >
                  <Dropdown
                    className={`form-control ${editables.weight || newUser
                      ? `non-editable`
                      : `editable`
                      } `}
                    placeholder=""
                    onChange={(e) =>
                      this.handleHeightandWeight(e, "weightMetric")
                    }
                    defaultValue={
                      (modalData.userprofile && modalData.userprofile.weight)
                        ? (this.getWeightMetric(modalData.userprofile.weight) || "kgs")
                        : "kgs"
                    }
                    selection
                    options={[
                      {
                        key: "kgs",
                        text: "kgs",
                        value: "kgs",
                      },
                      {
                        key: "lbs",
                        text: "lbs",
                        value: "lbs",
                      },
                    ]}
                    disabled={!newUser && !editables.weight}
                  />
                  {this.renderPencilIcon("weight")}
                </div>
                {/* {!newUser && (
                  <div
                    className="user-data-edit-icon"
                    style={{ lineHeight: "40px" }}
                    onClick={() => this.makeEditable("editWeight")}
                  >
                    <i className="fa fa-pencil"></i>
                  </div>
                )} */}
              </div>
              <span className="error">{this.state.weightErrorMessage}</span>
            </div>
          </div>
          {
            (newUser && this.state.userType !== "admin")
              ? ""
              :
              <div className="form-group-container">
                <div className="form-group col-12">
                  <label htmlFor="clients_notes">Client’s Notes (Operational Related)</label>
                  <div className="user-input-container">
                    <div className="input-group">
                      <textarea
                        value={modalData.client_note || ""}
                        onChange={event => {
                          const newModalData = { ...modalData };
                          newModalData.client_note = event.target.value;
                          this.setState({ modalData: newModalData });
                        }}
                        id="clients_notes"
                        name="clients_notes"
                        type="text"
                        placeholder="Client's Notes"
                        className="form-control flex-1"
                        readOnly={editables.clients_notes || newUser ? false : true}
                      ></textarea>
                      {this.state.userType === "admin" && this.renderPencilIcon("clients_notes")}
                    </div>
                    <span className="error">
                      {this.state.clientNoteErrorMessage || ""}
                    </span>
                  </div>
                </div>
              </div>
          }
        </div>
      </>
    );

    const activityForm = (
      <>
        <div className="form-group-container">
          <div className="form-group">
            <label>Last Login</label>
            <input
              type="text"
              name="last-login"
              className={"form-control user-data-input non-editable"}
              value={modalData.last_login && moment(modalData.last_login).format("MM/DD/YYYY") || ""}
              readOnly
            ></input>
          </div>
          <div className="form-group">
            <label>Joining Date</label>
            <input
              type="text"
              name="joining-data"
              className={"form-control user-data-input non-editable"}
              value={modalData.date_joined && moment(modalData.date_joined).format("MM/DD/YYYY") || ""}
              readOnly
            ></input>
          </div>
        </div>
        <div className="form-content">
          <span>Device Info.</span>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>Device Info</label>
            <input
               value={modalData.os_version || ""}
              type="text"
              name="device-info"
              className={"form-control user-data-input non-editable"}
              readOnly
            ></input>
          </div>
          <div className="form-group">
            <label>Operating System</label>
            <input
              value={modalData.os_type || ""}
              type="text"
              name="operating-system"
              className={"form-control user-data-input non-editable"}
              readOnly
            ></input>
          </div>
        </div>
        <div className="form-group-container">
          <div className="form-group">
            <label>App Version</label>
            <input
              value={modalData.app_version || ""}
              type="text"
              name="app-version"
              className={"form-control user-data-input non-editable"}
              readOnly
            ></input>
          </div>
          <div className="form-group">
            <label>Last Login</label>
            <input
              type="text"
              name="last-login"
              className={"form-control user-data-input non-editable"}
              readOnly
            ></input>
          </div>
        </div>
      </>
    );


    const referralSettingsForm = <>
      <WalletTransactions customerId={modalData.id} isEditable={isEditable} />
    </>

    const testClientForm = (
      <>
        <div className="row">
          <div className="form-check custom-control custom-checkbox ml-2" style={{ left: "49px" }}>
            <input
              value={true}
              checked={(modalData && modalData.is_test_user) || ""}
              onChange={(event, value) => {
                this.setState({
                  modalData: {
                    ...modalData, is_test_user: event.target.checked
                  }
                });
              }}
              id="isTestClient"
              name="isTestClient"
              type="checkbox"
              className="custom-control-input"
              disabled={!isEditable}
            />
            <label className="custom-control-label" htmlFor="isTestClient">
              Is the user a test client?
            </label>
          </div>
        </div>
        <div className="row">
          <div className="noteText">
            Note : Please only use this to signify if the user is a test client. This is to ensure
            that in the database we can keep track of it.
          </div>
        </div>
      </>
    )

    if (this.state.accessDenied) {
      return <AccessDenied />
    }
    const newAddressModalForm = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="label">Label</label>
            <div className="input-group">
              <textarea
                value={newAddress?.label || ""}
                onChange={this.handleChange}
                id="label"
                name="label"
                type="text"
                placeholder="Enter Label"
                className={
                  "form-control py-2" +
                  (errors.label ? " has-error" : "")
                }

              />

            </div>
            <span className="help-block">{errors.label}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="address_line2">Street Name</label>
            <div className="input-group">
              <textarea
                value={newAddress?.address_line2 || ""}
                onChange={this.handleChange}
                id="address_line2"
                name="address_line2"
                type="text"
                placeholder="Enter Street Name"
                className={
                  "form-control py-2" +
                  (errors.address_line2 ? " has-error" : "")
                }

              />

            </div>
            <span className="help-block">{errors.address_line2}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="building_apt">Building/Apt.</label>
            <div className="input-group">
              <textarea
                value={newAddress?.building_apt || ""}
                onChange={this.handleChange}
                id="building_apt"
                name="building_apt"
                type="text"
                placeholder="Enter Building / Apt"
                className={
                  "form-control py-2" +
                  (errors.building ? " has-error" : "")
                }

              />

            </div>
            <span className="help-block">{errors.building_apt}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="address_line1">Area/District</label>
            <div className="input-group">
              <textarea
                value={newAddress?.address_line1 || ""}
                onChange={this.handleChange}
                id="address_line1"
                name="address_line1"
                type="text"
                placeholder="Enter Area /District"
                className={
                  "form-control py-2" +
                  (errors.address_line1 ? " has-error" : "")
                }

              />

            </div>

          </div>
        </div>
        <div className="row">
        
           <div className="form-group col">
            <label>Country *</label>
            <SelectCountry
              selectedCountry={selectedCountry}
              onSelect={(value) =>
                this.setState({ selectedCountry: value, selectedCity: null })
              }
            />
          </div>
          <div className="form-group col">
            <label>City *</label>
            <SelectCity
              countryId={selectedCountry?.value ?? null}
              selectedCity={selectedCity}
              onSelect={(value) => this.setState({ selectedCity: value })}
            />
          </div>

        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor={`phone_no`}>Phone Number</label>
            <PhoneInput
              value={newAddress.phone_no}
              onChange={(value, data) => {
                this.handleNewPhoneNumberChange(value, data);
              }}
              id={`phone_no`}
              inputStyle={

                { width: "100%" }
              }

              autocompleteSearch
              autoFormat
            />

            <span className="error">
            </span>
          </div>
          <div className="form-group col">
            <label htmlFor={`map_url`}>Pin Location</label>
            <div className="user-input-container">
              <div className="input-group">
                <input
                  type="text"
                  name="map_url"
                  id={`map_url`}
                  className={`form-control py-2`}
                  value={newAddress.map_url || ""}
                  onChange={this.handleChange}
                  readOnly={false}
                  autoComplete="off"

                />

              </div>
              <span className="error">
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="lat">Label</label>
            <div className="input-group">
              <input
                value={newAddress?.lat || ""}
                onChange={this.handleChange}
                id="lat"
                name="lat"
                type="text"
                placeholder="Enter Latitude"
                className={
                  "form-control py-2" +
                  (errors.lat ? " has-error" : "")
                }

              />

            </div>
            <span className="help-block">{errors.lat}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="lng">Longitude</label>
            <div className="input-group">
              <input
                value={newAddress?.lng || ""}
                onChange={this.handleChange}
                id="lng"
                name="lng"
                type="number"
                placeholder="Enter Longitude"
                className={
                  "form-control py-2" +
                  (errors.lng ? " has-error" : "")
                }

              />

            </div>
            <span className="help-block">{errors.lng}</span>
          </div>
        </div>
      </div>
    )
    return (
      <div>
        <div className="page-header">
          {/* <h5>Clients</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={this.state.searchExpanded}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchEnter={this.handleSearchEnter}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
              <div className="action-item">
                <button
                  className="btn button-blue btn-md new-user"
                  onClick={this.handleSearchEnter}
                >
                  Search
                </button>
              </div>
              <button
                className="btn button-blue btn-md new-user"
                onClick={this.createNewUser}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>New User
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            columns={this.columns}
            data={finalList}
            responsive
            highlightOnHover
            sortServer
            onSort={this.handleSort}
            // onSort={this.customSort}
            sortIcon={<ArrowDownward></ArrowDownward>}
            defaultSortField="id"
            defaultSortAsc={false}
            pagination
            paginationServer
            paginationTotalRows={this.state.totalRows || 0}
            // paginationTotalRows={this.state.totalPage}
            paginationPerPage={this.state.rowsPerPage || DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            // onChangePage={this.onUserPageChange}
            onChangeRowsPerPage={this.handleRowsPerPageChange}
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {/* {"Found " + resultCount + " results"} */}
                  {`Found ${this.state.totalRows} results`}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          // expandableRows={true
          // }
          // expandableRowsComponent={
          //   <DetailedView showReschedule={this.showReschedule} />
          // }
          // expandOnRowClicked
          />
        </Card>
        <div className="user-data-container">
          <Modal
            show={this.state.showModal}
            onHide={this.hideModal}
            id="userDataModal"
            size="lg"
            className="user-data-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <div className="user-title">{!newUser && this.state.userName}</div>
                <button
                  className="btn btn-md user-data-save"
                  onClick={this.saveUserData}
                  disabled={!isEditable}
                >
                  Save
                </button>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Tabs
                id="controlled-tab-example"
                activeKey={this.state.key}
                onSelect={(k) => this.setState({ key: k })}
                className="mb-3"
                fill
                justify
              >
                <Tab eventKey="personal" title="Personal">
                  {personalForm}
                </Tab>
                <Tab eventKey="profile" title="Profile">
                  {profileForm}
                </Tab>
                <Tab
                  eventKey="activity"
                  title="Activity"
                  disabled={newUser ? true : false}
                >
                  {activityForm}
                </Tab>
                <Tab eventKey="referralSettings"
                  title="Referral Settings"
                  disabled={newUser ? true : false}
                >
                  {referralSettingsForm}

                </Tab>
                <Tab
                  eventKey="transactions"
                  title="Transactions"
                  disabled={newUser ? true : false}
                >
                  <ClientTransactions clientId={modalData.id} />
                  {/* <Card>
                    <DataTable
                      highlightOnHover
                      columns={this.transactionColumns}
                      data={transactionList}
                      defaultSortField="date"
                      sortIcon={<ArrowDownward></ArrowDownward>}
                      onChangePage={this.onUserPageChange}
                      responsive
                      noHeader={searchApplied ? false : true}
                      title={
                        searchApplied ? (
                          <p className="table-header">
                            {"Found " + transactionListLength + " results"}
                          </p>
                        ) : (
                          ""
                        )
                      }
                      noDataComponent={
                        loadError ? (
                          <AlertBox message={loadError} error={true}></AlertBox>
                        ) : (
                          <AlertBox message="There's nothing here."></AlertBox>
                        )
                      }
                    />
                  </Card> */}

                </Tab>
                <Tab title="Subscriptions" eventKey="subscriptions" disabled={newUser ? true : false}>
                  <ClientSubscriptions clientId={modalData.id} isEditable={isEditable} />
                </Tab>
                <Tab title="Family Member" eventKey="family_member" disabled={newUser ? true : false}>
                  <FamilyMembers clientId={modalData.id} isEditable={isEditable} />
                </Tab>
                <Tab eventKey="is_test_client" title="Is Test Client ?">
                  {testClientForm}
                </Tab>
              </Tabs>
            </Modal.Body>
          </Modal>
        </div>
        <div>
          <Modal
            size="lg"
            show={this.state.isShowNewAddressModal}
            onHide={this.hideNewAddressModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Add New Address
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{newAddressModalForm}</Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-secondary rounded-pill px-4 py-2"
                onClick={this.hideNewAddressModal}
              >
                Cancel
              </button>
              <button
                onClick={this.handleSubmitNewAdress}
                className="btn btn-lg btn-success rounded-pill px-4 py-2"
              >
                Save
              </button>
            </Modal.Footer>
          </Modal>
        </div>
       
      </div>
    );
  }
}

// function DetailedView(props) {
//   const data = props.data;
//   let date = moment(data.user_profile?.dob).format('MM/DD/YYYY');
//   return (
//     <div className="detailed-view">
//       <h5>Client Details</h5>

//       <p className="mb-1">
//         <b>Full name: </b>
//         {data.username ? data.username : ''}
//       </p>

//       <p className="mb-1">
//         <b>Phone number: </b>
//         {data.phone_no ? data.phone_no : ''}
//       </p>

//       <p className="mb-1">

//         <b>Gender: </b>
//         {data.user_profile ? data.user_profile?.gender : ''}
//       </p>

//       <p className="mb-1">

//         <b>Date of birth: </b>
//         {data.user_profile ? date : ''}
//       </p>

//       <p className="mb-1">
//         <b>Height: </b>
//         {data.user_profile ? data.user_profile?.height : ''}
//       </p>

//       <p className="mb-1">
//         <b>Weight: </b>
//         {data.user_profile ? data.user_profile?.weight : ''}
//       </p>

//     </div>
//   );
// }

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);
