import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";

import { fetchCountries } from "services/RegionService";
import {
  fetchCoachPackages,
  saveCoachPackage,
  updateCoachPackage,
} from "services/CoachConsultationPackageService";
import { getUserId } from "services/UserService";
import PhoenixAPI from "utils/PhoenixAPI";
import API from "utils/API";
import { compareStrings } from "utils/commons";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { fetchValeoPartners } from "services/ValeoPartnersService";
import PackageCityDetails from "components/Common/PackageCityDetails";
import Select from "react-select";
import PackageOrders from "components/Common/PackageOrders";
import SelectSecondaryCategory from "components/Common/SelectSecondaryCategory"; 
import internalCategoryOptions from "utils/InterenalCategory";
import Logs from "components/Logs/Logs";
import {CharLimit} from "utils/CharachterLimit";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const TWO_MEGA_BYTE = 2_097_152;
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];
const BestSeller_Options=[
  {
    text: "YES",
    value: true,
    key: "YES",
  },
  {
    text: "NO",
    value: false,
    key: "NO",
  },
];

const DISCOUNT_OPTIONS = [
  {
    text: "Percentage",
    value: "percentage",
    key: "percentage",
  },
  {
    text: "Fixed",
    value: "fixed",
    key: "fixed",
  },
];

class CoachConsultationPackages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      coachPackages: [],
      rankOptions: [],
      coachPackage: {},
      selectedCountry: null,
      searchTerm: null,
      searchApplied: false,
      errors: {},
      editables: {},
      isCoachPackageViewModalVisible: false,
      isConfirmDeleteModalVisible: false,
      product: null,
      productOptions: [],
      associatedCoachOptions: [],
      valeoPartners: [],
      valeoPartnerOptions: [],
      coachConsulationPackageIdOrders:[],
      coachPackageIdRows:0,
      secondaryCategoryOptions:[],
      pageIdForLogs : null,
      productOptionsEdit:[],
      associatedCoaches:[],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name:"UID",
        selector:"packageUid",
        width: "150px",
        wrap:true,
        sortable: true,

      },
      {
        name: "Name",
        selector: "externalName",
        wrap: true,
        sortable: true,
      },
      {
        name:"Secondry Category",
        selector:"secondaryCategoriesName",
        wrap: true,
        sortable: true,
        
      },
      {
        name: "Price",
        selector: "finalPrice",
        wrap: true,
        sortable: true,
      },
      {
        name: "Rank",
        selector: "rank",
        width: "100px",
        sortable: true,
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        maxWidth: "150px",
        center: true,
        cell: (coachPackage) => (
          <Status isActive={coachPackage.isActive ?? false} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (coachPackage) => this.renderViewButton(coachPackage.id),
      },
    ];
  }

  async componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    await this.loadProducts();
    await this.loadAssociatedCoaches();
    this.fetchCoachPackages();
    this.fetchValeoPartners();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.state;
    if (selectedCountry !== prevState.selectedCountry)
      this.createProductOptionsList();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.OTHER_ITEMS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchCoachPackages = async () => {
    this.showLoading();
    try {
      const coachPackages = (await fetchCoachPackages()) || [];
      this.setState({ coachPackages });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching coach consultation packages: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCoachConsultationId=async(packageId)=>{
    if(!packageId){
      return null;
    }
    try {
      this.showLoading();
      const url = `package-orders/Coach_Consultation_Package/${packageId}?page=1&size=25&ordering=-id`;
      const { data } = await PhoenixAPI.get(url);
      console.log("fetchCoachPackageOrders:: Fetched Custom Packages: ", data);
      return {content:data.content,totalOrders:data.totalElements};
    }
    catch (error) {
      console.log("fetchCoachPackageOrders:: Fetched Coach orders: ", error);
      this.showErrorNotification("Error on fetching CoachPackage orders! " + error.message);
      return null;
    }
    finally {
      this.hideLoading();
    }
  }

  loadProducts = async () => {
    const url = "products/";
    const productMapper = (product) => ({
      id: product.id,
      name: product.name,
      title: product.title,
      status: product.status,
      country: product.country,
    });

    try {
      const { data: allProducts } = await API.get(url);
      const products = allProducts ? allProducts.map(productMapper) : [];

      this.setState({ products }, () => this.createProductOptionsList());
    } catch (error) {
      console.error("Error on fetching products from this URL: ", url);
      console.error("Error on fetching products", error);
    }
  };

  loadAssociatedCoaches = async () => {
    try {
      const { data = [] } = await PhoenixAPI.get(`nutritionist-user-name`);
      const activeAssociatedCoaches =
        data.length > 0 && data.filter((it) => it.is_active === true && it.calendlyLinkId !==null);
      const associatedCoachOptions = activeAssociatedCoaches.map((it) => {
        return {
          key: it.id,
          value: it.id,
          label: it.full_name,
          calendlyLinkId:it.calendlyLinkId
        };
      });
      
      this.setState({ associatedCoachOptions, associatedCoaches:activeAssociatedCoaches });
    } catch (error) {
      console.error("Error on fetching associated coaches", error);
    }
  };

  fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
      const partnerOptions = partners.map((it) => {
        return { key: it.id, value: it.id, label: it.name };
      });
      partnerOptions.push({ key: "select", value: "select", label: "Select" });
      this.setState({
        valeoPartners: partners,
        valeoPartnerOptions: partnerOptions,
      });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {}
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  createProductOptionsList = () => {
    const {
      products,
      selectedCountry,
      product: productId,
      isNewPackage,
    } = this.state;
    const productsForCountry =
      products && selectedCountry
        ? products.filter((product) => product.country === selectedCountry.id)
        : [];

    if (!isNewPackage && productId) {
      const product = this.findProduct(productId);
      if (product && !productsForCountry.find(product))
        productsForCountry.push(product);
    }

    const activeProducts = productsForCountry.filter(
      (product) => product.status === "Active"
    );
    activeProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const inactiveProducts = productsForCountry.filter(
      (product) => product.status === "Inactive"
    );
    inactiveProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const noneOption = {
      key: "none",
      value: "none",
      text: "NONE",
    };
    const productOptions = [...activeProducts, ...inactiveProducts].map(
      (product) => ({
        key: product.id,
        value: product.id,
        text: `${product.name}${
          product.status === "Inactive" ? " (Inactive)" : ""
        }`,
      })
    );
    this.setState({ productOptions: [noneOption, ...productOptions],productOptionsEdit: [noneOption, ...productOptions],  secondaryCategoryOptions:activeProducts });
  };

  handleChange = ({ currentTarget: input }) => {
    const numberInputs = ["retailPrice", "finalPrice", "discountValue"];
    const coachPackage = { ...this.state.coachPackage };
    if (numberInputs.includes(input.name))
      coachPackage[input.name] = input.value ? Number(input.value) : "";
    
    else coachPackage[input.name] = input.value;
    this.setState({ coachPackage });
  };

  handleAddNewCoachPackage = () => {
    this.setState({ isNewCoachPackage: true }, () =>
      this.showCoachPackageViewModal()
    );
  };
   productNameList=()=>{
    const {
      products,
      selectedCountry,
      product: productId,
      isNewPackage,
    } = this.state;
    const productsForCountry =
      products && selectedCountry
        ? products.filter((product) => product.country === selectedCountry.id)
        : [];

    if (!isNewPackage && productId) {
      const product = this.findProduct(productId);
      if (product && !productsForCountry.find(product))
        productsForCountry.push(product);
    }

    const activeProducts = productsForCountry.filter(
      (product) => product.status === "Active"
    );
    activeProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const inactiveProducts = productsForCountry.filter(
      (product) => product.status === "Inactive"
    );
    inactiveProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    return activeProducts
    
   }
  handleViewCoachPackage = async (packageId) => {
    // const coachPackages = this.state.coachPackages ?? [];
    // const coachPackage = coachPackages.find((it) => it.id === packageId);
    if (!packageId) {
      console.log(`Coach Package with id ${packageId} is not found!`);
      return;
    }

    this.setState({
      pageIdForLogs : packageId
    })
    
    const secondaryCategoryOptions = this.productNameList()
    const { data: coachPackage = {} } = await PhoenixAPI.get(
      `coach-consultation-package/${packageId}`
    );

    const coachConsultationPackageIdDetails= await this.fetchCoachConsultationId(packageId);
    const {valeoPartners,associatedCoaches}= this.state || {}
    const partnerId= coachPackage.partnerId ?? null;
    const selectedPartner= valeoPartners.find((it)=>it.id===partnerId);
  
     const filteredSecondaryCategoryOptions= secondaryCategoryOptions &&secondaryCategoryOptions.filter((item)=>item.id!==coachPackage?.productCategoryId)
    const filteredPrimaryCategoryOptions = this.state.productOptions &&
      this.state.productOptions.filter((item) => {
        // Check if `selectedPackage.secondary_categories` exists
        if (coachPackage.secondaryCategories) {
          return !coachPackage.secondaryCategories.includes(item.key);
        }
        return true; // If `secondary_categories` doesn't exist, keep the item
      });

  

  
    const selectedCoach=associatedCoaches.filter((it)=>it.id===coachPackage.associateCoachId)

    const selectedCoachCalendlyLink= selectedCoach && selectedCoach.find((it)=>it.calendlyLinkId==coachPackage.calendlyLinkId)


     this.setState({ coachPackage, isNewCoachPackage: false,


      coachConsulationPackageIdOrders:coachConsultationPackageIdDetails.content,
      coachPackageIdRows:coachConsultationPackageIdDetails.totalOrders,
      valeoPartnerId: selectedPartner?.id || null,
      valeoPartnerName:selectedPartner?.name || "",
      secondaryCategoryOptions:filteredSecondaryCategoryOptions,



      productOptionsEdit:filteredPrimaryCategoryOptions,


      associateCoachName:selectedCoachCalendlyLink?selectedCoachCalendlyLink.full_name:"",
      associateCoachId:selectedCoachCalendlyLink?selectedCoachCalendlyLink.id:null,
      associateCalendlyLinkId:selectedCoachCalendlyLink?selectedCoachCalendlyLink.calendlyLinkId:null,

    }, () =>
      this.showCoachPackageViewModal()
    );
  };

  showCoachPackageViewModal = () => {
    const { selectedCountry, isNewCoachPackage } = this.state;
    const rankOptions = this.createRankOptions() || [];
 
    if (isNewCoachPackage) {
      const rank = Math.max(...rankOptions.map((it) => it.value));
      const coachPackage = {
        countryId: selectedCountry?.id,
        currency: selectedCountry?.currency,
        status: "Inactive",
        isBestSeller:false,
        rank,
        partnerId:null,
        internalCategory:"None",
        associateCalendlyLinkId:null,
        associateCoachName:null,
        associateCoachId:null,
      };
      this.setState({
        coachPackage,
        rankOptions,
        isCoachPackageViewModalVisible: true,
      });
    } else {

      this.setState({ rankOptions, isCoachPackageViewModalVisible: true });
    }
  };

  hideCoachPackageViewModal = () => {
    this.setState({ isCoachPackageViewModalVisible: false, pageIdForLogs:null });
    this.resetForm();
  };

  handlesaveCoachPackage = async () => {
    const { selectedCountry, coachPackage, isNewCoachPackage } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }
    if (coachPackage.internalCategory == "None") {
      coachPackage.internalCategory = "";
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const isValidId =
        coachPackage.id !== null && coachPackage.id !== undefined;

      const response = isValidId
        ? await updateCoachPackage(coachPackage)
        : await saveCoachPackage(coachPackage);

      this.hideCoachPackageViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Coach Consultation Package Updated Successfully."
          : "Coach Consultation Package Created Successfully."
      );
      this.fetchCoachPackages();
    } catch (error) {
      let seoExceptionCode = error.response.status;
      if (seoExceptionCode === 500) {
        this.showErrorNotification("seo url already exists: " + error);
      } else {
        this.showErrorNotification("Some error occurred: " + error.message);
      }
    } finally {
      this.hideLoading();
    }
  };

  validate = () => {
    const { coachPackage = {} } = this.state;
    console.log("coachPackage validate:", coachPackage);
    const errors = {};

    const {
      status,
      internalName,
      externalName,
      externalNameAr,
      rank,
      displayName,
      displayNameAr,

      retailPrice,
      finalPrice,
      discountType,
      discountValue,
      zohoAccountId,
      seoUrl,
      metaTitle,
      metaDescription,
      keywords,
      internalCategory,
    } = coachPackage;

    if (status === null) errors.status = "Status is a required field!";

    if (!internalName || internalName.trim() === "")
      errors.internalName = "Internal Name cannot be blank";
    else if (internalName.length > CharLimit.HUNDRED_LIMIT)
      errors.internalName = "Internal Name is limited to a 100 characters";

    if (!externalName || externalName.trim() === "")
      errors.externalName = "External Name (English) cannot be blank";
    else if (externalName.length > CharLimit.HUNDRED_LIMIT)
      errors.externalName =
        "External Name (English) is limited to a 100 characters";

    if (!externalNameAr || externalNameAr.trim() === "")
      errors.externalNameAr = "External Name (Arabic) cannot be blank";
    else if (externalNameAr.length > CharLimit.HUNDRED_LIMIT)
      errors.externalNameAr =
        "External Name (Arabic) is limited to a 100 characters";

    if (!displayName || displayName.trim() === "")
      errors.displayName = "Display Name (English) cannot be blank";
    else if (displayName.length > CharLimit.HUNDRED_LIMIT)
      errors.displayName =
        "Display Name (English) is limited to a 100 characters";

    if (!displayNameAr || displayNameAr.trim() === "")
      errors.displayNameAr = "Display Name (Arabic) cannot be blank";
    else if (displayNameAr.length > CharLimit.HUNDRED_LIMIT)
      errors.displayNameAr =
        "Display Name (Arabic) is limited to a 100 characters";

    if (rank === null || rank === undefined)
      errors.rank = "Rank cannot be blank";

    if (zohoAccountId && zohoAccountId.length > CharLimit.FOURTY_FIVE_LIMIT) {
      errors.zohoAccountId = "Zoho  Account Id is limited to 45 characters";
    } else if (!zohoAccountId) {
      errors.zohoAccountId = "Zoho  Account Id is a required field!";
    }

    if (
      retailPrice === null ||
      retailPrice === undefined ||
      retailPrice.toString().trim() === ""
    )
      errors.retailPrice = "Retail Price is a required field!";
    else if (Number.isNaN(Number(retailPrice)))
      errors.retailPrice = "Retail Price should be a valid number!";
    else if (retailPrice < 0)
      errors.retailPrice = "Retail Price should not be a negative number!";

    if (
      finalPrice === null ||
      finalPrice === undefined ||
      finalPrice.toString().trim() === ""
    )
      errors.finalPrice = "Final Price is a required field!";
    else if (Number.isNaN(Number(finalPrice)))
      errors.finalPrice = "Final Price should be a valid number!";
    else if (finalPrice < 0)
      errors.finalPrice = "Final Price should not be a negative number!";

    if (
      discountType === null ||
      discountType === undefined ||
      discountType.toString().trim() === ""
    )
      errors.discountType = "Discount Type is a required field!";

    if (
      discountValue === null ||
      discountValue === undefined ||
      discountValue.toString().trim() === ""
    )
      errors.discountValue = "Discount Value is a required field!";
    else if (Number.isNaN(Number(discountValue)))
      errors.discountValue = "Discount Value should be a valid number!";
    else if (discountValue < 0)
      errors.discountValue = "Discount value should not be a negative number!";
      
      if (!seoUrl || seoUrl.trim() === "")
      errors.seoUrl = "Seo URL cannot be blank";
    else if (seoUrl.length > CharLimit.HUNDRED_LIMIT)
      errors.seoUrl = "URL can not contain more than 100 characters!";

    if (metaTitle && metaTitle.length > CharLimit.HUNDRED_LIMIT)
      errors.metaTitle = "Title can not contain more than 100 characters!";

    if (metaDescription && metaDescription.length > CharLimit.TWO_HUNDRED_LIMIT)
      errors.metaDescription =
        "Description can not contain more than 200 characters!";

    if (keywords && keywords.length > CharLimit.FIVE_HUNDERED_LIMIT)
      errors.keywords = "keywords can not contain more than 500 characters!";

    return Object.keys(errors).length === 0 ? null : errors;
  };

  isInBetween = (number, start, end) => number >= start && number <= end;

  createRankOptions = () => {
    const { isNewCoachPackage } = this.state;
    const coachPackages = this.getOtherItemsForCurrentCountry();
    const ranks = coachPackages.filter((it) => it.rank).map((it) => it.rank);
    const minRank = Math.min(1, ...ranks) || 0;
    const maxRank = Math.max(...ranks) || 0;
    const until = isNewCoachPackage ? maxRank + 1 : maxRank;
    const rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });

    if (rankOptions.length === 0)
      rankOptions.push({ key: 1, value: 1, text: 1 });
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  };

  resetForm = () => {
    this.setState({
      coachPackage: {},
      errors: {},
      editables: {},
      partnerId: null,
      valeoPartnerId:null,
      valeoPartnerName:"",
       associateCoachId:null,
        associateCalendlyLinkId:null,
        associateCoachName:null,
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewCoachPackage(id);
        }}
      >
        View
      </button>
    </div>
  );

  handleSelectPartner = (partnerId) => {
    const { valeoPartnerOptions, valeoPartners, coachPackage } =
      this.state || {};
    if (partnerId === "select") {
      const newCoachPackage = { ...coachPackage, partnerId: null };
      this.setState({
        ...this.state,
        partnerId: null,
        valeoPartnerId: null,
        valeoPartnerName: "SELECT",
        coachPackage: newCoachPackage,
      });
    } else {
      const selectedId = valeoPartners.find((it) => it.id === partnerId) ?? {};
      const newCoachPackage = {
        ...coachPackage,
        partnerId: selectedId.id ?? null,
      };
      this.setState({
        ...this.state,
        partnerId: selectedId.id ?? null,
        valeoPartnerId: selectedId.id ?? null,
        valeoPartnerName: selectedId.name ?? "",
        coachPackage: newCoachPackage,
      });
    }
  };
  handleSelectAssociateCoach=(coachId,coachCalendlyLinkId)=>{
   const {associatedCoachOptions, coachPackage, associatedCoaches}=this.state;
   const selectCoach=associatedCoaches.filter((it)=>it.id===coachId)
   const selectCoachCalendlyLink= selectCoach.length>0 && selectCoach.find((it)=>it.calendlyLinkId==coachCalendlyLinkId)
  

   const newCoachPackage = {
    ...coachPackage,
    associateCoachId:selectCoachCalendlyLink? selectCoachCalendlyLink.id : null,
    calendlyLinkId:selectCoachCalendlyLink?selectCoachCalendlyLink.calendlyLinkId:null
  };
  this.setState({...this.state,associateCoachName:selectCoachCalendlyLink.full_name,associateCoachId:selectCoachCalendlyLink.id,associateCalendlyLinkId:selectCoachCalendlyLink.calendlyLinkId,coachPackage:newCoachPackage})

  }
  handleCityPackageItemsUpdated = (cityPackageItems = []) => {
    const { coachPackage } = this.state;
    this.setState({
      coachPackage: { ...coachPackage, cityDetails: cityPackageItems },
    });
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewCoachPackage ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredCoachPackages = () => {
    const coachPackages = this.getOtherItemsForCurrentCountry();
    return this.getSearchedOtherItems(coachPackages);
  };

  getOtherItemsForCurrentCountry = () => {
    const { coachPackages, selectedCountry } = this.state;
    return coachPackages && selectedCountry
      ? coachPackages.filter(
          (coachPackage) => coachPackage.countryId === selectedCountry.id
        )
      : [];
  };

  findProduct = (id) =>
    id && this.state.products
      ? this.state.products.find((product) => product.id === id)
      : null;

  getCountry = (selectedCountry) => {
    return selectedCountry;
  };

  getSearchedOtherItems = (coachPackages) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return coachPackages;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return coachPackages.filter((coachPackage) => {
      const {
        id,
        internalName,
        externalNameEnglish,
        externalNameArabic,
        rank,
        isNonCoachPackage,
      } = coachPackage;
      return (
        (internalName && internalName.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameEnglish &&
          externalNameEnglish.toLowerCase().indexOf(newValue) !== -1) ||
        (externalNameArabic &&
          externalNameArabic.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue) ||
        (rank && rank.toString() === newValue)
      );
    });
  };

  createPartnerOption = (bloodPackage) => {
    return {
      key: bloodPackage.valeoPartnerId,
      value: bloodPackage.valeoPartnerId,
      label: bloodPackage.valeoPartnerName,
    };
  };
  
  createOptionSelectCoach = (coach) => {
    return {
      key: coach.associateCalendlyLinkId,
      value: coach.associateCalendlyLinkId,
      label: coach.associateCoachName,
      calendlyLinkId: coach.associateCalendlyLinkId,
      associateCoachId:coach.associateCoachId
    }
  }

  render() {
    const {
      coachPackages,
      coachPackage,
      isNewCoachPackage,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
      valeoPartnerOptions,
      coachConsulationPackageIdOrders,
      coachPackageIdRows,
      secondary_categories,
      secondaryCategoryOptions,
      associatedCoachOptions
    } = this.state;
   
    const packageCountry = this.getCountry(selectedCountry);
    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const filteredCoachPackages =
      this.getFilteredCoachPackages(coachPackages) ?? [];

    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalName">Internal Name*</label>
            <div className="input-group">
              <input
                value={coachPackage?.internalName || ""}
                onChange={this.handleChange}
                id="internalName"
                name="internalName"
                type="text"
                placeholder="Internal Name"
                className={
                  "form-control py-2" +
                  (errors.internalName ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.internalName}
              />
              {this.renderPencilIcon("internalName")}
            </div>
            <span className="help-block">{errors.internalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status</label>
            <div className="input-group">
              <Dropdown
                value={coachPackage?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    coachPackage: {
                      ...coachPackage,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewCoachPackage && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="externalName">External Name (English)*</label>
            <div className="input-group">
              <input
                value={coachPackage?.externalName || ""}
                onChange={this.handleChange}
                id="externalName"
                name="externalName"
                type="text"
                placeholder="External Name in English"
                className={
                  "form-control py-2" +
                  (errors.externalName ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.externalName}
              />
              {this.renderPencilIcon("externalName")}
            </div>
            <span className="help-block">{errors.externalName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="externalNameAr">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={coachPackage?.externalNameAr || ""}
                onChange={this.handleChange}
                id="externalNameAr"
                name="externalNameAr"
                type="text"
                placeholder="External Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.externalNameAr ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.externalNameAr}
              />
              {this.renderPencilIcon("externalNameAr")}
            </div>
            <span className="help-block">{errors.externalNameAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="displayName">Display Name (English)*</label>
            <div className="input-group">
              <input
                value={coachPackage?.displayName || ""}
                onChange={this.handleChange}
                id="displayName"
                name="displayName"
                type="text"
                placeholder="Display Name in English"
                className={
                  "form-control py-2" + (errors.displayName ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.displayName}
              />
              {this.renderPencilIcon("displayName")}
            </div>
            <span className="help-block">{errors.displayName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="displayNameAr">Display Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={coachPackage?.displayNameAr || ""}
                onChange={this.handleChange}
                id="displayNameAr"
                name="displayNameAr"
                type="text"
                placeholder="Display Name in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.displayNameAr ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.displayNameAr}
              />
              {this.renderPencilIcon("displayNameAr")}
            </div>
            <span className="help-block">{errors.displayNameAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="product">Product Category</label>
            <div className="input-group">
              <Dropdown
                value={coachPackage.productCategoryId || "none"}
                onChange={(event, value) => {
                  const updatedSecondaryCategoryOptions = value.value === "none"
                    ? secondaryCategoryOptions // If "none" is selected, keep original options
                    : this.productNameList().filter((catOption) => catOption.id !== value.value)
                  this.setState({
                    coachPackage: {
                      ...coachPackage,
                      productCategoryId:
                        value.value === "none" ? null : value.value,
                    },
                    secondaryCategoryOptions: updatedSecondaryCategoryOptions
                  });
                }}
                id="product"
                name="product"
                placeholder="Product"
                search
                selection
                options={this.state.productOptionsEdit}
                disabled={!isNewCoachPackage && !editables.product}
                className={
                  "form-control" + (errors.product ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("product")}
            </div>
            <span className="help-block">{errors.product}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Rank</label>
            <div className="input-group">
              <Dropdown
                value={coachPackage?.rank}
                onChange={(event, value) => {
                  this.setState({
                    coachPackage: { ...coachPackage, rank: value.value },
                  });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankOptions}
                disabled={!isNewCoachPackage && !editables.rank}
                className={"form-control" + (errors.rank ? " has-error" : "")}
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.rank}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="product">Associate Coach</label>
            <div className="input-group">
              {/* <Dropdown
                value={coachPackage.associateCoachId || "none"}
                onChange={(event, value) => {    
                  this.setState({
                    coachPackage: {
                      ...coachPackage,
                      associateCoachId:
                        value.value === "none" ? null : value.value,
                    },
                  });
                }}
                id="associatedCoach"
                name="associatedCoach"
                placeholder="Associated Coach"
                search
                selection
                options={this.state.associatedCoachOptions}
                disabled={!isNewCoachPackage && !editables.associatedCoach}
                className={
                  "form-control" + (errors.associatedCoach ? " has-error" : "")
                }
              /> */}
              <Select
              key={`my_unique_select_key${coachPackage.calendlyLinkId}`}
              value={
                this.createOptionSelectCoach(this.state)
              }
              onChange={(option) => {
                this.handleSelectAssociateCoach(option.value,option.calendlyLinkId)
                // this.setState({
                //   coachPackage: {
                //     ...coachPackage,
                //     associateCoachId: option.value,
                //     calendlyLinkId:option.calendlyLinkId,
                    
                //   },
                // });
              }}
              options={this.state.associatedCoachOptions || []}
              styles={selectStyles}
              placeholder="Select Associate Coach"
              isDisabled={!isNewCoachPackage && !editables.associatedCoach}
              />
              {this.renderPencilIcon("associatedCoach")}
            </div>
            <span className="help-block">{errors.associatedCoach}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="zohoAccountId">Zoho Account ID *</label>
            <div className="input-group">
              <input
                value={coachPackage.zohoAccountId ?? ""}
                onChange={this.handleChange}
                id="zohoAccountId"
                name="zohoAccountId"
                type="number"
                maxLength="45"
                placeholder="Zoho account Id"
                className={
                  "form-control py-2" +
                  (errors.zohoAccountId ? " has-error" : "")
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
                disabled={!isNewCoachPackage && !editables.zohoAccountId}
              />
              {this.renderPencilIcon("zohoAccountId")}
            </div>
            <span className="help-block">{errors.zohoAccountId}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="partnerId">Select Partner Id</label>
            <Select
              key={`my_unique_select_key__${coachPackage.partnerId}`}
              value={
                coachPackage.partnerId === null
                  ? this.createPartnerOption(this.state)
                  : this.createPartnerOption(this.state)
              }
              onChange={(event) => {
                this.handleSelectPartner(event.value);
              }}
              options={valeoPartnerOptions || []}
              style={selectStyles}
              placeholder="Select Parnter"
              searchable
              lazyLoad
            />
            {/* {this.renderPencilIcon("partnerId")} */}

            <span className="help-block">{errors.partnerId}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Is BestSeller?</label>
            <div className="input-group">
              <Dropdown
                value={coachPackage?.isBestSeller}
                onChange={(event, value) => {
                  this.setState({
                    coachPackage: {
                      ...coachPackage,
                      isBestSeller: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={BestSeller_Options}
                disabled={!isNewCoachPackage && !editables.isBestSeller}
                className={"form-control" + (errors.isBestSeller ? " has-error" : "")}
              />
              {this.renderPencilIcon("isBestSeller")}
            </div>
            <span className="help-block">{errors.isBestSeller}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="cancellationNote">
              Cancellation Note (English)
            </label>
            <div className="input-group">
              <input
                value={coachPackage?.cancellationNote || ""}
                onChange={this.handleChange}
                id="cancellationNote"
                name="cancellationNote"
                type="text"
                placeholder="Cancellation Note in English"
                className={
                  "form-control py-2" +
                  (errors.cancellationNote ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.cancellationNote}
              />
              {this.renderPencilIcon("cancellationNote")}
            </div>
            <span className="help-block">{errors.cancellationNote}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="cancellationNoteAr">
              Cancellation Note (Arabic)
            </label>
            <div className="input-group">
              <input
                value={coachPackage?.cancellationNoteAr || ""}
                onChange={this.handleChange}
                id="cancellationNoteAr"
                name="cancellationNoteAr"
                type="text"
                placeholder="Cancellation Note in Arabic"
                className={
                  "form-control py-2 input-arabic" +
                  (errors.cancellationNoteAr ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.cancellationNoteAr}
              />
              {this.renderPencilIcon("cancellationNoteAr")}
            </div>
            <span className="help-block">{errors.cancellationNoteAr}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="internalCategory">Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={coachPackage?.internalCategory || "None"}
                onChange={(event, value) => {
                  this.setState({
                    coachPackage: {
                      ...coachPackage,
                      internalCategory: value.value,
                    },
                  });
                }}
                id="internalCategory"
                name="internalCategory"
                placeholder="Internal Category"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewCoachPackage && !editables.internalCategory}
                className={"form-control" + (errors.internalCategory ? " has-error" : "")}
              />
              {this.renderPencilIcon("internalCategory")}
            </div>
            <span className="help-block">{errors.internalCategory}</span>
          </div>
          <div className="form-group col-6">
             <label>Select Secondary Category</label>
             <div className="input-group">
             <SelectSecondaryCategory
              categories={secondaryCategoryOptions ?? []}
              selectedCategories={coachPackage?.secondaryCategories??[]}
              onSelect={(values) => {
                const filteredProductOptions = values.length > 0
                  ? this.state.productOptions.filter((item) => {
                    // Filter out items whose id matches any value in `values`
                    return !values.some((value) => value.value === item.key);
                  })
                  : this.state.productOptions;
                this.setState({
                  coachPackage: {
                    ...coachPackage,
                    secondaryCategories: values.map((it) => it.value),
                  },
                  productOptionsEdit: filteredProductOptions,
                 

                });
              }}
              isDisabled={!isNewCoachPackage && !editables.secondaryCategories}
                className={
                  "form-control" + (errors.secondaryCategories ? " has-error" : "")
                }
                menuPlacement={"top"}
             />
               {this.renderPencilIcon("secondaryCategories")}
             </div>
             <span className="help-block">{errors.secondaryCategories}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col" >
  
              <div className="input-group">
              <input
                value={true}
                checked={coachPackage?.isNonCoachPackage && coachPackage?.isNonCoachPackage || false}
                onChange={(event, value) => { console.log("target value",event.target.checked);
                  this.setState({
                    coachPackage: { ...coachPackage, isNonCoachPackage:event.target.checked}
                  });
                }}
                id="isRequired"
                name="is_non_coach_package"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp;  Is non coach package?
               </div> 

        </div>
        <div className="form-group col" >
              
              <div className="input-group">
              <input
                value={true}
                checked={coachPackage?.isB2bPackage && coachPackage?.isB2bPackage || false}
                onChange={(event, value) => { 
                  this.setState({
                    coachPackage: { ...coachPackage, isB2bPackage:event.target.checked}
                  });
                }}
                id="isRequired"
                name="isB2bPackage"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; is this a B2B Package?
               </div> 

        </div>
        
        </div>
      </div>
    );

    const coachConsultationPackageCitiesDetails = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>City Level Details</span>
          </div>
        </div>
        <PackageCityDetails
          cityPackageDetails={coachPackage.cityDetails || []}
          onCityPackageDetailsUpdated={this.handleCityPackageItemsUpdated}
          packageType={"coachConsultationPackage"}
          packageId={coachPackage.id || null}
          selectedCountry={selectedCountry}
          isEditable={isEditable}
        />
      </>
    );

    const formPricingOneTimeContent = (
      <div className="container">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="retailPrice">Retail Price*</label>
            <div className="input-group">
              <input
                value={coachPackage.retailPrice || ""}
                onChange={this.handleChange}
                id="retailPrice"
                name="retailPrice"
                type="text"
                placeholder="Retail Price"
                disabled={!isNewCoachPackage && !editables.retailPrice}
                className={
                  "form-control py-2" + (errors.retailPrice ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("retailPrice")}
            </div>
            <span className="help-block">{errors.retailPrice}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="finalPrice">Final Price*</label>
            <div className="input-group">
              <input
                value={coachPackage.finalPrice ?? ""}
                onChange={this.handleChange}
                id="finalPrice"
                name="finalPrice"
                type="text"
                placeholder="Final Price"
                disabled={!isNewCoachPackage && !editables.finalPrice}
                className={
                  "form-control py-2" + (errors.finalPrice ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("finalPrice")}
            </div>
            <span className="help-block">{errors.finalPrice}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="discount_type">Discount Type*</label>
            <div className="input-group">
              <Dropdown
                value={coachPackage.discountType || ""}
                onChange={(event, value) => {
                  this.setState({
                    coachPackage: {
                      ...coachPackage,
                      discountType: value.value,
                    },
                  });
                }}
                id="discount_type"
                placeholder="Select Discount Type"
                search
                selection
                options={DISCOUNT_OPTIONS}
                disabled={!isNewCoachPackage && !editables.discountType}
                className={
                  "form-control" + (errors.discountType ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discountType")}
            </div>
            <span className="help-block">{errors.discountType}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="discountValue">Discount Value*</label>
            <div className="input-group">
              <input
                value={coachPackage.discountValue ?? ""}
                onChange={this.handleChange}
                id="discountValue"
                name="discountValue"
                type="number"
                placeholder="Discount Value"
                disabled={!isNewCoachPackage && !editables.discountValue}
                className={
                  "form-control " + (errors.discountValue ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discountValue")}
            </div>
            <span className="help-block">{errors.discountValue}</span>
          </div>
        </div>
      </div>
    );
    const locationAndPricingForm = (
      <>
        <div className="container mt-4">
          <div className="row">
            <div className="form-group col">
            
            <div  className="form-check custom-control custom-checkbox ml-2"
              style={{ left: "7px", bottom: "7px" }}
            >
              <input
                value={true}
                checked={
                  (coachPackage && coachPackage.isPaidConsultation) || ""
                }
                onChange={(event, value) => {
                  this.setState({
                    coachPackage: {
                      ...coachPackage,
                      isPaidConsultation: event.target.checked,
                    },
                  });
                }}
                id="isPaidConsultation"
                name="isPaidConsultation"
                type="checkbox"
                className="custom-control-input"
                disabled={!isEditable}
              />
              <label
                className="custom-control-label"
                htmlFor="isPaidConsultation"
              >
                Is Paid Consultation ?
              </label>
            </div>
            </div>
            <div className="form-group col">
            <div
              className="form-check custom-control custom-checkbox ml-2"
              style={{ left: "7px", bottom: "7px" }}
            >
              <input
                value={true}
                checked={
                  (coachPackage && coachPackage.isVatExcluded) || ""
                }
                onChange={(event, value) => {
                  this.setState({
                    coachPackage: {
                      ...coachPackage,
                      isVatExcluded: event.target.checked,
                    },
                  });
                }}
                id="isVatExcluded"
                name="isVatExcluded"
                type="checkbox"
                className="custom-control-input"
                disabled={!isEditable}
              />
              <label
                className="custom-control-label"
                htmlFor="isVatExcluded"
              >
                No VAT for this product
              </label>
            </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col">
              <label htmlFor="country">Country</label>
              <input
                value={
                  isNewCoachPackage
                    ? selectedCountry && selectedCountry.country_name
                    : packageCountry && packageCountry.country_name
                }
                type="text"
                id="country"
                name="country"
                readOnly
                className="form-control"
              />
            </div>
            <div className="form-group col">
              <label htmlFor="currency">Currency</label>
              <input
                value={
                  isNewCoachPackage
                    ? selectedCountry && selectedCountry.currency
                    : packageCountry && packageCountry.currency
                }
                type="text"
                id="currency"
                name="currency"
                readOnly
                className="form-control"
              />
            </div>
          </div>
        </div>
        {formPricingOneTimeContent}
        {coachConsultationPackageCitiesDetails}
      </>
    );

    const formSeoMarketing = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="seoUrl">Seo URL *</label>
            <div className="input-group">
              <input
                value={(coachPackage && coachPackage.seoUrl) || ""}
                onChange={this.handleChange}
                id="seoUrl"
                name="seoUrl"
                type="text"
                placeholder="Seo URL"
                className={
                  "form-control py-2" + (errors.seoUrl ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.seoUrl}
              />
              {this.renderPencilIcon("seoUrl")}
            </div>
            <span className="help-block">{errors.seoUrl}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="metaTitle">Meta Title</label>
            <div className="input-group">
              <input
                value={(coachPackage && coachPackage.metaTitle) || ""}
                onChange={this.handleChange}
                id="metaTitle"
                name="metaTitle"
                type="text"
                placeholder="Meta Title"
                className={
                  "form-control py-2" + (errors.metaTitle ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.metaTitle}
              />
              {this.renderPencilIcon("metaTitle")}
            </div>
            <span className="help-block">{errors.metaTitle}</span>
          </div>{" "}
          <div className="form-group col">
            <label htmlFor="metaTitleAr">Meta Title Arabic</label>
            <div className="input-group">
              <input
                value={(coachPackage && coachPackage.metaTitleAr) || ""}
                onChange={this.handleChange}
                id="metaTitleAr"
                name="metaTitleAr"
                type="text"
                placeholder="Meta Title Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.metaTitleAr ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.metaTitleAr}
              />
              {this.renderPencilIcon("metaTitleAr")}
            </div>
            <span className="help-block">{errors.metaTitleAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="metaDescription">Meta Description</label>
            <div className="input-group">
              <input
                value={(coachPackage && coachPackage.metaDescription) || ""}
                onChange={this.handleChange}
                id="metaDescription"
                name="metaDescription"
                type="text"
                placeholder="Meta Description"
                className={
                  "form-control py-2" +
                  (errors.metaDescription ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.metaDescription}
              />
              {this.renderPencilIcon("metaDescription")}
            </div>
            <span className="help-block">{errors.metaDescription}</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="metaDescriptionAr">Meta Description Arabic</label>
            <div className="input-group">
              <input
                value={(coachPackage && coachPackage.metaDescriptionAr) || ""}
                onChange={this.handleChange}
                id="metaDescriptionAr"
                name="metaDescriptionAr"
                type="text"
                placeholder="Meta Description Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.metaDescriptionAr ? " has-error" : "")
                }
                disabled={!isNewCoachPackage && !editables.metaDescriptionAr}
              />
              {this.renderPencilIcon("metaDescriptionAr")}
            </div>
            <span className="help-block">{errors.metaDescriptionAr}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="keywords">Keywords</label>
            <div className="input-group">
              <textarea
                value={(coachPackage && coachPackage.keywords) || ""}
                onChange={this.handleChange}
                id="keywords"
                name="keywords"
                placeholder="Keywords"
                className={
                  "form-control py-2" + (errors.keywords ? " has-error" : "")
                }
                readOnly={!isNewCoachPackage && !editables.keywords}
              ></textarea>
              {this.renderPencilIcon("keywords")}
            </div>
            <span className="help-block">{errors.keywords}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="keywordsAr">Keywords Arabic</label>
            <div className="input-group">
              <textarea
                value={(coachPackage && coachPackage.keywordsAr) || ""}
                onChange={this.handleChange}
                id="keywordsAr"
                name="keywordsAr"
                placeholder="Keywords Arabic"
                className={
                  "form-control input-arabic py-2" +
                  (errors.keywordsAr ? " has-error" : "")
                }
                readOnly={!isNewCoachPackage && !editables.keywordsAr}
              ></textarea>
              {this.renderPencilIcon("keywordsAr")}
            </div>
            <span className="help-block">{errors.keywordsAr}</span>
          </div>
        </div>
      </div>
    );

    const coachConsulationPackageIdOrdersForm=(<>
    <PackageOrders
    packageType={"Coach_Consultation_Package"}
    packageId={coachPackage.id}
    packageOrderDetails={coachConsulationPackageIdOrders}
    totalRows={coachPackageIdRows}
    />
    </>)

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewCoachPackage}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredCoachPackages || []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredCoachPackages.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isCoachPackageViewModalVisible}
          onHide={this.hideCoachPackageViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Coach Package: </span>
                {coachPackage?.internalName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handlesaveCoachPackage}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_details" justify>
              <Tab eventKey="key_form_details" title="Details">
                {formBasicInformation}
              </Tab>
              <Tab eventKey="key_seo_form" title="Seo Marketing">
                {formSeoMarketing}
              </Tab>
              <Tab
                eventKey="key_form_location_pricing"
                title="Location & Pricing"
              >
                {locationAndPricingForm}
              </Tab>
              {!isNewCoachPackage && <Tab eventKey="key_form_coachpackageid_orders"
                title="Orders" >
                {coachConsulationPackageIdOrdersForm}</Tab>}
              {this.state.pageIdForLogs!=null? 
                <Tab eventKey="logs-coach-consultation" title="Logs" >
                  <Logs pageId={this.state.pageIdForLogs} pageType='COACH_CONSULTATION_PACKAGE' />
                </Tab>
              : ""}
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoachConsultationPackages);
