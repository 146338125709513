import API from "utils/API";

const apiEndPoint = "biomarkers/"
export function getBiomarkList(){
    return API.get(apiEndPoint);
}
export function saveBiomarker(biomarkers,countryId){
    const updatedApiEndPoint=`${apiEndPoint}/?country_id=${countryId}`;
    return API.post(apiEndPoint,biomarkers);
}
export function updateBiomarker(biomarkers,id,countryId){
    
    return API.put(apiEndPoint+id+'/'+`?country_id=${countryId}`,biomarkers);
}
export function deleteBiomarker(id){
    return API.delete(apiEndPoint+id+'/');
}