import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tabs, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { fetchCountries, fetchCities } from "services/RegionService";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import CountryDropdown from "components/Common/CountryDropdown";
import AccessDenied from "../../components/Common/AccessDenied";
import Select from "react-select";
import PackageHomecareCost from "./PackageHomcareCosts";
import SampleCollectionCharges from "./SampleCollectionCharges";
import * as XLSX from "xlsx";

const BASE_URL_HOMECARE_SERVICES = "home-care-service/";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const PackageListOptions = [
  {
    Key: "Custom Package",
    value: 1,
    label: "Custom Package",
    
  },
  {
    key: "Custom Package Addons",
    value: 2,
    label: "Custom Package Addons",
  }
];
class HomecareCosts extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      selectedCountry: null,
      searchTerm: null,
      homecareService: {},
      errors: {},
      editables: {},
      isHomecareServiceViewModalVisible: false,
      sampleCosts: [],
      serviceCosts: [],
      filteredCityHomecareServiceCosts:[],
      isServiceChargeModalVisible: false,
      selectedItems: [],
      personsData: [],
      isPersonViewModalVisbile: false,
      selectedCityId: null,
      personData: null,
      sampleCollectionObject: null,
      uploadedData: [],
    };

    this.columns = [
      {
        name: "Homecare Id",
        selector: "id",
        sortable: true,
      },
      {
        name: "Homecare Name",
        selector: "name",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        maxWidth: "150px",
        center: true,
        cell: (homecareService) => (
          <Status isActive={homecareService.isActive} />
        ),
        sortable: true,
      },
      {
        name: "Add Cost",
        center: true,
        cell: (homecareService) => this.renderViewButton(homecareService.id),
      },
    ];

    this.membersColumns = [
      {
        name: "Persons",
        selector: "person",
        sortable: true,
      },
      {
        name: "Charges",
        selector: "personCost",
        sortable: true,
      },
      {
        name: "Edit",
        center: true,
        cell: (person) => this.renderViewPerson(person.id),
      },
    ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    // this.fetchCities();
    this.fetchHomecareServices();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.HOMECARE_COSTS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry });
    } catch (error) {
      console.log("Error on fetching countries.", error);
    }
  };

  fetchHomecareServices = async () => {
    this.showLoading();
    try {
      const { data: homecareServices = [] } = await PhoenixAPI.get(
        BASE_URL_HOMECARE_SERVICES
      );
      this.setState({ homecareServices });
    } catch (error) {
      console.log("Error on fetching homecare services", error);
      this.showErrorNotification(
        "Error on fetching homecare services: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCities = async () => {
    try {
      this.showLoading();
      const cities = (await fetchCities()) ?? [];
      cities.forEach((city) => {
        city.countryId = city.country?.id;
        delete city.country;
      });
      this.setState({ cities });
    } catch (error) {
      this.showErrorNotification("Error on fetching cities!", error.message);
    } finally {
      this.hideLoading();
    }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  findCountryName = (id) => {
    const countries = this.state.countries || [];
    const countryObject = countries.find((country) => country.id === id);
    return countryObject?.country_name ?? "";
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry });

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const homecareService = { ...this.state.homecareService };
    homecareService[input.name] = input.value;
    this.setState({ homecareService });
  };

  handleViewHomecareService = async (id) => {
    const homecareServices = this.state.homecareServices ?? [];
    const homecareService = homecareServices.find((it) => it.id === id);
    if (!homecareService) {
      console.log(`Homecare Service with id ${id} is not found!`);
      return;
    }
    const { data: cities = [] } = await PhoenixAPI.get(
      `homecare-cost-city-dropdown/homecare-id/${homecareService.id}`
    );
    const cityOptions = cities.map((it) => {
      return { key: it.id, value: it.id, label: it.city_name };
    });
    this.setState(
      { homecareService, isNewHomecareService: false, cities, cityOptions },
      () => this.showHomecareServiceViewModal()
    );
  };

  handleViewPerson = (id) => {
    console.log("id: handleViewPerson", id);
    const personsData = this.state.personsData;
    const personData = personsData.find((it) => it.id === id);
    if (!personData) {
      console.log(`Person with  ${id} is not found!`);
      return;
    }
    this.setState({ personData }, () => this.showPersonViewModal());
  };

  showPersonViewModal = () => {
    this.setState({ isPersonViewModalVisbile: true });
  };

  hidePersonModal = () => {
    this.setState({ isPersonViewModalVisbile: false, personData: null });
  };
  handleHideSampleCollection = () => {
    this.hidePersonModal();
    this.fetchHomecareCosts();
  };

  findCity = (id) => {
    const cities = this.state.cities || [];
    return cities.find((city) => city.id === id);
  };

  handleServiceChargeModalVisible = () => {
    this.setState({ isServiceChargeModalVisible: true });
  };
  hideServiceChargeModal = () => {
    this.setState({ isServiceChargeModalVisible: false });
  };

  showHomecareServiceViewModal = () => {
    const { selectedCountry, isNewHomecareService } = this.state;

    if (isNewHomecareService) {
      const homecareService = {
        countryId: selectedCountry?.id,
        is_active: false,
      };
      this.setState({
        homecareService,
        isHomecareServiceViewModalVisible: true,
      });
    } else {
      this.setState({
        isHomecareServiceViewModalVisible: true,
      });
    }
  };

  hideHomecareServiceViewModal = () => {
    this.setState({ isHomecareServiceViewModalVisible: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      homecareService: {},
      errors: {},
      editables: {},
      selectCustomPackage: null,
      selectPackageName: "",
      selectedCity: null,
      selectedCityId: null,
      selectedCityName: "",
    });
  };

  getFilteredHomecareServices = () => {
    const homecareServices = this.getHomecareServicesForCurrentCountry();
    return this.getSearchedHomecareServices(homecareServices);
  };

  getHomecareServicesForCurrentCountry = () => {
    const { homecareServices, selectedCountry } = this.state;
    return homecareServices && selectedCountry
      ? homecareServices.filter(
          (homecareService) => homecareService.countryId === selectedCountry.id
        )
      : [];
  };

  getSearchedHomecareServices = (homecareServices) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return homecareServices;

    const cleanedSearchTerm = searchTerm.slice(0).trim().toLowerCase();
    return homecareServices.filter((homecareService) => {
      const { id, name } = homecareService;
      return (
        (name && name.toLowerCase().indexOf(cleanedSearchTerm) !== -1) ||
        (id && id.toString() === cleanedSearchTerm)
      );
    });
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewHomecareService(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  renderViewPerson = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewPerson(id);
        }}
      >
        Edit
      </button>
    </div>
  );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewHomecareService ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );
  createOption = (city) => {
    return {
      key: city.selectedCityId,
      value: city.selectedCityId,
      label: city.selectedCityName,
    };
  };
  createOptionSericeCharge = (selectPackage) => {
    console.log("select package",selectPackage)
    return {
      key: selectPackage.selectCustomPackage,
      value: selectPackage.selectCustomPackage,
      label: selectPackage.selectPackageName,
    };
  };
  fetchHomecareCosts = async () => {
    const { selectedCityId, homecareService, selectedCountry ,packageType} = this.state;
    console.log("packageType",packageType);
    try {
      const { data: homecarecosts = {} } = await PhoenixAPI.get(
        `homecare-costs/homecare-id/${homecareService.id}`
      );
      const { sampleCollectionCosts = [], serviceCosts = [] } =
        homecarecosts ?? {};
      const filteredHomecareSampleCosts = sampleCollectionCosts.filter(
        (item) => item.cityId == selectedCityId
      ); // filteredHomecareSampleCosts with cityId
      const filteredCityHomecareServiceCosts = serviceCosts.filter(
        (item) => item.cityId == selectedCityId
      )
      console.log("filtered homecare costs package type",packageType);
      const filteredHomecareServiceCosts = filteredCityHomecareServiceCosts.filter((serviceCost) => 
        packageType === "Custom_Package" || packageType === "Custom_Package_Addon"
          ? serviceCost.packageType === packageType // Further filter by packageType if it matches
          : true 
      );
  
      
      
      console.log("selectedCityIdselectedCityId", filteredHomecareSampleCosts,filteredHomecareServiceCosts);

      const sampleCollectionObject =
        filteredHomecareSampleCosts.length > 0 &&
        filteredHomecareSampleCosts[0];
      const personsData = [
        {
          id: 1,
          person: 1,
          personCost: sampleCollectionObject?.person1Cost ?? "",
          countryId: selectedCountry.id,
          cityId: selectedCityId,
          homecareId: homecareService.id,
        },
        {
          id: 2,
          person: 2,
          personCost: sampleCollectionObject?.person2Cost ?? "",
          countryId: selectedCountry.id,
          cityId: selectedCityId,
          homecareId: homecareService.id,
        },
        {
          id: 3,
          person: 3,
          personCost: sampleCollectionObject?.person3Cost ?? "",
          countryId: selectedCountry.id,
          cityId: selectedCityId,
          homecareId: homecareService.id,
        },
        {
          id: 4,
          person: 4,
          personCost: sampleCollectionObject?.person4Cost ?? "",
          countryId: selectedCountry.id,
          cityId: selectedCityId,
          homecareId: homecareService.id,
        },
        {
          id: 5,
          person: 5,
          personCost: sampleCollectionObject?.person5Cost ?? "",
          countryId: selectedCountry.id,
          cityId: selectedCityId,
          homecareId: homecareService.id,
        },
      ];

      this.setState({
        sampleCosts: sampleCollectionCosts,
        serviceCosts: filteredHomecareServiceCosts,
        selectedItems: [],
        personsData,
        sampleCollectionObject,
        filteredCityHomecareServiceCosts
      });
    } catch (error) {
      console.log("Error in fetching homecare costs", error.message);
    }
  };

  handleAllSelectedItems = (selectedItems = []) => {
    this.setState({ selectedItems: selectedItems });
  };

  handleHideAll = () => {
    console.log("hiding ");
    this.fetchHomecareCosts();
    //this.fetchHomecareCosts();
  };
  

  mappingPackages = (packages) => {
    const mappingCosts = packages.map((packages) => {
      const item = {
        uuid: packages.uuid,
        packageId: packages.packageId,
        PackageName: packages.packageName,
        Status: packages.status,
        Cost: packages.cost,
        UpdatedCosts: "",
        StartDate: "",
      };
      return item;
    });
    return mappingCosts;
  };
  downloadHomecareCostFile = () => {
    const { serviceCosts = [], packageType = "" } = this.state;
    const { selectedCountry = null } = this.state;

    const selectedItems = this.state.selectedItems;
    if (selectedItems.length > 0) {
      const mappedPackages = this.mappingPackages(selectedItems);
      if (mappedPackages.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedPackages);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");

        this.showSuccessNotification("File is Downloaded successfully");
      } else {
        return;
      }
    } else if (serviceCosts.length > 0) {
      const mappedPackages = this.mappingPackages(serviceCosts);
      if (mappedPackages.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedPackages);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "data.xlsx");

        this.showSuccessNotification("File is Downloaded successfully");
      } else {
        return;
      }
    }
  };
  formatDate = (dateString) => {
    const jsDate = new Date(dateString);
    const day = String(jsDate.getDate()).padStart(2, "0");
    const month = String(jsDate.getMonth() + 1).padStart(2, "0");
    const year = jsDate.getFullYear();
    return `${year}-${month}-${day}`;
  };

  handleReset = () => {
    // Reset the file input value
    if (this.fileInputRef.current) {
      this.fileInputRef.current.value = "";
    }
  };

  handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const arrayBuffer = e.target.result;
      const workbook = XLSX.read(arrayBuffer, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, {
        raw: false,
        dateNF: "yyyy-mm-dd",
      });
      const formattedData = jsonData.map((packages) => ({
        uuId: packages.uuId,
        packageId: packages.packageId,

        cost: packages.Cost,
        updatedCosts: packages.UpdatedCosts || "", // assuming UpdatedCosts is in the Excel sheet
        startDate: packages.StartDate
          ? this.formatDate(packages.StartDate)
          : "", // Convert Excel date to proper date stri
      }));
      this.setState({ uploadedData: formattedData ?? [] });
    };
    reader.readAsArrayBuffer(file);
  };
  UploadHomecareCostFile = async () => {
    const {
      uploadedData,
      homecareService,
      selectedCountry = null,
      packageType = "",
      selectedCityId,
    } = this.state;
    console.log("Upload adasdasd", uploadedData);
    if (uploadedData && uploadedData.length === 0) {
      this.showErrorNotification("Please choose File to upload!");
      return;
    }

    const filtereduploadedData =
      uploadedData && uploadedData.length > 0
        ? uploadedData.filter(
            (item) => item.updatedCosts !== "" && item.startDate !== ""
          )
        : [];
    console.log(
      "filtereduploadedDatafiltereduploadedData",
      filtereduploadedData
    );
    if (filtereduploadedData && filtereduploadedData.length === 0) {
      this.showErrorNotification(
        "Please update costs and start date for packages!"
      );
      return;
    }
    const mappedFormattedData =
      filtereduploadedData.length > 0 &&
      filtereduploadedData.map((item) => {
        const sample = {
          packageId: parseInt(item.packageId),
          cost: parseInt(item.updatedCosts),
          fromDate: item.startDate,
        };
        return sample;
      });
    console.log("Mapped ", mappedFormattedData);
    const createdBy = getUserId();

    const payload = {
      homecareId: homecareService.id,
      countryId: selectedCountry.id,
      cityId: selectedCityId,
      createdById: parseInt(createdBy),
      packageType: this.state.packageType,
      homecareServiceCosts: mappedFormattedData,
    };

    try {
      const response = await PhoenixAPI.post("homecare-service-costs", payload);
      this.showSuccessNotification("Costs saved successfully");
      this.fetchHomecareCosts();
      this.handleReset();
    } catch (error) {
      console.log("Error in Saving Package Cost", error.message);
      this.showErrorNotification("Error in Saving Package Cost");
    }
  };

  handleSelectPackage = (value) => {
    const { selectedCityId, selectedCountry,packageType,serviceCosts,filteredCityHomecareServiceCosts } = this.state;
    if (!selectedCityId) {
      this.showErrorNotification("Please select a city");
      return;
    }
    const selectedPackageType=value.label=="Custom Package"?"Custom_Package":"Custom_Package_Addon";
    console.log("serviceCosts",serviceCosts);
      const filteredServiceCosts=filteredCityHomecareServiceCosts.length>0?filteredCityHomecareServiceCosts.filter((service)=>service.packageType==selectedPackageType):[];
    
    this.setState({
      selectCustomPackage: value.value,
      selectPackageName: value.label,
      isServiceChargeModalVisible: true,
      packageType:selectedPackageType,
      serviceCosts:filteredServiceCosts
    });
  };
  render() {
    const {
      homecareService,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,

      isHomecareServiceViewModalVisible,
      isEditable,
      errors,
      cities,
      selectedCity,
      cityOptions,

      serviceCosts,
      selectPackageName,
      selectedCityId,
      personData,
      personsData,
      sampleCollectionObject,
    } = this.state;

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };
    const filteredHomecareServices = this.getFilteredHomecareServices() ?? [];
    const sampleCollectionChargeForm = (
      <div className="container mt-4">
        {selectedCountry && (
          <SampleCollectionCharges
            cityId={selectedCityId ?? null}
            homecareId={homecareService.id ?? null}
            countryId={selectedCountry.id ?? null}
            selectedPerson={personData}
            allPersons={personsData}
            sampleCollectionObject={sampleCollectionObject}
            handleHideSampleCollection={() => this.handleHideSampleCollection()}
          />
        )}
      </div>
    );
    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={selectedCountry && selectedCountry.country_name}
              type="text"
              id="country"
              name="country"
              readOnly
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            ></input>
            <span className="help-block">{errors.country}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="selectedCityId">Select City</label>
            <Select
              key={`my_unique_select_key__${selectedCity && selectedCity.id}`}
              value={this.createOption(this.state)}
              onChange={(value) => {
                this.setState(
                  {
                    selectedCityId: value.value,
                    selectedCityName: this.findCity(value.value)?.city_name,
                    packageType:""
                  },
                  () => this.fetchHomecareCosts()
                );
              }}
              options={cityOptions ?? []}
              styles={selectStyles}
              placeholder="Select City..."
              isDisabled={false}
            />
            <span className="help-block">{errors.city}</span>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col homecare-charge">
            <span>Sample Collection Charges</span>
          </div>
        </div>
        {selectedCityId && (
          <div className="container mt-4">
            <Card body>
              <DataTable
                data={this.state.personsData ?? []}
                columns={this.membersColumns}
                highlightOnHover
              />
            </Card>
          </div>
        )}
        <div className="row mt-2">
          <div className="col homecare-charge">
            <span>Service Charges</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="selectedCityId">Select Package</label>
            <div className="">
              <Select
                // key={`country_select${}`}
                value={this.createOptionSericeCharge(this.state)}
                onChange={(value) => {
                  this.handleSelectPackage(value);
                }}
                options={PackageListOptions || []}
                styles={selectStyles}
                placeholder="Select Package Type"
              />

              <span className="help-block">{errors.packageMessage}</span>
            </div>
          </div>
        </div>
      </div>
    );

    const serviceChargesForm = (
      <div className="container mt-4">
        <div className="row">
          <div
            className=""
            style={{ marginLeft: "200px", display: "flex", gap: "20px" }}
          >
            <button
              onClick={(e) => this.downloadHomecareCostFile()}
              id="show_orders"
              className="btn px-2"
              style={{ backgroundColor: "#0CA789", color: "white" }}
            >
              Download
            </button>

            <div className="" style={{ display: "flex", gap: "10px" }}>
              <button
                onClick={(e) => this.UploadHomecareCostFile()}
                id="show_orders"
                className="btn px-2"
                style={{ backgroundColor: "#0CA789", color: "white" }}
              >
                Upload File
              </button>
              <input
                type="file"
                accept=".xlsx, .xls"
                placeholder="Upload File"
                onChange={(e) => this.handleFileUpload(e)}
                ref={this.fileInputRef}
              />
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <PackageHomecareCost
            selectedPackageCosts={serviceCosts ?? []}
            packageType={selectPackageName ?? ""}
            cityId={selectedCityId ?? null}
            handleHideAll={() => this.handleHideAll()}
            handleAllSelected={this.handleAllSelectedItems}
            
          />
        </div>
      </div>
    );

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredHomecareServices || []}
            columns={this.columns}
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredHomecareServices.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={isHomecareServiceViewModalVisible}
          onHide={this.hideHomecareServiceViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Homecare Service:</span>
                {homecareService.name ? ` ${homecareService.name}` : ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
          </Modal.Header>
          <Modal.Body>{formBasicInformation}</Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.isServiceChargeModalVisible}
          onHide={this.hideServiceChargeModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>Homecare Service:</span>
                {homecareService.name ? ` ${homecareService.name}` : ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
          </Modal.Header>
          <Modal.Body>{serviceChargesForm}</Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.isPersonViewModalVisbile}
          onHide={this.hidePersonModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            {/* <Modal.Title> Package Name: {selectedPackageCost?.packageName ?? ""}</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>{sampleCollectionChargeForm}</Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomecareCosts);
