
import React, { Component } from "react";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import { getUserType, getUserName, getUserEmail, getIsExternalCoach } from "services/UserService";
import { isEmpty } from "utils/FormValidator";
import { filterList } from "utils/List";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Range from "components/BiomarkerRange/Range";
import Pdf from "@material-ui/icons/PictureAsPdf";
import DeleteButton from "@material-ui/icons/DeleteOutlineOutlined";
import MUIRichTextEditor from "mui-rte";
import moment from "moment";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { ContentState, convertToRaw } from "draft-js";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { fetchCountries } from "services/RegionService";
import SelectSupplements from "components/Common/SelectSupplements";
import VerifyTestResultsViewModal from "./VerifyTestResultsViewModal";
import SelectHowDoYouHearAboutUsOptions from "components/Common/SelectHowDoYouHearAboutUsOptions";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { Form } from "react-bootstrap";
import SelectBloodPackages from "components/Common/SelectBloodPackages";
import SelectCustomPackages from "components/Common/SelectCustomPackages";
import LongevityScore from "pages/LongevityScore/LongevityScore";
import OrderDetailsCard from "pages/CoachManagment/OrderDetailsCard";
import ClientDetailsCard from "pages/CoachManagment/ClientDetailsCard";

import BiomarkerRemovedModal from "components/Common/BiomarkerRemovedModal";

import { formatDate } from "utils/commons";
import SelectPackage from "components/Common/SelectPackage";
import SelectMiniPackages from "components/Common/SelectMiniPackages";
import CoachSupplementSuggestion from "pages/CoachManagment/CoachSupplementSuggestion";
import PERIOD_OPTIONS from "utils/SuggestedPeriods";
import FoodItemsTable from "components/Common/FoodItemsTable";
import PdfViewer from "components/Common/PdfViewer";
import SelectTests from "pages/PackageCreation/SelectTests";


const BASE_URL_LAB_REPORTS = "/lab-reports";
const BASE_URL_CHECK_AND_COMPLETE_PARENT_ORDER = "/admin/check-and-complete-parent-order/";
const BASE_URL_TEST_SEND_NOTIFICATION="user-own-tests/send-notification";
const BASE_URL_HOW_DID_YOU_HEAR_OPTIONS = "/admin/check-and-display-how-did-you-hear-options";
const BASE_URL_SUGGESTED_PACKAGES_SUPPLEMENTS="suggested-packages-and-supplements";
const BASE_URL_FOOD_INTOLERANCE= "admin/food-intolerance"

const LAB_OPTIONS = [
  {
    key: "alborg",
    value: "alborg",
    text: "Al Borg",
  },
  {
    key: "neuberg",
    value: "neuberg",
    text: "Neuberg",
  },
  {
    key: "thyrocare",
    value: "thyrocare",
    text: "Thyrocare",
  },
  {
    key: "unilabs",
    value: "unilabs",
    text: "Unilabs",
  },
  {
    key: "vestacare",
    value: "vestacare",
    text: "Vestacare",
  },
  {
    key:"biosytech",
    value:"biosytech",
    text:"Biosytech"
  },
  {
    key:"farabi",
    value:"farabi",
    text:"Farabi"
  },
  {
    key: "taib",
    value: "taib",
    text: "Taib Lab"
  },
  {
    key: "wareedlab",
    value: "wareedlab",
    text: "Wareed Lab"
  },
  {
    key: "lifenitylab",
    value: "lifenitylab",
    text: "Lifenity Lab"
  },
  {
    key: "alborg fox explorer",
    value:"alborg fox explorer",
    text: "Alborg Fox Explorer"
  },
  {
    key: "almushtaqbal",
    value: "almushtaqbal",
    text: "Al Mushtaqbal Lab"
  },
  {
    key: "lifenity_abudhabi",
    value: "lifenity_abudhabi",
    text: "Lifenity Lab Abu-Dhabi"
  },
  {
   key:"genalive",
   value:"genalive",
   text:"Genalive Lab"
  },
  {
    key: "alborg fox explorer",
    value:"alborg fox explorer",
    text: "Alborg Fox Explorer"
 
   },
  {
   key:"unilabs fox explorer",
   value:"unilabs fox explorer",
   text:"Unilabs Fox",
  },
  {
    key:"qlabs",
    value:"qlabs",
    text:"Q-Labs"
  }
];

class TestResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: getUserType(),
      userNameAdminPanel: getUserName(),
      userEmailAdminPanel: getUserEmail(),
      orderId: this.props.match.params.order,
      gender: this.props.match.params.gender,
      tests: [],
      testId: null,
      testType: null,
      testName: null,
      modalTitle: null,
      markers: [],
      foods: [],
      userId: null,
      userName: null,
      isLoaded: false,
      loadError: "",
      overview: "",
      overview_ar: "",
      msg_en: "",
      msg_ar: "",
      results: [],
      resultIds: [],
      comments: [],
      comments_ar: [],
      submitted: false,
      finalSubmit: false,
      testResultFormatted: [],
      recommendedTests: [],
      resultDate: "",
      viewMode: false,
      isPastOrder: false,
      resultFile: "",
      resultFileValidation: true,
      resultFileError: "",
      lab_result_file: "",
      testCategortList: null,
      rusult_file_id: "",
      index: 0,
      survey: null,
      sortConfig: { key: "test_category", direction: "ascending" },
      parsedLabTestResults: [],
      self_result_upload:false,
      coachSuggestions: {},
      howDidYouHearDetails: {},
      enableUploadResultsToAppBtn:false,
      enableUploadPartialResultsToAppBtn:false,
      listBiomarkersRemoved:[],
      biomarkersNamesRemoved:[],
      isRemoveBiomarkerModal:false,
      biomarkersDeleteConfirm:false,
      saveFlag:false,
      followUpPeriod:null,
      followUpPackage:{isBloodPackage:false, isMiniPackage:false},
      mappedSuggestions:[],
      isFoodIntoleranceTest:false,
      collapsibles:{},
      parsedLabFoodIntoleranceResults:[],
      selectedFoodItemIds:[],
      highLimit:null,
      mediumLimit:null,
      lowLimit:null,
      defaultOpen:false,
      postApiCall:false,
      pastBiomarkerResults:[],
      isSelfResultUpload:false,
      userUploadedFiles:[],
      selfResultBiomarkers:[],
      isExternalCoach:getIsExternalCoach(),
      biomarkerUnitOptions:[],
      testsFromUnits:[],
      isPackageContainsNonBloodBiomarkers:false,
      listNonBloodBiomarkersRemoved:[],
    };


    this.resultSubmitted = false;
    this.result_messages = [];
    this.event = "";
    this.event_ar = "";
    this.dateMessage = "";
    this.testMessage = "";
    this.overviewMessage = "";
    this.overviewArMessage = "";
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.uploadResult = this.uploadResult.bind(this);
    this.uploadResultFileSubmit = this.uploadResultFileSubmit.bind(this);
    this.submitFinalResults = this.submitFinalResults.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.saveOverviewEng = this.saveOverviewEng.bind(this);
    this.saveOverviewAr = this.saveOverviewAr.bind(this);
    this.handleAllsubmit = this.handleAllsubmit.bind(this);
    this.updateTable = this.updateTable.bind(this);
    this.handleRemoveBiomarkerChange=this.handleRemoveBiomarkerChange.bind(this);
    this.coachConsultDateRef = React.createRef();
    this.handleHideRemoveBiomarkerModal=this.handleHideRemoveBiomarkerModal.bind(this);
    this.handleCancelBiomarker=this.handleCancelBiomarker.bind(this);
    this.handleDeleteBiomarker=this.handleDeleteBiomarker.bind(this);
  }

  async componentDidMount() {
    this.fetchAclUser();
    // this.fetchLabReportResults();  // first change commented this API Call because its been alreday calling on load data
    await this.fetchCountryId();
    //find if this is a past order
    const currentPath = window.location.href.split("/admin")[1];
    this.biomarkerOptions();
    if (currentPath.includes("/past/")) {
      const {userType}= this.state || {};
   
      this.setState({ isPastOrder: true, viewMode: true }, () =>
        this.loadData()
      );
    } else {
      this.loadData();
      
    }
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false,
    });
  }
  handleCancelBiomarker(){
   
    this.setState({ isRemoveBiomarkerModal:false})
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const currentPath = window.location.href.split("/admin")[1];
        const pageName = currentPath.includes("/past/") ? Page.PAST_ORDERS : Page.ACTIVE_ORDERS;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  getOrderDetailsUrl = () => {
    const { userType, orderId } = this.state;
    const currentPath = window.location.href.split("/admin")[1];
    let baseUrl = "";
    if (currentPath.includes("/past/")) {
      // baseUrl =  userType === "lab" ? "order-lab-past" : "order-nutritionist-past";
      baseUrl =  userType === "lab" ? "order-lab-past" : "coach-combine-active-order";
    } else {
      baseUrl =  userType === "lab" ? "order-lab" : "order-nutritionist";
    }
    return `${baseUrl}/${orderId}/`;
  }

  fetchSupplements = async (suggestedConditions) => {
    const {partnerId, country_id:countryId=""}=suggestedConditions || {}
    if (!countryId) {
      console.log("Invalid country!", countryId);
      return [];
    }
    let url=""
    if(partnerId!==null || undefined){
       url = `supplement/partner?country=${countryId}&partnerId=${partnerId}`
    }
    else{
      url = `supplement/partner?country=${countryId}`
    }
    
    try {
      this.showLoading();
      const { data: supplements = [] } = await PhoenixAPI.get(url);
      this.setState({ supplements })
    } catch (error) {
      console.log("Error on fetching supplements", error);
      this.showErrorNotification(
        "Error on fetching supplements! " + error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  fetchBloodPackages=async(suggestedConditions)=>{
    const {partnerId, country_id:countryId=""}=suggestedConditions || {}
    if(!countryId){
     console.log("Invalid country!", countryId);
       return [];
    }
    let url=""
    if(partnerId!==null || undefined){
       url = `partner-blood-packages?country=${countryId}&partnerId=${partnerId}`
    }
    else{
      url = `partner-blood-packages?country=${countryId}`
    }
    try{
    this.showLoading();
    const { data: bloodPackages = [] } = await PhoenixAPI.get(url);
     this.setState({bloodPackages})
    }
    catch(error){
     console.log("Error on fetching blood packages", error);
     this.showErrorNotification(
       "Error on fetching blood packages! " + error.message
     );
    }
    finally {
     this.hideLoading();
   }
   }
 
   fetchCustomPackages=async(suggestedConditions)=>{
    const {partnerId, country_id:countryId=""}=suggestedConditions || {}
     if(!countryId){
      console.log("Invalid country!", countryId);
        return [];
     }
     let url=""
     if(partnerId!==null || undefined){
        url = `partner-custom-packages?country=${countryId}&partnerId=${partnerId}`
     }
     else{
       url = `partner-custom-packages?country=${countryId}`
     }
     try{
     this.showLoading();
     const { data: customPackages = [] } = await PhoenixAPI.get(url);
     this.setState({customPackages})
     }
     catch(error){
      console.log("Error on fetching blood packages", error);
      this.showErrorNotification(
        "Error on fetching blood packages! " + error.message
      );
     }
     finally {
      this.hideLoading();
    }
    }
 

  fetchHowDidYouHearDetails = async (userId, countryId) => {
    if (!userId || !countryId) {
      console.log("fetchHowDidYouHearDetails:: Invalid userId or countryId.", { userId, countryId });
      return;
    }

    try {
      const url = `${BASE_URL_HOW_DID_YOU_HEAR_OPTIONS}/userId/${userId}/countryId/${countryId}`;
      const { data: howDidYouHearDetails } = await PhoenixAPI.get(url);
      this.setState({ howDidYouHearDetails });
    } catch (error) {
      console.log("fetchHowDidYouHearDetails:: Error on fetching how did you hear details!", error);
      this.showErrorNotification("Error on fetching how did you hear details! " + error.message);
    }
  }

  saveHowDoYouHearAboutUsDetails = async () => {
    const { howDidYouHearDetails, order } = this.state;
    const userId = order?.user?.id;
    const cartOrderId = order?.cart_order_id;
    if (!howDidYouHearDetails || !userId || !cartOrderId) {
      console.log("saveHowDoYouHearAboutUsDetails:: Invalid data!", { userId, cartOrderId, howDidYouHearDetails });
      this.showErrorNotification("Cannot save how do you hear details! Invalid data.");
      return;
    }

    const { showOptions, answers, notes } = howDidYouHearDetails;
    if ((!notes || notes.trim() === "") && (!answers || !answers.length)) {
      console.log("saveHowDoYouHearAboutUsDetails:: Invalid data!", { notes, answers });
      return;
    }

    const payload = {
      userId,
      cartOrderId,
      howDoYouHearAboutUsIds: answers ?? null,
      notes: notes ?? null,
    }
    const url = "/hear-about-valeo";
    try {
      if (showOptions) {
        await PhoenixAPI.post(url, payload);
      } else {
        await PhoenixAPI.patch(url, payload);
      }
      this.showSuccessNotification("How do you hear about us saved successfully.");
    } catch (error) {
      console.log("saveHowDoYouHearAboutUsDetails:: Error on saving how do you hear about us details!", error);
      this.showErrorNotification("Error on saving, how do you hear about us details.");
    }
  }

  handleUnitChange=async(event,value,item,toValue)=>{
  // console.log("item is",item,value,item.result);
  const url="lab/biomarker/convert-result";
  //console.log("options",this.state.biomarkerUnitOptions);
  

  const filteredOptions = this.state.biomarkerUnitOptions.filter(option => option.key === item.name && option.value==value.value);
    // console.log("filtered option",value.value,item,filteredOptions);
  const payload={
    "valeoBiomarkerName": item.name,
    "result": item?.pastResult?item.pastResult:item.result,
    "toUnits": toValue,
    "fromUnits": value.value,
    "conversionFactor": filteredOptions.length > 0 ? filteredOptions[0].conversionFactor : 1
  }
  if(value.value=="None"){
    const resultValue=item?.pastResult?item.pastResult:item.result;
  let results = [...this.state.results];
      let tests = [...this.state.tests];
      let resultdata = [...this.state.tests[item.index].results];
      results[item.index] = resultValue;
      item.results[0]['result'] = resultValue;
      item.result = resultValue;
      resultdata[0].result = resultValue;
      item['result'] =resultValue ;
      item['fromUnit']=value.value;
      item.results = resultdata;
      tests[item.index] = item;
      this.setState({ testData: [...tests], isLoaded: true, results: results,saveFlag:false }, () => {
        this.updateTable();
      });
      }
else{
 const response=await  PhoenixAPI.patch(url,payload);
    const {data}=response;
    //  const resultValue=Number(data?.result?.toFixed(2)); //removing toFixed for non blood biomarker
    const resultValue=Number(data?.result??"");
      if (Number(item.result) < 0) return;
      let results = [...this.state.results];
      let tests = [...this.state.tests];
      let resultdata = [...this.state.tests[item.index].results];
      results[item.index] = data.result;
      item.results[0]['result'] = resultValue;
      item.result = resultValue;
      resultdata[0].result = resultValue;
      item['result'] =resultValue ;
      item['fromUnit']=value.value;
      item.results = resultdata;
      tests[item.index] = item;
      this.setState({ testData: [...tests], isLoaded: true, results: results,saveFlag:false }, () => {
        this.updateTable();
      });
 }
    }
    
  
biomarkerOptions=async()=>{
  const url="biomarker/unit-conversion";
  const { data=[]} = await PhoenixAPI.get(url);
   let unitOptions=[{ text: "None", value: "", key: "None",conversionFactor:1}];
   
  data.map((item)=>{
    let unitOption = {};
    unitOption["text"] = item.fromUnits;
    unitOption["value"] = item.fromUnits;
    unitOption["key"] = item.valeoBiomarkerName;
    unitOption["conversionFactor"]=item.conversionFactor;
  unitOptions.push(unitOption);
  })
  const modifiedLabUnitsArr = data.reduce((acc, curr) => {
    const { valeoBiomarkerName, fromUnits } = curr;
    const found = acc.find(item => item[valeoBiomarkerName]);
    
    if (found) {
        if (!found[valeoBiomarkerName].includes(fromUnits)) {
            found[valeoBiomarkerName].push(fromUnits);
        }
    } else {
        acc.push({ [valeoBiomarkerName]: [fromUnits] });
    }

    return acc;
}, []);

   this.setState({biomarkerUnitOptions:unitOptions, modifiedLabUnitsArr})
}

  fetchCountryId = async () => {
    const url = this.getOrderDetailsUrl();
   
    try {
      const { userType, orderId , bloodPackages,miniPackages,isExternalCoach} = this.state;
      const currentPath = window.location.href.split("/admin")[1];
      // console.log("Curresntpath:", currentPath)
      const loginUserId=getUserId()
      if(userType ==="nutritionist" || userType ==="externalNutritionist"  && currentPath.includes("/past/")){
        const params={
          isPastOrder:true,
          isExternalCoach:isExternalCoach,
          coachId:loginUserId
        }
        const { data } = await PhoenixAPI.get(`${url}`,{params});
        const {order:orderDetails={}}= data || {}
        

     
        if (orderDetails && orderDetails.country_id) {
          const countries = (await fetchCountries()) ?? [];
          const country = countries.find(it => it.id === orderDetails.country_id);
          const coachSuggestions = {
            is_coach_consult_done: orderDetails.is_coach_consult_done,
            coach_consult_date: orderDetails.coach_consult_date,
            is_supplement_required: orderDetails.is_supplement_required,
            // coach_supplement_suggestion: orderDetails.coach_supplement_suggestion
            //   ? orderDetails.coach_supplement_suggestion.map((it) => it.supplement_id)
            //   : [],
              coach_supplement_suggestion: orderDetails.coach_supplement_suggestion??[],
              is_custom_package_required: orderDetails.is_custom_package_required,
              coach_custom_package_suggestions: orderDetails.coach_custom_package_suggestions
              ? orderDetails.coach_custom_package_suggestions.map((it) => it.custom_package_id)
              : [],
              is_blood_package_required: orderDetails.is_blood_package_required,
              coach_blood_package_suggestions: orderDetails.coach_blood_package_suggestions
              ? orderDetails.coach_blood_package_suggestions.map((it) => it.package_id)
              : [],
          };
          this.setState({ 
            countryId: country ? country.id : null,
            order: orderDetails ?? null,
            coachSuggestions, 
          }, () => console.log("CountryID: ", this.state.countryId));
        }
      }
      else{
        const { data:orderDetails={}} = await API.get(url);
        if (orderDetails && orderDetails.country) {
          const countries = (await fetchCountries()) ?? [];
          const country = countries.find(it => it.country_name === orderDetails.country || it.country_name_ar === orderDetails.country);
          const coachSuggestions = {
            is_coach_consult_done: orderDetails.is_coach_consult_done,
            coach_consult_date: orderDetails.coach_consult_date,
            is_supplement_required: orderDetails.is_supplement_required,
            // coach_supplement_suggestion: orderDetails.coach_supplement_suggestion
            //   ? orderDetails.coach_supplement_suggestion.map((it) => it.supplement_id)
            //   : [],
              is_custom_package_required: orderDetails.is_custom_package_required,
              coach_custom_package_suggestions: orderDetails.coach_custom_package_suggestions
              ? orderDetails.coach_custom_package_suggestions.map((it) => it.custom_package_id)
              : [],
              is_blood_package_required: orderDetails.is_blood_package_required,
              coach_blood_package_suggestions: orderDetails.coach_blood_package_suggestions
              ? orderDetails.coach_blood_package_suggestions.map((it) => it.package_id)
              : [],
              coach_supplement_suggestion: orderDetails.coach_supplement_suggestion??[],
          };
          this.setState({ 
            countryId: country ? country.id : null,
            order: orderDetails ?? null,
            coachSuggestions, 
          }, () => console.log("CountryID: ", this.state.countryId));
        }
      }
     
      // console.log("Order Details: ", orderDetails);
     
    } catch (error) {
      console.log("Error on fetching country id", error);
    }
  }



  handleVerifyResults = () => {
    this.setState({ isVerifyTestResultsViewModalVisible: true });
  }
  handleHideRemoveBiomarkerModal=()=>{
    this.setState({isRemoveBiomarkerModal:false,listBiomarkersRemoved:[],biomarkersNamesRemoved:[],listNonBloodBiomarkersRemoved:[]});

  }

  handleSaveAfterDeleting = async (event) => {
    let countData = 0;


    const { tests = [], orderId, order} = this.state;
    const ordersUserId = order.user.id ?? "";
    const userId= getUserId()
    const nonBloodTests = tests && tests.length > 0 ? tests.filter((item) => item.is_non_blood_biomarker) : [];
    const bloodTests = tests && tests.length > 0 ? tests.filter((item) => !item.is_non_blood_biomarker) : [];
    const filteredBloodTestResults=bloodTests && bloodTests.filter((item)=>item.result && item.result)
    const filteredNonBloodTestResults= nonBloodTests && nonBloodTests.filter((item)=>item.result&& item.result)
    const newTestResults= filteredBloodTestResults.length>0 ? filteredBloodTestResults.map(test => ({
      id: test.testResultsId,
      resultDate: test.resultDate,
      testId: test.id,
      testResultdetails: test.results.map(resultArr => ({
          id: resultArr?.id??null,
          biomarkerId: resultArr.biomarker,
          result: resultArr.result,
          doctorComment: resultArr.doctor_comment,
          doctorCommentAr: resultArr.doctor_comment_ar,
          fromUnit: test.fromUnit
      }))
  })):[];
    const nonBloodBiomarkerResults = filteredNonBloodTestResults.length > 0 ? filteredNonBloodTestResults.map((item) => {
      return {
        "id": item?.non_blood_biomarker_result?.id ?? null,
        "nonBloodBiomarkerId": item.non_blood_biomarker_id,
        "resultDate": item?.resultDate ?? "",
        "result": item?.result ?? ""
      }
    }) : []
    console.log("nonBloodBiomarkerResultsnonBloodBiomarkerResults", newTestResults, nonBloodBiomarkerResults)

    const saveResults={
      userId: parseInt(ordersUserId),
      orderId: parseInt(orderId),
      uploadedById:parseInt(userId),
      testResults:newTestResults??[],
      nonBloodBiomarkerResults:nonBloodBiomarkerResults??[],

    }
      await this.handleSaveResults(saveResults)

    // await this.handleSaveNonBloodBiomarkers(nonBloodTests)
    // bloodTests.forEach((test, index) => {

    //   countData++;
    //   if (test && test.result) {  // changed condition test.result && test.result !== null || test.result !== ""

    //     this.handleSubmit(event, index);
    //   }




    // })


    await this.uploadOCRAuditTrail();
   return this.loadData();
    // this.handleNotification();
  }

  handleDeleteBiomarker = async () => {
    const { listBiomarkersRemoved, userId, orderId, tests = [], listNonBloodBiomarkersRemoved } = this.state;
    const labUserId = getUserId()
    const testIds = listBiomarkersRemoved.join(',');
    const nonBloodTestIds = listNonBloodBiomarkersRemoved.join(',');


    if (listBiomarkersRemoved.length > 0 || listNonBloodBiomarkersRemoved.length > 0) {
      try {
        const url = `delete/biomarkers?userId=${userId}&orderId=${orderId}&testIds=${testIds}&labUserId=${labUserId}&nonBloodTestIds=${nonBloodTestIds}`;
        const resposne = await PhoenixAPI.delete(url);
        if (listBiomarkersRemoved.length > 0 || listNonBloodBiomarkersRemoved.length > 0) {
          if (tests.length > 0) {
            const nonBloodTests = tests && tests.length > 0 ? tests.filter((item) => item.is_non_blood_biomarker) : [];
            const bloodTests = tests && tests.length > 0 ? tests.filter((item) => !item.is_non_blood_biomarker) : [];
            const filteredBloodTests = bloodTests.filter((test) => { return !listBiomarkersRemoved.includes(test.id); });
            const filteredNonBloodTests = nonBloodTests.filter((test) => { return !listNonBloodBiomarkersRemoved.includes(test.non_blood_biomarker_id); });
            const filteredTests = [...filteredBloodTests, ...filteredNonBloodTests]
            this.setState({ tests: [...filteredTests] }, (e) => this.handleSaveAfterDeleting(e));
          }

        }

      }
      catch (error) {
        console.log("error is", error);
        this.showErrorNotification("error on delete biomarker", error.message);
      }
    }
  }

  handleDeleteFooditems=async()=>{
    const {selectedFoodItemIds, initialFoodItems, orderId,order}=this.state;
    const foodItemIds= selectedFoodItemIds.join(",")
    const ordersUserId = order.user.id ?? "";
    const userId= getUserId()
    let deletedResults=[]
    initialFoodItems.forEach(category => {
      category.foodItems.length > 0 && category.foodItems.forEach(item => {
        deletedResults.push(item);
      });
    });
    const finalDeletedResults= deletedResults.map((item)=>{
      const obj={
        resultDate: item.resultDate?item.resultDate:moment(new Date()).format("YYYY-MM-DD"),
        userId: parseInt(ordersUserId),
        orderId: parseInt(orderId),
        foodItemId: item.foodItemId,
        foodCategoryId: item.foodCategory,
        uploadedById:parseInt(userId),
        result: parseInt(item.result),
        resultFlag: item.flag,
        units: item.unit,
        customerPriority: item.customerPriority,
        isDeleted: item.isDeleted,
        id:item.testResultId ?? null
      }
      return obj;
    })
    // console.log("finalDeletedResults", finalDeletedResults)
    if(selectedFoodItemIds.length>0){
      const url = `admin/results/${orderId}/${ordersUserId}`;
      try{
        await PhoenixAPI.patch(url, finalDeletedResults);
      }
      catch(error){
        console.log("error is", error.message);
        this.showErrorNotification("error on delete biomarker", error.message);
      }
       
    }

  }

  handleSaveNonBloodBiomarkers=async(nonBloodTests=[])=>{
    const {orderId, userId}= this.state;
    
    const filteredNonBloodTests =nonBloodTests && nonBloodTests.length>0?nonBloodTests.filter((item)=>(item.result !== null && item.result !== "" && item.result !== undefined)):[];
    const mappedNonBloodBiomarker=filteredNonBloodTests.map((item)=>{
      return {
         "id":item?.non_blood_biomarker_result?.id ??null,
        "nonBloodBiomarkerId":item.non_blood_biomarker_id,
        "orderId":parseInt(orderId),
        "userId":userId,
        "resultDate":item.resultDate,
        "result":item.result
      }
    })

    if(mappedNonBloodBiomarker && mappedNonBloodBiomarker.length>0){
      try{
        const response= await PhoenixAPI.post("save-all-order-non-blood-biomarker-results", mappedNonBloodBiomarker);
        console.log("Response", response)
      }
      catch(error){
       this.showErrorNotification("Error in saving Non blood biomarkers", error.message)
       console.log("Error in saving non blood biomarkers", error.message)
      }
    }
    console.log("filteredNonBloodTests filteredNonBloodTests",mappedNonBloodBiomarker);
  }

  handleSaveResults=async(payload)=>{
     try{
      this.showLoading()
      const response= await PhoenixAPI.post("save-and-update-test-results", payload);
      console.log("response in saving test results",response);
      this.showSuccessNotification("Results are saved successfully")
     }
     catch(error){
       console.log("Error in saving test results", error.message)
       this.showErrorNotification("Error in saving test results",error.message) 
     }
     finally{
      this.hideLoading()
     }
  }
  handleAllsubmit=async(event)=> {
  
    const {isFoodIntoleranceTest, initialFoodItems, orderId,userNameAdminPanel, order, postApiCall=false}= this.state || {}
     const ordersUserId = order.user.id ?? "";
    const userId= getUserId()
    if(isFoodIntoleranceTest){
    
       // Extracting food items from each category
       const foodResults= initialFoodItems.reduce((accumulator, category) => {
           accumulator.push(...category.foodItems);
           return accumulator;
       }, []);
       
       // 'foodResults' now contains all food items from both categories
       let foodItemWithResults=[]
          foodResults.forEach((item)=>{
        if(item.result){
          foodItemWithResults.push(item)
        }
        else{
          const newItem={...item,result:0}
          foodItemWithResults.push(newItem)
        }
      })
  
      const finalSaveResults=foodItemWithResults.map((item)=>{
        const obj={
          resultDate: item.resultDate?item.resultDate:moment(new Date()).format("YYYY-MM-DD"),
          userId: parseInt(ordersUserId),
          orderId: parseInt(orderId),
          foodItemId: item.foodItemId,
          foodCategoryId: item.foodCategory,
          uploadedById:parseInt(userId),
          result: parseInt(item.result),
          resultFlag: item.flag,
          units: item.unit,
          customerPriority: item.customerPriority,
          isDeleted: item.isDeleted,
          id:item.testResultId ?? null
          
        }
        return obj
      })
      // try{
      //    await this.handleDeleteFooditems()
      // }
      // catch(error){
      //   console.log("error is", error);
      //   this.showErrorNotification("error on delete food Items", error.message);
      // }
       
       if(finalSaveResults.length>0){
        try{
          const response=postApiCall?
          await PhoenixAPI.post(`admin/result-details/${orderId}/${ordersUserId}`,finalSaveResults):
          await PhoenixAPI.patch(`admin/results/${orderId}/${ordersUserId}`,finalSaveResults);
          console.log("response", response)
          this.props.showNotificationMessage({
            notificationMessage: "Result successfully updated.",
            successMessage: true,
            showNotification: true,
          });
        }
 
        catch(error){
         console.log("Error in saving results", error.message)
        }
        this.loadFinalFoodResults()
       } 
    }
    else{
      // try {
      //   await this.handleDeleteBiomarker();
      // }
      // catch (error) {
      //   this.showErrorNotification("error on delete biomarker", error.message);
      // }
      let countData = 0;
      
     
      const{tests=[]} = this.state;
      
      const nonBloodTests=tests && tests.length>0 ? tests.filter((item)=>item.is_non_blood_biomarker):[];
      const bloodTests=tests && tests.length>0 ? tests.filter((item)=>!item.is_non_blood_biomarker):[];
      const filteredBloodTestResults=bloodTests && bloodTests.filter((item)=>item.result && item.result)
      const filteredNonBloodTestResults= nonBloodTests && nonBloodTests.filter((item)=>item.result&& item.result)
      const newTestResults= filteredBloodTestResults.length>0 ? filteredBloodTestResults.map(test => ({
        id: test.testResultsId,
        resultDate: test.resultDate,
        testId: test.id,
        testResultdetails: test.results.map(resultArr => ({
            id: resultArr?.id??null,
            biomarkerId: resultArr.biomarker,
            result: resultArr.result,
            doctorComment: resultArr.doctor_comment,
            doctorCommentAr: resultArr.doctor_comment_ar,
            fromUnit: test.fromUnit
        }))
    })):[];
      const nonBloodBiomarkerResults = filteredNonBloodTestResults.length > 0 ? filteredNonBloodTestResults.map((item) => {
        return {
          "id": item?.non_blood_biomarker_result?.id ?? null,
          "nonBloodBiomarkerId": item.non_blood_biomarker_id,
          "resultDate": item?.resultDate ?? "",
          "result": item?.result ?? ""
        }
      }) : []
      console.log("nonBloodBiomarkerResultsnonBloodBiomarkerResults", newTestResults, nonBloodBiomarkerResults)

      const saveResults={
        userId: parseInt(ordersUserId),
        orderId: parseInt(orderId),
        uploadedById:parseInt(userId),
        testResults:newTestResults??[],
        nonBloodBiomarkerResults:nonBloodBiomarkerResults??[],

      }
        await this.handleSaveResults(saveResults)
      // await this.handleSaveNonBloodBiomarkers(nonBloodTests)
      // bloodTests.forEach((test,index) => {
        
      //   countData++;
      //   if (test && test.result){  // changed condition test.result && test.result !== null || test.result !== ""
          
      //     this.handleSubmit(event,index);
      //   }
       

      //   if (bloodTests && bloodTests.length && countData === bloodTests.length){
      //     this.loadData();
      //   }
        
      // })

  
      this.uploadOCRAuditTrail();
      return this.loadData()
      // this.handleNotification();
    }
   
  
  }
  loadData() {
    this.setState({ isLoaded: false });
    this.fetchLabReportResults();
    this.loadTests();
    this.getTestCategories();
    
  }

  loadFinalFoodResults = async () => {
    const { order, initialFoodItems, isFoodIntoleranceTest = false, isPastOrder = false, userType , collapsibles,foodItemsInitialResults,postApiCall} = this.state;

    const { id: orderId = null, user } = order || {};
    const { id: userId = null } = user || {}

    if (isFoodIntoleranceTest) {
      try {
        this.showLoading();
        const { data: foodResults = {} } = await PhoenixAPI.get(`admin/lab/result/food-intolerance-tests/${orderId}/${userId}`);
       
        const { results = [], summary = [], highLimit="",lowLimit="", mediumLimit="" , uploadedAt=""} = foodResults ?? {};
       
        const filtered = summary.map((item) => {
          if (item.foodItems.length > 0) {
            return item
          }
          else {
            return null
          }
        })

        const finalFiltered = filtered.filter((it) => it !== null)

        let allFoodItems = []
        if (userType === "nutritionist" && isPastOrder || userType === "externalNutritionist" && isPastOrder) {
          /// for coach past orders view 
          finalFiltered.forEach(category => {
            category.foodItems.length > 0 && category.foodItems.forEach(item => {
              allFoodItems.push(item);
            });
          });


          const finalResults = finalFiltered.map(item => ({
            ...item,
            foodItems: item.foodItems.map(food => ({
              ...food,
              result: food.score,
              flag: food.rating,
              // Set 'result' as the value of 'score'
            }))
          }));

          // Creating output array 'b' by updating each "foodItem" array
          finalResults.forEach((item)=>{
          
           return collapsibles[item.categoryId] = true
          })
          // this.setState({ collapsibles });
          const activeFoodItems = finalResults.map(item => {
            const filteredItems = item.foodItems.filter(food => !food.isDeleted);
            return { ...item, foodItems: filteredItems };
          });
          this.setState({ initialFoodItems: activeFoodItems, highLimit,mediumLimit,lowLimit, resultDate:uploadedAt, collapsibles, postApiCall:false })
        }
        else {
          results.forEach(category => {
            category.foodItems.length > 0 && category.foodItems.forEach(item => {
              allFoodItems.push(item);
            });
          });
          let final
          if(isPastOrder){
            const mappingfoodItems=results.map((item)=>{
              const foodItems = item.foodItems.map(food => ({
                ...food,
                flag: "",
                unit: null,
                highLimit: null,
                mediumLimit: null,
                lowLimit: null,
                isDeleted: false,
                position: null,
                result:null,
                resultDate:null,
                resutlFlag:"",
                customerPriority: false,
                isDeleted: false,
                foodItemId:food.id
              }));
              return {
                ...item,
                foodItems
              };
            })
            final = mappingfoodItems.map((category) => {
              const updatedFoodItems = category.foodItems.map((foodItem) => {
                const matchingResult = allFoodItems.find(result => result.foodName === foodItem.foodName);
                if (matchingResult) {
                  return {
                    ...foodItem,
                    ...matchingResult, foodItemId: foodItem.foodItemId
                    , result: matchingResult.score, flag: matchingResult.rating
                  };
                } else {
                  return {...foodItem,foodItemId: foodItem.foodItemId
                  };
                }
              })
  
              return {
                ...category,
                foodItems: updatedFoodItems
              };
            })
          }
          else{
           
            const finalResults= results.map((item)=>{
              const foodItems=item.foodItems.map(food=>({
              ...food,
              flag: "",
              unit: null,
              highLimit: null,
              mediumLimit: null,
              lowLimit: null,
              isDeleted: false,
              position: null,
              result:null,
              resultDate:null,
              resutlFlag:"",
              customerPriority: false,
              isDeleted: false,
              foodItemId:food.foodItemId,
              id:food.foodItemId,
              foodCategory: food.categoryId,
              }))
              return{
                ...item,foodItems
              }
            })
            
            final = finalResults.map((category) => {
              const updatedFoodItems = category.foodItems.map((foodItem) => {
                const matchingResult = allFoodItems.find(result => result.foodName === foodItem.foodName);
                if (matchingResult) {
                  return {
                    ...foodItem,
                    ...matchingResult, foodItemId: foodItem.foodItemId
                    , result: matchingResult.score, flag: matchingResult.rating
                  };
                } else {
                  return {...foodItem,foodItemId: foodItem.foodItemId
                  };
                }
              })
  
              return {
                ...category,
                foodItems: updatedFoodItems
              };
            })
          }
           
            const activeFoodItems = final.map(item => {
              const filteredItems = item.foodItems.filter(food => !food.isDeleted);
              return { ...item, foodItems: filteredItems };
            });
            this.setState({ initialFoodItems: activeFoodItems, highLimit,mediumLimit,lowLimit, resultDate:uploadedAt, defaultOpen:false,selectedFoodItemIds:[],collapsibles:{}, postApiCall:false})
          
          
        }
      }
      catch (error) {
        console.log("Error occurred in fetching food intolerance results:", error.message)
        this.showErrorNotification("Error occurred in fetching food intolerance results:", error.message)
      }
      finally {
        this.hideLoading()
      }
    }

  }

  handleNotification = async () => {
    const { userId, orderId, self_result_upload } = this.state;
    let payload = {}
    payload["userId"] = userId;
    payload["orderId"] = orderId;
    if (self_result_upload) {
      try {
        const response = await PhoenixAPI.post(`${BASE_URL_TEST_SEND_NOTIFICATION}`, payload);
        console.log("sending user-own-tests notification", response)
      }
      catch (error) {
        console.log("Error in sending user-own-tests notification", BASE_URL_TEST_SEND_NOTIFICATION)
        console.log("some error occurred: ", error.message)
      }
    }
    else {
      return
    }
  }

  isInBetween = (number, rangeStart = 0, rangeEnd = 0) => {
    return number >= rangeStart && number <= rangeEnd;
  };

  findOptimalStatus=(row)=>{
    let result = row.result && row.result ? row.result : (this.state.results && this.state.results.length > 0 ? this.state.results[row.index] : '');
    const markers =
    this.state.tests[row.index] &&  this.state.tests[row.index].markers && this.state.tests[row.index].markers.length > 0
        ? this.state.tests[row.index].markers[0]
        : "";
     const {is_optimal_range = false, optimal_range_start, optimal_range_end }=markers
     let val = false;
     if (is_optimal_range) {
       val =
 
         this.isInBetween(
           Number(result),
           Number(optimal_range_start),
           Number(optimal_range_end)
 
         );
     }
     return val;
  }
  returnFlage(row) {
    
    // let result = row.result && row.result ? (Number(row.result) == 0 || Number(row.result) == 1 ? Number(row.result) : (Number(row.result) % 1 === 0 ? row.result - 1 : (row.result - 0.1))) : (this.state.results && this.state.results.length > 0 ? this.state.results[row.index] : '');
    // result = result == 0 && result != '' ? null : result;
    let result = row.result && row.result ? row.result : (this.state.results && this.state.results.length > 0 ? this.state.results[row.index] : '');
    const markers =
    this.state.tests[row.index] &&  this.state.tests[row.index].markers && this.state.tests[row.index].markers.length > 0
        ? this.state.tests[row.index].markers[0]
        : "";
    var statusName = "";
    var statusStyle = {};

  
    const {is_biomarker_multiple_ranges,is_show_biomarker,problematic_biomarker_answer, problematic_biomarker_question, is_optimal_range = false, optimal_range_start, optimal_range_end }= markers || {};
    const isProblematicBiomarker =!is_show_biomarker && is_biomarker_multiple_ranges;
    const showProblematicQuestion= is_biomarker_multiple_ranges
    const optimalRangeStatus=this.findOptimalStatus(row)
    markers.biomarker_list &&
      markers.biomarker_list.map((val) => {

        if (result <= val.range_end && (val.range_start == null || val.range_start == 0)) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        }
        if (result > val.range_start && result <= val.range_end) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        } else if (result > val.range_start && (val.range_end == null || val.range_end == 0)) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        } else if (result > val.range_start && (val.range_end !== null || val.range_end !== 0)) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        } else if (!statusName && val.range_start && val.status_name) {
          statusName = val.status_name;
          statusStyle = {
            background: val.status_color_code,
          };
        }

      });
    if (!statusName) {
      statusName = "Out of Range";
      statusStyle = {
        background: "#CCCCCC",
      };
    }
    if (result!=null || "") {
      return (
        <>
        <div className="status-div" style={{ filter:isProblematicBiomarker? "blur(6px)":"" }}>
          {!optimalRangeStatus ?<div>
            <div className="status" style={statusStyle}>
              <p style={{ 'borderRadius': '0.25rem' }}>{statusName}</p>
            </div>
          </div>:<div
              className="status px-2 py-2 text-wrap"
              style={{  background:"rgb(0, 122, 255)", borderRadius: "5px", minWidth: "100px",filter:isProblematicBiomarker? "blur(6px)":"" }}
            >
              <p style={{ textAlign: "center",color: "white" }}>Optimal</p>
            </div>}
          
        </div>
        {optimalRangeStatus?<div style={{filter:isProblematicBiomarker? "blur(6px)":"" }}>Optimal : {optimal_range_start} - {optimal_range_end}</div>:""}
        {showProblematicQuestion?<div style={{marginTop:"10px",fontStyle:"Italic"}}>This is a problematic biomarker</div>:""}
        {/* {showProblematicQuestion? <div style={{fontSize:"14px",color:"#07141A", fontWeight:"600",marginTop:"10px",textWrap:"wrap", minWidth: "100px"}}>{problematic_biomarker_question}</div>:""} */}
        </>
        

      );
    } else {
      return ('Out Of Range')
    }
  }

  renderProblematicAnswer=(problematic_biomarker_answer)=>{
    return(
    <>
    {problematic_biomarker_answer ?<div style={{ backgroundColor:"#F2F2F2", borderRadius:"4px",padding:"16px",textWrap:"wrap",  minWidth: "250px"}}>{problematic_biomarker_answer}</div>:""}
    
    </>)
   }

  renderRange(item){
    const markers =
    this.state.tests[item.index] && this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0
      ? this.state.tests[item.index].markers[0]
      : "";
       
      const {is_biomarker_multiple_ranges,is_show_biomarker,problematic_biomarker_answer, problematic_biomarker_question,
         is_optimal_range=false,optimal_range_start=null,optimal_range_end=null} = markers || {};
      const isProblematicBiomarker =!is_show_biomarker && is_biomarker_multiple_ranges;
      const showProblematicQuestion= is_biomarker_multiple_ranges
    
    return(
      <>
       {
        this.state.tests[item.index] && this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0 && item.result != null || ""? (
        <>
       <div className="form-row justify-content-center align-items-center" style={{filter:isProblematicBiomarker? "blur(6px)":"" }}>
       <Range
          result={Number(item.result)}
         biomarker_list={
           this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0 ? this.state.tests[item.index].markers[0]
          .biomarker_list : []
            }
          val={1}
          isOptimalRange={is_optimal_range}
          optimalRangeStart={optimal_range_start}
          optimalRangeEnd={optimal_range_end}
          /> 
          
                         
         </div>
         {/* {showProblematicQuestion && this.renderProblematicAnswer(problematic_biomarker_answer)} */}
        </>
      ) : ('Out Of Range')
      }
      </>
    )
  }
  returnCategories(row) {

    if (this.state.testCategortList && this.state.tests[row.index] && this.state.tests[row.index].test_category) {
      let temp = ''
      this.state.tests[row.index].test_category.map((item2, index) => {
        if (this.state.testCategortList.filter((item3) => item3.key == item2).length) {
          temp = temp + this.state.testCategortList.filter((item3) => item3.key == item2)[0].text;
          if (index != row.test_category.length - 1) {
            temp = temp + ', '
          }
        }
      })
      return temp
    }
    else{
      return ""
    }
    
  }


  getTestCategories() {
    API.get("test-category/")
      .then((response3) => {
        let categoryList = response3.data
        categoryList = filterList(categoryList, 'active')
        let temp = []
        categoryList.map((item) => {
          temp.push({
            text: item.name,
            value: item.id,
            key: item.id
          })
        })
        this.setState({
          testCategortList: temp
        })
      }).catch((err) => {
        console.error("error fetching test categories", err);
      })
  }

  // NewTestAPI=async(newTestAPIData, tests)=>{
  //   const {packageId="", userId="", countryId="", orderId=""}= newTestAPIData || {}
  //  try{
  //   const {data:newTests=[]}=await PhoenixAPI.get(`v2/tests/packageId/${packageId}/country/${countryId}/orderId/${orderId}?userId=${userId}`)
  //   console.log("newAPINewResponsenewAPINewResponse", newTests)
  //   console.log("Testss:",tests )
  //   let markers=[];
  //   let biomarker_list = [];
     
  //   newTests.forEach((it,index)=>{
  //     if(it.test_type==="B"){
  //       let activeList = filterList(
  //         it.biomarkers,
  //         "active"
  //       );
  //       markers = activeList.map((marker) => {
  //         const {is_show_biomarker,problematic_biomarker_status=[],is_biomarker_multiple_ranges}= marker ;
            
  //          //mapping problematic bimarker status
  //         if (this.state.gender == "M") {
  //           if(is_show_biomarker && is_biomarker_multiple_ranges){
  //             biomarker_list = filterList(marker.problematic_biomarker_status, "M");
  //           }
  //           else{
  //             biomarker_list = filterList(marker.biomarker_status, "M");
  //           }
            
  //         } else if (this.state.gender == "F") {
  //           if(is_show_biomarker && is_biomarker_multiple_ranges){
  //             biomarker_list = filterList(marker.problematic_biomarker_status, "F");
  //           }
  //           else{
  //             biomarker_list = filterList(marker.biomarker_status, "F");
  //           }
           
  //         }
  //         return {
  //           id: marker.id,
  //           name: marker.name,
  //           name_ar: marker.name_ar,
  //           unit: marker.unit,
  //           biomarker_list:
  //             biomarker_list.length !== 0 ? biomarker_list : [],
  //           is_show_biomarker:marker.is_show_biomarker ?? false,
  //           is_biomarker_multiple_ranges:marker.is_biomarker_multiple_ranges ?? false,
  //           problematic_biomarker_question:marker.problematic_biomarker_question ?? "",
  //           problematic_biomarker_answer:marker.problematic_biomarker_answer ?? ""

  //         };
  //       });
  //     }
  //     else if(it.test_type==="F"){
  //       const activeList = filterList(
  //        it.foodintolerance,
  //         "active"
  //       );
  //       markers = activeList.map((marker) => {
  //         return {
  //           id: marker.id,
  //           name: marker.food_name,
  //           name_ar: marker.food_name_ar,
  //         };
  //       });
  //     }
  //     it.markers=markers;
  //   })
  //   console.log("NewTestAPI :problematic",newTests)
  //  }
  //  catch(error){
  //  console.log("error:", error.message)
  //  }
   
  // }

  getLoadOrdersUrl=()=>{
       const { orderId, userType, isPastOrder } = this.state;
       let baseUrl="";
      
       if (isPastOrder) {
         // baseUrl =  userType === "lab" ? "order-lab-past" : "order-nutritionist-past";
         baseUrl =  userType === "lab" ? "order-lab-past" : "coach-combine-active-order";
       } else {
         baseUrl =  userType === "lab" ? "order-lab" : "order-nutritionist";
       }

       return `${baseUrl}/${orderId}/?is_non_blood_test_required=yes`;
  }

  // handleFollowUpPackages=()=>{

  //   const {order,bloodPackages,miniPackages}= this.state;

  //   const {orderFollowUpDto={}}= order || {};
  //   console.log("order Follow", orderFollowUpDto)
  //   const followUpPackage={
  //     "isBloodPackage":orderFollowUpDto && orderFollowUpDto.isBloodPackage || false,
  //     "isMiniPackage":orderFollowUpDto && orderFollowUpDto.isMiniPackage || false,
  //   }

  
  //   const newOrderFollowUpDto = { ...orderFollowUpDto}
  //   const newOrder= {...order, orderFollowUpDto:newOrderFollowUpDto}
  //   this.setState({followUpPackage, order:newOrder})
  // }
  fetchSuggestedPackageSupplements=async(suggestedConditions)=>{

    // console.log("fetchSuggestedPackageSupplements")

    const {partnerId, countryId}=suggestedConditions || {};
    if(!countryId){
      console.log("Invalid country!", countryId);
        return [];
     }
     let url=""
     if(partnerId!==null || undefined){
        url = `${BASE_URL_SUGGESTED_PACKAGES_SUPPLEMENTS}?country=${countryId}&partnerId=${partnerId}`
     }
     else{
       url = `${BASE_URL_SUGGESTED_PACKAGES_SUPPLEMENTS}?country=${countryId}`
     }
     try{
      this.showLoading();
      const { data } = await PhoenixAPI.get(url);
      // return data
      const {supplements, customPackages,bloodPackages, miniPackages}= data || {}
       
       this.setState({supplements,customPackages,bloodPackages, miniPackages})
      }
      catch(error){
       console.log("Error on fetching suggested pacakges and supplements", error);
       this.showErrorNotification(
         "Error on fetching suggested pacakges and supplements! " + error.message
       );
      }
      finally {
       this.hideLoading();
     }
   }

  combinedAPI = async (tests, nonBloodTests) => {
    let results = [],
    resultIds = [],
    comments = [],
    comments_ar = [],
    recommendedTests = [];
    const { order = {}, orderId, countryId, isSelfResultUpload } = this.state || {}
    const userId = order.user.id ?? "";
    const username= order.user.username ??"";
    const {doctors_note="", doctors_note_ar=""}=order || {}
    const packageId=order.order_item_id ?? ""
    let data
    try {
      
      if(packageId){
        const  response = await PhoenixAPI.get(`v2/tests/country/${countryId}/orderId/${orderId}?userId=${userId}&isNonBloodTestRequired=yes`,{
          params:{
            packageId:packageId
          }
        })
        data = response.data;
      }
      else{
        const response= await PhoenixAPI.get(`v2/tests/country/${countryId}/orderId/${orderId}?userId=${userId}&isNonBloodTestRequired=yes`)
        data = response.data
      }
     

      
      let newTests = []
      data.map((item) => {
        tests.map((it) => {
          if (it.id === item.id) {
            newTests.push(item)
          }
        })
      })

      let newNonBloodTests=[];
      console.log("non blood tests",data,nonBloodTests);
      data.map((item) => {
        nonBloodTests.map((it) => {
          if (it.non_blood_biomarker_id === item.non_blood_biomarker_id) {
            newNonBloodTests.push(item)
          }
        })
      })
     
    
     const testMarkers=newTests.map((test,index)=>{
      let markers = [];
      let biomarker_list = [];
          if(test.test_type==="B"){
          let activeList = filterList(
            test.biomarkers,
            "active"
          );
          if(activeList && activeList.length>0){
            markers = activeList.map((marker) => {
              const {isShowBiomarker,problematicBiomarkerStatus=[],isBiomarkerMultipleRanges, is_optimal_range=false, 
                optimal_range_start=null, 
                optimal_range_end=null}= marker ;
                 
               //mapping problematic bimarker status
              if (this.state.gender == "M") {
                if(isShowBiomarker && isBiomarkerMultipleRanges){
  
                  biomarker_list = filterList(marker.problematicBiomarkerStatus, "M");
                }
                else{
                  biomarker_list = filterList(marker.biomarker_status, "M");
                }
                
              } else if (this.state.gender == "F") {
                const {isShowBiomarker,problematicBiomarkerStatus=[],isBiomarkerMultipleRanges}= marker ;
                if(isShowBiomarker && isBiomarkerMultipleRanges){
                  biomarker_list = filterList(marker.problematicBiomarkerStatus, "F");
                }
                else{
                  biomarker_list = filterList(marker.biomarker_status, "F");
                }
               
              }
              return {
                id: marker.id,
                name: marker.name,
                name_ar: marker.name_ar,
                unit: marker.unit,
                biomarker_list:
                  biomarker_list.length !== 0 ? biomarker_list : [],
                is_show_biomarker:marker.isShowBiomarker ?? false,
                is_biomarker_multiple_ranges:marker.isBiomarkerMultipleRanges ?? false,
                problematic_biomarker_question:marker.problematicBiomarkerQuestion  ?? "",
                problematic_biomarker_answer:marker.problematicBiomarkerAnswer  ?? "",
                is_optimal_range:marker.is_optimal_range,
                optimal_range_start:marker.optimal_range_start,
                optimal_range_end:marker.optimal_range_end,
                fromUnit:"",
              };
            });
          }
          else{
             return  test
          }
         
        }
        else if(test.test_type==="F"){
          const activeList = filterList(
            test.foodintolerance,
            "active"
          );
          markers = activeList.map((marker) => {
            return {
              id: marker.id,
              name: marker.food_name,
              name_ar: marker.food_name_ar,
            };

          });
        }

       const {result_Details,test_result} = test || {}
       const result = test.result_Details ? test.result_Details.result :null;
       const resultId = test.result_Details ? test.result_Details.id :null;
       const fromUnit=test.result_Details ? test.result_Details.fromUnit:"";
       const itemObject={
         markers:markers ??[],
         id:test.id,
         name:test.name,
         name_ar:test.name_ar,
         test_category:test.test_category,
         type:test.test_type,
         index:index,
         customer_priority: test.test_result ? test.test_result.customer_priority : false,
         recommendations: [],
         resultDate: test.test_result ? test.test_result.result_date : moment(new Date()).format("YYYY-MM-DD"),
         results: [
          {
            id: resultId,
            test_results: test.test_result ? test.test_result.id : null,
            biomarker: markers[0].id,
            result: result,
            doctor_comment: null,
            doctor_comment_ar: null,
            created_at: test.test_result ? test.test_result.result_date : null,
            result_date: test.test_result ? test.test_result.result_date : null,
          },
        ],
        result:result,
        exists:true,
        testResultsId:test.test_result ? test.test_result.id : null,
        isEdit:resultId?true:false,
        orderItemId:test.orderitem_id,
        fromUnit:fromUnit,
        non_blood_biomarker_id:test.non_blood_biomarker_id,
        is_non_blood_biomarker:test.is_non_blood_biomarker,
        non_blood_biomarker_result:test.non_blood_biomarker_result
       }

        return itemObject
     })
      
   
     const nonBloodTestMarkers=newNonBloodTests.map((nonBloodtest)=>{
    
      const result= nonBloodtest.non_blood_biomarker_result? nonBloodtest.non_blood_biomarker_result.result:null;
      const itemObject={
        markers:[],
        id:nonBloodtest.id,
        name:nonBloodtest.name,
        name_ar:nonBloodtest.name_ar,
        test_category:nonBloodtest.test_category,
        type:nonBloodtest.test_type,
        customer_priority: false,
        recommendations: [],
        resultDate:  nonBloodtest.non_blood_biomarker_result ? nonBloodtest.non_blood_biomarker_result.resultDate:moment(new Date()).format("YYYY-MM-DD"),
        results: [
         {
           id: null,
           test_results:  null,
           biomarker: null,
           result:result,
           doctor_comment: null,
           doctor_comment_ar: null,
           created_at: nonBloodtest.non_blood_biomarker_result ? nonBloodtest.non_blood_biomarker_result.result_date : null,
           result_date: nonBloodtest.non_blood_biomarker_result ? nonBloodtest.non_blood_biomarker_result.result_date : null,
         },
       ],
       result:result,
       exists:true,
       testResultsId: null,
       isEdit:false,
       orderItemId:nonBloodtest.orderitem_id,
       fromUnit:"",
       non_blood_biomarker_id:nonBloodtest.non_blood_biomarker_id,
       is_non_blood_biomarker:nonBloodtest.is_non_blood_biomarker,
       non_blood_biomarker_result:nonBloodtest.non_blood_biomarker_result
      }
      return itemObject
     })

    const newTestMarkers=[...testMarkers,...nonBloodTestMarkers] ///new newTestMarkers;
    const mappingIndexsTests=[];
    newTestMarkers.forEach((item, index)=>{
    
      mappingIndexsTests.push({...item,index:index})
      
       
    })
    const isPackageContainsNonBloodBiomarkers = mappingIndexsTests.length>0 && mappingIndexsTests.some((element) => element.is_non_blood_biomarker === true)
    console.log("neewList: newTests:neewList: newTests:",isPackageContainsNonBloodBiomarkers)
    this.setState({
      tests: mappingIndexsTests,
      isLoaded: true,
    }, () => this.setCurrentTestResults());
    console.log("mappingIndexsTestsmappingIndexsTests", mappingIndexsTests);
    mappingIndexsTests.forEach((item,index)=>{
      // console.log("testsMarkers:", item)
      if(item.type==="B"){
       results.push(item.result)
       resultIds.push(item.results[0].id ? item.results[0].id.toString() : "" )
       comments.push(item.results[0].doctor_comment ?? "")
       comments_ar.push(item.results[0].doctor_comment_ar ?? "")
       this.setState({resultDate:item.resultDate})
      }
      else if(item.type==="F"){
        results.push(item.result)
        resultIds.push(item.results[0].id ? item.results[0].id.toString() : "" )
        comments.push(item.results[0].doctor_comment ?? "")
       comments_ar.push(item.results[0].doctor_comment_ar ?? "")
       this.setState({resultDate:item.resultDate})
      }
    })

const overview_obj = doctors_note !== null 
? stateFromHTML(doctors_note)
: stateFromHTML("");

const overview_ar_obj = doctors_note_ar !== null
? stateFromHTML(doctors_note_ar)
: stateFromHTML("");
    
console.log("tests in index tests",mappingIndexsTests);

this.setState({

  tests:mappingIndexsTests,
  userId:userId,
  results: results,
  resultIds: resultIds,
  comments: comments,
  comments_ar: comments_ar,
  userName:username,
    overview: JSON.stringify(convertToRaw(overview_obj)),
    overview_ar: JSON.stringify(convertToRaw(overview_ar_obj)),
    msg_en:doctors_note != null ? doctors_note: stateToHTML(ContentState.createFromText("")),
    msg_ar:doctors_note_ar != null? doctors_note_ar: stateToHTML(ContentState.createFromText("")),  
    isPackageContainsNonBloodBiomarkers
},()=>this.fetchBiomarkerSpecificLabUnits())
    
    }
    catch (error) {
      this.showErrorNotification(
        "Error on fetching tests! " + error
      );
    }
  }
  fetchBiomarkerSpecificLabUnits=async()=>{
    let tests = [...this.state.tests];
     try{
       const {data:labUnits=[]}= await PhoenixAPI.get("biomarker-labspecific-unit");
       
      //  tests.forEach((item)=>{
      //    const findLabUnit= labUnits.find(it=>it.valeo_test_name===item.name);
      //    let labUnit=findLabUnit?.lab_test_unit??""
      //    if(findLabUnit){
      //     item["fromUnit"]=labUnit;
      //     tests[item.index]=item
      //    }
      //  })
      //  this.setState({testData:[...tests], tests, labSpecificUnits:labUnits})
      this.setState({labSpecificUnits:labUnits})
     }
     catch(error){
       this.showErrorNotification(
         "Error on fetching biomarker specific lab units! " + error.message
       );
       console.log( "Error on fetching biomarker specific lab units! " + error.message)
     }
  }
  newResultsAPI = (orderItems) => {

    let tests = [];
    const { testList = [] , nonBloodTestList=[]} = this.state;
    console.log("newResultsApI orderItems:testList", orderItems,testList, nonBloodTestList);
    orderItems.map((item) => {
      testList.map((testItem) => {
        if (testItem.id === item.test) {
          let itemObject = {
            id: testItem.id,
            name: testItem.name,
            name_ar: testItem.name_ar,
            type: testItem.test_type,
            test_category: testItem.test_category,
            
          };
          tests.push(itemObject);
        }
      })
    })

    let nonBloodTests=[]
    orderItems.map((item) => {
      nonBloodTestList.map((testItem) => {
        if (testItem.non_blood_biomarker_id=== item.non_blood_biomarker_id) {
          let itemObject = {
            id: testItem.id,
            name: testItem.name,
            name_ar: testItem.name_ar,
            type: testItem.test_type,
            test_category: testItem.test_category,
            non_blood_biomarker_id:testItem.non_blood_biomarker_id,
          };
          nonBloodTests.push(itemObject);
        }
      })
    })

    console.log("Tests:: nonBloodTests", {tests, nonBloodTests})
     this.combinedAPI(tests, nonBloodTests)
  }

  handleFoodIntoleranceTest=async()=>{
    const {isFoodIntoleranceTest, order={}}=this.state;
    const {id:orderId=""}= order || {}
    try{
      
      const {data:initialFoodItems=[]}= await PhoenixAPI.get(`admin/lab/food-intolerance-tests/${orderId}`)
      const updatedFoodItems = initialFoodItems.map(item => {
        const foodItems = item.foodItems.map(food => ({
          ...food,
          flag: "",
          unit: null,
          highLimit: null,
          mediumLimit: null,
          lowLimit: null,
          isDeleted: false,
          position: null,
          result:null,
          resultDate:null,
          resutlFlag:"",
          customerPriority: false,
          isDeleted: false,
          foodItemId:food.id
        }));
      
        return {
          ...item,
          foodItems
        };
      });
      
       this.setState({initialFoodItems:updatedFoodItems, isLoaded: true, foodItemsInitialResults:updatedFoodItems},()=>this.loadFinalFoodResults())
    }
    catch(error){
      console.log("error in fetching food item results", error.message)
    }
    
  }

  fetchPreviousResult= async(order)=>{
    if(!order)
    { console.log("order is null");
      return;
    }
    const orderId = order.id;
      const biomarkerPastResultUrl=`previous-results/${orderId}`;
      try{
           const {data:pastBiomarkerResults=[]}=await PhoenixAPI.get(biomarkerPastResultUrl);
           this.setState({pastBiomarkerResults})
      }catch (error) {
      console.log("Error on fetching lab report results from ", biomarkerPastResultUrl, error);
    }
    
  }
  
  fetchselfResultBiomarkers=async()=>{
  try{
    const {data=[]}= await PhoenixAPI.get("/user-own-tests/api/v2/biomarkers");
      const  selfResultBiomarkers=Object.values(data);
     
    this.setState({selfResultBiomarkers})
  }
  catch(error){
    this.showErrorNotification("Error in fetching biomarkers", error.message);
    console.log("Error in fetching biomarkers", error.message);
  }
  }

  fetchUserUploadedFiles=async()=>{
    const {order}=this.state;
    const {user, country_id,id:orderId=null}= order || {}
    try{
     const {data: userUploadedFiles = []}= await PhoenixAPI.get(`user-own-tests/api/v2/accept-reports/${country_id}/${user.id}?orderId=${orderId}`)
     this.fetchselfResultBiomarkers()
     this.setState({userUploadedFiles})
    }
    catch(error) {
      this.showErrorNotification("Error on fetching user uploaded files",error.message)
      console.log("Error on fetching user uploaded files", error)
    }
  }
  newloadOrders=async()=>{
    
      const url=this.getLoadOrdersUrl()
     
      const { orderId, userType, isPastOrder, isEditable, isExternalCoach} = this.state;
      const loginUserId=getUserId()
      if(userType==="nutritionist" && isPastOrder || userType==="externalNutritionist" && isPastOrder){
        const params={
          isPastOrder:true,
          isExternalCoach:isExternalCoach,
          coachId:loginUserId,
          isNonBloodTestRequired:"yes"
        }
        try{
          const { data } = await PhoenixAPI.get(`${url}`,{params});
          const {order={}, labResultFiles=[], optionaAndAnswersHearAboutValeo={}, clientPastOrders = [],myOrdersResponse={}} =data || {}
          const isCoachEditAccess= order.isCoachEditAccess ?? false;
          const cartOrders = myOrdersResponse?.cartOrders ?? [];
          const customerTransactions= cartOrders.flatMap((it)=>it.items) ?? [];
          const coachSuggestions = {
            is_coach_consult_done: order.is_coach_consult_done,
            coach_consult_date: order.coach_consult_date,
            is_supplement_required: order.is_supplement_required,
            // coach_supplement_suggestion: order.coach_supplement_suggestion
            //   ? order.coach_supplement_suggestion.map((it) => it.supplement_id)
            //   : [],
              coach_supplement_suggestion: order.coach_supplement_suggestion??[],
             
              is_custom_package_required: order.is_custom_package_required,
              coach_custom_package_suggestions: order.coach_custom_package_suggestions
              ? order.coach_custom_package_suggestions.map((it) => it.custom_package_id)
              : [],
              is_blood_package_required: order.is_blood_package_required,
              coach_blood_package_suggestions: order.coach_blood_package_suggestions
              ? order.coach_blood_package_suggestions.map((it) => it.package_id)
              : [],
          };

          const {orderFollowUpDto={}}= order || {};
          // console.log("order Follow", orderFollowUpDto)
          const followUpPackage={
            "isBloodPackage":orderFollowUpDto && orderFollowUpDto.isBloodPackage || false,
            "isMiniPackage":orderFollowUpDto && orderFollowUpDto.isMiniPackage || false,
          }
      

          
          // const newMappedSupplements=coachSupplementSuggestions.map((suggestion)=>{
      
          //   const obj={
          //     "supplement_id":suggestion.supplementId,
          //     "updated_date": today,
          //      "doses_eng":suggestion.dosesEng,
          //      "doses_ar":suggestion.dosesAr,
          //      "first_suggestion_date":suggestion.firstSuggestionDate ?? today,
          //       "period_id":suggestion.periodId,
          //       "order_id":parseInt(orderId),
          //       "user_id":parseInt(userId),
       
          //       // "period_eng":suggestion.periodEng,
          //   }
          //   return obj
          //  })
          const newMapped=order.coach_supplement_suggestion ??[];
          const newMappedSupplements = newMapped.length>0 ? newMapped.map(item => {
            const { id, ...rest } = item; // Destructuring to remove 'id'
            return rest; // Returning object without 'id'
          }):[]
 
          this.setState({
            orderDetails:order, 
            howDidYouHearDetails:optionaAndAnswersHearAboutValeo,
            customerTransactions: customerTransactions,
            isCustomerTransactionsLoaded: customerTransactions && customerTransactions.length>0 ? true:false,
            clientPastOrders,
            coachSuggestions, 
             followUpPackage,
             mappedSuggestions:newMappedSupplements??[],
             isEditable:isCoachEditAccess?true:false,
          })
          labResultFiles.map((val,index)=>{
            if (val.order == orderId) {
              this.setState({
                lab_result_file: labResultFiles[index].lab_result_s3_url,
                rusult_file_id: labResultFiles[index].id,
              });
            }
          })
   
          const userId = order?.user?.id;
          // console.log("UserId: Past Orders:", userId);
          const countryId = data?.country_id;
          const {partner_id=null,country_id="", order_items}=order || {}; 
                const suggestedConditions={
                  partnerId:partner_id,
                  countryId:country_id
                }
             this.fetchSuggestedPackageSupplements(suggestedConditions)
            //  this.fetchHowDidYouHearDetails(userId, countryId);
           
             let {self_result_upload}= order;
                   if(self_result_upload ===null || self_result_upload === undefined){
                     this.setState({self_result_upload:false})
                   }
                else{
                     this.setState({self_result_upload})
             }
             this.setUsername(data?.user);
            var survey = {};
            if (order && order.survey) {
              survey = order.survey;
              this.setState({survey})
            }
          const {custom_package={}}= order || {};
          const {is_food_intolerance_test=false,is_food_intolerance_smart_report=false}=custom_package || {};
          const username = order.user.username ?? "";
          const { doctors_note = "", doctors_note_ar = "" } = order || {};
          const overview_obj = doctors_note !== null
            ? stateFromHTML(doctors_note)
            : stateFromHTML("");

          const overview_ar_obj = doctors_note_ar !== null
            ? stateFromHTML(doctors_note_ar)
            : stateFromHTML("");
            if(is_food_intolerance_smart_report){

              this.setState({
                isFoodIntoleranceTest:is_food_intolerance_smart_report,
                userName:username,
               overview: JSON.stringify(convertToRaw(overview_obj)),
               overview_ar: JSON.stringify(convertToRaw(overview_ar_obj)),
               msg_en:doctors_note != null ? doctors_note: stateToHTML(ContentState.createFromText("")),
               msg_ar:doctors_note_ar != null? doctors_note_ar: stateToHTML(ContentState.createFromText("")),
               userId
              },()=>this.handleFoodIntoleranceTest())
            }
            else{
              this.newResultsAPI(order_items)
            }
            if(!is_food_intolerance_smart_report && !is_food_intolerance_test){
            this.fetchPreviousResult(order);
            }
        }
        catch(error){
          this.setState({
            loadError: "Some error has occured. Please try again",
          });
        }
        
      }
      else{
        try{
          
          const {data} =await API.get(`${url}`)
           const {order_items, user, custom_package={}, self_result_upload=false}=data || {}
           console.log("order_items", order_items)
           const {is_food_intolerance_test=false}=custom_package || {};
           if(is_food_intolerance_test){
           this.setState({isFoodIntoleranceTest:is_food_intolerance_test},()=>this.handleFoodIntoleranceTest())
           }
          else if(self_result_upload){
             this.setState({isSelfResultUpload:self_result_upload},()=>this.fetchUserUploadedFiles())
           }
          
            this.setState({userId:user.id})
           this.setUsername(data?.user);
           if (userType === "nutritionist" || userType ==="externalNutritionist" || userType === "lab") {
            this.getLabResults();
          }
           this.newResultsAPI(order_items)
        }
        catch(error){
          this.setState({
            loadError: "Some error has occured. Please try again",
          });
        }
        
      }
  }
  loadOrders() {
    let url;
    let results = [],
      resultIds = [],
      comments = [],
      comments_ar = [],
      recommendedTests = [];

    const { orderId, userType, isPastOrder } = this.state;
    if (userType === "nutritionist") url = "order-nutritionist";
    else if(userType === "externalNutritionist") url ="order-nutritionist";
    else if (userType === "lab") url = "order-lab";
    else url = "order-list";

    if (isPastOrder) url += "-past/";
    else url += "/";

    this.props.toggleLoading({
      isLoading: true,
    });
    
    API.get(url + orderId + "/")
      .then((response) => {
        let data = response.data;
        this.setState({ orderDetails: data });
        const {order_item_id=""}= data || {};
        const {partner_id=null,country_id=""}=data || {}; 
        const suggestedConditions={
          partnerId:partner_id,
          country_id:country_id
        }
        const userId = data?.user?.id;
        const countryId = data?.country_id;
        if(userType==="nutritionist" || userType ==="externalNutritionist"){
          this.fetchSupplements(suggestedConditions);
          this.fetchBloodPackages(suggestedConditions);
          this.fetchCustomPackages(suggestedConditions);
        //  const countryId = countryId;
        //  const userId = userId;
         this.fetchHowDidYouHearDetails(userId, countryId);
        }
     
        let {self_result_upload}= data;
        if(self_result_upload ===null || self_result_upload === undefined){
          this.setState({self_result_upload:false})
        }
        else{
          this.setState({self_result_upload})
        }
        let tests = [];
        this.props.toggleLoading({
          isLoading: false,
        });
        this.setUsername(data?.user);
        response.data.order_items.map((item) => {
          this.state.testList.map((testItem) => {
            if (testItem.id === item.test) {
              let itemObject = {
                id: testItem.id,
                name: testItem.name,
                name_ar: testItem.name_ar,
                type: testItem.test_type,
                test_category: testItem.test_category
              };
              tests.push(itemObject);
            }
          });
        });
        if (userType === "nutritionist" || userType === "lab" || userType ==="externalNutritionist") {
          this.getLabResults();
        }
        if (userType === "nutritionist" || userType ==="externalNutritionist") {
          var survey = {};
          if (data.survey !== null) {
            survey = data.survey;
          }
        }
          
        for (let test of tests) {
          // API.get("tests/" + test.id + "/", { params: { country: this.state.countryId } })
           API.get(`tests/${test.id}/?country=${this.state.countryId}&order_id=${orderId}&user_id=${userId}`)
            .then((response2) => {
              // console.log("Response:", test,response2.data )
              let markers = [];
              let biomarker_list = [];

              if (response2.data.test_type === "B") {
                let activeList = filterList(
                  response2.data.biomarkers,
                  "active"
                );
                
                markers = activeList.map((marker) => {
                  const {is_show_biomarker,problematic_biomarker_status=[],is_biomarker_multiple_ranges}= marker ;
          
                   //mapping problematic bimarker status
                  if (this.state.gender == "M") {
                    if(is_show_biomarker && is_biomarker_multiple_ranges){
                      biomarker_list = filterList(marker.problematic_biomarker_status, "M");
                    }
                    else{
                      biomarker_list = filterList(marker.biomarker_status, "M");
                    }
                    
                  } else if (this.state.gender == "F") {
                    if(is_show_biomarker && is_biomarker_multiple_ranges){
                      biomarker_list = filterList(marker.problematic_biomarker_status, "F");
                    }
                    else{
                      biomarker_list = filterList(marker.biomarker_status, "F");
                    }
                   
                  }
                  return {
                    id: marker.id,
                    name: marker.name,
                    name_ar: marker.name_ar,
                    unit: marker.unit,
                    biomarker_list:
                      biomarker_list.length !== 0 ? biomarker_list : [],
                    is_show_biomarker:marker.is_show_biomarker ?? false,
                    is_biomarker_multiple_ranges:marker.is_biomarker_multiple_ranges ?? false,
                    problematic_biomarker_question:marker.problematic_biomarker_question ?? "",
                    problematic_biomarker_answer:marker.problematic_biomarker_answer ?? ""

                  };
                });
              } else if (response2.data.test_type === "F") {
                const activeList = filterList(
                  response2.data.foodintolerance,
                  "active"
                );
                markers = activeList.map((marker) => {
                  return {
                    id: marker.id,
                    name: marker.food_name,
                    name_ar: marker.food_name_ar,
                  };
                });
              }
              test.markers = markers;
              
            })
            .catch((error) => {
              console.log("Error on fetching data", error);
              this.setState({
                loadError: "Some error has occured. Please try again",
              });

              this.props.showNotificationMessage({
                notificationMessage:
                  "Some error has occured. Please try again.",
                successMessage: false,
                showNotification: true,
              });
              return;
            });
        }

        let loadCount = 0;

        for (let index in tests) {
          tests[index].index = index;
          tests[index].results = [{
            'result': ''
          }];
          tests[index]['result'] = '';
          // console.log(tests[index].type);
          this.setState({
            resultDate:
              tests[index].resultDate === undefined
                ? moment(new Date()).format("YYYY-MM-DD")
                : tests[index].resultDate,
            testName: tests[index].name,
            testId: tests[index].id,
            testType: tests[index].type,
          });
          if (tests[index].type === "B") url = "biomarker-test-result";
          else if (tests[index].type === "F")
            url = "food-intolerance-test-result";

          API.get(url + "/", {
            params: {
              order: orderId,
              test: tests[index].id,
            },
          })
            .then((response2) => {
              if (response2.data.length > 0) {
                if (response2.data.length > 1) {
                  const copyOfData = [...response2.data];
                  // console.log("Test Results:: beforeSort:", copyOfData);
                  response2.data.sort((first, second) => {
                    const firstTestResult = first.testresults && first.testresults.length ? first.testresults[0] : null;
                    const secondTestResult = second.testresults && second.testresults.length ? second.testresults[0] : null;
                    if (firstTestResult && secondTestResult) {
                      const diff = moment(firstTestResult.created_at).diff(secondTestResult.created_at);
                      if (diff > 0) return 1;
                      else if (diff < 0) return -1;
                      else return 0;
                    }
                    return 0;
                  });
                  // console.log("Test Results:: afterSort:", response2.data);
                }
                tests[index].exists = true;
                tests[index].testResultsId = response2.data[response2.data.length - 1].id;
                tests[index].isEdit = false;
                tests[index].customer_priority=response2.data[response2.data.length - 1].customer_priority;
                if (tests[index].type === "B") {
                  tests[index].results = response2.data[response2.data.length - 1].testresults && 
                    response2.data[response2.data.length - 1].testresults.length > 0 
                    ? response2.data[response2.data.length - 1].testresults 
                    : tests[index].results;
                  tests[index].recommendations = response2.data[0].recommendations;
                  tests[index].resultDate = response2.data[response2.data.length - 1].result_date;

                  let markers = tests[index].markers;
                  if (tests[index].results.length > 0 && tests[index].results[0].id) {
                    tests[index].isEdit = true;
                    tests[index]['result'] = tests[index].results ? tests[index].results[0].result : '';
                    let loadCount = 0,
                      newMarkers = [];
                    recommendedTests = tests[index].recommendations.map((test) => {
                      return test.test.id;
                    });

                    for (let i in tests[index].results) {
                      //load original biommarker list (even if it was changed later)
                      API.get("biomarkers/" + tests[index].results[i].biomarker + "/")
                        .then((response) => {
                          loadCount++;

                          newMarkers[i] = {
                            id: response.data.id,
                            name: response.data.is_active
                              ? response.data.name
                              : response.data.name + " (Archived)",
                            unit: response.data.unit,
                          };

                          if (loadCount === tests[index].results.length) {
                            this.setState({
                              markers: newMarkers,
                              index: index,
                            });

                            this.props.toggleLoading({
                              isLoading: false,
                            });
                          }
                        })
                        .catch((error) => {
                          
                          this.props.showNotificationMessage({
                            notificationMessage:
                              "Some error has occured. Please try again.",
                            successMessage: false,
                            showNotification: true,
                          });

                          this.props.toggleLoading({
                            isLoading: false,
                          });
                        });

                      results[index] = (tests[index].results[i].result);
                      resultIds[index] = (tests[index].results[i].id);
                      comments[index] = (tests[index].results[i].doctor_comment);
                      comments_ar[index] = (tests[index].results[i].doctor_comment_ar);
                      this.setState({
                        results: results,
                        resultIds: resultIds,
                        comments: comments,
                        comments_ar: comments_ar,
                        recommendedTests: recommendedTests,
                        resultDate:
                          tests[index].resultDate === undefined
                            ? moment(new Date()).format("YYYY-MM-DD")
                            : tests[index].resultDate,
                        testName: tests[index].name,
                      
                        testId: tests[index].id,
                        testType: tests[index].type,
                      });
                    }
                  } else {
                    //for the setting the select placeholder
                    results = Array(markers && markers.length ? markers.length : []);

                    comments = Array(markers.length).fill("");
                    comments_ar = Array(markers.length).fill("");

                    this.setState({
                      markers: markers,
                      results: results,
                      resultIds: resultIds,
                      comments: comments,
                      comments_ar: comments_ar,
                      recommendedTests: recommendedTests,
                      resultDate:
                        tests[index].resultDate === undefined
                          ? moment(new Date()).format("YYYY-MM-DD")
                          : tests[index].resultDate,
                      testName: tests[index].name,
                      testId: tests[index].id,
                      testType: tests[index].type,
                      index: index,
                    });

                    this.props.toggleLoading({
                      isLoading: false,
                    });
                  }
                  this.result_messages = Array(markers && markers.length ? markers.length : []);
                } else if (tests[index].type === "F") {
                  tests[index].results = response2.data[0].detailed_result;
                  tests[index].resultDate = response2.data[response2.data.length - 1].result_date;
                  let markers = tests[index].markers;
                  if (tests[index].results.length > 0 && tests[index].results[0].id) {
                    tests[index].isEdit = true;
                    tests[index]['result'] = tests[index].results ? tests[index].results[0].result : '';
                    let loadCount = 0,
                      newMarkers = [];
                    for (let i in tests[index].results) {
                      API.get("food-intolerance/" + tests[index].results[i].food + "/")
                        .then((response) => {
                          loadCount++;

                          newMarkers[i] = {
                            id: response.data.id,
                            name: response.data.is_active
                              ? response.data.food_name
                              : response.data.food_name + " (Archived)",
                          };

                          if (loadCount === tests[index].results.length) {
                            this.setState({
                              markers: newMarkers,
                            });

                            this.props.toggleLoading({
                              isLoading: false,
                            });
                          }
                        })
                        .catch((error) => {
                          
                          this.props.showNotificationMessage({
                            notificationMessage:
                              "Some error has occured. Please try again.",
                            successMessage: false,
                            showNotification: true,
                          });

                          this.props.toggleLoading({
                            isLoading: false,
                          });
                        });
                      results[index] = (tests[index].results[i].result.toString());
                      resultIds[index] = (tests[index].results[i].id);
                      comments[index] = (tests[index].results[i].doctor_comment);
                      comments_ar[index] = (tests[index].results[i].doctor_comment_ar);

                      this.setState({
                        results: results,
                        resultIds: resultIds,
                        comments: comments,
                        comments_ar: comments_ar,
                        recommendedTests: recommendedTests,
                        resultDate:
                          tests[index].resultDate === undefined
                            ? moment(new Date()).format("YYYY-MM-DD")
                            : tests[index].resultDate,
                        testName: tests[index].name,
                        testId: tests[index].id,
                        testType: tests[index].type,
                      });
                    }

                    //for the setting the select placeholder
                    for (let i = 0; i < markers.length; i++) {
                      results[i] = results[i] ? results[i] : 0;
                    }

                  } else {
                    //for the setting the select placeholder
                    results = Array(markers.length).fill(0);

                    comments = Array(markers.length).fill("");
                    comments_ar = Array(markers.length).fill("");

                    this.setState({
                      markers: markers,
                      results: results,
                      resultIds: resultIds,
                      comments: comments,
                      comments_ar: comments_ar,
                      recommendedTests: recommendedTests,
                      resultDate:
                        tests[index].resultDate === undefined
                          ? moment(new Date()).format("YYYY-MM-DD")
                          : tests[index].resultDate,
                      testName: tests[index].name,
                      testId: tests[index].id,
                      testType: tests[index].type,
                      index: index,
                    });

                    this.props.toggleLoading({
                      isLoading: false,
                    });
                  }
                  this.result_messages = Array(markers && markers.length ? markers.length : []);
                }
              } else {
                tests[index].exists = false;
              }
              loadCount++;

              if (tests && tests.length && loadCount === tests.length) {
                this.setState({
                  tests: tests,
                  isLoaded: true,
                }, () => this.setCurrentTestResults());

                this.props.toggleLoading({
                  isLoading: false,
                });
              }
            })
            .catch((error) => {
              console.log("Error on fetching data", error);
              this.setState({
                loadError: "Some error has occured. Please try again",
              });

              this.props.showNotificationMessage({
                notificationMessage:
                  "Some error has occured. Please try again.",
                successMessage: false,
                showNotification: true,
              });
              return;
            });
        }
        /**
        const overview_obj = isPastOrder
          ? response.data.doctors_note != null
            ? ContentState.createFromText(response.data.doctors_note)
            : ContentState.createFromText("")
          : response.data.doctors_note != null
            ? stateFromHTML(response.data.doctors_note)
            : stateFromHTML("");
        const overview_ar_obj = isPastOrder
          ? response.data.doctors_note_ar != null
            ? ContentState.createFromText(response.data.doctors_note_ar)
            : ContentState.createFromText("")
          : response.data.doctors_note_ar != null
            ? stateFromHTML(response.data.doctors_note_ar)
            : stateFromHTML("");
        */
        
        const overview_obj = response.data.doctors_note !== null 
              ? stateFromHTML(response.data.doctors_note)
              : stateFromHTML("");
              
        const overview_ar_obj = response.data.doctors_note_ar !== null
              ? stateFromHTML(response.data.doctors_note_ar)
              : stateFromHTML("");
        const username =
          response.data.user.username +
          " (" +
          response.data.user.email +
          ")";
          
        this.setState({
          tests: tests,
          userId: response.data.user.id,
          userName: username,
          overview: JSON.stringify(convertToRaw(overview_obj)),
          msg_en:
            response.data.doctors_note != null
              ? response.data.doctors_note
              // stateToHTML(
              //   ContentState.createFromText(response.data.doctors_note)
              // )
              : stateToHTML(ContentState.createFromText("")),
          overview_ar: JSON.stringify(convertToRaw(overview_ar_obj)),
          msg_ar:
            response.data.doctors_note_ar != null
              ? response.data.doctors_note_ar
              // stateToHTML(
              //   ContentState.createFromText(response.data.doctors_note_ar)
              // )
              : stateToHTML(ContentState.createFromText("")),
          survey: survey,
        });
      })
      .catch((error) => {
        
        this.setState({
          loadError: "Some error has occured. Please try again",
        });


        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true,
        });
      });
  }

  async loadTests() {
    const { orderId, userType, isPastOrder } = this.state;
    
    // API.get("tests/")
    //   .then((response) => {
    //     console.log("TesListFormatted tests/ ApI", response.data )
    //     let testListFormatted = response.data.map((test) => {
          
    //       return {
    //         text: test.name,
    //         value: test.id,
    //         key: test.id,
    //       };
    //     });

    //     this.setState({
    //       testListFormatted: testListFormatted,
    //       testList: response.data
    //     });
    //     // if(!userType==="nutritionist" && !isPastOrder){
    //     //   this.loadOrders();
    //     // }
    //       // this.loadOrders();
    //     this.newloadOrders()

    //   })
    //   .catch((error) => {
    //     console.log("Error on fetching tests", error);
    //     // this.setState({ loadError: 'Some error has occured. Please try again' })
    //   });
    try{
      const {data=[]}=await PhoenixAPI.get(`tests-v2?orderId=${orderId}&isNonBloodTestRequired=yes`)
                                                                                                
         const testList= data&& data.filter((item)=>item.is_non_blood_biomarker==false);
         console.log("testListtestList", testList)

         const nonBloodTestList=data &&data.filter((item)=>item.is_non_blood_biomarker==true)
         console.log("nonBloodTestList nonBloodTestList", nonBloodTestList)
         let testListFormatted = testList.map((test) => {
          
          return {
            text: test.name,
            value: test.id,
            key: test.id,
          };
         })
             this.setState({
          testListFormatted: testListFormatted,
          testList: testList,
          nonBloodTestList:nonBloodTestList
        });
       this.newloadOrders()
    }
    catch(error){
      console.log("Error on fetching tests", error);
    }
  }

  handleChange(event, row) {
    const target = event.target;
    let results = [...this.state.results];
    let comments = [...this.state.comments];
    let comments_ar = [...this.state.comments_ar];
    // changed condition for row && row.index && row.index >= 0 to row  && row.index >= 0
    if (row &&  row.index >= 0) {
      if (target.name === "marker-result") {
        if (Number(target.value) < 0) return;
        let tests = [...this.state.tests];
        let resultdata = [...this.state.tests[row.index].results];
        results[row.index] = target.value;
        row.results[0]['result'] = target.value;
        row.result = target.value;
        resultdata[0].result = target.value;
        row['result'] = target.value;
        row.results = resultdata;
        tests[row.index] = row;
        this.setState({ testData: [...tests], isLoaded: true, results: results,saveFlag:false }, () => {
          this.updateTable();
        });
      } else if (target.name === "marker-comments") {
        comments[row.index] = target.value;
        this.setState({ comments: comments });
      } else if (target.name === "marker-comments-ar") {
        comments_ar[row.index] = target.value;
        this.setState({ comments_ar: comments_ar });
      }
      this.setState({ [target.name]: target.value });
    }
  }

  handleRemoveBiomarkerChange(event,row){
    const target = event.value;

   const{ 
      listBiomarkersRemoved,
      biomarkersNamesRemoved,
    }=this.state;
      if(event.target.checked){
     this.setState((prevState) => {
      // Create a new array by spreading the previous state's array and pushing the new value
      let updatedList;
    
    if (row.is_non_blood_biomarker) {
      console.log("non blood",row.is_non_blood_biomarker)
      updatedList = [...prevState.listNonBloodBiomarkersRemoved, row.non_blood_biomarker_id];
      console.log("updated list",updatedList);
    } else {
      console.log("blood")
      updatedList = [...prevState.listBiomarkersRemoved, row.id];
      console.log("updated list",updatedList);
    }

    const updatedNames = [...prevState.biomarkersNamesRemoved, row.name];
      // Update the state with the new array
      console.log("row id",row.id,row.name,row)
      return {listBiomarkersRemoved: row.is_non_blood_biomarker ? prevState.listBiomarkersRemoved : updatedList,
        listNonBloodBiomarkersRemoved: row.is_non_blood_biomarker ? updatedList : prevState.listNonBloodBiomarkersRemoved,
        biomarkersNamesRemoved: updatedNames};});
    
     }
     else {
      this.setState((prevState) => {
        let filteredList, filteredBiomarkerNames;

        // Check if it is a non-blood biomarker and filter accordingly
        if (row.is_non_blood_biomarker) {
          // Filter non-blood biomarkers
         
          filteredList = prevState.listNonBloodBiomarkersRemoved.filter((biomarker) => row.non_blood_biomarker_id !== biomarker);
        } else {
          // Filter regular biomarkers
          filteredList = prevState.listBiomarkersRemoved.filter((biomarker) => row.id !== biomarker);
        }
    
        // Filter names regardless of the biomarker type
        filteredBiomarkerNames = prevState.biomarkersNamesRemoved.filter((biomarkerName) => row.name !== biomarkerName);
    
        // Update the state with the filtered lists
        return {
          listBiomarkersRemoved: row.is_non_blood_biomarker ? prevState.listBiomarkersRemoved : filteredList,
          listNonBloodBiomarkersRemoved: row.is_non_blood_biomarker ? filteredList : prevState.listNonBloodBiomarkersRemoved,
          biomarkersNamesRemoved: filteredBiomarkerNames,
        };
      });
     
     }
     

  }
  updateTable() {
    let data = [...this.state.testData];
    this.setState({ test: data, isLoaded: true, })
  }

  handleSubmit(event, index) {
  //  event.preventDefault();
    let testResults = [], recommendedTestsFormatted = [];
    const {
      testId,
      testType,
      userId,
      orderId,
      results,
      resultIds,
      tests:bloodTests=[],
      comments,
      comments_ar,
      markers,
      recommendedTests,
      resultDate,
    } = this.state;
  
    const tests=bloodTests && bloodTests.length>0 ? bloodTests.filter((item)=>!item.is_non_blood_biomarker):[];
    this.setState({ submitted: true });
    const isValid = true //this.validateResults();
    if (tests[index].type === "B") {  /// new change calling from tests[index] tests[index].type
      if (tests[index].isEdit) {
        for (let i in tests[index].markers) {
          // console.log("test in for loop",tests[index])
          try {
            testResults.push({
              id: tests[index].results[i].id,
              biomarker: tests[index].markers[i].id,
              result: tests[index].results[i].result,
              // doctor_comment: comments[i],
              // doctor_comment_ar: comments_ar[i],
               doctor_comment: tests[index].results[i].doctor_comment,
              doctor_comment_ar: tests[index].results[i].doctor_comment_ar,
              from_unit: tests[index].fromUnit,
              
            });
          } catch (error) {
            console.log("handleSubmit: error: ", error);
          }
        }
      } else {
        for (let i in tests[index].markers) {
          try {
            testResults.push({
              biomarker: tests[index].markers[i].id,
              result: tests[index].results[i].result,
              // doctor_comment: comments[i],
              // doctor_comment_ar: comments_ar[i],
              doctor_comment: tests[index].results[i].doctor_comment,
              doctor_comment_ar: tests[index].results[i].doctor_comment_ar,
              from_unit: tests[index].fromUnit,
            });
          } catch (error) {
            console.log("handleSubmit: error: ", error);
          }
        }
      }
    } else if (tests[index].type === "F") {
      if (tests[index].isEdit) {
        for (let i in tests[index].markers) {
          try {
            testResults.push({
              id: tests[index].results[i].id,
              food: tests[index].markers[i].id,
              result: tests[index].results[i].result,
              // doctor_comment: comments[i],
              // doctor_comment_ar: comments_ar[i],
              doctor_comment: tests[index].results[i].doctor_comment,
              doctor_comment_ar: tests[index].results[i].doctor_comment_ar,
              from_unit: tests[index].fromUnit,
            });
          } catch (error) {
            console.log("handleSubmit: error: ", error); 
          }
        }
      } else {
        for (let i in tests[index].markers) {
          try {
            testResults.push({
              food: tests[index].markers[i].id,
              result: tests[index].results[i].result,
              // doctor_comment: comments[i],
              // doctor_comment_ar: comments_ar[i],
              doctor_comment: tests[index].results[i].doctor_comment,
              doctor_comment_ar: tests[index].results[i].doctor_comment_ar,
              from_unit:tests[index].fromUnit,
            });
          } catch (error) {
            console.log("handleSubmit: error: ", error); 
          }
        }
      }
    }

    for (let test of recommendedTests) {
      recommendedTestsFormatted.push({
        test: test,
      });
    }

    if (isValid) {
      this.props.toggleLoading({
        isLoading: true,
      });
      if (tests[index].isEdit) {
        if (tests[index].type === "B") { //changed
          API.put("test-results/" + tests[index].testResultsId + "/", {
            result_date: tests[index].resultDate, // changed
            test: tests[index].id,
            user: userId ? userId : localStorage.getItem('userId'),
            order: orderId,
            testresults: testResults,
            recommendations: recommendedTestsFormatted,
          })
            .then((response) => {
              this.props.toggleLoading({
                isLoading: false,
              });
              this.props.showNotificationMessage({
                notificationMessage: "Result successfully updated.",
                successMessage: true,
                showNotification: true,
              });

            })
            .catch((error) => {
              console.log("Error on updating test results", error);
              this.props.toggleLoading({
                isLoading: false,
              });

              this.props.showNotificationMessage({
                notificationMessage:
                  "Some error has occured. Please try again.",
                successMessage: false,
                showNotification: true,
              });
            });
        } else if (tests[index].type === "F") {
          API.put("food-intolerance-result/" + tests[index].testResultsId + "/", {
            result_date: tests[index].resultDate, //changed
            test: tests[index].id,
            user: userId ? userId : localStorage.getItem('userId'),
            order: orderId,
            detailed_result: testResults,
          })
            .then((response) => {
              this.props.toggleLoading({
                isLoading: false,
              });
              this.props.showNotificationMessage({
                notificationMessage: "Result successfully updated.",
                successMessage: true,
                showNotification: true,
              });
            })
            .catch((error) => {
              console.log("Error on updating result", error);
              this.props.toggleLoading({
                isLoading: false,
              });


              this.props.showNotificationMessage({
                notificationMessage:
                  "Some error has occured. Please try again.",
                successMessage: false,
                showNotification: true,
              });
            });
        } else {
          
          this.props.toggleLoading({
            isLoading: false,
          });


          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        }
      } else {
        if (tests[index].type === "B") {
          API.post("test-results/", {
            result_date: tests[index].resultDate, // chnaged
            test: tests[index].id,
            user: userId ? userId : localStorage.getItem('userId'),
            order: orderId,
            testresults: testResults,
            recommendations: recommendedTestsFormatted,
          })
            .then((response) => {
              this.props.toggleLoading({
                isLoading: false,
              });
              this.props.showNotificationMessage({
                notificationMessage: "Result successfully updated.",
                successMessage: true,
                showNotification: true,
              });
            })
            .catch((error) => {
              console.log("Error on saving result", error);
              
              this.props.toggleLoading({
                isLoading: false,
              });

              this.props.showNotificationMessage({
                notificationMessage:
                  "Some error has occured. Please try again.",
                successMessage: false,
                showNotification: true,
              });
            });
        } else if (tests[index].type === "F") {
          API.post("food-intolerance-result/", {
            result_date: tests[index].resultDate, //changed
            test: tests[index].id,
            user: userId ? userId : localStorage.getItem('userId'),
            order: orderId,
            detailed_result: testResults,
          })
            .then((response) => {
              this.props.toggleLoading({
                isLoading: false,
              });

              this.props.showNotificationMessage({
                notificationMessage: "Result successfully updated.",
                successMessage: true,
                showNotification: true,
              });
            })
            .catch((error) => {
              console.log("Error on updating result", error);
              
              this.props.toggleLoading({
                isLoading: false,
              });
              this.props.showNotificationMessage({
                notificationMessage:
                  "Some error has occured. Please try again.",
                successMessage: false,
                showNotification: true,
              });
            });
        } else {       
          this.props.toggleLoading({
            isLoading: false,
          });

          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        }
      }
    }
  }

  submitFinalResults(event, markCompleted = false) {
    event.preventDefault();
    const { orderId, msg_ar, msg_en, coachSuggestions } = this.state;
    let isValid = this.validateFinalResults();
    this.setState({ finalSubmit: true });

    if (isValid) {
      const payload = {
        doctors_note: msg_en,
        doctors_note_ar: msg_ar,
        is_result_uploaded: true,
      };
      if (coachSuggestions) {
        payload.is_coach_consult_done =
          coachSuggestions.is_coach_consult_done ?? false;
        if (coachSuggestions.is_coach_consult_done) {
          payload.coach_consult_date = coachSuggestions.coach_consult_date;
        }
        payload.is_supplement_required =
          coachSuggestions.is_supplement_required ?? false;
        payload.coach_supplement_suggestion =
          coachSuggestions.coach_supplement_suggestion
            ? coachSuggestions.coach_supplement_suggestion.map((it) => ({
                supplement_id: it,
              }))
            : [];
            payload.is_custom_package_required =
            coachSuggestions.is_custom_package_required ?? false;
          payload.coach_custom_package_suggestions =
            coachSuggestions.coach_custom_package_suggestions
              ? coachSuggestions.coach_custom_package_suggestions.map((it) => ({
                  custom_package_id: it,
                }))
              : [];
  
              payload.is_blood_package_required =
              coachSuggestions.is_blood_package_required ?? false;
            payload.coach_blood_package_suggestions =
              coachSuggestions.coach_blood_package_suggestions
                ? coachSuggestions.coach_blood_package_suggestions.map((it) => ({
                    package_id: it,
                  }))
                : [];
      }
      if (markCompleted) payload.order_status = "COMPLETED";

      API.patch("order-nutritionist/" + orderId + "/", payload)
        .then((response) => {
          if (markCompleted) {
            // PhoenixAPI.post(`${BASE_URL_CHECK_AND_COMPLETE_PARENT_ORDER}${orderId}/`);
          }
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "Test results successfully submitted.",
            successMessage: true,
            showNotification: true,
          });
          this.setState({ finalSubmit: false });
          this.resetFinalValidator();
          if (markCompleted) {
            this.props.history.goBack();
            return;
          }
          
          this.loadData();
        })
        .catch((error) => {
          console.log("Error on submitting test results", error);
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        });
    }
  }

  handleSavePastOrder = (event) => {
    event.preventDefault();
    const { orderId, coachSuggestions, msg_en, msg_ar, order , mappedSuggestions,miniPackages, followUpPackage} = this.state;


    const {orderFollowUpDto={}} = order || {};
    let isValid = this.validatePastOrder();
    this.setState({ finalSubmit: true });
    const userId=getUserId()
    const today = moment().format("YYYY-MM-DD");
    const newMappedSupplements=mappedSuggestions.map((suggestion)=>{

      const obj={
        "supplement_id":suggestion.supplementId,
        "updated_date": today,
         "doses_eng":suggestion.dosesEng,
         "doses_ar":suggestion.dosesAr,
         "first_suggestion_date":suggestion.firstSuggestionDate ?? today,
          "period_id":suggestion.periodId,
          // "order_id":parseInt(orderId),
          // "user_id":parseInt(userId),
          // "id":suggestion.id ?? null
      }
      return obj
    })

    
    if (isValid) {
      const payload = {
        doctors_note: msg_en,
        doctors_note_ar: msg_ar,
        follow_up_date: order?.follow_up_date ?? null,
        follow_up_note: order?.follow_up_note ?? null,
        client_didnt_showed_up: order?.client_didnt_showed_up ?? false,
        modified_by:userId,
      };
      if (coachSuggestions) {
        payload.is_coach_consult_done =
          coachSuggestions.is_coach_consult_done ?? false;
        if (coachSuggestions.is_coach_consult_done) {
          payload.coach_consult_date = coachSuggestions.coach_consult_date;
        }
        // payload.is_supplement_required =
        //   coachSuggestions.is_supplement_required ?? false;
        // payload.coach_supplement_suggestion =
        //   coachSuggestions.coach_supplement_suggestion
        //     ? coachSuggestions.coach_supplement_suggestion.map((it) => ({
        //         supplement_id: it,
        //       }))
        //     : [];
        payload.is_supplement_required =
        newMappedSupplements.length>0? true:false;
        
         payload.coach_supplement_suggestion = newMappedSupplements.length>0?newMappedSupplements:[];
            payload.is_custom_package_required =
            coachSuggestions.is_custom_package_required ?? false;
          payload.coach_custom_package_suggestions =
            coachSuggestions.coach_custom_package_suggestions
              ? coachSuggestions.coach_custom_package_suggestions.map((it) => ({
                  custom_package_id: it,
                }))
              : [];
  
              payload.is_blood_package_required =
              coachSuggestions.is_blood_package_required ?? false;
            payload.coach_blood_package_suggestions =
              coachSuggestions.coach_blood_package_suggestions
                ? coachSuggestions.coach_blood_package_suggestions.map((it) => ({
                    package_id: it,
                  }))
                : [];
      }

      if(orderFollowUpDto !==null || undefined){
        const {miniPackages:selectedMiniPackages=[]}= orderFollowUpDto || {};
        let selectedMiniPackageName
        let finalMiniPackage
        if(selectedMiniPackages && selectedMiniPackages.length>0){
          selectedMiniPackageName= miniPackages.length>0 &&miniPackages.filter((filterMini)=>selectedMiniPackages.length>0&&selectedMiniPackages.includes(filterMini.id)).map((mini)=>mini.name);
           finalMiniPackage=miniPackages.length>0 &&miniPackages.filter((selectpackage)=>selectedMiniPackages.length>0&&selectedMiniPackageName.includes(selectpackage.name)).map((mini)=>mini.id);
        }
   

       
        if (followUpPackage.isBloodPackage) {
          let follow_up = {
            "follow_up": orderFollowUpDto.followUp,
            "is_blood_package": followUpPackage.isBloodPackage ?? false,
            "is_mini_package": followUpPackage.isMiniPackage ?? false,
            "order_follow_up_mini_package": followUpPackage.isMiniPackage ? (finalMiniPackage.length > 0 ? finalMiniPackage : []) : [],
            "blood_package_id": followUpPackage.isBloodPackage ? orderFollowUpDto.bloodPackageId : null,
          }
          payload.order_follow_up = follow_up
        }
        else if (followUpPackage.isMiniPackage) {
          let follow_up = {
            "follow_up": orderFollowUpDto.followUp,
            "is_blood_package": followUpPackage.isBloodPackage ?? false,
            "is_mini_package": followUpPackage.isMiniPackage ?? false,
            "order_follow_up_mini_package": followUpPackage.isMiniPackage ? (finalMiniPackage.length > 0 ? finalMiniPackage : []) : [],
            "blood_package_id": followUpPackage.isBloodPackage ? orderFollowUpDto.bloodPackageId : null,
          }
          payload.order_follow_up = follow_up
        }

        else {
          payload.order_follow_up = null
        }
      }
    
      
      API.patch("order-nutritionist-past/" + orderId + "/", payload)
        .then((response) => {
          this.props.showNotificationMessage({
            notificationMessage: "Saved successfully.",
            successMessage: true,
            showNotification: true,
          });
          this.setState({ finalSubmit: false });
          this.resetFinalValidator();
          this.loadData();
        })
        .catch((error) => {
          console.log("Error on submitting test results", error);
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        })
        .finally(() => this.props.toggleLoading({ isLoading: false }));
    }
    else{
      this.showErrorNotification("There are some invalid form fields!")
      return
    }
  }

  validateResults() {
    let isValid = true;
    let markers = [...this.state.markers];
    let resultDate = this.state.resultDate;
    for (let i = 0; i < markers.length; i++) {
      if (isEmpty(this.state.tests[i].results[i])) {
        this.result_messages[i] = "Result is required";
        isValid = false;
      } else this.result_messages[i] = null;
    }

    if (isEmpty(resultDate)) {
      this.dateMessage = "Date is required.";
      isValid = false;
    } else this.dateMessage = null;

    return isValid;
  }

  validateFinalResults() {
    let isValid = true;
    const { overview, overview_ar, tests, msg_en, msg_ar } = this.state;
    const {
      is_coach_consult_done,
      coach_consult_date,
      is_supplement_required,
      coach_supplement_suggestion,
    } = this.state.coachSuggestions ?? {};

    this.testMessage = null;

    for (let test of tests) {
      if (!test.exists) {
        isValid = false;
        this.testMessage = "Test results are required.";
      }
    }

    if (msg_en === "<p><br></p>") {
      this.overviewMessage = "Overall result is required";
      isValid = false;
    } else {
      this.overviewMessage = null;
    }

    if (msg_ar === "<p><br></p>") {
      this.overviewArMessage = "Overall result is required";
      isValid = false;
    } else {
      this.overviewArMessage = null;
    }

    if (is_coach_consult_done && !coach_consult_date) {
      this.coachConsultDateErrorMessage = "Consultation date is a required field!";
      isValid = false;
    } else if (is_coach_consult_done && moment().isBefore(coach_consult_date)) {
      this.coachConsultDateErrorMessage = "Consultation date cannot be a future date!";
      isValid = false;
    } else {
      this.coachConsultDateErrorMessage = null
    };

    if (
      is_supplement_required &&
      (!coach_supplement_suggestion || !coach_supplement_suggestion.length)
    ) {
      this.coachSupplementSuggestionErrorMessage = "Atleast 1 supplement is required!";
      isValid = false;
    } else {
      this.coachSupplementSuggestionErrorMessage = null;
    }

    return isValid;
  }

  validatePastOrder = () => {
    let isValid = true;
    const {
      is_coach_consult_done,
      coach_consult_date,
      is_supplement_required,
      coach_supplement_suggestion,
    } = this.state.coachSuggestions ?? {};

    const { followUpPackage, order } = this.state || {};
    const { orderFollowUpDto = {} } = order || {};
    const { isBloodPackage, isMiniPackage } = followUpPackage || {}
    const { bloodPackageId, miniPackages, followUp } = orderFollowUpDto || {}

    if (is_coach_consult_done && !coach_consult_date) {
      this.coachConsultDateErrorMessage = "Consultation date is a required field!";
      isValid = false;
    } else if (is_coach_consult_done && moment().isBefore(coach_consult_date)) {
      this.coachConsultDateErrorMessage = "Consultation date cannot be a future date!";
      isValid = false;
    } else {
      this.coachConsultDateErrorMessage = null
    };

    // if (
    //   is_supplement_required &&
    //   (!coach_supplement_suggestion || !coach_supplement_suggestion.length)
    // ) {
    //   this.coachSupplementSuggestionErrorMessage = "Atleast 1 supplement is required!";
    //   isValid = false;
    // } else {
    //   this.coachSupplementSuggestionErrorMessage = null;
    // }
    if(followUp){
      this.followUpErrorMessage=null
    }
    if (isBloodPackage && !bloodPackageId) {
      this.followUpBloodPackageErrorMessage = "Please Select MiniPackage";
      isValid = false;
    }
    else if (isBloodPackage && bloodPackageId) {
      if (!followUp || followUp.trim() === "") {
        this.followUpErrorMessage = "Please Select Followup Date";
        isValid = false;
      }
    }


    if (isMiniPackage && !(miniPackages && miniPackages.length > 0)) {
      this.followUpMiniPackageErrorMessage = "Please Select MiniPackage";
      isValid = false;
    }

    else if (isMiniPackage && (miniPackages && miniPackages.length > 0)) {
      if (!followUp || followUp.trim() === "") {
        this.followUpErrorMessage = "Please Select Followup Date";
        isValid = false;
      }
    }

    return isValid;
  }

  resetValidator() {
    this.result_messages = [];
    this.dateMessage = "";
    this.setState({ submitted: false });
  }

  resetFinalValidator() {
    this.testMessage = "";
    this.overviewMessage = "";
    this.overviewArMessage = "";
    this.coachSupplementSuggestionErrorMessage = "";
    this.coachSupplementSuggestionErrorMessage = "";
    this.followUpErrorMessage="";
    this.followUpBloodPackageErrorMessage="";
    this.followUpMiniPackageErrorMessage="";
    this.setState({ finalSubmit: false });
  }

  uploadResult(event) {
    if (event.target.files.length !== 0) {
      if (event.target.files[0].type == "application/pdf") {
        this.setState({
          resultFile: event.target.files[0],
          resultFileValidation: true,
          resultFileError: "",
        });
        this.resultSubmitted = true;
      } else {
        this.setState({
          resultFileValidation: false,
          resultFileError: "Result should be a pdf file.",
        });

        this.resultSubmitted = false;
      }
    } else {
      this.resultSubmitted = false;
    }
  }

  uploadResultFileSubmit(e) {
    e.preventDefault();
    if (this.resultSubmitted && this.state.resultFileValidation) {
      this.props.toggleLoading({
        isLoading: true,
      });
      const formData = new FormData();
      formData.append("lab_result", this.state.resultFile);
      formData.append("order", this.state.orderId);
      formData.append("lab_result_s3", this.state.resultFile);
      
      API.post("lab-result/", formData)
        .then((response) => {
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "Result successfully updated.",
            successMessage: true,
            showNotification: true,
          });
          this.setState({
            lab_result_file: response.data[0].lab_result_s3_url,
            rusult_file_id: response.data[0].id,
          });
        })
        .catch((error) => {
          console.log("Error on updating result", error);
          this.props.toggleLoading({
            isLoading: false,
          });
          this.props.showNotificationMessage({
            notificationMessage: "Some error has occured. Please try again.",
            successMessage: false,
            showNotification: true,
          });
        });
    }
  }

  handleDeleteFile(e) {
    e.preventDefault();
    const url = `/payment/delete/lab-result/${this.state.rusult_file_id}`; 
    // API.delete("lab-result/" + this.state.rusult_file_id + "/")
    PhoenixAPI.delete(url)
      .then((response) => {
        this.setState({
          lab_result_file: "",
          result_file_id: "",
        });
      })
      .catch((error) => {
        console.log("Error on deleting lab result", error);
      });
  }

  getLabResults() {
    const orderId = this.state.orderId;
    API.get("lab-result/?order=" + orderId)
      .then((response1) => {
        if (response1.data.length) {
          response1.data.map((val, index) => {
            if (val.order == orderId) {
              this.setState({
                lab_result_file: response1.data[index].lab_result_s3_url,
                rusult_file_id: response1.data[index].id,
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log("Error on fetching lab results", error);
      });
  }

  saveOverviewEng(data) {
    this.setState({
      overview: JSON.stringify(convertToRaw(this.event.getCurrentContent())),
      msg_en: stateToHTML(this.event.getCurrentContent()),
    });
  }
  saveOverviewAr(data) {
    this.setState({
      overview_ar: JSON.stringify(
        convertToRaw(this.event_ar.getCurrentContent())
      ),
      msg_ar: stateToHTML(this.event_ar.getCurrentContent()),
    });
  }


  requestSort = (key) => {
    let direction = 'ascending';
    if (
      this.state.sortConfig &&
      this.state.sortConfig.key === key &&
      this.state.sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }

    let data = {
      'key': key,
      'direction': direction
    }

    this.setState({ sortConfig: data })
    // setSortConfig({ key, direction });
  };

  getClassNamesFor = (name) => {
    if (!this.state.sortConfig) {
      return;
    }
    return this.state.sortConfig.key === name ? this.state.sortConfig.direction : undefined;
  };

  findTestCategoryName = (testCategoryId) => {
    const { testCategortList: testCategories = [] } = this.state;
    return testCategories.find(it => it.key === testCategoryId)?.text;
  }
  
  sortedItems = (items) => {
    const { parsedLabTestResults, isSelfResultUpload} = this.state;
   
    if (parsedLabTestResults && parsedLabTestResults.length) {
      items.forEach(item => {
      
        const labTestResult = parsedLabTestResults.find(it => it.valeoTestName === item.name);
        item.position = labTestResult?.position ?? null;
      })
    }
    
    let sortableItems = [...items];
    
    if (this.state.sortConfig !== null && !isSelfResultUpload) {
      sortableItems.sort((a, b) => {
        if (this.state.sortConfig.key === "test_category") {
          const c1Id = a && a.test_category && a.test_category.length ? a.test_category[0] : null;
          const c2Id = b && b.test_category && b.test_category.length ? b.test_category[0] : null;
          const c1 = this.findTestCategoryName(c1Id);
          const c2 = this.findTestCategoryName(c2Id);
          if (c1 < c2) {
            return this.state.sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (c1 > c2) {
            return this.state.sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        }
        if (a[this.state.sortConfig.key] < b[this.state.sortConfig.key]) {
          return this.state.sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[this.state.sortConfig.key] > b[this.state.sortConfig.key]) {
          return this.state.sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    else if(isSelfResultUpload){
      const result= sortableItems.sort((firstItem, secondItem) => {
        return firstItem.orderItemId-secondItem.orderItemId
        
        ;
      });
   
      
    }
    //  console.log("sort in return ",sortableItems);
    return sortableItems;
  }

  fetchLabReportResults = async () => {
    const { orderId, userType } = this.state;
    if (userType !== "lab") return;

    if (!orderId) {
      console.log(`Invalid orderId ${orderId}! Can't fetch lab test results from phoenix`);
      return;
    }

    const url = `${BASE_URL_LAB_REPORTS}?orderId=${this.state.orderId}`;
    try {
      const { data: parsedLabTestResults = [] } = await PhoenixAPI.get(url);
      // console.log("fetchLabReportResults::", { parsedLabTestResults });
      this.setState({ parsedLabTestResults });
    } catch (error) {
      console.log("Error on fetching lab report results from ", url, error);
    }
   
    
  }
 
 getPastResult=(item)=>{

  const testPrevResult=this.state.pastBiomarkerResults.filter((prevBiomarker)=>prevBiomarker.biomarker_id==item.results[0].biomarker)
   if(testPrevResult.length==1){
    return testPrevResult[0].result;
   }
   else
  return "N/A"
 }

  handleLabReportPDFSelect = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      this.resultSubmitted = false;
      return;
    }

    const file = files[0];
    if (file.type === "application/pdf") {
      this.setState({
        labReportFile: file,
        labReportFileSubmitted: true,
        labReportFileValidation: true,
        labReportFileError: "",
      });
    } else {
      this.setState({
        labReportFileSubmitted: false,
        labReportFileValidation: false,
        labReportFileError: "Lab Report should be a pdf file.",
      });
    }
  }

  handleLabReportPDFUpload = async (e) => {
    const { 
      orderId,
      userId,
      userNameAdminPanel,
      labName,
      labReportFile, 
      labReportFileSubmitted, 
      labReportFileValidation, isPackageContainsNonBloodBiomarkers
    } = this.state;

    e.preventDefault();

    if (!labName) {
      this.setState({ labNameError: "Select Lab" });
      return;
    }

    if (labReportFile && labReportFileSubmitted && labReportFileValidation) {
      this.showLoading();
       let url="";
       if(labName==="alborg fox explorer" || labName==="unilabs fox explorer"){
        url=`${BASE_URL_FOOD_INTOLERANCE}/upload`
       }
       else{
        url = `${BASE_URL_LAB_REPORTS}/upload?is_non_blood_biomarkers_included=${isPackageContainsNonBloodBiomarkers}`;
       }
       
      const formData = new FormData();
      formData.append("orderId", orderId);
      formData.append("userId", userId);
      formData.append("uploadedBy", userNameAdminPanel);
      formData.append("labName", labName);
      formData.append("labReport", labReportFile);
      try {
        const response = await PhoenixAPI({
          method: "post",
          url: url,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        const { data: parsedLabTestResults = [] } = response;
        // console.log("parsedLabTestResults", parsedLabTestResults)
        if(labName==="alborg fox explorer" || labName==="unilabs fox explorer"){
          this.setState({parsedLabFoodIntoleranceResults:parsedLabTestResults, postApiCall:true},()=>this.loadFoodResults())
        }
        else{
          this.setState({ parsedLabTestResults }, () => this.loadResults());
        }
     
        this.showSuccessNotification("Lab Report Uploaded Successfully");
      } catch (error) {
        console.log("Error on uploading lab report to url ", url, error);
        this.showErrorNotification("Some error has occured. Please try again.");
      } finally {
        this.hideLoading();
      }
    }
  }

  mergeUnits = (h, j) => {
    const hMap = h.reduce((acc, curr) => {
      const key = Object.keys(curr)[0];
      acc[key] = new Set(curr[key]);
      return acc;
    }, {});

    j.forEach(item => {
      const { key, value } = item;
      if (hMap[key]) {
        hMap[key].add(value);
      } else {
        hMap[key] = new Set([value]);
      }
    });

    return Object.keys(hMap).map(key => ({
      [key]: Array.from(hMap[key])
    }));
  };
  handleFromUnitsForLab=()=>{
    const {labName="", labSpecificUnits=[], biomarkerUnitOptions=[], modifiedLabUnitsArr=[]}= this.state;
   
    let tests = [...this.state.tests];
    const filteredLabSpecificUnits=labSpecificUnits.filter((item)=>item.lab_name==labName)
    let unitModified=[];
    tests.forEach((item)=>{
      const findLabUnit= filteredLabSpecificUnits&&filteredLabSpecificUnits.find(it=>it.valeo_test_name===item.name);
      let labUnit=findLabUnit?.lab_test_unit??"";
    
      if(findLabUnit){
       item["fromUnit"]=labUnit;
       tests[item.index]=item;
       unitModified.push({text:findLabUnit.lab_test_unit,value:findLabUnit.lab_test_unit,key:findLabUnit.valeo_test_name, conversionFactor:1})
      //  biomarkerUnitOptions.push({text:findLabUnit.lab_test_unit,value:findLabUnit.lab_test_unit,key:findLabUnit.valeo_test_name, conversionFactor:1});
       console.log("findLabUnit findLabUnit", findLabUnit)
      }
      else{
        item["fromUnit"]="";
        tests[item.index]=item;
      }
    })
    const output = this.mergeUnits(modifiedLabUnitsArr, unitModified)
    console.log("Outpuedasds", output)
    const options = output.reduce((acc, curr) => {
      const key = Object.keys(curr)[0];
      curr[key].forEach(unit => {
        const option = { key: key, value: unit, text: unit };
        if (!acc.some(item => item.key === key && item.value === unit)) {
          if (!acc.some(item => item.key === key && item.text)) {
            option.text = unit;
          }
          acc.push(option);
        }
      });
      return acc;
    }, []);
    options.forEach((item) => {
      const unitValue = biomarkerUnitOptions.find((it => it.value === item.value))
      if (unitValue) {
        item["conversionFactor"] = unitValue.conversionFactor
      }
      else {
        item["conversionFactor"] = 1;
      }
    })
    console.log("finalBIomarkerOptions", options)
    this.setState({testData:[...tests], tests, biomarkerUnitOptions:options})
    console.log("Lab Name: " + labName, filteredLabSpecificUnits);
  }
  renderLabReportUploadForm = () => {
    const allowedEmails = [
      "kirti+lab@feelvaleo.com", 
      "chinnalab@feelvaleo.com",
      "zoha+lab@feelvaleo.com",
      "giri+lab@feelvaleo.com",
      "geoza+lab@feelvaleo.com",
      "dummylab@feelvaleo.com",
      "ravi+lab@feelvaleo.com",
      "pavithra+lab@feelvaleo.com",
      "saimaneesh+lab@feelvaleo.com",
      "vignesh+lab@feelvaleo.com",
      "harsha+lab@feelvaleo.com",
      "vidya+lab@feelvaleo.com",
      "vini+lab@feelvaleo.com",
      "eliyaz+lab@feelvaleo.com",
      "deepa+lab@feelvaleo.com",
      "palanisamy+lab@feelvaleo.com",
      "vignesh.aridass+lab@feelvaleo.com",
      "abhishek.tiwari@feelvaleo.com",
      "abhishek+lab@feelvaleo.com",
      "anamur+lab@feelvaleo.com",
      "chinmayi+lab@feelvaleo.com",
      "santosh+lab@feelvaleo.com",
      "sumita+lab@feelvaleo.com",
      "abhishek.tiwari+lab@feelvaleo.com",
      "nishant+lab@feelvaleo.com",
      "rishu+lab@feelvaleo.com",
      "rajnarayana.dasari+lab@feelvaleo.com",
      "sureshkumar+lab@feelvaleo.com",
      "chan+lab@feelvaleo.com",
      "manisha+lab@feelvaleo.com",
      "teja+lab@feelvaleo.com",
      "shivakumar+lab@feelvaleo.com",
      "syed+lab@feelvaleo.com",
      "aniket+lab@feelvaleo.com",
      "aparna+lab@feelvaleo.com",
      "anamur+lab@feelvaleo.com"
    ];
    const currentUserEmail = getUserEmail();
    if (!allowedEmails.includes(currentUserEmail))
      return "";
   
    const { 
      labName,
      labReportFileSubmitted, 
      labReportFileValidation, 
      labReportFileError,
      labNameError,
    } = this.state;
    return (
      <form>
        <div className="container">
          <div className="row">
          <div className="form-group col">
            <label htmlFor="lab_name">Lab</label>
              <Dropdown
                value={labName}
                onChange={(event, value) => this.setState({ labName: value.value, labNameError: "" },()=>this.handleFromUnitsForLab()) }
                id="lab_name"
                name="lab_name"
                placeholder="Select Lab"
                search
                selection
                options={LAB_OPTIONS}
                disabled={!this.state.isEditable}
                className={"form-control" + (labNameError ? " has-error" : "")}
              />
            <span className="help-block">{labNameError ?? ""}</span>
          </div>
            <div className="form-group col">
              <label htmlFor="lab_report_file" style={{ 'paddingLeft': '5px' }}>
                Lab Report PDF
              </label>
              <div>
                <input
                  type="file"
                  id="lab_report_file"
                  name="lab_report_file"
                  accept="text/pdf"
                  className={"form-control" + (labReportFileValidation === false ? " has-error" : "")}
                  onChange={this.handleLabReportPDFSelect}
                  disabled={!this.state.isEditable}
                />
                <span className="help-block">{labReportFileError}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-2">
              <button
                className="btn button button-green result-submit "
                style={{ 'padding': '.375rem .75rem' }}
                type="submit"
                disabled={!labReportFileSubmitted || !this.state.isEditable}
                onClick={this.handleLabReportPDFUpload}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }

  fetchLabReportFiles = async () => {
    const { orderId } = this.state;
    if (!orderId) {
      console.log("fetchLabReportFiles(): Invalid orderId", orderId);
      return;
    }
    try {
      const { data: labReportFiles = [] } =  await PhoenixAPI.get("/lab-report-files", { params: { orderId: this.state.orderId } })
      // console.log("labReportFiles: ", labReportFiles);
      const labReportFile = labReportFiles.find(it => it.isDeleted === false);
      // console.log("labReportFile: ", labReportFile);
      return labReportFile ?? {};
    } catch (error) {
      console.log("Error on fetching lab report files", error);
    }
  }

  setCurrentTestResults = () => {
    const { tests = [] } = this.state;
    const currentResultsByTest = {};
    tests.forEach(test => currentResultsByTest[test.id] = test.result);
    this.setState({ currentResultsByTest })
    // console.log("currentResultsByTest: ", currentResultsByTest);
  }

  uploadOCRAuditTrail = async () => {
    const labReportFile = await this.fetchLabReportFiles();
    const { orderId, tests = [], currentResultsByTest = {}, userEmailAdminPanel } = this.state;
    const newResultsByTest = {};
    const bloodTests=tests && tests.length>0 ? tests.filter((item)=>!item.is_non_blood_biomarker):[];
    bloodTests.forEach(test => newResultsByTest[test.id] = test.result);
    // console.log("newResultsByTest: ", newResultsByTest);
    const updatedResultsByTest = Object.keys(newResultsByTest)
      .filter(it => newResultsByTest[it] !== currentResultsByTest[it])
      .map(it => ({ 
        orderId: orderId && Number(orderId),
        biomarkerId: it && Number(it), 
        previousValue: Number(currentResultsByTest[it]) ?? null, 
        newValue: Number(newResultsByTest[it]) ?? null,

        labName: labReportFile.labName ?? null,
        reportFileName: labReportFile.fileName ?? null,
        modifiedBy: userEmailAdminPanel
      }));
    // console.log("updatedResultsByTest: ", updatedResultsByTest);
    if (updatedResultsByTest && updatedResultsByTest.length) {
      try {
        const response = await PhoenixAPI.post("/ocr-audit-trails", updatedResultsByTest);
        // console.log("OCR audit trials saved successfully!", response);
        this.showSuccessNotification("OCR Audit Trails Saved Successfully!");
      } catch (error) {
        console.log("Error on saving ocr audit trails", error);
        this.showErrorNotification("Error on saving OCR Audit Trails!");
      }
    } else {
      console.log("Results are not changed, so not saving OCR Audit Trails");
    }
  }

  loadResults = () => {
    // console.log("Loading Results!");
    const { parsedLabTestResults = [] } = this.state;

    let results = [...this.state.results];
    let tests = [...this.state.tests];
    let fromUnitsData=[...this.state.testsFromUnits];
    const sortableItems = this.sortedItems(tests) || [];
    const foundTests = [];
    const notFoundTests = [];
    sortableItems.forEach(item => {
      const parsedResult = parsedLabTestResults.find(it => it.valeoTestName === item.name);
      if (parsedResult) {
        // console.log("Item is",item,this.state.tests,this.state.tests[item.index].results);
        foundTests.push(item.name);
        // const value = Number(parsedResult.valeoResult?.toFixed(2)); // commented to handle non blood biomarkers
        // const value = Number(parsedResult.valeoResult) //commented to handle non blood
        const value = parsedResult?.valeoResult??""
        const labUnit=parsedResult.unit;
        let resultdata = [...this.state.tests[item.index].results??[]];
     //   let unitdata=[...this.state.tests[item.index].unit];
     let unitdata=[];
        results[item.index] = value;
        fromUnitsData[item.index]=labUnit;
        item.results[0]['result'] = value;
      
        item.result = value;
      
        resultdata[0].result = value;
        item.fromUnit=labUnit;
        item['result'] = value;
        item['fromUnit']=labUnit;
        item['pastResult']=value;
        item.results = resultdata;
      
        tests[item.index] = item;
      } else {
        notFoundTests.push(item.name);
      }
    });
    this.setState(
      { testData: [...tests], isLoaded: true, results: results,testsFromUnits:fromUnitsData },
      () => this.updateTable()
    );
    // console.log(`Tests for which parsed results found: ${foundTests.length} / ${sortableItems.length}`, foundTests.join(", "));
    // console.log(`Tests for which parsed results not found: ${notFoundTests.length} / ${sortableItems.length}`, notFoundTests.join(", "));
  }

  loadFoodResults=()=>{
    const {parsedLabFoodIntoleranceResults=[], foodItemsInitialResults=[],highLimit,lowLimit, mediumLimit, initialFoodItems, collapsibles}=this.state;
    // console.log("parsedLabFoodIntoleranceResults", parsedLabFoodIntoleranceResults, initialFoodItems)
    let final = foodItemsInitialResults.map(category => {
      const updatedFoodItems = category.foodItems.map(foodItem => {
        const matchingResult = parsedLabFoodIntoleranceResults.find(result => result.valeofoodItemName === foodItem.foodName);
      
        if (matchingResult) {
          return {
            ...foodItem,
            ...matchingResult, foodItemId:foodItem.foodItemId
          };
        } else {
          return {...foodItem,foodItemId:foodItem.foodItemId};
        }
      });
    
      return {
        ...category,
        foodItems: updatedFoodItems
      };
    });

    this.setState({initialFoodItems:final,highLimit,mediumLimit,lowLimit,collapsibles:{}})
    
  }

  setUsername = (user) => {
    // console.log("Setting username", user);
    if (!user) return;
    const { username, full_name, first_name, last_name, email } = user;
    if(username!==null || undefined){
      const name = username || full_name || `${first_name}  ${last_name}`;
      this.setState({ userName: `${name} (${email})` }, () => {
        // console.log("username is set: ", this.state.userName);
      });
    }
    else{
      const name = full_name || `${first_name}  ${last_name}`;
      this.setState({ userName: `${name} (${email})` }, () => {
        // console.log("username is set: ", this.state.userName);
      });
    }
  }

  isOnlyResultPDFRequired = () => {
    const { orderDetails } = this.state;
    const isCustomPackage = orderDetails && (orderDetails.order_item_type === "Custom_Package" || orderDetails.custom_package);
    const operationType = isCustomPackage ? orderDetails.custom_package?.operation_type : "";
    // console.log("isOnlyResultPDFRequired::", {isCustomPackage, operationType, orderDetails});
    return isCustomPackage && ["PDF Download", "Requires Coach Note"].some(it => operationType && operationType.includes(it));
  }

  canUploadResultsOnApp = () => {
    const { lab_result_file, tests, resultIds, isSelfResultUpload=false } = this.state;
   console.log("tests", tests)
    const isResultPDFUploaded = isSelfResultUpload ?true:lab_result_file && lab_result_file !== "" ? true : false;
    if (this.isOnlyResultPDFRequired()) {
      return isResultPDFUploaded;
    }

    const isAllResultsFilled = tests 
      && tests.every(test => test.result !== null && test.result !== undefined && test.result !== "")
      // && (resultIds && tests.length === resultIds.length) && !resultIds.includes("");
    //   console.log("resultIds && tests.length === resultIds.length",resultIds.length);
    // console.log("canUploadResultsOnApp:: ", { lab_result_file, tests });
    console.log("canUploadResultsOnApp:: ", { isResultPDFUploaded, isAllResultsFilled });
      return isResultPDFUploaded && isAllResultsFilled;
  }

  canUploadPartialResultsOnApp=()=>{
    const { lab_result_file, tests, resultIds } = this.state;
    const isResultPDFUploaded = lab_result_file && lab_result_file !== "" ? true : false;
    if (this.isOnlyResultPDFRequired()) {
      return false
    }
    
    const isAllResultsFilled = tests 
    && tests.some(test => test.result && test.result !== null)
    && (tests && tests.length>0) 
    // && (resultIds && resultIds.includes(""));
    // console.log('resultIds', resultIds.includes(""))
  // console.log("canUploadResultsOnApp:: ", { lab_result_file, tests });
  // console.log("canUploadResultsOnApp:: ", { isResultPDFUploaded, isAllResultsFilled });
  // console.log("isAllResultsFilled", isAllResultsFilled)
  return isAllResultsFilled;
  
  }

  uploadResultsOnApp = async (isPartial=false) => {
    const { orderId, tests,saveFlag } = this.state;
    const userId = getUserId();
    if (orderId === null || orderId === undefined || Number.isNaN(Number(orderId))) {
      // console.log("uploadResultsOnApp:: Invalid orderId=", orderId);
      this.showErrorNotification(`Can't upload results on app! Invalid order id: ${orderId}`);
      return;
    }
    if(!isPartial){
      const isAllResultsFilled = tests && tests.every(test => test.result !==null);
      if(!isAllResultsFilled){
        this.showErrorNotification(`Test results are required!`);
        return
      }
    }
    
    const payload = { id: orderId, order_status: isPartial?"PARTIAL RESULTS UPLOADED TO APP":"RESULTS UPLOADED TO APP",modified_by: userId};
    try {
      const response = await API.patch(`/order-status/notify/${orderId}/`, payload);
      // console.log("uploadResultsOnApp:: Response: ", response);
      const message = response?.data?.message ?? "Rsults are successfully uploaded on app.";
      this.showSuccessNotification(message);

      // PhoenixAPI.post(`${BASE_URL_CHECK_AND_COMPLETE_PARENT_ORDER}${orderId}/`);
      if(!isPartial){
        PhoenixAPI.get(`admin/results-uploaded/${orderId}`)
      }
      // console.log("saveFlag is",saveFlag);
       this.setState({saveFlag:false});
    } catch (error) {
      this.showErrorNotification("Error on uploading results on app!", error);
    }
  }

  mergeUnits = (h, j) => {
    const hMap = h.reduce((acc, curr) => {
      const key = Object.keys(curr)[0];
      acc[key] = new Set(curr[key]);
      return acc;
    }, {});

    j.forEach(item => {
      const { key, value } = item;
      if (hMap[key]) {
        hMap[key].add(value);
      } else {
        hMap[key] = new Set([value]);
      }
    });

    return Object.keys(hMap).map(key => ({
      [key]: Array.from(hMap[key])
    }));
  };
  handleFromUnitsForLab=()=>{
    const {labName="", labSpecificUnits=[], biomarkerUnitOptions=[], modifiedLabUnitsArr=[]}= this.state;

    let tests = [...this.state.tests];
    const filteredLabSpecificUnits=labSpecificUnits.filter((item)=>item.lab_name==labName)
    let unitModified=[];
    tests.forEach((item)=>{
      const findLabUnit= filteredLabSpecificUnits&&filteredLabSpecificUnits.find(it=>it.valeo_test_name===item.name);
      let labUnit=findLabUnit?.lab_test_unit??"";

      if(findLabUnit){
       item["fromUnit"]=labUnit;
       tests[item.index]=item;
       unitModified.push({text:findLabUnit.lab_test_unit,value:findLabUnit.lab_test_unit,key:findLabUnit.valeo_test_name, conversionFactor:1})
       console.log("findLabUnit findLabUnit", findLabUnit)
      }
      else{
        item["fromUnit"]="";
        tests[item.index]=item;
      }
    })
    const output = this.mergeUnits(modifiedLabUnitsArr, unitModified)
    console.log("output", output)
    const options = output.reduce((acc, curr) => {
      const key = Object.keys(curr)[0];
      curr[key].forEach(unit => {
        const option = { key: key, value: unit, text: unit };
        if (!acc.some(item => item.key === key && item.value === unit)) {
          if (!acc.some(item => item.key === key && item.text)) {
            option.text = unit;
          }
          acc.push(option);
        }
      });
      return acc;
    }, []);
    options.forEach((item) => {
      const unitValue = biomarkerUnitOptions.find((it => it.value === item.value))
      if (unitValue) {
        item["conversionFactor"] = unitValue.conversionFactor
      }
      else {
        item["conversionFactor"] = 1;
      }
    })
    console.log("finalBIomarkerOptions", options)
    this.setState({testData:[...tests], tests, biomarkerUnitOptions:options})
    console.log("Lab Name: " + labName, filteredLabSpecificUnits);
  }
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getClientDetails = () => {
    const { dependent_id, member , user} = this.state.order ?? {};
    const { username, full_name, first_name, last_name, email } = user;
    const {isSelfResultUpload, userUploadedFiles=[]}= this.state
    const countUserUploadedFiles=userUploadedFiles.length>0?userUploadedFiles.length:0;
    let clientName=""
    if(username){
      let  name = username || full_name || `${first_name}  ${last_name}`;
      clientName= `${name} (${email})`
    }
    else{
      let  name = full_name || `${first_name}  ${last_name}`;
      clientName=`${name} (${email})`
    }
   
    if (dependent_id) {
      const name = this.getDependentName(member);
      const relation = member?.relation ?? "";
      return <>
        <p className="pl-2">
          <b>Client : </b>{name}
        </p>
        <p className="pl-2">
          <b>Relation : </b>{relation}
        </p>
      </>
    } else {
        return (
          <>
          <p className="pl-2">
            <b>Client : </b>{clientName}
          </p>
         {isSelfResultUpload &&  <p className="pl-2"><b>No of Files :</b> {countUserUploadedFiles}</p>}
          </>
          
        );
    }
  }

  getNutritionistName=()=>{
   const{nutritionist={}} =this.state.order
   let nutritionistName=nutritionist?.username ?? "";
   
   return (<p className="pl-4">
   <b>Feedback Done By :</b> {nutritionistName} </p>);
  }

  getLongevityScore=()=>{
    const{user,country_id,is_longevity_expired,longevity_score,longevity_percentile}=this.state.order;
    // console.log("client det",this.state.order);
    const member=this.state.order?.member||{};
    const url=`/dashboard/customer-longevity-score/${user.id}/${country_id}`;
   
   return( <>{ longevity_score ==null ||longevity_score ==undefined  || Object.keys(member).length>0?<></>:<div className="row mb-3">
            <div className="col">
              <p className="mb-3 pl-4">
            <><NavLink  to={url} target="_blank">
            Longevity Score 
          </NavLink>  : {longevity_score} {is_longevity_expired?"(E)":""}</>
                </p>  
                <p className="mb-3 pl-4"><b>Longevity Percentile - {longevity_percentile}%</b></p>
                </div></div>} </>)
  }

  getDependentName = (member) => {
    if (member) {
      const email = this.state.order?.user?.email ?? null;
      const { first_name, last_name } = member;
      const name = `${first_name ?? ""} ${last_name ?? ""}`.trim();
      return email ? `${name} (${email})` : name;
    } else {
      console.log("getDependentName:: Invalid dependent", { member });
      return "";
    }
  }

  renderGenderCheck = () => {
    const { gender } = this.props.match.params
    return (
      <>
        {gender == "null" ? <div style={{ "color": "red", "padding": "12px" }}>Please update the Gender of the Client </div> : ""}
      </>
    )
  }


  saveButtonStatus=(buttonName)=>{
    const{saveFlag,isLoaded,isPastOrder,isEditable,listBiomarkersRemoved,listNonBloodBiomarkersRemoved,userType,}=this.state;
    const btnUploadResultsOnApp = (
      <>
       {isPastOrder?<div></div>:<button className="btn button button-green result-submit btn-right"
        type="submit"
        onClick={(e) => {this.uploadResultsOnApp();this.saveButtonStatus("upload")}}
        disabled={!isEditable}
      >
        Upload Results on App
      </button>}
      </>
    
    );

    const btnUploadPartialResultsOnApp = (
      <>
       {isPastOrder?<div></div>:<button className="btn button button-green result-submit btn-right"
        type="submit"
        onClick={(e) => this.uploadResultsOnApp(true)}
        disabled={!isEditable}
      >
        Upload Partial Results on App
      </button>}
      </>
    
    );
    if(buttonName=="Save"){
    this.setState({saveFlag:true});
    
    return(<>{ userType === "lab" && this.canUploadResultsOnApp() && !isLoaded && listBiomarkersRemoved.length==0 &&  listNonBloodBiomarkersRemoved.length==0 && saveFlag &&
    <div className="row mb-3">
      <div className="col"></div>
      <div className="col-3">
        {btnUploadResultsOnApp}
      </div>
    </div>
  }
   {  userType === "lab" && this.canUploadPartialResultsOnApp() && !isLoaded && listBiomarkersRemoved.length==0 && listNonBloodBiomarkersRemoved.length==0 && saveFlag &&
    <div className="row mb-3">
      <div className="col"></div>
      <div className="col-3">
        {btnUploadPartialResultsOnApp}
      </div>
    </div>
  }</>)
    }
   else if(buttonName=="Upload")
     this.setState({saveFlag:false});

  }


    
  
  

  handleCoachSuggestions=(suggestions=[])=>{
      
      const {id:orderId=null, user={}}= this.state.order || {};
      const {id:userId=null}= user || {}
      
    //   const today = moment().format("YYYY-MM-DD");
    // const mappedSuggestions=suggestions.map((suggestion)=>{
      
    //  const obj={
    //    "supplement_id":suggestion.supplementId,
    //    "updated_date": today,
    //     "doses_eng":suggestion.dosesEng,
    //     "doses_ar":suggestion.dosesAr,
    //     "first_suggestion_date":suggestion.firstSuggestionDate ?? today,
    //      "period_id":suggestion.periodId,
    //      "order_id":parseInt(orderId),
    //      "user_id":parseInt(userId),

    //      // "period_eng":suggestion.periodEng,
    //  }
    //  return obj
    // })
    this.setState({mappedSuggestions:suggestions})
    
 }

  handleFollowUpDate = (period) => {
    const { order } = this.state || {}
    const { orderFollowUpDto = {} } = order || {};
    const newOrderFollowUpDto = { ...orderFollowUpDto, followUp: period.value, followUpDate: period.label }
    this.setState({ order: { ...order, orderFollowUpDto: newOrderFollowUpDto, followUpPeriod: period.value } })
  }

  handleFollowUpBloodPackage = (bloodPackage) => {
    const { order } = this.state || {}
    const { orderFollowUpDto = {} } = order || {};

    const newOrderFollowUpDto = { ...orderFollowUpDto, bloodPackageId: bloodPackage.value, miniPackages:[] }
    this.setState({ order: { ...order, orderFollowUpDto: newOrderFollowUpDto } })
  }



  handleFollowUpMiniPackages = (SelectedMiniPackages) => {
    const { order, miniPackages } = this.state || {}
    const { orderFollowUpDto = {} } = order || {};
    const newOrderFollowUpDto = { ...orderFollowUpDto, miniPackages: SelectedMiniPackages.map((it) => it.value), bloodPackageId:null }
    this.setState({ order: { ...order, orderFollowUpDto: newOrderFollowUpDto } })
  }

  
  renderLastSuggestedPackageName = (orderFollowUpDto) => {

    const { miniPackages = [] } = this.state || {};
    const { lastBloodPackageName, lastMiniPackages } = orderFollowUpDto || {};

    if (lastBloodPackageName) {

      return lastBloodPackageName
    }
    let finalArray = []
    if (lastMiniPackages && lastMiniPackages.length > 0) {

      lastMiniPackages.map(id => {
        const found = miniPackages.find(item => item.id === id);
        finalArray.push(found ? found.name : '')

      });

      let uniqueV = finalArray.filter((item, index) => {
        // Return only the first occurrence of each element
        return finalArray.indexOf(item) === index;
      });

      return uniqueV.join(",")
    }
  }


  // handleFollowUpMiniPackages = (SelectedMiniPackages) => {
  //   const { order, miniPackages } = this.state || {}
  //   const { orderFollowUpDto = {} } = order || {};
  //   const newOrderFollowUpDto = { ...orderFollowUpDto, miniPackages: SelectedMiniPackages.map((it) => it.value), bloodPackageId:null }
  //   this.setState({ order: { ...order, orderFollowUpDto: newOrderFollowUpDto } })
  // }
  handleRemoveFoodItem = (value, selectedItem) => {
    const { collapsibles } = this.state || {}
    const { selectedFoodItemIds } = this.state;
    if (selectedFoodItemIds.includes(selectedItem.testResultId)) {
      let filtered = selectedFoodItemIds.filter((data) => data != selectedItem.testResultId);
      this.setState({ selectedFoodItemIds: filtered }, () => this.setRemovingIds());
    }
    else {
      this.setState({
        selectedFoodItemIds: [...selectedFoodItemIds, selectedItem.testResultId],
      }, () => this.setRemovingIds());
    }
    const { initialFoodItems, highLimit, mediumLimit, lowLimit } = this.state || {};
    const final = initialFoodItems.map(item => {
      const updatedFoodItems = item.foodItems.map(food => {
        if (food.id === selectedItem.id) {
          food.isDeleted = value
        }

        return food
      })
      return { ...item, foodItems: updatedFoodItems };
    })
    final.forEach((item) => {

      return collapsibles[item.categoryId] = true
    })
    this.setState({ initialFoodItems: final, collapsibles, defaultOpen: true })

  }

  handleCollapsibleChange = (key) => {
    const collapsibles = {};
    if (this.state.collapsibles[key] === undefined) collapsibles[key] = true;
    this.setState({ collapsibles });
  };

  setRemovingIds=(value)=>{
    const {defaultOpen, selectedFoodItemIds}= this.state || {};
     if(selectedFoodItemIds && selectedFoodItemIds.length===0){
      this.setState({defaultOpen:false})
     }
  }
   
handleScore=(value, selectedItem)=>{
  const {initialFoodItems}= this.state || {};
  let {highLimit,mediumLimit,lowLimit} = this.state || {};
  if(highLimit==null || mediumLimit==null || lowLimit==null){
    //handle score change  by these values for rendering for first time
   highLimit=20.0;
   mediumLimit=19.99;
   lowLimit=9.99
  }
   const final=initialFoodItems.map(item=>{
    const updatedFoodItems=item.foodItems.map(food=>{
      if(food.id==selectedItem.id){
        food.result=value

        
        if(food.result>highLimit){
          
          food.flag="HIGH";
          food.customerPriority=true;
        }
        else if(food.result>mediumLimit && food.result<=highLimit){
          
          food.flag="HIGH"
          food.customerPriority=true;
        }

         else if(food.result>lowLimit && food.result<=mediumLimit){
          
          food.flag="MEDIUM"
          food.customerPriority=true;
        }
         else if(food.result<=lowLimit){
          
          food.flag="LOW"
          food.customerPriority=false;
        }
      }
     
      return food
    })
    return { ...item, foodItems: updatedFoodItems };
   })
    this.setState({initialFoodItems:final})
}
  


  renderFoodIntoleranceResults = () => {
    
    const { collapsibles, initialFoodItems=[],userType,isPastOrder, highLimit,mediumLimit,lowLimit, defaultOpen} = this.state;
    
    return (
      <>
        {initialFoodItems.length > 0 &&
          initialFoodItems.map((item, index) => {
            return (
              <Card key={index}>
                <Card.Body>
                  <div className="row">

                    <div className="col">
                      <div style={{ fontWeight: 500 }}>
                        {item.categoryName}
                      </div>
                    </div>


                    <div className="col">
                      <i
                        onClick={() =>
                          (userType === "nutritionist" && isPastOrder || userType === "externalNutritionist" && isPastOrder)?"": defaultOpen?"":this.handleCollapsibleChange(
                            item.categoryId
                          )
                        }
                        className={`caret square ${collapsibles[false]
                          ? "up"
                          : "down"
                          } icon big`}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </div>
                  </div>
                  {
                    collapsibles[item.categoryId] ? (
                    <>
                      <FoodItemsTable
                        foodItems={item.foodItems ?? []}
                        foodCategory={item.categoryName}
                        userType={userType === "nutritionist" && isPastOrder || userType === "externalNutritionist" && isPastOrder?"coach":userType}
                        isPastOrder={isPastOrder}
                        addScore={this.handleScore}
                        removeFoodItem={this.handleRemoveFoodItem}
                        highLimit={highLimit}
                        mediumLimit={mediumLimit}
                        lowLimit={lowLimit}
                      />
                    </>
                  ) : null}
          
                </Card.Body>
              </Card>
            )
          })

        }
      </>
    )
  }

  renderUploadedFiles=()=>{
    const {userUploadedFiles}= this.state || {};
     return userUploadedFiles.map((file)=>{
        return (
          <div style={{}}>
           <PdfViewer pdfFileUrl={file.s3Url}/>
          </div>
          
          
        )
      })
  }
  handleAddSelectedBiomarkers=async()=>{
    const {selfResultBiomarkersIds=[], selfResultBiomarkers, order}= this.state || {};
    const {id:orderId=null,order_item_type:packageType="", order_item_id:packageId=null}= order || {}
    if(selfResultBiomarkersIds && selfResultBiomarkersIds.length===0){
      this.showErrorNotification("Please Select Biomarkers")
      return 
    }
    const filtered=selfResultBiomarkersIds.length>0 && selfResultBiomarkersIds.map((biomarkerId)=>{
                return selfResultBiomarkers.find(biomarker=>biomarker.id===biomarkerId)
        
    })
    const mappedBiomarkers=filtered.map((item)=>{
      const itemObject={
        "id":item.id,
        "name":item.name,
        "nameAr":item.name_ar,
        "price": item.price,
        "testType": item.test_type,
        "isActive": item.is_active,
      }
      return itemObject;
    })
    if(mappedBiomarkers && mappedBiomarkers.length>0){
      try{
        this.showLoading()
         const response= await PhoenixAPI.post(`user-own-tests/api/v2/add-order-items/${orderId}/${packageId}/`,mappedBiomarkers);
         this.setState({selfResultBiomarkersIds:[]})
         this.loadData();
         this.showSuccessNotification("Biomarkers added successfully")
         console.log("response", response)
      }
      catch(error){
        console.log("Error in saving in order-items", error.message);
        this.showErrorNotification("Error in saving biomarkers", error.message)
      }
      finally{
        this.hideLoading()
      }
      
    }
    
   
  }

  render() {
    let finalList = [];
    const {
      tests,
      isLoaded,
      loadError,
      userType,
      saveFlag,
      viewMode,
      resultFileError,
      resultFileValidation,
      survey,
      testData,
      isPastOrder,
      parsedLabTestResults,
      order = {},
      coachSuggestions = {},
      errors = {},
      howDidYouHearDetails={},
      isEditable={},
      followUpPackage,
      bloodPackages,
      miniPackages,
      supplements,
      isFoodIntoleranceTest,
      collapsibles,
      initialFoodItems,
      isSelfResultUpload
    } = this.state;
  
    const {orderFollowUpDto={}}= order || {};
    // if (this.state.submitted) this.validateResults();
    if (this.state.finalSubmit) this.validateFinalResults();
    if (isLoaded) finalList = testData ? testData : tests;
    // console.log("tests are in render",tests);
    const sortableItems = this.sortedItems(tests);
    console.log("sortable Itemsare in render",sortableItems);
    const btnUploadResultsOnApp = (
      <>
       {isPastOrder?<div></div>:<button className="btn button button-green result-submit btn-right"
        type="submit"
        onClick={(e) => {this.uploadResultsOnApp();this.saveButtonStatus("upload")}}
        disabled={!isEditable}
      >
        Upload Results on App
      </button>}
      </>
    
    );

    const btnUploadPartialResultsOnApp = (
      <>
       {isPastOrder?<div></div>:<button className="btn button button-green result-submit btn-right"
        type="submit"
        onClick={(e) => {this.saveButtonStatus("upload");this.uploadResultsOnApp(true)}}
        disabled={!isEditable}
      >
        Upload Partial Results on App
      </button>}
      </>
    
    );
    


    const coachSuggestionCard = (
      <div
        className="ui raised card"
        style={{ width: "100%", maxWidth: "100%", zIndex: 5 }}
      >
        <div className="content">
          <div className="font-weight-bold mb-2">Consultation Details</div>
          <div className="description">
            <div style={{ border: "2px solid black", padding: "20px", marginBottom: "20px" }}>
              <div className="row">
                <div className="col-4">
                  <label
                    htmlFor="is_coach_consult_done"
                    style={{ color: "black" }}
                  >
                    Is consultation done?
                  </label>
                  <br />
                  <Checkbox
                    id="is_coach_consult_done"
                    checked={coachSuggestions.is_coach_consult_done ?? null}
                    onChange={(e, data) => {
                      const newCoachSuggestions = {
                        ...coachSuggestions,
                        is_coach_consult_done: data.checked,
                        coach_consult_date: "",
                      };
                      this.setState({ coachSuggestions: newCoachSuggestions }, () => {
                        this.coachConsultDateRef.current = "";
                      });
                    }}
                    label={coachSuggestions.is_coach_consult_done ? "Yes" : "No"}
                    disabled={isEditable ? order.is_coach_consult_done ?? false : true}
                    toggle
                  />
                </div>
                <div className="col-4">
                  <label htmlFor="coach_consult_date" style={{ color: "black" }}>
                    Consultation Date
                  </label>
                  <input
                    ref={this.coachConsultDateRef}
                    value={coachSuggestions.coach_consult_date}
                    onChange={(e) => {
                      const selectedDate = e.target.value;
                      if (moment().isBefore(selectedDate)) {
                        e.target.value = "";
                        return;
                      }
                      const newCoachSuggestions = {
                        ...coachSuggestions,
                        coach_consult_date: selectedDate,
                      };
                      this.setState({ coachSuggestions: newCoachSuggestions });
                    }}
                    max={moment().format("YYYY-MM-DD")}
                    type="date"
                    name="coach_consult_date"
                    id="coach_consult_date"
                    className="form-control"
                    disabled={isEditable ? !coachSuggestions.is_coach_consult_done : true}
                  />
                  <span className="help-block">{this.coachConsultDateErrorMessage}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col">
                  <Form.Check
                    type="checkbox"
                    checked={order.client_didnt_showed_up || false}
                    onChange={(event) => this.setState({
                      order: { ...order, client_didnt_showed_up: event.target.checked }
                    })}
                    id="client_didnt_showed_up"
                    name="client_didnt_showed_up"
                    size="large"
                    label="Client didn't showed up"
                    className="font-weight-bold"
                  />
                </div>
              </div>
            </div>
            {/* <div className="row mt-2">
              <div className="col-4">
                <label
                  htmlFor="is_supplement_required"
                  style={{ color: "black" }}
                >
                  Is supplements suggestion required?
                </label>
                <br />
                <Checkbox
                  id="is_supplement_required"
                  checked={coachSuggestions.is_supplement_required ?? null}
                  onChange={(e, data) => {
                    const newCoachSuggestions = {
                      ...coachSuggestions,
                      is_supplement_required: data.checked,
                      coach_supplement_suggestion: [],
                    };
                    this.setState({ coachSuggestions: newCoachSuggestions });
                  }}
                  label={coachSuggestions.is_supplement_required ? "Yes" : "No"}
                  disabled={isEditable ? order.is_supplement_required ?? false : true}
                  toggle
                />
              </div>
              <div className="col-4">
                <label
                  htmlFor="coach_supplement_suggestion"
                  style={{ color: "black" }}
                >
                  Suggested Supplements
                </label>
                <SelectSupplements
                  id="coach_supplement_suggestion"
                  supplements={this.state.supplements}
                  selectedSupplementIds={
                    coachSuggestions.coach_supplement_suggestion ?? []
                  }
                  onSupplementSelect={(options) => {
                    const newCoachSuggestions = {
                      ...coachSuggestions,
                      coach_supplement_suggestion: options.map(
                        (it) => it.value
                      ),
                    };
                    this.setState({ coachSuggestions: newCoachSuggestions });
                  }}
                  menuPlacement="bottom"
                  showOnlyActive={true}
                  isDisabled={isEditable ? !coachSuggestions.is_supplement_required : true}
                />
                <span className="help-block">
                  {this.coachSupplementSuggestionErrorMessage}
                </span>
              </div>
            </div> */}
            <div className="font-weight-bold" style={{ padding: "10px" }}> Coach Suggestion Details</div>
            <div style={{ border: "2px solid black", padding: "20px" }}>
              <CoachSupplementSuggestion

                suggestions={coachSuggestions.coach_supplement_suggestion ?? []}
                countryId={this.state.countryId ?? null}
                suggestedSupplements={supplements}
                suggestionsUpdate={this.handleCoachSuggestions}
                 isEditable={isEditable}
                 isPastOrder={true}
              />
              <div className="suggestions" style={{ padding: "20px" }}>

                <div className="font-weight-bold">Additional Suggestions</div>
                <div className="row mt-2">
                  <div className="col-4">
                    <label
                      htmlFor="is_supplement_required"
                      style={{ color: "black" }}
                    >
                      Is blood tests suggestion required?
                    </label>
                    <br />
                    <Checkbox
                      id="is_blood_package_required"
                      checked={coachSuggestions.is_blood_package_required ?? null}
                      onChange={(e, data) => {
                        const newCoachSuggestions = {
                          ...coachSuggestions,
                          is_blood_package_required: data.checked,
                          coach_blood_package_suggestions: [],
                        };
                        this.setState({ coachSuggestions: newCoachSuggestions });
                      }}
                      label={coachSuggestions.is_blood_package_required ? "Yes" : "No"}
                      disabled={isEditable ? order.is_blood_package_required ?? false : true}
                      toggle
                    />
                  </div>
                  <div className="col-4">
                    <label
                      htmlFor="coach_blood_package_suggestions"
                      style={{ color: "black" }}
                    >
                      Suggested Blood Tests
                    </label>
                    <SelectBloodPackages
                      id="coach_blood_package_suggestions"
                      bloodPackages={this.state.bloodPackages ?? []}
                      selectedBloodPackageIds={
                        coachSuggestions.coach_blood_package_suggestions ?? []
                      }
                      onSelect={(options) => {
                        const newCoachSuggestions = {
                          ...coachSuggestions,
                          coach_blood_package_suggestions: options.map(
                            (it) => it.value
                          ),
                        };
                        this.setState({ coachSuggestions: newCoachSuggestions });
                      }}
                      menuPlacement="bottom"
                      showOnlyActive={true}
                      isDisabled={!isEditable || !coachSuggestions.is_blood_package_required}
                    />
                    <span className="help-block">
                      {errors.coach_blood_package_suggestion}
                    </span>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-4">
                    <label
                      htmlFor="is_supplement_required"
                      style={{ color: "black" }}
                    >
                      Is custom tests suggestion required?
                    </label>
                    <br />
                    <Checkbox
                      id="is_custom_package_required"
                      checked={coachSuggestions.is_custom_package_required ?? null}
                      onChange={(e, data) => {
                        const newCoachSuggestions = {
                          ...coachSuggestions,
                          is_custom_package_required: data.checked,
                          coach_custom_package_suggestions: [],
                        };
                        this.setState({ coachSuggestions: newCoachSuggestions });
                      }}
                      label={coachSuggestions.is_custom_package_required ? "Yes" : "No"}
                      disabled={isEditable ? order.is_custom_package_required ?? false : true}
                      toggle
                    />
                  </div>
                  <div className="col-4">
                    <label
                      htmlFor="coach_supplement_suggestion"
                      style={{ color: "black" }}
                    >
                      Suggested Custom Tests
                    </label>
                    <SelectCustomPackages
                      id="coach_custom_package_suggestions"
                      customPackages={this.state.customPackages ?? []}
                      selectedCustomPackageIds={
                        coachSuggestions.coach_custom_package_suggestions ?? []
                      }
                      onSelect={(options) => {
                        const newCoachSuggestions = {
                          ...coachSuggestions,
                          coach_custom_package_suggestions: options.map(
                            (it) => it.value
                          ),
                        };
                        this.setState({ coachSuggestions: newCoachSuggestions });
                      }}
                      menuPlacement="bottom"
                      showOnlyActive={true}
                      isDisabled={!isEditable || !coachSuggestions.is_custom_package_required}
                    />
                    <span className="help-block">
                      {errors.coach_custom_package_suggestion}
                    </span>
                  </div>
                </div>
              </div>
            </div>




            <div className="font-weight-bold" style={{padding:"10px"}}>Follow Up Details</div>
            <div className="suggestions" style={{border:'2px solid black',  padding:"10px"}}>
              <div className="row mt-2">
                <div className="col-4" style={{ paddingTop: "13px" }}>
                  <label
                    htmlFor="is_supplement_required"
                    style={{ color: "black" }}
                  >
                    Follow Up Date
                  </label>
                </div>
                <div className="col-4">

                  <Dropdown
                    value={orderFollowUpDto && orderFollowUpDto.followUp}
                    onChange={(event, value) => {
                      this.handleFollowUpDate(value)
                    }}
                    id="followUp"
                    name="followUp"
                    placeholder="followUp Period"
                    search
                    selection
                    options={PERIOD_OPTIONS}
                     disabled={!isEditable}
                    className={"form-control"}
                  />
                <span className="help-block">{this.followUpErrorMessage}</span>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <label
                    htmlFor="is_supplement_required"
                    style={{ color: "black" }}
                  >
                    Follow Up Package
                  </label>
                </div>


              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <label
                    htmlFor="is_supplement_required"
                    style={{ color: "black" }}
                  >
                    Blood Package
                  </label>
                  <br />
                  <Checkbox
                    id="isBloodPackage"
                    checked={followUpPackage.isBloodPackage ?? null}
                    onChange={(e, data) => {
                      const newFollowUpPackage = {
                        ...followUpPackage,
                        isBloodPackage: data.checked,
                        isMiniPackage: false,

                      };
                      this.setState({ followUpPackage: newFollowUpPackage });

                    }}
                    label={followUpPackage.isBloodPackage ? "Yes" : "No"}
                    disabled={isEditable ? followUpPackage.isMiniPackage ?? false : true}
                    toggle
                  />
                </div>
                {followUpPackage.isBloodPackage ? <div className="col-4">
                  <SelectPackage
                    packages={bloodPackages ?? []}
                    selectedPackage={orderFollowUpDto && orderFollowUpDto.bloodPackageId}
                    onSelect={(value) =>
                      this.handleFollowUpBloodPackage(value)
                    }
                    // isDisabled={!isNewSuggestion && !editables.periodId}
                    className={
                      "form-control"
                    }

                  />
                <span className="help-block">{this.followUpBloodPackageErrorMessage}</span>
                </div> : ""}
              </div>
              <div className="row mt-2">
                <div className="col-4">
                  <label
                    htmlFor="is_supplement_required"
                    style={{ color: "black" }}
                  >
                    Mini Package
                  </label>
                  <br />
                  <Checkbox
                    id="isMiniPackage"
                    checked={followUpPackage.isMiniPackage ?? null}
                    onChange={(e, data) => {
                      const newFollowUpPackage = {
                        ...followUpPackage,
                        isMiniPackage: data.checked,
                        isBloodPackage: false,

                      };
                      this.setState({ followUpPackage: newFollowUpPackage });
                    }}
                    label={followUpPackage.isMiniPackage ? "Yes" : "No"}
                    disabled={isEditable ? followUpPackage.isBloodPackage ?? false : true}
                    toggle
                  />
                </div>
                {followUpPackage.isMiniPackage ? <div className="col-4">
                  <SelectMiniPackages

                    miniPackages={miniPackages ?? []}
                    selectedMiniPackageIds={orderFollowUpDto && orderFollowUpDto.miniPackages || []}
                    onSelect={(options) => {
                      this.handleFollowUpMiniPackages(options)
                    }}
                    menuPlacement={"bottom"}
                    placeholder={"Select Mini Packages"}
                    className={
                      "form-control"
                    }
                  />
             <span className="help-block">{this.followUpMiniPackageErrorMessage}</span>
                </div> : ""}
              </div>
              <div className="row mt-4">
                <div className="col-4"></div>
                <div className="col-4"></div>
                <div className="col-4">
                  <div className="last-suggested">
                    <div>Last Suggested FollowUp Date - {orderFollowUpDto && orderFollowUpDto.lastSuggestedDate ? formatDate(orderFollowUpDto.lastSuggestedDate) : "-"}</div>
                    <div>Last Suggested Package - {this.renderLastSuggestedPackageName(orderFollowUpDto)}</div>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
      </div>
    );
    
    const howDoYouHearAboutUsDetailsCard = (
      <div
        className="ui raised card"
        // style={{ width: "50%", maxWidth: "100%"}}
        style={{ width: "100%" }}
      >
        <div className="content">
          <div className="description">
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="how_did_you_hear_options" className="font-weight-bold">
                  How did you hear about us
                </label>
                <SelectHowDoYouHearAboutUsOptions
                  id="how_did_you_hear_options"
                  options={howDidYouHearDetails?.options ?? []}
                  selectedOptions={howDidYouHearDetails?.answers ?? []}
                  onSelect={(options) => {
                    const newHowDidYouHearDetails = {
                      ...howDidYouHearDetails,
                      answers: options.map((it) => it.value),
                    };
                    this.setState({ howDidYouHearDetails: newHowDidYouHearDetails });
                  }}
                  menuPlacement="bottom"
                  showOnlyActive={true}
                  isDisabled={howDidYouHearDetails?.showOptions === false}
                />
                <span className="help-block">
                  {errors.howDidYouHearDetails}
                </span>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="hdydas_notes" className="font-weight-bold">
                  How did you hear about us (Notes)
                </label>
                <textarea
                  value={howDidYouHearDetails?.notes ?? ""}
                  onChange={(event) => {
                    const newHowDidYouHearDetails = { ...howDidYouHearDetails, notes: event.target.value };
                    this.setState({ howDidYouHearDetails: newHowDidYouHearDetails });
                  }}
                  id="hdydas_notes"
                  name="hdydas_notes"
                  placeholder="How did you hear about us (Notes)"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-2 mx-auto float-right">
              <button
                onClick={(e) => this.saveHowDoYouHearAboutUsDetails()}
                type="button"
                className="btn btn-lg px-4 mt-4"
                style={{ backgroundColor: "#CEE741" }}
                disabled={!isEditable}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    const followUpDetailsCard = (
      <div className="ui raised card" style={{ width: "100%"}}>
        <div className="content">
          <div className="description">
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="follow_up_date" className="font-weight-bold">
                  Follow up Date
                </label>
                <input 
                  type="date" 
                  value={order.follow_up_date}
                  onChange={(event) => this.setState({
                    order: {...order, follow_up_date: event.target.value}
                  })}
                  id="follow_up_date" 
                  name="follow_up_date" 
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <label htmlFor="follow_up_note" className="font-weight-bold">
                  Follow up Note
                </label>
                <textarea
                  value={order.follow_up_note ?? ""}
                  onChange={(event) => this.setState({
                    order: {...order, follow_up_note: event.target.value}
                  })}
                  id="follow_up_note"
                  name="follow_up_note"
                  placeholder="Follow up Note"
                  className="form-control"
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <Form.Check
                  type="checkbox"
                  checked={order.client_didnt_showed_up || false}
                  onChange={(event) => this.setState({
                    order: { ...order, client_didnt_showed_up: event.target.checked }
                  })}
                  id="client_didnt_showed_up"
                  name="client_didnt_showed_up"
                  size="large"
                  label="Client didn't showed up"
                  className="font-weight-bold"
                />
              </div>
            </div>
            <div className="row mt-2 mx-auto float-right">
              <button
                onClick={this.handleSavePastOrder}
                type="button"
                className="btn btn-lg px-4 mt-4"
                style={{ backgroundColor: "#CEE741" }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    );


    const coachReviewCard = (
      <div className="ui raised card" style={{ width: "100%", maxWidth: "100%" }}>
        <div className="content">
          <div className="font-weight-bold mb-2">Coach’s Review</div>
          <div className="description">
            <form>
              <div className="form-row">
                <div className="col-6">
                  <label>
                    Overall Result (English) *
                    <span>click on save icon to save your data</span>
                  </label>
                  <MUIRichTextEditor
                    // readOnly={!isEditable || viewMode ? true : false}
                    readOnly={!isEditable}
                    value={this.state.overview ? this.state.overview : ""}
                    name="overview"
                    label="Overall result in English"
                    onChange={(e) => {
                      this.event = e;
                    }}
                    onSave={this.saveOverviewEng}
                    inlineToolbar={true}
                    className={
                      "form-control " + (this.overviewMessage && "has-error")
                    }
                  />
                  <p className="help-block rte-mui">{this.overviewMessage}</p>
                </div>
                <div className="col-6">
                  <label>
                    Overall Result (Arabic) *
                    <span>click on save icon to save your data</span>
                  </label>
                  <MUIRichTextEditor
                    value={
                      this.state.overview_ar ? this.state.overview_ar : ""
                    }
                    // readOnly={!isEditable || viewMode ? true : false}
                    readOnly={!isEditable}
                    name="overview_ar"
                    label="Overall result in Arabic"
                    onSave={this.saveOverviewAr}
                    inlineToolbar={true}
                    className={
                      "form-control " +
                      (this.overviewArMessage && "has-error")
                    }
                    onChange={(e) => {
                      this.event_ar = e;
                    }}
                  />
                  <p className="help-block rte-mui">
                    {this.overviewArMessage}
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    )

    const coachSuggestionAndReviewCards = (
      <Card>
        <Card.Body className="mx-2">
          <div className="row">
            {coachSuggestionCard}
          </div>
          <div className="row mt-4">
            {coachReviewCard}
          </div>
          {isPastOrder &&
            <div className="row mt-4 float-right">
              <button
                  onClick={(e) => this.handleSavePastOrder(e)}
                  type="submit"
                  // className="btn button-green button"
                  className="btn btn-lg px-4"
                  style={{ backgroundColor: "#CEE741" }}
                  disabled={!isEditable}
                >
                  Notify Client
              </button>
            </div>
          }
        </Card.Body>
      </Card>
    )

 
    
    const { userId, orderId, clientPastOrders,isRemoveBiomarkerModal,listBiomarkersRemoved,biomarkersNamesRemoved ,listNonBloodBiomarkersRemoved, lab_result_file,selfResultBiomarkers, selfResultBiomarkersIds} = this.state;
   
    return (
      <div className="">
        <VerifyTestResultsViewModal
          tests={this.state.tests}
          results={this.state.results}
          testResults={sortableItems}
          parsedLabTestResults={this.state.parsedLabTestResults ?? []}
          viewMode={viewMode}
          userType={userType} 
          result_messages={this.result_messages}
          handleChange={this.handleChange}
          handleSave={this.handleAllsubmit}
          testCategortList={this.state.testCategortList}
          labReportPdfUrl={this.state.lab_result_file}
          onHide={(e) => this.setState({ isVerifyTestResultsViewModalVisible: false })}
          isVisible={this.state.isVerifyTestResultsViewModalVisible}
        />
        {(userType === "nutritionist" || userType ==="externalNutritionist") && (
          <div className="survey-links">
            <ul>
              <li>
                <NavLink to={`/dashboard/client-notes/${this.state.userId}/${this.state.orderId}`} target="_blank">
                  <button disabled={!userId || !orderId} className="btn button-purple">Client Notes</button>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={
                    "/dashboard/medical-profile/survey/results/" +
                    this.state.userId
                  }
                  target="_blank"
                >
                  <button className="btn button-purple">Health Profile</button>
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={
                    "/dashboard/lifestyle-profile/survey/results/" +
                    this.state.userId
                  }
                  target="_blank"
                >
                  <button className="btn button-purple">
                    Lifestyle Profile
                  </button>
                </NavLink>
              </li>
              {survey != null ? (
                survey.survey_type === "GE" ? (
                  <li>
                    <NavLink
                      target="_blank"
                      to={
                        "/dashboard/health-profile/surveys/results/general/:survey/" +
                        this.state.userId +
                        "/" +
                        survey.id +
                        "/" +
                        this.state.orderId +
                        "/" +
                        survey.survey_type
                      }
                    >
                      <button className="btn button-purple">
                        General Survey
                      </button>
                    </NavLink>
                  </li>
                ) : (
                  <li>
                    <NavLink
                      target="_blank"
                      to={
                        "/dashboard/health-profile/surveys/results/general/:survey/" +
                        this.state.userId +
                        "/" +
                        survey.id +
                        "/" +
                        this.state.orderId +
                        "/" +
                        survey.survey_type
                      }
                    >
                      <button className="btn button-purple">
                        Customized Survey
                      </button>
                    </NavLink>
                  </li>
                )
              ) : (
                ""
              )}
            </ul>
          </div>
        )}
        {this.renderGenderCheck()}
        <div className="form-row">
          <h5 className="col-8 pl-0">{viewMode ? "View Test Results" : isSelfResultUpload?"Client Upload Results":"Upload Test Results"}</h5>
          {userType === 'lab' ?
            <>
              {isPastOrder ?"":(isFoodIntoleranceTest || isSelfResultUpload)?"":<div className="col-2">
                <button
                  className="btn button button-green result-submit btn-right"
                  type="submit"
                  onClick={(e) => {if(listBiomarkersRemoved.length>0 || listNonBloodBiomarkersRemoved.length>0 ){
                        this.showErrorNotification("Please Save before Verifying");
                  }
                  
                      else              
                    {
                      this.handleVerifyResults(e);
                    }
                  
                }
              }
                  disabled={!isEditable}
                  
                >
                  Verify
                </button>
              </div>}
              {isPastOrder ? "" :
                <div className="col-2">
                  <button
                    className="btn button button-green result-submit btn-right"
                    type="submit"
                    onClick={(e) => {
                      if (listBiomarkersRemoved.length > 0 || listNonBloodBiomarkersRemoved.length>0) {
                        this.setState({ isRemoveBiomarkerModal: true });
                      } else { 
                        this.handleAllsubmit(e) 
                      }
                      this.saveButtonStatus("Save");
                    }}
                    disabled={!isEditable}
                  >
                    Save
                  </button>
                </div>}
            </>
            : ''}
        </div>
        

        {(userType ==="nutritionist" || userType==="externalNutritionist") && isLoaded?(
         <div className="ui" style={{marginBottom:"25px"}}> 
          <div className="" style={{display:"flex", width:"100%"}}>
          <div className="col">
            {
              <OrderDetailsCard
                key="order_details_card"
                order={order}
                customerTransactions={this.state.customerTransactions ?? []}
                isCustomerTransactionsLoaded={
                  this.state.isCustomerTransactionsLoaded
                }
                clientPastOrders={clientPastOrders}
              />
            }
          </div>
          <div className="col">
            {<ClientDetailsCard key="client_details_card" order={order} />}
          </div>
        </div>
         </div> 
        ):""}
        { (userType ==="lab" && !isSelfResultUpload) && isLoaded ? (
         (<>
            <div className="row mb-3">
              <div className="col">
                <p className="mb-3 pl-2">
                  <b>Order ID: </b>
                  {this.state.orderId}
                </p>
                {this.getClientDetails()}
                {/* <p className="pl-3">
                  <b>Client : </b>
                  {this.state.order?.dependent_id ? this.getDependentName() : this.state.userName}
                </p> */}
              </div>
              {userType === "lab" && this.canUploadResultsOnApp() && listBiomarkersRemoved.length==0  &&  listNonBloodBiomarkersRemoved.length ==0 && saveFlag &&
                <div className="col-3">
                  {btnUploadResultsOnApp}
                </div>
              }
               {userType === "lab" && this.canUploadPartialResultsOnApp() && listBiomarkersRemoved.length==0  && listNonBloodBiomarkersRemoved.length==0 && saveFlag &&
                <div className="col-3">
                  {btnUploadPartialResultsOnApp}
                </div>
              }
            </div> 
            {(userType === 'lab'  && !isSelfResultUpload) ? (
              <>
              <div className="form-row">
                <div className="col-4 col-sm-12 col-md-4 d-flex pl-3">
                  <label style={{ width: '50px' }}><b>Date :</b> </label>
                  <input
                    type="date"
                    className={
                      "form-control " + (this.dateMessage && "has-error")
                    }
                    style={{ 'marginTop': '-7px' }}
                    placeholder="Result Date"
                    value={this.state.resultDate}
                    name="resultDate"
                    onChange={(e) => this.handleChange(e)}
                  ></input>
                  <span className="help-block">{this.dateMessage}</span>
                </div>
              </div>
              {this.renderLabReportUploadForm()}
              </>
            ) : (
              <></>
            
            // <><div className="row mb-3">
            // <div className="col">
            //   <p className="mb-3 pl-4">
            //     <b>Date : </b>
            //     {this.state.resultDate}
            //   </p>
                
            //     {this.getNutritionistName()}
                
            //     </div></div>
            //     {this.getLongevityScore()}
            //   </>
           )}

          </>
        )) : (
          ""
        )}
        {isSelfResultUpload &&
               <div className="row mb-3">
               <div className="col">
                 <p className="mb-3 pl-2">
                   <b>Order ID: </b>
                   {this.state.orderId}
                 </p>
                 {this.getClientDetails()}
               </div>
               {userType === "lab" && this.canUploadResultsOnApp() && listBiomarkersRemoved.length==0  && saveFlag &&
                 <div className="col-3">
                   {btnUploadResultsOnApp}
                 </div>
               }
                {userType === "lab" && this.canUploadPartialResultsOnApp() && listBiomarkersRemoved.length==0  && saveFlag &&
                 <div className="col-3">
                   {btnUploadPartialResultsOnApp}
                 </div>
               }
             </div> 
          }
        <div className="row">
         <div className="col-12">
         {
         <div>
          {isFoodIntoleranceTest ?this.renderFoodIntoleranceResults():
          (!isSelfResultUpload ? <Card className={this.testMessage ? "has-error" : ""}>
              <div className="col-12">
                <h5 className="col-10 pl-0 pt-2">Tests</h5>
                {sortableItems && sortableItems.length > 0 ? <table className="result-table">
                  <thead className="result-table-head">
                    <tr>
                      <th onClick={() => this.requestSort('position')} className={this.getClassNamesFor('position')}>
                        Position
                      </th>
                      <th onClick={() => this.requestSort('name')}
                        className={this.getClassNamesFor('name')}>Name</th>
                      <th onClick={() => this.requestSort('test_category')}
                        className={this.getClassNamesFor('test_category')}>Category</th>
                      <th>Result</th>
                      {isPastOrder && (userType === "nutritionist"  || userType ==="externalNutritionist")? <th style={{textAlign:"center", padding: '15px' }}>Past Result</th>:<></>}
                      <th>Unit</th>
                      <th>From Units</th>
                      <th style={{textAlign:"center"}}>Flag</th>
                      <th style={{textAlign:"center"}}>Range</th>
                      {userType === "lab" && !isPastOrder ?<th>Remove for Client on App</th>:<></>}
                     {isPastOrder? <th>Customer Priority</th>:<></>}
                      {/* {userType == 'lab' ? <th>Action</th> : ''} */}
                    </tr>
                  </thead>
                  <tbody className="result-table-body">
                    {
                      sortableItems ? sortableItems.map((item) => (
                       
                        <tr key={item.id} className={lab_result_file!==""?( item.result? "result-table-row":"result-table-row no-result"):"result-table-row"}>
                          <td>{item.position ?? ""}</td>
                          <td>{item.name}</td>
                          <td>{this.returnCategories(item)}</td>
                          <td style={{'width': '100px'}}>
                            {this.state.tests[item.index] && this.state.tests[item.index].is_non_blood_biomarker?
                            <input
                            readOnly={
                              viewMode || userType !== "lab"
                                ? true
                                : false
                            }
                            className={
                              "form-control " +
                              (this.result_messages[
                                item.index
                              ] && "has-error")
                            }
                            type="text"
                            placeholder="Result"
                            step="any"
                            onChange={(e) =>
                              this.handleChange(e, item)
                            }
                            min={0}
                            // row.results && row.results[0] ? row.results[0].result : ''
                            value={item.result}
                            name="marker-result"
                          ></input>
                            :<input
                              readOnly={
                                viewMode || userType !== "lab"
                                  ? true
                                  : false
                              }
                              className={
                                "form-control " +
                                (this.result_messages[
                                  item.index
                                ] && "has-error")
                              }
                              type="number"
                              placeholder="Result"
                              step="any"
                              onChange={(e) =>
                                this.handleChange(e, item)
                              }
                              min={0}
                              // row.results && row.results[0] ? row.results[0].result : ''
                              value={item.result}
                              name="marker-result"
                            ></input>}
                          </td>
                        {isPastOrder && (userType === "nutritionist"  || userType ==="externalNutritionist")?<td style={{textAlign:"center",padding: '15px' }}>{this.getPastResult(item)}</td>:<></>}
                          <td>
                            {this.state.tests[item.index] && this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0 ? this.state.tests[item.index].markers[0]
                              .unit : ''}
                          </td>
                         { 
                            <td style={{ 'width': '145px' }}>
                              {this.state.tests[item.index] && this.state.tests[item.index].is_non_blood_biomarker?"":
                              
                              <Dropdown
                              value={item?.fromUnit}
                              onChange={(event, value) => {
                                this.handleUnitChange(event, value, item, this.state.tests[item.index].markers[0]
                                  .unit)
                              }}
                              id="from units "
                              name="from units"
                              placeholder="from units"
                              search
                              selection
                              options={[...this.state.biomarkerUnitOptions.filter((option) => option.key == item.name), { text: "None", value: "None", key: "None", conversionFactor: 1 }]}
                              disabled={!isEditable || this.state.resultIds.filter(id => Boolean(id)).length > 0 || this.state.tests[item.index] && this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0 && this.state.tests[item.index].markers[0].unit == ""}
                              className={"form-control"}
                            />}
                             
                            </td>}
                          <td style={{ 'textAlign': 'center' }}>{this.state.tests[item.index] && this.state.tests[item.index].is_non_blood_biomarker?"":this.returnFlage(item)}</td>


                          <td style={{ 'width': '350px' }}>
                            {/* {
                              this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0 && item.result != '' ? (
                                <div className="form-row justify-content-center align-items-center">
                                  <Range
                                    result={Number(item.result)}
                                    biomarker_list={
                                      this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0 ? this.state.tests[item.index].markers[0]
                                        .biomarker_list : []
                                    }
                                    val={1}
                                  />
                                  
                                </div>
                              ) : ('Out Of Range')
                            } */}
                            {this.state.tests[item.index] && this.state.tests[item.index].is_non_blood_biomarker?"":this.renderRange(item)}
                          </td>
                          {userType=="lab" && !isPastOrder?<td style={{'text-align':'center'}}>
                           {this.state.tests[item.index] && this.state.tests[item.index].is_non_blood_biomarker?<input type="checkbox"
                          key={item.non_blood_biomarker_id}
                          //checked={this.state.tests[item.index].removeBiomarker}
                          className="larger-checkbox"
                         
                          readOnly={viewMode || userType!=="lab" 
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.handleRemoveBiomarkerChange(e, item)
                          }

                          />: <input type="checkbox"
                          key={item.id}
                          //checked={this.state.tests[item.index].removeBiomarker}
                          className="larger-checkbox"
                         

                          readOnly={viewMode || userType!=="lab" 

                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.handleRemoveBiomarkerChange(e, item)
                          }

                          />}
                          
                          </td>:<></>}
                         {isPastOrder? <td style={{'text-align':'center'}}>
                         {this.state.tests[item.index] && this.state.tests[item.index].is_non_blood_biomarker?"" 
                          :<Checkbox 
                          key={item.id}
                          checked={this.state.tests[item.index].customer_priority}

                          />}
                          
                          </td>:<></>}

                          {/* {userType == 'lab' ?
                            <td>
                              <button
                                className="btn button button-green btn-sm"
                                onClick={(e) => this.handleSubmit(e, item.index)}
                              >
                                Save
                              </button>
                            </td> : ''} */}

                        </tr>
                      )) : (
                        <span className="help-block ml-4 mt-2 mb-3">
                          {this.testMessage}
                        </span>
                      )
                    }
                  </tbody>
                </table> : (<AlertBox message="There's nothing here."></AlertBox>)}
                <BiomarkerRemovedModal
          isVisible={isRemoveBiomarkerModal}
          onHide={this. handleHideRemoveBiomarkerModal}
          biomarkersList={biomarkersNamesRemoved}
          onCancelBiomarker={this.handleCancelBiomarker}
          onOk={this.handleDeleteBiomarker}
        />

              </div>
              {/* <DataTable
                highlightOnHover
                columns={userType == 'lab' ? this.columns : this.coachcolumns}
                data={finalList}
                defaultSortField="name"
                sortIcon={<ArrowDownward></ArrowDownward>}
                responsive
                title={<p className="table-header">Tests</p>}
                noDataComponent={
                  loadError ? (
                    <AlertBox message={loadError} error={true}></AlertBox>
                  ) : (
                    <AlertBox message="There's nothing here."></AlertBox>
                  )
                }
                expandableRows={true}
                defaultExpandedField={"results"}
                expandableRowsComponent={isLoaded && <DetailedView />}
              /> */}
              <span className="help-block ml-4 mt-2 mb-3">
                {this.testMessage}
              </span>
            </Card>:"")}
          </div>}

            {(isSelfResultUpload && !isPastOrder) && <div className="" style={{display:"flex",justifyContent:"space-evenly", gap:"20px"}}>
              <div className="col scrollable" style={{width:"35%"}}>
                {this.renderUploadedFiles()}
              </div>

              <div className="" style={{width:"65%"}}>
                <div className="row">
                  <div className="col" style={{width:"60%"}}>
                  <SelectTests
                id="tests"
                tests={selfResultBiomarkers ?? []}
                selectedTestIds={selfResultBiomarkersIds?? []}
                onSelect={(values) => {
                  this.setState({
                   
                      selfResultBiomarkersIds:values.map((it) => it.value),
                    
                  });
                }}
                // isDisabled={!isNewMiniPackage && !editables.tests}
                className={"form-control" + (errors.tests ? " has-error" : "")}
                placeholder={"Select Biomarkers"}
              />
                  </div>
                  <div className="col">
                     <button className="btn button button-green result-submit btn-right"
                     
                      onClick={()=>this.handleAddSelectedBiomarkers()}
                     >
                         Add Selected
                     </button>
                  </div>
                </div>
                <div className="" style={{marginTop:"20px"}}>
                <Card className={this.testMessage ? "has-error" : ""}>
              <div className="col-12">
                <h5 className="col-10 pl-0 pt-2">Tests</h5>
                {sortableItems && sortableItems.length > 0 ? <table className="result-table">
                  <thead className="result-table-head">
                    <tr>
                      <th onClick={() => this.requestSort('position')} className={this.getClassNamesFor('position')}>
                        Position
                      </th>
                      <th onClick={() => this.requestSort('name')}
                        className={this.getClassNamesFor('name')}>Name</th>
                      <th onClick={() => this.requestSort('test_category')}
                        className={this.getClassNamesFor('test_category')}>Category</th>
                      <th>Result</th>
                      {isPastOrder && (userType === "nutritionist"  || userType ==="externalNutritionist")? <th style={{textAlign:"center", padding: '15px' }}>Past Result</th>:<></>}
                      <th>Unit</th>
                      <th>From Units</th>
                      <th style={{textAlign:"center"}}>Flag</th>
                      <th style={{textAlign:"center"}}>Range</th>
                      {userType === "lab" && !isPastOrder ?<th>Remove for Client on App</th>:<></>}
                     {isPastOrder? <th>Customer Priority</th>:<></>}
                      {/* {userType == 'lab' ? <th>Action</th> : ''} */}
                    </tr>
                  </thead>
                  <tbody className="result-table-body">
                    {
                      sortableItems ? sortableItems.map((item) => (
                      
                        <tr key={item.id} className= {lab_result_file!==""?(item.result?"result-table-row":"result-table-row no-result"):"result-table-row"} >
                          <td>{item.position ?? ""}</td>
                          <td>{item.name}</td>
                          <td>{this.returnCategories(item)}</td>
                          <td style={{'width': '100px'}}>
                            <input
                              readOnly={
                                viewMode || userType !== "lab"
                                  ? true
                                  : false
                              }
                              className={
                                "form-control " +
                                (this.result_messages[
                                  item.index
                                ] && "has-error")
                              }
                              type="number"
                              placeholder="Result"
                              step="any"
                              onChange={(e) =>
                                this.handleChange(e, item)
                              }
                              min={0}
                              // row.results && row.results[0] ? row.results[0].result : ''
                              value={item.result}
                              name="marker-result"
                            ></input>
                          </td>
                        {isPastOrder && (userType === "nutritionist"  || userType ==="externalNutritionist")?<td style={{textAlign:"center",padding: '15px' }}>{this.getPastResult(item)}</td>:<></>}
                          <td>
                            {this.state.tests[item.index] && this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0 ? this.state.tests[item.index].markers[0]
                              .unit : ''}
                          </td>
                          { <td style={{'width': '100px'}}>
                          <Dropdown
                    value={ item.fromUnit}
                    onChange={(event, value) => {
                      this.handleUnitChange(event,value,item,this.state.tests[item.index].markers[0].unit)
                    }}
                    id="from units "
                    name="from units"
                    placeholder="from units"
                    search
                    selection
                    options={[...this.state.biomarkerUnitOptions.filter((option)=>option.key==item.name),{ text: "None", value: "", key: "None", conversionFactor: 1 }]}
                     disabled={!isEditable}
                    className={"form-control"}
                  />
                          </td>}

                          <td style={{ 'textAlign': 'center' }}>{this.returnFlage(item)}</td>


                          <td style={{ 'width': '350px' }}>
                            {/* {
                              this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0 && item.result != '' ? (
                                <div className="form-row justify-content-center align-items-center">
                                  <Range
                                    result={Number(item.result)}
                                    biomarker_list={
                                      this.state.tests[item.index].markers && this.state.tests[item.index].markers.length > 0 ? this.state.tests[item.index].markers[0]
                                        .biomarker_list : []
                                    }
                                    val={1}
                                  />
                                  
                                </div>
                              ) : ('Out Of Range')
                            } */}
                            {this.renderRange(item)}
                          </td>
                          {userType=="lab" && !isPastOrder?<td style={{'text-align':'center'}}><input type="checkbox"
                          key={item.id}
                          //checked={this.state.tests[item.index].removeBiomarker}
                          className="larger-checkbox"
                         
                          readOnly={viewMode || userType!=="lab" 
                              ? true
                              : false
                          }
                          onChange={(e) =>
                            this.handleRemoveBiomarkerChange(e, item)
                          }

                          /></td>:<></>}
                         {isPastOrder? <td style={{'text-align':'center'}}><Checkbox 
                          key={item.id}
                          checked={this.state.tests[item.index].customer_priority}

                          /></td>:<></>}

                          {/* {userType == 'lab' ?
                            <td>
                              <button
                                className="btn button button-green btn-sm"
                                onClick={(e) => this.handleSubmit(e, item.index)}
                              >
                                Save
                              </button>
                            </td> : ''} */}

                        </tr>
                      )) : (
                        <span className="help-block ml-4 mt-2 mb-3">
                          {this.testMessage}
                        </span>
                      )
                    }
                  </tbody>
                </table> : (<AlertBox message="There's nothing here."></AlertBox>)}
                <BiomarkerRemovedModal
          isVisible={isRemoveBiomarkerModal}
          onHide={this. handleHideRemoveBiomarkerModal}
          biomarkersList={biomarkersNamesRemoved}
          onCancelBiomarker={this.handleCancelBiomarker}
          onOk={this.handleDeleteBiomarker}
        />

              </div>
              {/* <DataTable
                highlightOnHover
                columns={userType == 'lab' ? this.columns : this.coachcolumns}
                data={finalList}
                defaultSortField="name"
                sortIcon={<ArrowDownward></ArrowDownward>}
                responsive
                title={<p className="table-header">Tests</p>}
                noDataComponent={
                  loadError ? (
                    <AlertBox message={loadError} error={true}></AlertBox>
                  ) : (
                    <AlertBox message="There's nothing here."></AlertBox>
                  )
                }
                expandableRows={true}
                defaultExpandedField={"results"}
                expandableRowsComponent={isLoaded && <DetailedView />}
              /> */}
              <span className="help-block ml-4 mt-2 mb-3">
                {this.testMessage}
              </span>
            </Card>
                </div>
              </div>
            </div>}

         
            {userType === "nutritionist"  || userType ==="externalNutritionist" ? (
              <Card body>
                <h5 className="mb-3">Attachments</h5>
                {this.state.lab_result_file != "" ? (
                  <form>
                    <div className="form-row">
                      <div className="col-12">
                        <a
                          className="view-pdf"
                          href={this.state.lab_result_file}
                          target="_blank"
                        >
                          <label>
                            <Pdf></Pdf> View Result document
                          </label>
                        </a>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div>
                    <p>Lab Results not uploaded</p>
                  </div>
                )}
              </Card>
            ) : (
              (<div>
                <Card body>
                <h5 className="mb-3">Attachment</h5>
                {this.state.lab_result_file != "" ? (
                  <form>
                    <div className="form-row">
                      <div className="col-12">
                        <a
                          className="view-pdf"
                          href={this.state.lab_result_file}
                          target="_blank"
                        >
                          <label>
                            <Pdf></Pdf> View Result document
                          </label>
                        </a>
                        {isPastOrder ? (
                          ""
                        ) : (
                          <button
                            className="btn"
                            onClick={this.handleDeleteFile}
                            disabled={!isEditable}
                          >
                            Delete<DeleteButton></DeleteButton>
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                ) : isPastOrder ? (
                  <p>No file Uplaoded</p>
                ) : (
                  <form>
                    <div className="form-row d-flex">
                      <label style={{ 'paddingLeft': '5px' }}>
                        Upload the result document as pdf. (For safe keeping
                        purposes)
                      </label>
                      <div className="col-10">
                        <input
                          type="file"
                          id="resultInput"
                          name="result"
                          accept="text/pdf"
                          className={
                            "form-control " +
                            (!resultFileValidation && "has-error")
                          }
                          onChange={this.uploadResult}
                        />
                        <span className="help-block">{resultFileError}</span>
                      </div>
                      <div className="col-2">
                        <button
                          className="btn button button-green result-submit "
                          style={{ 'padding': '.375rem .75rem' }}
                          type="submit"
                          disabled={!this.resultSubmitted}
                          onClick={this.uploadResultFileSubmit}
                        >
                          Upload
                        </button>
                      </div>
                    </div>

                  </form>
                )}
              </Card>
              </div>)
            
            )}
          </div>
        </div>
        {userType === "nutritionist" || userType ==="externalNutritionist"  ? (
          <>
            {isPastOrder && <div className="row mb-4">
              <div className="col-6">{howDoYouHearAboutUsDetailsCard}</div>
            {/*  <div className="col-6">{followUpDetailsCard}</div>*/}
            </div>}
            {coachSuggestionAndReviewCards}
            {/* {!viewMode && userType === "nutritionist" || userType ==="externalNutritionist" ? (
              <div className="form-buttons">
                <button
                  onClick={(e) => this.submitFinalResults(e, false)}
                  disabled={!isEditable}
                  type="submit"
                  className="btn button mt-3"
                  style={{ backgroundColor: "#CEE741" }}
                >
                  Submit for Later
                </button>
                <button
                  onClick={(e) => this.submitFinalResults(e, true)}
                  disabled={!isEditable}
                  type="submit"
                  className="btn button-green button mt-3"
                >
                  Submit to Client
                </button>
              </div>
            ) : (
              ""
            )} */}
          </>
        ) : (
          ""
        )}

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestResults);

