import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import routes from "routes";
import arrow_img from "assets/img/arrow.svg";
import { getUserType, getUserId, getisCostingOnHomecareView } from "services/UserService";
import MenuIcon from "@material-ui/icons/Menu";
import {
  showNotification,
  hideNotification
} from "services/NotificationService";
import {
  sendMessage,
  addResponse,
  loadClients,
  connectDB
} from "services/MessagingService";
import API from "utils/API";
import logo_img from "assets/img/Valeo-LogoTypeonDark.png";
import PhoenixAPI from "utils/PhoenixAPI";
import { clearData } from "services/UserService";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aclUser: null,
      clients: []
    };
  }

  async componentDidMount() {
    await this.fetchUserDetails();

    this.fetchClients();
    const { clients } = this.state;
    loadClients(clients);

    // sendMessage(2, "hey");
    // addResponse(2, {
    //   text: "Hey there",
    //   date: "29-January-2020",
    //   time: "18:12 pm"
    // });
    this.forceUpdate();
    this.fetchAclUser();
    const { menuExpanded, routerName } = this.props;
    // Effect code here
    if (routerName === "Completed Orders") {
      this.props.toggleMenu();
    }
    if (routerName === "New Orders") {
      this.props.toggleMenu();
    }
  }

  fetchUserDetails = async() => {
    console.log("fetchUserDetails:: Fetching user details!");

    try {
      const { data: users } = await API.get("/users/");
      // console.log("fetchUserDetails:: Fetched user details=", users);
      if (users && users.length) {
        const loggedInUser = users[0];
        if (!loggedInUser || !loggedInUser.is_active) {
          console.log("fetchUserDetails:: User is inactive! logging out. user=", loggedInUser);
          this.logout();
        }
        return;
      }
      console.log("fetchUserDetails:: User not found! logging out. user=", users);
      this.logout();
    } catch (error) {
      console.log("fetchUserDetails:: Error on fetching users!", error);
      if (error?.response?.status === 401) {
        console.log("fetchUserDetails:: Unauthorized user!");
        this.logout();
      }
    }
  }

  logout = () => {
    console.log()
    if (this && this.props && this.props.history) {
      this.props.history.replace("/login");
    } else {
      window.location.href = '/login'
      clearData();
    }
  }

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", {userId});
      return;
    }

    try {
      const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
      this.setState({ aclUser });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  }

  fetchClients() {
    API.get("nutritionist-clients/")
      .then((response) => {
        this.setState({ clients: response.data }, () => { this.setUsers() });
      })
      .catch(error => {
        console.log("Error on fetching nutritionist clients", error);
      });
  }

  setUsers(){
    let temp = [];
    this.state.clients.map((item) => {
      let client = {};
      client.id = item.id;
      client.user_id = item.user_id;
      client.name = item.full_name;
      client.dp =
      "https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500";
      client.messages= []
      temp.push(client)
    })
    this.setState({
      clients: temp
    }, () => {
      loadClients(this.state.clients);
      connectDB();
    })
  }

  hanleOverlay(e, props) {
    const clickX = e.clientX; // X-coordinate of the click event
    const distanceFromLeft = clickX - e.target.getBoundingClientRect().left;
    if (distanceFromLeft > 240) {
      if (e.target.className === "sidebar-overlay") {
        props.toggleMenu();
      }

    }
  }

  render() {
    const { menuExpanded } = this.props;

    return (
      <div className={"sidebar " + (menuExpanded ? "" : "collapsed")}>
        {menuExpanded && <div className="sidebar-overlay" onClick={(e) => this.hanleOverlay(e, this.props)}></div>}
        <div className="logo-container">
          <MenuIcon
            className="menu-icon"
            onClick={this.props.toggleMenu}
          ></MenuIcon>
          <NavLink className="logo" activeClassName="active" to="/dashboard">
            <img src={logo_img} alt="logo" className="valeo-logo"></img>
            <div className="mob-valeo-logo">
            <img src="https://d25uasl7utydze.cloudfront.net/assets/valeo_logo%20(1).svg" className="mob-image "></img>
             <div className="logo-txt">
               <span>VALEO</span>
                <span>Nursecare Portal</span>

             </div>
            </div>
          </NavLink>
        </div>
        <Navigation
          aclUser={this.state.aclUser ?? null}
          expandMenu={this.props.expandMenu}
          setRouterName = {this.props.setRouterName}
          menuExpanded={menuExpanded}
          toggleMenu={this.props.toggleMenu}
        ></Navigation>
      </div>
    );
  }
}

function Navigation(props) {
  let role = getUserType();
  console.log("role is",role);
  const navLinks = routes.map((route, index) => {
    if (!route.hasSubroute && route.display) {
      let hasAccess = route.role.includes(role);
        
      if (hasAccess)
        return (
          <div className="nav-content" key={index}>
            <NavLink
              className="nav-link nav-header"
              activeClassName="active"
              to={route.path}
              onClick={() => hideNotification(route.notificationId), () => props.setRouterName(route.name)}
            >
              <route.icon className="nav-icon" />
              <span className="nav-text">
                <span className="text">{route.name}</span>
                {route.hasNotification ? (
                  <div className="notification"></div>
                ) : (
                  ""
                )}
              </span>
            </NavLink>
          </div>
        );
    } else {
      let hasAccess = route.role.includes(role);

      if (hasAccess && route.display)
        return (
          <div
            className="nav-content"
            key={index}
            onClick={() => props.expandMenu()}
          >
            <div
              className="nav-header collapsed nav-link accordion-header"
              data-toggle="collapse"
              data-target={"#collapse" + index}
              aria-expanded="false"
              aria-controls={"#collapse" + index}
            >
              <span>
                <route.icon className="nav-icon" />
                <span className="nav-text">
                  <span className="text">{route.name}</span>
                </span>
              </span>
              <img className="arrow-down" alt="arrow" src={arrow_img}></img>
            </div>
            <div
              className="collapse"
              id={"collapse" + index}
              aria-labelledby={"heading1" + index}
              data-parent="#accordion"
            >
              <div className="sub-links">
                {route.subroutes.map((subroute, key) => {
                  if (
                    subroute.display &&
                    subroute.role &&
                    subroute.role.includes(role)
                  )
                    return (
                      <NavLink
                        key={key}
                        activeClassName="active"
                        to={subroute.path}
                        onClick={() => hideNotification(route.notificationId) , () => props.setRouterName(subroute.name)}
                      >
                        <span className="text">{subroute.name}</span>
                        {subroute.hasNotification ? (
                          <div className="notification"></div>
                        ) : (
                          ""
                        )}
                      </NavLink>
                    );
                })}
              </div>
            </div>
          </div>
        );
    }
  });

  if(role){
    return (
      <div id="accordion" className="nav-links">
        {
        /* {navLinks} */
        createNavLinksOlder(props)
        }
    
      </div>
    );
  }
  else{
     window.location.href="/login"
  }
}

function createNavLinksOlder(props){
  const role = getUserType();

  const { aclUser } = props;
  const isUserSuperAdmin = () => {
    return aclUser && aclUser.roles &&
      aclUser.roles.find(role => role.name && role.name.toLowerCase() === "super admin");
  }

  const isAdminUser=()=>{   // or only admin user
    return aclUser && aclUser.roles &&
    aclUser.roles.find(role => role.name && role.name.toLowerCase() === "admin");
  }
  const hasRouteAccess = (route) => {
    // console.log("hasRouteAccess::", {role, aclUser, route});
    if (route.name === "Access Control") {
      return isUserSuperAdmin();
    }

    if(route.name == !"Access Control"){ ///for only admin user
     return isAdminUser()
    }


    if (aclUser) {
      if (isUserSuperAdmin())
        return route.role && route.role.includes(role);
      if(isAdminUser()){  // for only admin user condition
        return route.role && route.role.includes(role);
      }
      const { pagePermissions } = aclUser;
      const pagePermission = pagePermissions.find(it => it.pageName?.toLowerCase() === route.name?.toLowerCase());
      if (!pagePermission) {
        // console.log("hasRouteAccess:: Page permission not found for page=", route.name);
        return false;
      }

      const pageAccessRights = pagePermission.pageAccessRights ?? [];
      if (pageAccessRights.find(it => it.isActive && ["no show"].includes(it.accessRightName?.toLowerCase())))
        return false;
      return pageAccessRights.find(it => ["view", "edit"].includes(it.accessRightName?.toLowerCase()));
    }
   
    return route.role && route.role.includes(role);
  }

  const renderSubroutes = (route) => {
    const subroutes = route?.subroutes ?? [];
    const isHomecareCostingView = getisCostingOnHomecareView()??false;
   const filtereSubRoutes=isHomecareCostingView?subroutes: subroutes.filter((route)=>route.name!=="Total Orders")
   
    return filtereSubRoutes
    .filter(route => route.display ?? false)
    .filter(route => hasRouteAccess(route))
    .map((subroute, key) => {
      return (
        <NavLink
        key={key}
        activeClassName="active"
        to={subroute.path}
        onClick={() => {
              hideNotification(route.notificationId);
              props.setRouterName(subroute.name);
            }}
          >
            <span className="text">{subroute.name}</span>
            {subroute.hasNotification ? (
              <div className="notification"></div>
            ) : (
              ""
            )}
          </NavLink>
        );
      })
  }

  return routes
    .filter(route => route.display ?? false)
    .filter(route => hasRouteAccess(route))
    .map((route, index) => {
          
      /* if(route.name=="HomeCare Mobile Portal" ){
       if( window.innerWidth>768)
        return null;
       
      
      }*/
        
      if (route.hasSubroute) {
        return (
          <div
            className="nav-content"
            key={index}
            onClick={() => props.expandMenu()}
          >
            <div
              className="nav-header collapsed nav-link accordion-header"
              data-toggle="collapse"
              data-target={"#collapse" + index}
              aria-expanded="false"
              aria-controls={"#collapse" + index}
            >
              <span>
                <route.icon className="nav-icon" />
                <span className="nav-text">
                  <span className="text">{route.name}</span>
                </span>
              </span>
              <img className="arrow-down" alt="arrow" src={arrow_img}></img>
            </div>
            <div
              className="collapse"
              id={"collapse" + index}
              aria-labelledby={"heading1" + index}
              data-parent="#accordion"
            >
              <div className="sub-links">
                {renderSubroutes(route)}
              </div>
            </div>
          </div>
        );
      } else { 
        return (
          <>
          {!(route.name && (route.name==="HomeCare Mobile Portal" || route.name==="Ticket Management Mobile Portal" || route.name==="Total Orders Mobile Portal")) && <div className="nav-content" key={index}>
            <NavLink
              to={route.path}
              className="nav-link nav-header"
              activeClassName="active"
              onClick={() => {
                hideNotification(route.notificationId);
                props.setRouterName(route.name);
              }}
            >
              <route.icon className="nav-icon" />
              <span className="nav-text">
                <span className="text">{route.name}</span>
                {route.hasNotification ? (
                  <div className="notification"></div>
                ) : (
                  ""
                )}
              </span>
            </NavLink>
          </div>}
          </>
          
        );
      }
    });
}

export default Header;
