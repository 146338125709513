import React, { Component } from "react";
import API from "utils/API";
import {
  setAccessToken,
  setRefreshToken,
  clearToken
} from "../services/TokenService";
import { setUserType, setUserName, clearData, setUserId, setUserEmail, setisExternalCoach, setisCostingOnHomecareView} from "../services/UserService";
import FormValidator from "../utils/FormValidator";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo_img from "assets/img/logo.png";
import { Link } from "react-router-dom";
import {messaging, onMessageListener} from "../config/firebase"
import { v4 as uuidv4 } from 'uuid';

class Login extends Component {
  constructor(props) {
    super(props);
    this.validator = new FormValidator([
      {
        field: "emailAddress",
        method: "isEmpty",
        validWhen: false,
        message: "Email address is required."
      },
      {
        field: "emailAddress",
        method: "isEmail",
        validWhen: true,
        message: "Email address not valid."
      },
      {
        field: "password",
        method: "isEmpty",
        validWhen: false,
        message: "Password is required."
      }
    ]);

    this.state = {
      emailAddress: "",
      password: "",
      error: "",
      accessToken: "",
      refreshToken: "",
      loading: false,
      validation: this.validator.valid()
    };

    this.submitted = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    // clearData();
    const newDeviceId = uuidv4();
      console.log("device_id", newDeviceId);
      sessionStorage.setItem("device_id", newDeviceId);
  }

  handleChange(event) {
    const target = event.target;
    this.setState({ [target.name]: target.value });

    const error = this.state.error;
    if (error) {
      this.setState({ error: "" });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    const validation = this.validator.validate(this.state);
    this.submitted = true;
    this.setState({ validation: validation, error: "" });

    if (validation.isValid) {
      this.setState({ loading: true });

      API.post("token/", {
        email: this.state.emailAddress,
        password: this.state.password
      })
        .then(response => {
          const accessToken = response.data.access;
          const refreshToken = response.data.refresh;
          setAccessToken(accessToken);
          setRefreshToken(refreshToken);
          this.setState({
            error: "",
            accessToken: accessToken,
            refreshToken: refreshToken
          });
          API.get("users/")
            .then(res => {
             
              if (res.data[0].is_active) {
                if (res.data[0].is_homecare_professional) setUserType("homeCareProfessional"); //for nurse user
                else if (res.data[0].is_nutritionist_user && !res.data[0].is_external_coach_user){
                  setUserType("nutritionist");   //for coach user
                  setisExternalCoach(res.data[0].is_external_coach_user?? false)  //for external coach users
                }  
                 else if (res.data[0].is_nutritionist_user && res.data[0].is_external_coach_user){
                    setUserType("externalNutritionist")
                    setisExternalCoach(res.data[0].is_external_coach_user?? false)
                  } 
                else if (res.data[0].is_crm_user) setUserType("crm");
                else if (res.data[0].is_lab_user) setUserType("lab");
                else if 
                  (res.data[0].is_homecare_user) {
                  setUserType("homecare")
                  setisCostingOnHomecareView(res.data[0].is_costing_on_homecare??false);
                  }

                  //homecare user
                else if (res.data[0].is_superuser) setUserType("admin");

                else {
                  this.setState({
                    error: "Incorrect username or password.",
                    loading: false
                  });
                  // clearToken();
                }
                // debugger;

                if (res.data[0].email) setUserEmail(res.data[0].email);

                if (res.data[0].username) setUserName(res.data[0].username);
                else {
                  let userName = res.data[0].email.split("@");
                  setUserName(userName[0]);
                }

                if (res.data[0].id) setUserId(res.data[0].id);
                //only when user is logged in notification is registered through fcm token
                if (messaging != null) {
                  messaging.requestPermission()
                    .then(async function () {
                      const token = await messaging.getToken();
                      //register device
                      API.post("devices/", {
                        registration_id: token,
                        type: 'web'
                      }).then(() => {
                        console.log('Notifications are live')
                        onMessageListener()
                          .then((payload) => {
                            const { title, body } = payload.data;
                            console.log(`${title}; ${body}`);
                          })
                          .catch((err) => {
                            console.log(JSON.stringify(err));
                          });

                      }).catch((err) => {
                        console.log('register device failure', err);
                      })
                    })
                    .catch(function (err) {
                      console.log("Unable to get permission to notify.", err);
                    });
                }
                window.location.href="/dashboard/home";
              } else {
                this.setState({
                  error: "Incorrect username or password.",
                  loading: false
                });
                // clearToken();
              }
            })
            .catch(error => {
              console.log("Error on fetching users", error);
              this.setState({
                error: "Some error has occured. Please try again.",
                loading: false
              });
            });
        })
        .catch(error => {
          console.log("Error on saving token", error);
          if (error.response) {
            if (error.response.status === 401)
              this.setState({ error: "Incorrect username or password." });
            else
              this.setState({
                error: "Some error has occured. Please try again."
              });
          } else {
            this.setState({
              error: "Some error has occured. Please try again."
            });
          }

          this.setState({
            loading: false
          });
        });

        
    }
  }

  render() {
    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    return (
      <div className="login-container">
        <div className="login-form">
          <img src={logo_img} alt="logo" className="valeo-logo"></img>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label>Email address *</label>
              <input
                type="text"
                className={
                  "form-control " +
                  (validation.emailAddress.isInvalid && "has-error")
                }
                name="emailAddress"
                value={this.state.emailAddress}
                onChange={this.handleChange}
                placeholder="Your email address"
              />
              <span className="help-block">
                {validation.emailAddress.message}
              </span>
            </div>
            <div className="form-group">
              <label>Password *</label>
              <input
                type="Password"
                className={
                  "form-control " +
                  (validation.password.isInvalid && "has-error")
                }
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                placeholder="Password"
              />
              <span className="help-block">{validation.password.message}</span>
            </div>
            <button
              type="submit"
              className="form-control login-button button"
              disabled={this.state.loading ? true : false}
            >
              {this.state.loading ? (
                <CircularProgress
                  size={15}
                  className="valeo-loader"
                ></CircularProgress>
              ) : (
                  "Login"
                )}
            </button>
            <div>
              <Link
                className="form-control login-button button text-center"
                disabled={this.state.loading ? true : false}
                to={`/login/forgot-password/`}
                target="_blank"
              >
                Forgot Password
              </Link>
            </div>
            <FormError error={this.state.error}></FormError>
          </form>
        </div>
      </div>
    );
  }
}

function FormError(props) {
  let error = props.error;

  if (error) return <div className="mt-4 alert alert-danger">{error}</div>;

  return null;
}

export default Login;
