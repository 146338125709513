import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowDownward } from "@material-ui/icons";
import { Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import FormValidator from "utils/FormValidator";
import { isEmpty } from "utils/FormValidator";
import AlertBox from "components/AlertBox/AlertBox";
import SelectAgent from "../Orders/SelectAgent";
import Search from "components/Search/Search";
import { fetchOrders, saveOrder } from "services/OrderService";
import { fetchAdminUsers } from "services/UserService";
import { getOrderStatusWithColor } from "../Orders/OrderUtils";
import CreateOrderViewModal from "../Orders/CreateOrderViewModal";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { saveOrderStatusLogs } from "services/OrderService";
import {
  formatDate,
  formatTime,
  formatDateTime,
  compareStrings,
} from "utils/commons";
import { updateChildOrder, updateChildPastOrder,fetchOrder ,handleMokaffaRefundReturn } from "services/OrderService";
import Button from "react-bootstrap/Button";
import ModalPopup from "components/ModalPopup/ModalPopup";
import * as XLSX from 'xlsx';
import "./NewOrdersRevamp.scss";
import EditCustomerShippingAddress from "pages/Orders/EditCustomerShippingAddress";
import CustomLoader from "components/CustomLoader/CustomLoader";
import cancelReasonOptions from "../../utils/CancelReason";

const DEFAULT_ROWS_PER_PAGE = 50;
const ROWS_PER_PAGE_OPTIONS = [50, 150, 200, 250];
const BASE_URL_CITIES = "cities/";
const BASE_URL_COUNTRIES = "countries/";
const BASE_URL_Update_OrderNotes = "new-cart/update";
const BASE_URL_Update_ConfirmTime = "order-reschedule";
const OLD_BASE_URL_ORDER = "order-list/";
const BASE_URL_UPDATE_CART_ORDER_ADDRESS="/admin/update-cart-order-address";
const BASE_URL_SAMPLE_DETAILS_VALIDITY="/sample-details-validity";

const orderStatusList = [
  { text: "None", value: "", key: "" },
  {
    text: "Order Unassigned",
    value: 1,
    key: 1,
    status: "CREATED",
  },
  {
    text: "Order Assigned",
    value: 2,
    key: 2,
    status: "ASSIGNED",
  },
  {
    text:"Order Confirmed",
    value:3,
    key:3,
    status:"ORDER CONFIRMED"
  },
  {
    text:"HomeCare/Lab Confirmed",
    value:4,
    key:4,
    status:"LAB CONFIRMED"
  },
  {
    text:"Sample Collected by Home Care",
    value:5,
    key:5,
    status:"SAMPLE COLLECTED BY HOMECARE"
  },
  {
    text:"Report Received by Lab",  // sample recieved is changed to Report Received 
    value:6,
    key:6,
    status:"REPORT RECEIVED"
  },
  {
    text:"Partial Results Uploaded",
    value:7,
    key:7,
    status:"PARTIAL RESULTS UPLOADED TO APP"
  },
  {
    text:"Result Uploaded for Coach Review",
    value:8,
    key:8,
    status:"RESULTS UPLOADED TO APP"
  },
  {
    text:"Order Completed",
    value:9,
    key:9,
    status:"COMPLETED"
  },
  {
    text:"Order Cancelled",
    value:10,
    key:10,
    status:"CANCELLED"
  },
  {
    text:"ReCollection",
    value: 11,
    key: 11,
    status: "RECOLLECTION",
  }
];

const homeCareRating = [
  { text: "None", value: "", key: "" },
  {
    text: 1,
    value: 1,
    key: 1,
  },
  {
    text: 2,
    value: 2,
    key: 2,
  },
  {
    text: 3,
    value: 3,
    key: 3,
  },
  {
    text: 4,
    value: 4,
    key: 4,
  },
  {
    text: 5,
    value: 5,
    key: 5,
  },
];

const homeCareStatus = [
  { text: "None", value: "", key: "" },
  {
    text: "Not Assigned",
    value: 1,
    key: 1,
    status: "NOT ASSIGNED",
  },
  {
    text: "Assigned",
    value: 2,
    key: 2,
    status: "ASSIGNED",
  },
  {
    text: "Confirmed",
    value: 3,
    key: 3,
    status: "CONFIRMED",
  },
  {
    text: "Rescheduled",
    value: 4,
    key: 4,
    status: "RESCHEDULED",
  },
];

const userResponseGiven = [
  { text: "None", value: "", key: "" },
  {
    text: "Yes",
    value: 1,
    key: 1,
  },
  {
    text: "No",
    value: 2,
    key: 2,
  },
];

const BASE_URL_COUPONS = "coupon-code/summary";
class NewOrdersRevamp extends Component {
  constructor(props) {
    super(props);

    this.confirmvalidator = new FormValidator([
      {
        field: "confirmDate",
        method: "isEmpty",
        validWhen: false,
        message: "Date is required.",
      },
      {
        field: "confirmTime",
        method: "isEmpty",
        validWhen: false,
        message: "Time is required.",
      },
    ]);
  

    this.customConfirmValidator = {
      confirmDate: {
        message: null,
      },
      confirmTime:{
        message:null,
      }
    };

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      agents: [],
      orders: [],
      cities: [],
      countries: [],
      coupons: [],
      loadError: null,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "",
      sortDirection: "desc",
      searchTerm: null,
      searchApplied: false,
      isCreateOrderViewModalVisible: false,
      newOrders: [],
      red: false,
      blue: false,
      green: false,
      allOrders: false,
      reCollected:false,
      bucketList: [],
      order: {},
      labs: [],
      homeCareServices: [],
      columns: [],
      confirmvalidation: this.confirmvalidator.valid(),
      confirmDate: "",
      confirmTime: "",
      selectedBucket: "",
      clientConfirm: false,
      editConfirmTime: false,
      rescheduleConfirmTime: false,
      confirmTimeModalError: "",
      rescheduleReason: "",
      oldConfirmDate: "",
      oldConfirmTime: "",
      submitEnableConfirmTime: false,
      isrescheduleDateConfirmPopup:false,
      confirmDatePopup:false,
      isShowErrorModal:false,
      downloadOption:false,
      csvFormat: false,
      xlsFormat: false,
      cancelNotes:"",
      showCancellationModal:false,
      cancelError:"",
      errors:{},
      isEditCustomerShippingAddressViewModalVisible:false,
      shippingAddressModal:{},
      agentId:null,
      fullOrder:{},

     
  
      isLoading:false,
      cancelReason:"",
      dropdownOpen:false,
      cancelReasonError:"",
      showSampleDetailWarning:false,
      SampleDetailModalData:"",

      notifyClient:false

    };
    // this.columns = [
    //   {
    //     name: "Cart Order ID",
    //     selector: "id",
    //     sortField: "id",
    //     sortable: true,
    //     wrap: true,
    //   },
    //   {
    //     name: "Purchased On",
    //     selector: "created_at",
    //     sortField: "created_at",
    //     // sortable: true,
    //     wrap: true,
    //     format: (row) => (row.created_at ? formatDate(row.created_at) : "-"),
    //   },
    //   {
    //     name: "Purchaser",
    //     selector: "userName",
    //     sortField: "userName",
    //     wrap: true,
    //     sortable: false,
    //     format: (order) => this.getUserName(order),
    //   },
    //   {
    //     name: "Payment Mode",
    //     selector: "payment_method",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Total Price",
    //     selector: "final_price",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "City",
    //     selector: "city_name",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Country",
    //     selector: "country_name",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Child Order Id",
    //     selector: "child_order_id",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Client Name",
    //     selector: "client_name",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Package Name",
    //     selector: "package_name",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Order Status",
    //     selector: "order_status",
    //     wrap: true,
    //     // sortable: true,
    //     cell: (order) => this.renderOrderStatusDropdown(order),
    //   },
    //   {
    //     name: "Agent Assigned",
    //     selector: "agent_assigned",
    //     sortField: "agentId",
    //     wrap: true,
    //     sortable: false,
    //     minWidth: "200px",
    //     cell: (order) => this.renderAgentDropdown(order),
    //   },
    //   {
    //     name: "Appointment Date & Time",
    //     selector: "appointment_time",
    //     wrap: true,
    //     sortable: false,
    //     minWidth: "180px",
    //     cell: (order) => this.getAppointmentDateAndTime(order),
    //   },
    //   {
    //     name: "Confirmed Date & Time",
    //     selector: "confirmed_time",
    //     wrap: true,
    //     cell: (order) => this.getConfirmedDateAndTime(order),
    //   },
    //   {
    //     name: "Homecare",
    //     selector: "paymentMethod",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Homecare Confirmation Status",
    //     selector: "paymentMethod",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Lab",
    //     selector: "paymentMethod",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Details Sent To Lab",
    //     selector: "paymentMethod",
    //     wrap: true,
    //     // sortable: true,
    //     cell: (order) => this.renderDetailsSentToLab(order),
    //   },
    //   {
    //     name: "Lab Confirmation",
    //     selector: "paymentMethod",
    //     wrap: true,
    //     // sortable: true,
    //     cell: (order) => this.renderLabConfirmation(order),
    //   },
    //   {
    //     name: "Nurse Reached Check",
    //     selector: "paymentMethod",
    //     wrap: true,
    //     // sortable: true,
    //     cell: (order) => this.renderNurseReachedCheck(order),
    //   },
    //   {
    //     name: "User Response on Nurse Message",
    //     selector: "paymentMethod",
    //     wrap: true,
    //     // sortable: true,
    //   },
    //   {
    //     name: "Feedback msg Sent",
    //     selector: "paymentMethod",
    //     wrap: true,
    //     // sortable: true,
    //     cell: (order) => this.renderFeedBackMsg(order),
    //   },
    //   {
    //     name: "Feedback response",
    //     selector: "paymentMethod",
    //     wrap: true,
    //     // sortable: true,
    //     cell:(order)=>this.renderFeedBackResponse(order)

    //   },
    //   {
    //     name: "Thank you Msg",
    //     selector: "is_thankyou_msg_given",
    //     wrap: true,
    //     // sortable: true,
    //     cell: (order) => this.renderThankyouMsg(order),
    //   },
    //   {
    //     name: "Action",
    //     center: true,
    //     minWidth: "170px",
    //     cell: (order) => this.renderEditButton(order),
    //   },
    //   {
    //     name: "View",
    //     center: true,
    //     minWidth: "170px",
    //     cell: (order) => this.renderViewButton(order),
    //   },
    // ];
  }

  componentDidMount() {
    this.fetchAclUser();
    this.fetchAgents();
    // this.fetchOrders();
    this.fetchNewOrders();
    this.fetchBuckets();
    this.fetchCities();
    this.fetchCountries();
    this.fetchCoupons();
    this.loadLabList();
    this.homeCareServices();
    this.fetchNurses();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.CART_ORDERS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchAgents = async () => {
    try {
      const { data: agents = [] } = await PhoenixAPI.get(
        "/admin/operations-users"
      );
      const operationUsers = agents.map((user) => {
        return {
          email: user.email,
          firstName: user.fname,
          lastName: user.lname,
          id: user.id,
        };
      });
      this.setState({ agents: operationUsers });
    } catch (error) {
      this.showErrorNotification("Error on fetching agents: ", error.message);
    }
  };

  loadLabList = async () => {
    API.get("create-laboratory/")
      .then((response) => {
        const activeList = response.data; //filterList(response.data, "active");
        const activeLabs= activeList && activeList.filter((item)=>item.is_active)
        let newList = activeLabs.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        let labtListFormatted = newList.map((lab) => {
          return {
            text: lab.name,
            value: lab.id,
            key: lab.id,
          };
        });
        labtListFormatted.splice(0, 0, { text: "None", value: "", key: "" });
       
        this.setState({
          labs: response.data,
          labtListFormatted: labtListFormatted,
        });
      })
      .catch((error) => {
        console.log("Error on fetching laboratories", error);
      });
  };

  

  homeCareServices = async () => {
    try {
      const { data: services = [] } = await API.get("home-care-service/");
      const filteredServices = services.filter((it) => it.is_active === true);
      const homeCareServices = filteredServices.map((service) => {
        return {
          text: service.name,
          key: service.id,
          value: service.id,
        };
      });

      homeCareServices.splice(0, 0, { text: "None", value: "", key: "" });
      this.setState({ homeCareServices });
    } catch (error) {
      this.showErrorNotification("Error on fetching agents: ", error.message);
    }
  };


  fetchNurses = async () => {
    try {
      const { data: nurses = [] } = await PhoenixAPI.get("nurse/all");

      const nursesList = nurses.map((service) => {
        return {
          text: service.fullName,
          key: service.professionalId,
          value: service.professionalId,
        };
      });

      nursesList.splice(0, 0, { text: "None", value: "", key: "" });

      this.setState({ nursesList, nurses });

    } catch (error) {
      this.showErrorNotification("Error on fetching nurses: ", error.message);
    }
  };

  getSampleDetails=async(order)=>{
    try{
       const {data}= await PhoenixAPI.get(`${BASE_URL_SAMPLE_DETAILS_VALIDITY}/${order}`);
       
        return data;
       
       
    }
    catch(error){
      this.showErrorNotification("Error on fetching sample details", error.message);
    }
  }

  handleBroadCastingOrder=async(order)=>{
 
   const {child_order_id=null, confirmed_time=null, user_id,city_id,address_id=null,is_sample_validated}= order || {};
    if(!child_order_id){
      this.showErrorNotification("Error on broadcasting order", "Please select order to broadcast");
      return;
    }
    if(!confirmed_time){
      this.showErrorNotification("Please fill confirm date and time first");
      return;
     }
     const {data:userCityAddress=[]}= await PhoenixAPI.get(`user-address/${user_id}/${city_id}`)
     if(address_id==null){
          
          this.setState({
           order:
           {...order,countryId:order.country_id,cityId:order.city_id, 
             userDetails:{userName:order.client_name,email:order.email}, 
             orderUUID:order.order_uuid},
             isEditCustomerShippingAddressViewModalVisible:true,
           shippingAddressModal:{addressLine1:null,addressLine2:null,buildingApt:null, mapUrl:null}, userCityAddress:userCityAddress})
           return;
     }
    try{
     this.showLoading();
    /* const addedAllSampleDetails= await this.getSampleDetails(child_order_id);
     if(addedAllSampleDetails.statusCode==400 && (is_sample_validated==null && !is_sample_validated))

     {
      console.log("please",addedAllSampleDetails?.message);
      const result ="please add sample details for below\n"+ addedAllSampleDetails?.message;
      this.setState({SampleDetailModalData: result,showSampleDetailWarning:true});
      return;
     }
     else if(!is_sample_validated || is_sample_validated==null){
      const result="please check sample validity";
      this.setState({SampleDetailModalData: result,showSampleDetailWarning:true});
      return;

     }

  

     }*/
const response= await PhoenixAPI.post(`send-broadcast-basket-v3?orderId=${child_order_id}`);

     this.fetchNewOrders();
     this.showSuccessNotification("Order is Broadcasted successfully");

    }
    catch(error){
      this.showErrorNotification("Error on broadcasting order", error.message);
      console.log("Error on broadcasting order", error.message)
    }
    finally{
      this.hideLoading();
    }

  }

  showManuallyAssignModal=async(order)=>{
   const { child_order_id,  order_status, client_name, package_name, homecare_name, professional_id,homecare_status, user_id,city_id,address_id=null } = order || {};
   const {data:userCityAddress=[]}= await PhoenixAPI.get(`user-address/${user_id}/${city_id}`)
   if(address_id==null){
        
        this.setState({
         order:
         {...order,countryId:order.country_id,cityId:order.city_id, 
           userDetails:{userName:order.client_name,email:order.email}, 
           orderUUID:order.order_uuid},
           isEditCustomerShippingAddressViewModalVisible:true,
         shippingAddressModal:{addressLine1:null,addressLine2:null,buildingApt:null, mapUrl:null}, userCityAddress:userCityAddress})
         return;
   }
    this.setState({
      showManuallyAssignModal:true,
      order,
    });
  }
  renderHomeCareOptions=(order)=>{
    const {is_broadcast_basket_show=false}= order || {}
    return (
    <div className="">
      <div>
        <button
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
          onClick={()=>this.handleBroadCastingOrder(order)}
          disabled={((!is_broadcast_basket_show)|| order.is_recollection_order)}
        >
          Broadcasting
        </button>
      </div>
      
    </div>)
  }
  homeCareNurse=(order)=>{ 
        const{}  ={order}|{}
        const {  homecare_name = "" } = order || {};
    const{homeCareServices,nurses}=this.state;

    
    let homeCareNursesList;
    if(homecare_name)
    {
     const homeCareId= homeCareServices.find((homecare)=>homecare.value==homecare_name);
 const homeCareNurses=  nurses.filter((nurse)=>nurse.homeCareServiceId==homecare_name);
  homeCareNursesList = homeCareNurses.map((homeCareNurse) => {
  return {
    text: homeCareNurse.fullName,
    key: homeCareNurse.professionalId,
    value: homeCareNurse.professionalId,
  };
  });
  homeCareNursesList.splice(0, 0, { text: "None", value: "", key: "" });
 
  }

  return (
    <select
      className="form-control"
      onChange={(e) => this.handleNurseSelect(e.target.value, order)}
      value={homecare_name?order.professional_id?order.professional_id:"":""}
    >
      {homecare_name? homeCareNursesList.map((status, index) => (
          <option key={index} value={status.value}>
            {status.text}
          </option>
        )):this.state.nursesList &&
        this.state.nursesList.map((status, index) => (
          <option key={index} value={status.value}>
            {status.text}
          </option>
        ))
       
        }
    </select>
  );
}

  // fetchOrders = async (page = 1) => {
  //   const {
  //     rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  //     sortField,
  //     sortDirection,
  //     searchApplied,
  //     searchTerm,
  //   } = this.state;
  //   const config = {
  //     page,
  //     rowsPerPage,
  //     sortField,
  //     sortDirection,
  //     searchApplied,
  //     searchTerm: searchTerm ? searchTerm.trim() : searchTerm,
  //   };
  //   this.showLoading();
  //   try {
  //     const { count: totalRows, results: orders } =
  //       (await fetchOrders(config)) || [];

  //     this.setState({ totalRows, orders });
  //   } catch (error) {
  //     this.showErrorNotification("Error on fetching orders: ", error.message);
  //     this.setState({ loadError: "Some error has occured. Please try again" });
  //   } finally {
  //     this.hideLoading();
  //   }
  // };

  fetchNewOrders = async (page = 0, orderStatus = "UNASSIGNED", agentId, sortStatus="CREATED_AT") => {
    const {
      rowsPerPage = DEFAULT_ROWS_PER_PAGE,
      sortField,
      sortDirection,
      searchApplied,
      searchTerm,
      selectedStartDate,
      selectedEndDate,
      userId,
      timeRangeUpperLimit,
      selectedBucket,
      reCollected
    } = this.state;

    if(orderStatus==="ASSIGNED"){
      sortStatus="APPOINTMENT_TIME"
    }
    else if(orderStatus==="ALL_ORDERS"){
      sortStatus="CREATED_AT"
    }
    else if(orderStatus!="UNASSIGNED"){
      sortStatus="CONFIRMED_TIME"
    }



    if(sortField==="created_at"){
      sortStatus="CREATED_AT";
      //  orderStatus=selectedBucket
    }
    else if(sortField==="appointment_time"){
      sortStatus="APPOINTMENT_TIME";
      // orderStatus=selectedBucket;
    }
    else if(sortField==="confirmed_time"){
      sortStatus="CONFIRMED_TIME";
      // orderStatus=selectedBucket
    }

    const params =reCollected?{
      page,
      page_size: rowsPerPage,
      ordering: sortDirection == "desc" ? `-${sortStatus}` : sortStatus,
      
      agentId: agentId,
      recollectionOrders:reCollected

    } :{
      page,
      page_size: rowsPerPage,
      ordering: sortDirection=="desc" ?`-${sortStatus}`:sortStatus,
      orderStatus: orderStatus,
      agentId: agentId,
      recollectionOrders:reCollected
    };
    if (selectedStartDate)
      params.fromDate = moment(selectedStartDate).format("YYYY-MM-DD");
    if (selectedEndDate)
      params.toDate = moment(selectedEndDate).format("YYYY-MM-DD");

    if (searchApplied) params.search = searchTerm ? searchTerm.trim() : searchTerm;

    if (timeRangeUpperLimit) params.timeRangeUpperLimit = timeRangeUpperLimit;
    try {
      this.showLoading();
      const { data } = await PhoenixAPI.get(`/admin/v2/cart-orders`, {
        params,
      });

      const { count, results = [] } = data;
      const totalRows = count;

      this.setState(
        {
          totalRows,
          newOrders: results??[],
          order: {},
          selectedBucket: orderStatus,
        },
        () => this.fetchBuckets()
      );
    } catch (error) {
      console.log("Error in Fetching  Orders");
      this.showErrorNotification(
        "Some Error occured in fetching orders:",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchBuckets = async () => {
    try {
      this.showLoading();
      const { data = [] } = await PhoenixAPI.get(`admin/v2/status-count`);

      this.setState({ bucketList: data });
    } catch (error) {
      console.log("Error in Fetching  filtered Orders");
      this.showErrorNotification(
        "Some Error occured in fetching filtered orders:",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };
  handleHideEditCustomerShippingAddressViewModal=()=>{
   this.setState( {isEditCustomerShippingAddressViewModalVisible:false,
      shippingAddressModal:null})
  }

  hideSampleDetailWarning=()=>{
    this.setState({ showSampleDetailWarning: false,SampleDetailModalData:"" });
  }

  


  fetchCities = async () => {
    try {
      const { data: cities = [] } = await API.get(BASE_URL_CITIES);
      this.setState({ cities });
    } catch (error) {
      console.log("Error in fetching cities", error);
    }
  };

  fetchCountries = async () => {
    try {
      const { data: countries = [] } = await API.get(BASE_URL_COUNTRIES);
      this.setState({ countries });
    } catch (error) {
      console.log("Error in fetching countries", error);
    }
  };

  fetchCoupons = async () => {
    try {
      const { data: coupons = [] } = await PhoenixAPI.get(BASE_URL_COUPONS);
      this.setState({ coupons });
    } catch (error) {
      console.log("Error in fetching coupons", error);
    }
  };

  newOrdersColumns = () => {
    return [
      {
        name: "Order ID",
        selector: "order_uuid",
        sortField: "id",
        sortable: true,
        wrap: true,
        center:true,
        format: (order) => {
          const { child_order_id } = order || {};
          return (
            <>
              <Link
                id={order.order_uuid}
                to={`/dashboard/orders/newOrder/${order.id}`}
                target="_blank"
              >
                {order.order_uuid ?? ""}
              </Link>
            </>
          );
        },
      },
      {
        name: "Purchased On",
        selector: "created_at",
        sortField: "created_at",
        sortable: true,
        wrap: true,
        center:true,
        format: (row) => (row.created_at ? formatDate(row.created_at) : "-"),
      },
      {
        name: "Purchaser",
        selector: "userName",
        sortField: "userName",
        wrap: true,
        sortable: false,
        center:true,
        format: (order) => this.getUserName(order),
      },
      {
        name: "Client Details",
        selector: "whatsapp_number",
        wrap: true,
        minWidth: "170px",
        center: true,
        cell: (order) => this.renderClientdetails(order),
      },
      {
        name: "Payment Mode",
        selector: "payment_method",
        wrap: true,
        center:true,
        // sortable: true,
      },
      {
        name: "Total Price",
        selector: "final_price",
        wrap: true,
        center:true,
        // sortable: true,
      },
      {
        name: "Payment Status",
        selector: "payment_status",
        wrap: true,
        center: true,
        // sortable: true,
      },
      {
        name: "City",
        selector: "city_name",
        wrap: true,
        center:true,
        // sortable: true,
      },
      // {
      //   name: "Country",
      //   selector: "country_name",
      //   wrap: true,
      //   // sortable: true,
      // },
      {
        name: "Child Order Id",
        selector: "child_order_id",
        wrap: true,
        center:true,
        // sortable: true,
      },
      {
        name: "Client Name",
        selector: "client_name",
        wrap: true,
        center:true,
        // sortable: true,
      },
      {
        name: "Package Name",
        selector: "package_internal_name",
        wrap: true,
        center:true,
        cell: (order) => this.renderPackageName(order),
        // sortable: true,
      },
      {
        name: "Quantity",
        selector: "quantity",
        wrap: true,
        center:true,
        // sortable: true,
      },
      {
        name:"Discount Coupon",
        selector:"coupon_code",
        wrap:true,
        center: true,
        // sortable: true,
      },
      {
        name: "Order Status",
        selector: "order_status",
        wrap: true,
        minWidth: "200px",
        center:true,
        format: (order) => (
          <select
            className="form-control"
            onChange={(e) => this.handleOrderStatus(e.target.value, order)}
            value={order.order_status}
            disabled={order.order_status === "10"}
          >
            {orderStatusList &&
              orderStatusList.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.text}
                </option>
              ))}
          </select>
        ),
      },
      {
        name: "Agent Assigned",
        selector: "agent_assigned",
        sortField: "agentId",
        wrap: true,
        sortable: false,
        minWidth: "200px",
        center:true,
        cell: (order) => this.renderAgentDropdown(order),
      },
      {
        name: "Appointment Date & Time",
        selector: "appointment_time",
        wrap: true,
        sortable: true,
        minWidth: "180px",
        center:true,
        // format: (row) => row.appointment_time ? row.appointment_time:"",
        cell: (row) => this.renderAppoinmentDate(row),
      },
      {
        name: "Confirmed Date & Time",
        selector: "confirmed_time",
        wrap: true,
        minWidth: "180px",
        sortable:true,
        center:true,
        format: (row) =>
          row.confirmed_time ? (
            <>
              {formatDateTime(row.confirmed_time)}
              <button
                className="btn button button-green btn-sm"
                onClick={() => this.showConfirmTime(row)
                }
                disabled={row.order_status==="8" || row.order_status==="9" || row.order_status==="10"}

              >Confirm Date & Time</button>
            </>
          ) : (
            <button
              className="btn button button-green btn-sm"
              onClick={() => this.showConfirmTime(row)}
            >
              Confirm Date & Time
            </button>
          ),
      },
      {
        name: "Op's Order Notes",
        selector: "order_notes",
        // sortable: true,
        sortField: "order_notes",
        wrap: true,
        center:true,
        format: (row) =>
          row.order_notes ? (
            <div>
              <div className="orderNotes">{row.order_notes}</div>

              <EditIcon
                className="edit-icon"
                onClick={() => this.showNoteAssignModal(row)}
              ></EditIcon>
            </div>
          ) : (
            <button
              className="btn button button-green btn-sm"
              onClick={() => this.showNoteAssignModal(row)}
            >
              Add Notes
            </button>
          ),
      },
     {
        name:"Client Notes",
        selector: "client_note",
        // sortable: true,
        sortField: "client_note",
        wrap: true,
        minWidth:"250px",
        center: true,
        format:(row)=> row.client_note? <div>
              <div className="orderNotes" style={{ fontWeight: "bold" }}>{row.client_note}</div></div>:"",

      },
      {
        name: "Previous Homecare, Nurse, Lab",
        selector: "",
        // sortable: true,
        sortField: "",
        wrap: true,
        center: true,
        minWidth: "220px",
        cell:(row)=>this.renderPreviousHomecare(row)
      },
      {
        name: "Homecare",
        selector: "homecare_name",
        wrap: true,
        minWidth: "210px",
        center: true,
       
       cell:(row)=>(row.homecare_name?row.homecare_name:"-")  
        // format: (order) => {
        //   return (
        //     <select
        //       className="form-control"
        //       onChange={(e) => this.handleServiceSelect(e.target.value, order)}
        //       value={order.homecare_name?order.homecare_name:""}
        //       disabled={true}
        //     >
        //       {this.state.homeCareServices &&
        //         this.state.homeCareServices.map((status, index) => (
        //           <option key={index} value={status.value}>
        //             {status.text}
        //           </option>
        //         ))}
        //     </select>
        //   );
        // },
      },
      {
        name: "Broadcast Homecare",
        selector: "homecare_name",
        wrap: true,
        // sortable: true,
        minWidth: "210px",
        center: true,
        // cell: (order) => this.renderServiceDropdown(order),
        // format: (order) => {
        //   return (
        //     <select
        //       className="form-control"
        //       onChange={(e) => this.handleServiceSelect(e.target.value, order)}
        //       value={order.homecare_name?order.homecare_name:""}
        //     >
        //       {this.state.homeCareServices &&
        //         this.state.homeCareServices.map((status, index) => (
        //           <option key={index} value={status.value}>
        //             {status.text}
        //           </option>
        //         ))}
        //     </select>
        //   );
        // },
         cell:(order)=>this.renderHomeCareOptions(order)
      },
      {
        name: "Lab",
        selector: "lab_name",
        wrap: true,
        center:true,
        // sortable: true,
        minWidth: "200px",
        cell:(row)=>(row.lab_name?row.lab_name:"-")  

      },
      // {
      //   name: "HomeCare Costs",
      //   selector: "home_care_cost",
      //   sortField: "home_care_cost",
      //   wrap: true,
      //   minWidth: "160px",
      //   center: true,
      //   format: (row) =>{
      //     return (
      //       <>
      //        <div className="lab_cost" style={{textAlign:"center"}}>{row.homecare_name || row.home_care_cost?row.home_care_cost ?? "-":"-"}</div>
      //         {row.home_care_cost!==null || row.home_care_cost!==undefined ? (
      //           row.homecare_name?
      //             <div>
      //             <EditIcon
      //               className="edit-icon"
      //               onClick={() => this.showPayNurseModal(row)}
      //             ></EditIcon>
      //           </div>:""
      //         ) : (
      //           <button
      //             className="btn button button-green btn-sm"
      //             onClick={() => this.showPayNurseModal(row)}
      //           >
      //             Add HomeCare Costs
      //           </button>
      //         )}
      //         {row.homecare_cost_notes ?
      //           <div style={{ marginTop: "4px" }}>
      //             <div className="home_care_cost">{row.homecare_cost_notes}</div>
                  
      //             <>
      //             <EditIcon
      //               className="edit-icon"
      //               onClick={() => this.showHomecareCostModal(row)}
      //             ></EditIcon>
      //             </>
      //           </div> :
      //           ""}
      //       </>
      //     )
         
      //   }
         
      // },
      // {
      //   name: "Lab Costs",
      //   selector: "lab_cost",
      //   sortField: "lab_cost",
      //   wrap: true,
      //   center: true,
      //   minWidth: "160px",
      //   format: (row) => {

      //     return (
      //       <>
      //         <div className="lab_cost" style={{textAlign:"center"}}>{row?.lab_cost ?? "-"}</div>
      //         {row.lab_cost_notes ?
      //           <div>
      //             <div className="home_care_cost">{row.lab_cost_notes}</div>

      //             <EditIcon
      //               className="edit-icon"
      //               onClick={() => this.showLabCostModal(row)}
      //             ></EditIcon>
      //           </div> :
      //           <div>
      //             <button
      //               className="btn button button-green btn-sm"
      //               onClick={() => this.showLabCostModal(row)}
      //             >
      //               Add Lab Costs Notes
      //             </button>
      //           </div>}
      //       </>
      //     )

      //   }

      // },
      {
        name: "Special Instructions",
        selector: "special_instructions",
        sortField: "special_instructions",
        wrap: true,
        minWidth:"200px",
        center:true,
        format: (row) =>
          row.special_instructions ? (
            <div>
              <div className="orderNotes">
                {row.special_instructions}
              </div>

              <EditIcon
                className="edit-icon"
                onClick={() => this.showSpecialInstructionsModal(row)}
              ></EditIcon>
            </div>
          ) : (
            <button
              className="btn button button-green btn-sm"
              onClick={() => this.showSpecialInstructionsModal(row)}
            >
              Add Special Instructions
            </button>
          ),
      },
      {
        name: "Details Sent To Lab",
        selector: "paymentMethod",
        wrap: true,
        // sortable: true,
        center:true,
        cell: (order) => this.renderDetailsSentToLab(order),
      },
    
      {
        name: "Nurse Reached Check",
        selector: "paymentMethod",
        wrap: true,
        // sortable: true,
        center:true,
        cell: (order) => this.renderNurseReachedCheck(order),
      },
      {
        name: "User Response on Nurse Message",
        selector: "is_response_given",
        wrap: true,
        // sortable: true,
        center:true,
        minWidth: "170px",
        format: (row) => (
        
          <select
            className="form-control"
            onChange={(e) => this.assignUserResponse(e.target.value, row)}
            value={row.is_response_given!==null || undefined? (row.is_response_given === true ? 1 : 2):""}
          >
            {userResponseGiven.length > 0 &&
              userResponseGiven.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.text}
                </option>
              ))}
          </select>
        ),
      },
      {
        name: "Feedback msg Sent",
        selector: "paymentMethod",
        wrap: true,
        center:true,
        // sortable: true,
        cell: (order) => this.renderFeedBackMsg(order),
      },
      {
        name: "Feedback response",
        selector: "homecare_rating",
        wrap: true,
        center:true,
        minWidth: "170px",
        format: (row) => (
          <select
            className="form-control"
            onChange={(e) => this.assignRating(e.target.value, row)}
            value={row.homecare_rating}
          >
            {homeCareRating.length > 0 &&
              homeCareRating.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.text}
                </option>
              ))}
          </select>
        ),
      },
      {
        name: "Thank you Msg",
        selector: "is_thankyou_msg_given",
        wrap: true,
        center:true,
        // sortable: true,
        cell: (order) => this.renderThankyouMsg(order),
      },
      // {
      //   name: "Action",
      //   center: true,
      //   minWidth: "170px",
      //   cell: (order) => this.renderEditButton(order),
      // },
      {
        name: "View",
        center: true,
        minWidth: "170px",
        cell: (order) => this.renderViewButton(order),
      },
    ];
  };
  
  handleSort = (column, sortDirection) => {

    const {selectedBucket=""}= this.state || {}
    const page=0;


    this.setState(
      {
        sortField: column.sortField || column.selector,
        sortDirection: sortDirection,
        newOrders:[]
      },
      () => this.fetchNewOrders(page,selectedBucket )
    );
  };

  handlePageChange = (page) => {
    // this.fetchOrders(page);
    const modifiedPage = page - 1;
    const { selectedBucket = "" } = this.state;
    this.fetchNewOrders(modifiedPage, selectedBucket);
  };

  handleRowsPerPageChange = (newRowsPerPage, page) => {
    console.log("OrdersDashboard:: handleRowsPerPageChange:", {
      newRowsPerPage,
      page,
    });
    const { selectedBucket = "" } = this.state;
    // this.setState({ rowsPerPage: newRowsPerPage }, () =>
    //   this.fetchOrders(page)
    // );
    const modifiedPage = page - 1;
    this.setState({ rowsPerPage: newRowsPerPage }, () =>
      this.fetchNewOrders(modifiedPage, selectedBucket)
    );
  };

  handleCreateOrder = () => {
    this.showCreateOrderViewModal();
  };

  handleShowOrders = () => {
    const page = 0;
    const { selectedBucket = "" } = this.state;
    const orderStatus = selectedBucket;
    this.setState({ newOrders: [] });
    this.fetchNewOrders(page, orderStatus);
  };
  handleSaveOrder = () => {
    console.log("OrdersDashboard: handleSaveOrder");
    this.setState({ isCreateOrderViewModalVisible: false }, () =>
      window.location.reload(false)
    );
  };

  handleSearch = (queryString) => {
    const searchTerm = queryString ?? "";
    // this.setState({ searchApplied: true, searchTerm });
    this.setState({ searchTerm });
  };

  handleSearchEnter = () => {
    const page = 0;
    const orderStatus = "ALL_ORDERS";
    this.setState({ searchApplied: true, newOrders: [] }, () =>
      this.fetchNewOrders(page, orderStatus)
    );
  };

  handleSearchClear = () => {
    const page = 0;
    const orderStatus = "UNASSIGNED";
    this.setState(
      { searchTerm: "", searchApplied: false, newOrders: [] },
      () => {
        this.fetchNewOrders(page, orderStatus);
      }
    );
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  validateOrderStatus = (status, order) => {
    console.log("Order:", order);
    const {
      order_item_type: itemType = "",
      operation_type: operationType = "",
      doctors_note: doctorsNote = "",
      doctors_note_ar: doctorsNoteAr = "",
      is_reviewed = false,
      is_result_uploaded: isResultUploaded = false,
      lab_result: isAttachmentUploaded = false,
      nutritionist_id: nutritionist = null,
    } = order || {};
    const orderStatus = status;
    const areResultsRequired =
      itemType === "Blood_Package" ||
      itemType === "Mini_Package" ||
      (itemType === "Custom_Package" &&
        operationType &&
        operationType.includes("PDF Download"));
    const isCoachReviewRequired =
      itemType === "Blood_Package" ||
      itemType === "Mini_Package" ||
      (itemType === "Custom_Package" &&
        operationType &&
        operationType.includes("Requires Coach Note"));
    if (
      [
        "COMPLETED",
        "Order Completed",
        "COACH_REVIEWED",
        "COACH REVIEWED",
        "Coach Reviewed",
      ].includes(orderStatus)
    ) {
      if (areResultsRequired && !isResultUploaded)
        return "Upload results to mark as completed!";

      if (
        isCoachReviewRequired &&
        (nutritionist === null || nutritionist === undefined)
      )
        return "Assign a Wellbeing coach to mark as completed!";

      if (
        isCoachReviewRequired &&
        nutritionist &&
        (!doctorsNote || !doctorsNoteAr)
      )
        return "Add Coach review's to mark as completed!";
    }

    if (
      orderStatus &&
      [
        "RESULTS UPLOADED",
        "RESULTS UPLOADED TO APP",
        "RESULTS_UPLOADED_TO_APP",
        "PARTIAL RESULTS UPLOADED TO APP",
      ].includes(orderStatus.toUpperCase())
    ) {
      console.log("validateOrderStatus::", {
        orderStatus,
        isAttachmentUploaded,
      });
      if (!isAttachmentUploaded) return "Kindly upload lab reports first";
    }
    return null;
  };
  hideCancelModal=()=>{
    console.log(" cancel order close");
    this.setState({showCancellationModal:false,cancelNotes:"",cancelError:"",cancelReasonError:"",cancelReason:"",order:{}})
  }

  handleOrderStatus = async (value, order) => {
    if (order.payment_method === "COD_MAMOPAY" && order.order_status <= 3 && (Number(value) > 3 && Number(value)<10) && !order.transaction_reference){
      this.setState({
        isShowErrorModal:true,
      })
      return;
    }

    if(order.is_draft_order){
     const isOrderStatusCheck=order.order_status <= 3 && (Number(value) > 3 && Number(value)<10) && (!order.transaction_reference)
     if(isOrderStatusCheck){
      this.setState({
        isShowErrorModal:true,
      })
      return;
     }
    }
    
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();
    const page = 0;
    const orderStatus = selectedBucket;
    const { child_order_id } = order || {};
    const prevStatus=order.order_status;

    const result = orderStatusList.filter((it) => it.key == value);
 

    const status = result[0].status;
  
    const orderStatusErrorMessage = this.validateOrderStatus(status, order);

    if (orderStatusErrorMessage) {
      this.showErrorNotification(orderStatusErrorMessage);
      return;
    }
    const payload = {
      modified_by: modifiedBy,
      order_status: status,
    };
    try {
      if(status=="CANCELLED" ) {
               
      this.setState({showCancellationModal:true,order})
        
      }
      else{
      const response = await updateChildOrder(child_order_id, payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders(page, orderStatus);
      }
    } catch (error) {
      console.log("Error in updating Orders");
      this.showErrorNotification(
        "Some Error occured in updating  orders:",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleAgentSelect = async (order, agentId) => {
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();
    const page = 0;
    const orderStatus = selectedBucket;
    const { child_order_id, order_status, user_id,city_id , address_id=null} = order || {};
    
    // const fullOrder= await fetchOrder(order.id);
    // const{addressDetails=null}=fullOrder;
    const {data:userCityAddress=[]}= await PhoenixAPI.get(`user-address/${user_id}/${city_id}`)
    if(address_id==null){
         
         this.setState({
          order:
          {...order,countryId:order.country_id,cityId:order.city_id, 
            userDetails:{userName:order.client_name,email:order.email}, 
            orderUUID:order.order_uuid},
          agentId:agentId,isEditCustomerShippingAddressViewModalVisible:true,
          shippingAddressModal:{addressLine1:null,addressLine2:null,buildingApt:null, mapUrl:null}, userCityAddress:userCityAddress})
    }
    else{
      const payload = {
        modified_by: modifiedBy,
        agent_assigned: agentId,
        
      };
  
      try {
        this.showLoading();
        const isPastOrder = order_status === "9" || order_status === "10";
        const response = isPastOrder
          ? await updateChildPastOrder(child_order_id, payload)
          : await updateChildOrder(child_order_id, payload);
        console.log("response:", response);
        this.showSuccessNotification("Order is Updated Successfully");
        this.fetchNewOrders(page, orderStatus);
      } catch (error) {
        console.log("Error in updating Orders");
        this.showErrorNotification(
          "Some Error occured in updating  orders:",
          error.message
        );
      } finally {
        this.hideLoading();
      }

    }
  };
  handleSaveShippingAddress=async(shippingAddress) => {
    const { order,agentId ,fullOrder} = this.state;
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();
    const page = 0;
    if (!order) {
      console.log("saveCustomerShipping Address:: Invalid order:", order);
      return;
    }
    const {addressLine1,addressLine2,buildingApt,cityId,countryId,mapUrl, addressId}=shippingAddress ?? {};
    const{user_id,id}=order;
    const orderStatus = selectedBucket;
    const { child_order_id, order_status } = order || {};
    const address={
      userId:user_id,
      addressLine1:addressLine1,
      addressLine2:addressLine2,
      buildingApt:buildingApt,
      cityId:cityId,
      countryId:countryId,
      defaultAddress:false,
      mapUrl:mapUrl,
      id:addressId
    }
    

    const payload = {
      modified_by: modifiedBy,
      agent_assigned: agentId,
      
    };

    try {

      

      this.showLoading();
      const responseAddress=await PhoenixAPI.patch(`${BASE_URL_UPDATE_CART_ORDER_ADDRESS}/${id}`, address);
      const isPastOrder = order_status === "9" || order_status === "10";
      // const response = isPastOrder
      //   ? await updateChildPastOrder(child_order_id, payload)
      //   : await updateChildOrder(child_order_id, payload);
      // console.log("response:", response);
      this.handleHideEditCustomerShippingAddressViewModal();
      this.showSuccessNotification("Order is Updated Successfully");
      
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error in updating Orders");
      this.showErrorNotification(
        "Some Error occured in updating  orders:",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  }


  handleServiceSelect = async (serviceId, order) => {
    
     this.setState({order:{...order, homecare_name:serviceId, professional_id:null, homecare_status:""}})
    // const { selectedBucket = "" } = this.state || {};
    // const modifiedBy = getUserId();
    // const page = 0;
    // const orderStatus = selectedBucket;
    // const { child_order_id, order_status } = order || {};
    // const payload = {
    //   modified_by: modifiedBy,
    //   home_care_id: serviceId,
    // };

    // try {
    //   this.showLoading();
    //   const isPastOrder = order_status === "9" || order_status === "10";
    //   const response = isPastOrder
    //     ? await updateChildPastOrder(child_order_id, payload)
    //     : await updateChildOrder(child_order_id, payload);
    //   // console.log("response:", response);
    //   this.hideManuallyAssignModal()
    //   await this.fetchNewOrders(page, orderStatus);
    //   this.homeCareNurse(order);
    //   this.showSuccessNotification("Order is Updated Successfully");
    // } catch (error) {
    //   console.log("Error in updating Orders");
    //   this.showErrorNotification(
    //     "Some Error occured in updating  orders:",
    //     error.message
    //   );
    // } finally {
    //   this.hideLoading();
    // }
  };


  handleNurseSelect = async (nurseId, order) => {
    const { nursesList, nurses, selectedBucket = "", homeCareServices=[]} = this.state || {};
     const selectedNurse = nurses.filter(
      (item) => item.professionalId == nurseId
    );

    const nurseObject = selectedNurse[0] || {};
    const orderStatus = selectedBucket;
    const { homecare_name: homeCareId = null, child_order_id, confirmed_time=null } = order || {};
 
    const selectedHomeCareService=homeCareServices.filter((it)=>it.value==homeCareId)
    

    const { homeCareServiceId } = nurseObject || {};

    const homcareName=selectedHomeCareService.length>0 && selectedHomeCareService[0].text
     if(!confirmed_time){
      this.showErrorNotification("Please fill confirm date and time first");
      return;
     }
     if (!homeCareId) {
      this.showErrorNotification("Please select a Homecare first then select the Nurse again");
      return;
    } 
    
    /*else if (parseInt(homeCareId) !== homeCareServiceId) {
      this.showErrorNotification(`Please choose different nurse, this nurse doesn't belong to valeo ${homcareName}`);
      return;
    }*/
    this.setState({order:{...order, professional_id:nurseId}})
   

    // const payload = {
    //   homecareId: homeCareId,
    //   orderId: child_order_id,
    //   professionalId: nurseObject?.professionalId || null ,
    //   userId: parseInt(modifiedBy) ?? null,
    // };
    // try {
    //   this.showLoading();
    //   const response = await PhoenixAPI.post("nurse/assign-nurse", payload);
    //   console.log("response:", response);
      
    // //  await this.fetchNewOrders(page, orderStatus);
    //   console.log("checking proffessional id",nurseObject?.professionalId)
    //  if(nurseObject?.professionalId !=="" && nurseObject?.professionalId !==null && nurseObject?.professionalId !==undefined )

    //   this.showSuccessNotification("Nurse is Assigned Successfully");
    //   else{
    //     this.showSuccessNotification("Order Updated Successfully");
    //   }
    //   this.hideManuallyAssignModal()
    // } catch (error) {
    //   console.log("Error in assigning nurse");
    //   this.showErrorNotification(
    //     "Some Error occured in updating  orders:",
    //     error.message
    //   );
    // } finally {
    //   this.hideLoading();
    // }
  };

  validate = () => {
    const { order } = this.state;
    const errors = {};
    const { child_order_id, homecare_name: homeCareId = null, professional_id: nurseId = null, order_status, homecare_status: homecarestatus = null } = order || {};
    if (!homeCareId) {
      errors.homecare = "Please select a Homecare";
    }
    // if (!nurseId) {
    //   errors.nurse = "Please select a Nurse";
    // }
    if (!homecarestatus) {
      errors.homecarestatus = "Please select a Homecare Status";
    }
    return Object.keys(errors).length === 0 ? null : errors;
  }
  
  handleManuallyAssign = async () => {
    const { order, nurses, selectedBucket = "" } = this.state || {}
    const { child_order_id, homecare_name: homeCareId = null, professional_id: nurseId = null, order_status, homecare_status: homecarestatus = null } = order || {};

    const page = 0;
    const orderStatus = selectedBucket;
    // const errors = this.validate()
    // this.setState({ errors: errors || {} });
    // if (errors) {
    //   this.showErrorNotification("There are some invalid form fields!");
    //   return;
    // }

    const result = homeCareStatus.filter((it) => it.key == homecarestatus) ?? null;

    const status = result[0]?.status ?? null;
    const modifiedBy = getUserId();
    const isPastOrder = order_status === "9" || order_status === "10";

    const payload = {
      modified_by: modifiedBy,
      home_care_id: homeCareId ?homeCareId: null,
      homecare_status: status ?status: null,
    }

    try {
      this.setState({isLoading: true});
      try {

        const response = isPastOrder
          ? await updateChildPastOrder(child_order_id, payload)
          : await updateChildOrder(child_order_id, payload);
  
        console.log("Response:", response)
      }
      catch (error) {
        console.log("Error in assigning nurse", error.message)
      }
  
  
  
      const selectedNurse = nurses.filter(
        (item) => item.professionalId == nurseId
      );
  
      const nurseObject = selectedNurse[0] || {};
      const {professionalId=null}=nurseObject || {};
      if(professionalId){
        const nursePayload = {
          homecareId: homeCareId,
          orderId: child_order_id,
          professionalId: nurseObject?.professionalId || null,
          userId: parseInt(modifiedBy) ?? null,
        };
        try {
    
          const response = await PhoenixAPI.post("nurse/assign-nurse", nursePayload);
    
          console.log("response: " + response);
        }
        catch (error) {
          console.log("error: " + error.message)
        }
      }
     
  
      this.hideManuallyAssignModal()
      await this.fetchNewOrders(page, orderStatus);
      this.showSuccessNotification("Orders updated successfully")
    }
    catch (error) {
      console.log("Error in updating order: " + error.message)
      this.showErrorNotification(
        "Some Error occured in updating  orders:",
        error.message
      );
    }
    finally {
      this.setState({isLoading: false});
    }



  }


  renderPackageName = (order) => {
    const { is_miniPackage_added = false, package_internal_name = "", is_custom_package_addon_added = false } = order || {};
    let packageName = "";
    if (is_miniPackage_added) {
      packageName = `${package_internal_name} +Mini packages`
    }
    else if (is_custom_package_addon_added) {
      packageName = `${package_internal_name} + Addons`
    }
    else {
      packageName = `${package_internal_name}`
    }
    return <>{packageName}</>;
  };

  renderPackageNameForDownload=(order)=>{
    const { is_miniPackage_added = false, package_internal_name = "" } = order || {};
    const packageName = is_miniPackage_added
      ? `${package_internal_name} +Mini packages`
      : `${package_internal_name}`;
    return packageName;
  }

  renderPreviousHomecare = (order) => {
    const { previous_homecare_name=null,previous_lab_name=null,previous_nurse_name=null} = order || {}
    let combinedArray=[];
    if(previous_homecare_name){
      combinedArray.push(previous_homecare_name)
    }
    if(previous_nurse_name){
      combinedArray.push(previous_nurse_name)
    }
    if(previous_lab_name){
      combinedArray.push(previous_lab_name)
    }
   
    return combinedArray?.join(",")  ?? ""  
  }


  showConfirmTime =async (row) => {
    const { child_order_id, user_id,city_id,address_id=null } = row || {};
    const confirmedTime = row.confirmed_time && row.confirmed_time;
    console.log("confirmedTime", confirmedTime);
    const parts =
      confirmedTime && confirmedTime ? confirmedTime.split("T") : "";
    const datePart = parts[0] ? parts[0] : "";
    const timePart = parts[1] ? parts[1].slice(0, 5) : "";
    const formattedDateTime = `${datePart} ${timePart}`;
    this.confirmsubmitted = false;
    this.customConfirmValidator.confirmDate.message = null;
    this.customConfirmValidator.confirmTime.message=null;
    const {data:userCityAddress=[]}= await PhoenixAPI.get(`user-address/${user_id}/${city_id}`)
    if(address_id==null){
        
      this.setState({
       order:
       {...row,countryId:row.country_id,cityId:row.city_id, 
         userDetails:{userName:row.client_name,email:row.email}, 
         orderUUID:row.order_uuid},
         isEditCustomerShippingAddressViewModalVisible:true,
       shippingAddressModal:{addressLine1:null,addressLine2:null,buildingApt:null, mapUrl:null}, userCityAddress:userCityAddress})
       return;
 }

    const confirm_text_bundle =
      row.bundle && row.confirm_text_bundle &&
      (!row.is_confirmed || (row.reschedule_reason !== null && row.reschedule_reason !== ""));
    this.setState({
      childOrderId: child_order_id,
      showConfirmTime: true,
      confirmDate: formattedDateTime.split(" ")[0]
        ? formattedDateTime.split(" ")[0]
        : "",
      confirmTime: formattedDateTime.split(" ")[1]
        ? formattedDateTime.split(" ")[1]
        : "",
      confirmvalidation: this.confirmvalidator.valid(),
      clientConfirm: row.is_confirmed,
      rescheduleReason: row.reschedule_reason,
      selectedBucket: row.order_status,
      notifyClient:row.notify_client_reschedule,
      confirm_text_bundle:confirm_text_bundle

    });
  };

  showNoteAssignModal(row) {
    const { child_order_id, order_notes, order_status ,order_uuid} = row || {};
    console.log("child order is ",child_order_id);
    this.setState({
      childOrderId: child_order_id,
      order_notes: order_notes,
      showNoteAssignModal: true,
      childOrderstatus:order_status,
      orderUUID:order_uuid
    });
  }

  CancelNotesValidation(cancelNotes,cancelReason){
    if(cancelNotes=="" || cancelNotes==null){
    this.setState({
      cancelError:"please add cancel notes"
    })
  
  }
   if(cancelReason=="" || cancelReason==null){
    this.setState({
      cancelReasonError:"please add cancel reason"
    })
    
  }
  if(cancelReason !=="" && cancelReason !==null && cancelNotes!=null && cancelNotes !=="") {
    this.setState({
      cancelError:"",
      cancelReasonError:""
    })
    return true;
  }
  return false;
  }

  showPayNurseModal(row) {
    const { child_order_id, home_care_cost, order_status, homecare_cost_notes } = row || {};
    this.setState({
      childOrderId: child_order_id,
      home_care_cost: home_care_cost,
      showPayNurseModal: true,
      childOrderstatus: order_status,
      homecare_cost_notes: homecare_cost_notes,
    });
  }


  showLabCostModal(row){
    const { child_order_id, lab_cost_notes, order_status } = row || {};
    this.setState({
      childOrderId: child_order_id,
      lab_cost_notes: lab_cost_notes,
      showLabCostNotesModal: true,
      childOrderstatus: order_status,
    });
  }
  hideLabCostModal=()=>{
    this.setState({showLabCostNotesModal: false})
  }

  showHomecareCostModal(row) {
    const { child_order_id, homecare_cost_notes, order_status } = row || {};
    this.setState({
      childOrderId: child_order_id,
      homecare_cost_notes: homecare_cost_notes,
      showHomecareCostNotesModal: true,
      childOrderstatus: order_status,
    });
  }

  hideHomecareCostNotesModal = () => {
    this.setState({ showHomecareCostNotesModal: false })
  }

  showSpecialInstructionsModal(row) {

    const { child_order_id, special_instructions, order_status } = row || {};
    this.setState({
      childOrderId: child_order_id,
      special_instructions: special_instructions,
      showSpecialInstructionsModal: true,
      childOrderstatus:order_status
    });
  }


  hideConfirmTime = () => {
    this.setState({
      isrescheduleDateConfirmPopup:false,
      showConfirmTime: false,
      clientConfirm: false,
      editConfirmTime: false,
      rescheduleReason: null,
      confirmTimeModalError: null,
      submitEnableConfirmTime: false,
      confirmDatePopup:false,
      notifyClient:false
      
    });
  };

  assignLab = async (labId, order) => {
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();
    const page = 0;
    const orderStatus = selectedBucket;
    const { child_order_id, order_status,homecare_name} = order || {};
    
    const payload =homecare_name!==null? {
      modified_by: modifiedBy,
      lab_1: labId,
       homecare_status:"ASSIGNED"
    } : {
      modified_by: modifiedBy,
      lab_1: labId};
    try {
      this.showLoading()
      const isPastOrder=order_status === "9" || order_status === "10";
      const response = isPastOrder?await updateChildPastOrder(child_order_id, payload):await updateChildOrder(child_order_id, payload);
      console.log("response:", response);
      if(homecare_name!==null)
        this.showSuccessNotification("order is updated successfully");
      else{
      this.showSuccessNotification("Order is Updated Successfully");
      }
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error in updating Orders");
      this.showErrorNotification(
        "Some Error occured in updating  orders:",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  assignRating = async (ratingId, order) => {
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();
    const page = 0;
    const orderStatus = selectedBucket;
    const { child_order_id, order_status } = order || {};
    const payload = {
      modified_by: modifiedBy,
      homecare_rating: parseInt(ratingId),
    };
    try {
      this.showLoading()
      const isPastOrder=order_status === "9" || order_status === "10";
      const response = isPastOrder?await updateChildPastOrder(child_order_id, payload):await updateChildOrder(child_order_id, payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error in updating Orders");
      this.showErrorNotification(
        "Some Error occured in updating  orders:",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleHomeCareStatus = async (value, order) => {
    this.setState({order:{...order, homecare_status:value}})
    // const { selectedBucket = "" } = this.state || {};
    // const modifiedBy = getUserId();
    // const page = 0;
    // const orderStatus = selectedBucket;
    // const { child_order_id, order_status } = order || {};

    // const result = homeCareStatus.filter((it) => it.key == value);

    // const status = result[0].status;
    // const payload = {
    //   modified_by: modifiedBy,
    //   homecare_status: status,
    // };
    // try {
    //   this.showLoading();
    //   const isPastOrder = order_status === "9" || order_status === "10";
    //   const response = isPastOrder
    //     ? await updateChildPastOrder(child_order_id, payload)
    //     : await updateChildOrder(child_order_id, payload);
    //   console.log("response:", response);
    //   this.hideManuallyAssignModal()
    //   this.showSuccessNotification("Order is Updated Successfully");
    //   this.fetchNewOrders(page, orderStatus);
    // } catch (error) {
    //   console.log("Error in updating Orders");
    //   this.showErrorNotification(
    //     "Some Error occured in updating  orders:",
    //     error.message
    //   );
    // } finally {
    //   this.hideLoading();
    // }
  };
  assignUserResponse = async (responseId, order) => {
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();
    const page = 0;
    const orderStatus = selectedBucket;
    const { child_order_id, order_status } = order || {};
    let responseGiven = false;
    if (responseId === "1") {
      responseGiven = true;
    }
    const payload = {
      modified_by: modifiedBy,
      is_response_given: responseGiven,
    };
    try {
      this.showLoading()
      const isPastOrder=order_status === "9" || order_status === "10";
      const response = isPastOrder?await updateChildPastOrder(child_order_id, payload):await updateChildOrder(child_order_id, payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error in updating Orders");
      this.showErrorNotification(
        "Some Error occured in updating  orders:",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };
  handleCheckBox = async (e, data, order, checkbox) => {
    const { selectedBucket = "" } = this.state || {};
  const page = 0;
    const orderStatus = selectedBucket;
    const { child_order_id, order_status } = order || {};
    const modifiedBy = getUserId();
  
    const payload = {
      modified_by: modifiedBy,
    };
    payload[`${checkbox}`] = data.checked;
    try {
      this.showLoading()
      const isPastOrder=order_status === "9" || order_status === "10";
      const response = isPastOrder?await updateChildPastOrder(child_order_id, payload):await updateChildOrder(child_order_id, payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error in updating Orders");
      this.showErrorNotification(
        "Some Error occured in updating  orders:",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  confirmTime = async (phase) => {
    const {
      confirmDate,
      confirmTime,
      childOrderId,
      clientConfirm,
      editConfirmTime,
      rescheduleReason,
      selectedBucket = "",
      confirmTimeModalError="",
      oldConfirmDate,
      oldConfirmTime,
      submitEnableConfirmTime,
      confirmDatePopup,
    } = this.state;
    console.log("confirmdate,old confirmdate",confirmDate,oldConfirmDate);
    let isValidDate=false;
    let  isValidClientConfirm=true;
    if(oldConfirmDate ==null || oldConfirmDate=="" || oldConfirmDate==undefined){
      const formattedDate = moment(confirmDate, "YYYY-MM-DD", true);
      if (formattedDate.isValid()){
        this.customConfirmValidator.confirmDate.message = null;
       isValidDate=true;
      }
    } else if (oldConfirmDate !== null) {
      isValidDate =
        phase == "Confirm"
          ? this.validateCnfirmDate(confirmDate, phase)
          : this.validateCnfirmDate(
              confirmDate,
              phase,
              oldConfirmDate,
              oldConfirmTime,
              confirmTime,
              oldConfirmTime
            );
    }

    if (phase == "Confirm") {
      if (rescheduleReason || (clientConfirm && !submitEnableConfirmTime)) {
        isValidClientConfirm = false;
        this.setState({
          confirmTimeModalError: "Please do changes before submit",
        });
      } else if (!clientConfirm) {
        this.setState({ confirmTimeModalError: "Please check confirm client" });
        isValidClientConfirm = false;
      } else {
        this.setState({ confirmTimeModalError: "" });
      }
    }
    else if(oldConfirmDate!==null){
     isValidDate = phase=="Confirm"  ?this.validateCnfirmDate(confirmDate,phase):this.validateCnfirmDate(confirmDate,phase,oldConfirmDate,oldConfirmTime,confirmTime,oldConfirmTime);
    }
      
    if(phase=="Confirm" ){
      if(rescheduleReason || clientConfirm && !submitEnableConfirmTime ){
        isValidClientConfirm=false;
        this.setState({confirmTimeModalError:"Please do Changes before submit"});
      
     }
    else if(!clientConfirm){
        this.setState({confirmTimeModalError:"Please check confirm client"});  
          isValidClientConfirm=false;
       }
      
       else{
        this.setState({confirmTimeModalError:""});
       }
    }
     if(phase=="Edit") {
      if(rescheduleReason == null || rescheduleReason == undefined || rescheduleReason =="")
      {
        isValidClientConfirm=false;
        this.setState({confirmTimeModalError:"Please enter reason"});
      }
      else{
        this.setState({confirmTimeModalError:""});
      }
    }
    
    const confirmvalidation = this.confirmvalidator.validate(this.state);
    this.setState({ confirmvalidation });
    console.log(
      "confirmvalidation.isValid && isValidDate",
      confirmvalidation.isValid && isValidDate
    );
    const modifiedBy = getUserId();
    const page = 0;
    let orderStatus = selectedBucket;

    if (confirmvalidation.isValid && isValidDate && isValidClientConfirm ) {
     // 
     console.log("phase is",phase);
     let payload={};
      if(phase=="Confirm")
      {
         
         console.log("client confirm",clientConfirm);
         payload = {
          modified_by: modifiedBy,
          isCheckBoxEnabled:clientConfirm,
          orderId:childOrderId,
        };

      } else if (phase == "Edit") {
        this.setState({isrescheduleDateConfirmPopup:true})
         
       
      }
      try {

        if(phase=="Confirm"){

        const response = await this.updateOrderConfirmTime(payload);
        console.log("response:", response);
        this.setState({selectedBucket:"CONFIRMED_ORDERS"});
        this.hideConfirmTime();
        this.showSuccessNotification("Order is Updated Successfully");
        orderStatus="CONFIRMED_ORDERS";
        this.fetchNewOrders(page, orderStatus);
        }
      } catch (error) {
        console.log("Error on saving confirmed time", error);
        this.showErrorNotification("Some error has occured. Please try again.");
      }
    } else {
        if(!submitEnableConfirmTime)
        this.showErrorNotification("Please do Changes before submit "); 
        else
        this.showErrorNotification("There are some invalid fields"); 
      
      
    }
  };


  ConfirmDateEditPopup=async()=>{
    const {
      confirmDate,
      confirmTime,
      childOrderId,
      clientConfirm,
      editConfirmTime,
      rescheduleReason,
      selectedBucket = "",
      confirmTimeModalError = "",
      oldConfirmDate,
      oldConfirmTime,
      submitEnableConfirmTime,
      confirmDatePopup,
      notifyClient
    } = this.state;
    const modifiedBy = getUserId();
    let orderStatus = selectedBucket;
    try {
     const payload = {
        rescheduledDateTime: confirmDate + " " + confirmTime,
        modified_by: modifiedBy,
        orderId: childOrderId,
        rescheduleReason: rescheduleReason,
        isNotifyClientReschedule:notifyClient
      };
      const page=0;
      const response = await this.updateOrderConfirmTime(payload);
      console.log("response:", response);
      this.setState({ selectedBucket: "CONFIRMED_ORDERS" });
      this.hideConfirmTime();
      this.showSuccessNotification("Order is Updated Successfully");
      orderStatus = "CONFIRMED_ORDERS";
      this.fetchNewOrders(page, orderStatus);
      }
     catch (error) {
      console.log("Error on saving confirmed time", error);
      this.showErrorNotification("Some error has occured. Please try again.");
    }
  }

  hideNoteAssignModal = () => {
    this.setState({ showNoteAssignModal: false});
  };

  hidePayNurseModal=()=>{
    this.setState({ showPayNurseModal: false});
  }
  hideSpecialInstructionsModal=()=>{
    this.setState({showSpecialInstructionsModal:false})
  }

  hideManuallyAssignModal = () => {
     this.setState({ showManuallyAssignModal: false, errors:{}});
  }

  updateOrderNotes = async (payload) => {
    const config = {
      method: "PATCH",
      url: `${BASE_URL_Update_OrderNotes}/`,
      data: payload,
    };

    return await PhoenixAPI.request(config);
  };
   
   
  

 

  updateOrderConfirmTime = async (payload) => {
    const logged_in_user_id = getUserId()
    const config = {
      method: "POST",
      url: `${BASE_URL_Update_ConfirmTime}/?logged_in_user_id=${logged_in_user_id}`,
      data: payload,
    };
    return await PhoenixAPI.request(config);
  };


  addNotes = async () => {
    const { selectedBucket = "", childOrderstatus=""} = this.state || {};
    const{order}=this.state;
    console.log("order is",order);
    const order_notes = this.state.order_notes;
   const orderUUId= this.state.orderUUID;
   const modifiedBy = getUserId();
   console.log("orderUUid",orderUUId);
   const payload = {
    "modified_by": modifiedBy,
    "orderUUID" : orderUUId,
    "orderNote" : order_notes,
  };
    const page = 0;
    const orderStatus = selectedBucket;
    this.hideNoteAssignModal();
    try {
      this.showLoading()
      const isPastOrder=childOrderstatus === "9" || childOrderstatus === "10";
      const response = isPastOrder?await this.updateOrderNotes(payload):await this.updateOrderNotes(payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error on saving confirmed time", error);
      this.showErrorNotification("Some error has occured. Please try again.");
    }
  };


  
  addHomecareCosts=async()=>{
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();


    const { childOrderId, home_care_cost, order, childOrderstatus, homecare_cost_notes } =
      this.state;
    const { order_status } = order || {};


    const payload = {
      modified_by: modifiedBy,
      home_care_cost: parseInt(home_care_cost),
      homecare_cost_notes:homecare_cost_notes,
      is_homecare_cost_manually_updated:true,
    };
    const page = 0;
    const orderStatus = selectedBucket;
    this.hidePayNurseModal();
    try {
      this.showLoading()
      const isPastOrder=childOrderstatus === "9" || childOrderstatus === "10";
      const response = isPastOrder?await updateChildPastOrder(childOrderId, payload):await updateChildOrder(childOrderId, payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error on saving confirmed time", error);
      this.showErrorNotification("Some error has occured. Please try again.");
    }
  }

 
  addSpecialInstructions=async()=>{
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();

    const { childOrderId ,special_instructions, order, childOrderstatus} = this.state;
    const {  order_status } = order || {};
    const payload = {
      modified_by: modifiedBy,
      special_instructions: special_instructions,
    };
    const page = 0;
    const orderStatus = selectedBucket;
    this.hideSpecialInstructionsModal();
    try {
      this.showLoading()
      const isPastOrder=childOrderstatus === "9" || childOrderstatus === "10";
      const response = isPastOrder?await updateChildPastOrder(childOrderId, payload):await updateChildOrder(childOrderId, payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error on saving confirmed time", error);
      this.showErrorNotification("Some error has occured. Please try again.");
    }
  }


  addLabCostNotes=async()=>{
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();

    const { childOrderId, lab_cost_notes, order, childOrderstatus } =
      this.state;
    const { order_status } = order || {};
    const payload = {
      modified_by: modifiedBy,
      lab_cost_notes: lab_cost_notes,
    };
    const page = 0;
    const orderStatus = selectedBucket;
    this.hideLabCostModal();
    try {
      this.showLoading();
      const isPastOrder = childOrderstatus === "9" || childOrderstatus === "10";
      const response = isPastOrder
        ? await updateChildPastOrder(childOrderId, payload)
        : await updateChildOrder(childOrderId, payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error on saving confirmed time", error);
      this.showErrorNotification("Some error has occured. Please try again.");
    }
  }

  addHomecareCostNotes=async()=>{
    const { selectedBucket = "" } = this.state || {};
    const modifiedBy = getUserId();
    const { childOrderId, homecare_cost_notes, order, childOrderstatus } =
      this.state;
    const { order_status } = order || {};
    const payload = {
      modified_by: modifiedBy,
      homecare_cost_notes: homecare_cost_notes,
    };
    const page = 0;
    const orderStatus = selectedBucket;
    this.hideHomecareCostNotesModal();
    try {
      this.showLoading();
      const isPastOrder = childOrderstatus === "9" || childOrderstatus === "10";
      const response = isPastOrder
        ? await updateChildPastOrder(childOrderId, payload)
        : await updateChildOrder(childOrderId, payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders(page, orderStatus);
    } catch (error) {
      console.log("Error on saving confirmed time", error);
      this.showErrorNotification("Some error has occured. Please try again.");
    }
  }
  handleViewMyOrders = async () => {
    const userId = getUserId();
    this.setState({ userId });
    const page = 0;
    const orderStatus = "ALL_ORDERS";
    this.fetchNewOrders(page, orderStatus, userId);
  };

  handleDateSelect = ({ target: input }) => {
    if (input.name === "selectedStartDate" && this.state.selectedEndDate) {
      if (moment(input.value).isAfter(this.state.selectedEndDate)) {
        this.setState({
          selectedStartDate: input.value,
          selectedEndDate: input.value,
        });
      }
    }

    this.setState({ [input.name]: input.value });
  };

  getTimerOrders = (orderStatus) => {
    const { allOrders, red, blue, green ,reCollected} = this.state || {};
    const page = 0;
     
    this.setState({ newOrders: [] });
    if (allOrders) {
      this.fetchNewOrders(page, orderStatus);
    } else if (red) {
      this.fetchNewOrders(page, orderStatus);
    } else if (blue) {
      this.fetchNewOrders(page, orderStatus);
    } else if (green) {
      this.fetchNewOrders(page, orderStatus);
    } 
    else if(reCollected){
      this.fetchNewOrders(page, orderStatus);
    }
    else {
      this.setState({ timeRangeUpperLimit: null }, () =>
        this.fetchNewOrders(page, orderStatus)
      );
    }
  };

  handleTimerSlots = async (event, value) => {
    const target = event.target.name;
    console.log("target:", target);
    const { selectedBucket } = this.state || {};
    const page = 0;
    const orderStatus = selectedBucket;


    if(target=="reCollected"){
      this.setState(
        {
          reCollected: event.target.checked,
          red: false,
          blue: false,
          green: false,
          allOrders: false,
          timeRangeUpperLimit: null,
        },
        () => this.getTimerOrders(orderStatus)
      );
    }
    if (target === "allOrders") {
      this.setState(
        {
          allOrders: event.target.checked,
          red: false,
          blue: false,
          green: false,
          reCollected: false,
          timeRangeUpperLimit: null,
        },
        () => this.getTimerOrders(orderStatus)
      );
    }
    if (target === "red") {
      this.setState(
        {
          red: event.target.checked,
          allOrders: false,
          blue: false,
          green: false,
          reCollected: false,
          timeRangeUpperLimit: 6,
        },
        () => this.getTimerOrders(orderStatus)
      );
    } else if (target === "blue") {
      this.setState(
        {
          blue: event.target.checked,
          allOrders: false,
          red: false,
          green: false,
          recollected: false,
          timeRangeUpperLimit: 12,
        },
        () => this.getTimerOrders(orderStatus)
      );
    } else if (target === "green") {
      this.setState(
        {
          green: event.target.checked,
          allOrders: false,
          red: false,
          blue: false,
          recollected: false,
          timeRangeUpperLimit: 18,
        },
        () => this.getTimerOrders(orderStatus)
      );
    }
  };

  handleBucket = async (item) => {
    let { status = "" } = item || {};
    const page = 0;
    const orderStatus = status;
    this.setState({ newOrders: [], selectedBucket: status });
    await this.fetchNewOrders(page, orderStatus);
  };
  showCreateOrderViewModal = () => {
    this.setState({ isCreateOrderViewModalVisible: true });
  };

  hideCreateOrderViewModal = () => {
    this.setState({ isCreateOrderViewModalVisible: false });
  };

  getFormattedPurchasedDate = (purchasedDate) => {
    const date = moment(purchasedDate).format("MMM DD, YYYY");
    const time = moment(purchasedDate).format("hh:mm A");
    return `${date} at ${time}`;
  };

  getUserName = (order) => {
    if (!order) return "";
    const { userName, userMail, client_name, email, purchaser_full_name, is_repeated_client } =
      order;
      if(is_repeated_client){
        return (
          <>
          <div>{`${purchaser_full_name} ${email}`}</div>
          <div style={{color:"rgb(143, 209, 79)"}}>Repeated Client</div>
          </>
        )
      }
      else{
        return (
          <>
          <div>{`${purchaser_full_name} ${email}`}</div>
          <div style={{color:"rgb(242, 71, 38)"}}>New Client</div>
          </>
        )
      }
     
  };

  getUserNameForMapping=(order)=>{
    if (!order) return "";
    const { userName, userMail, client_name, email, purchaser_full_name, is_repeated_client } =
      order;
      if(is_repeated_client){
        return `${purchaser_full_name} ${email} (Repeated Client)` 
      }
      else{
        return `${purchaser_full_name} ${email} New Client`
      }
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  renderOperationalStatus = (order) => {
    const { status, color } = getOrderStatusWithColor(order.orderStatus) ?? {};
    return (
      <span
        className={`ui small ${color ?? "green"} label`}
        style={{ fontWeight: "normal" }}
      >
        {status ?? ""}
      </span>
    );
  };

  renderTotalPrice = (order) => {
    const { finalPrice } = order ?? {};
    const { currency, finalAmount } = order?.transactionDetails ?? {};
    return `${currency ?? ""} ${finalAmount ?? finalPrice ?? ""}`;
  };

  renderNumberOfItems = (itemsCount) => {
    if (itemsCount === null || itemsCount === undefined) return "";
    return `${itemsCount} ${itemsCount > 1 ? "items" : "item"}`;
  };

  handleEditOrder = async (order) => {
    console.log("Order: handleEdit", order);
    const modifiedBy = getUserId();
    console.log("modified by:", modifiedBy);
    const {
      child_order_id,
      is_detail_sent_to_lab = false,
      is_lab_confirmation_done = false,
      is_nurse_msg_added = false,
      is_feedback_msg_given = false,
      is_thankyou_msg_given = false,
    } = order || {};
    const payload = {
      modified_by: modifiedBy,
    };
    payload.is_detail_sent_to_lab = is_detail_sent_to_lab;
    payload.is_lab_confirmation_done = is_lab_confirmation_done;
    payload.is_nurse_msg_added = is_nurse_msg_added;
    payload.is_feedback_msg_given = is_feedback_msg_given;
    payload.is_thankyou_msg_given = is_thankyou_msg_given;

    try {
      const response = await updateChildOrder(child_order_id, payload);
      console.log("response:", response);
      this.showSuccessNotification("Order is Updated Successfully");
      this.fetchNewOrders();
    } catch (error) {
      console.log("Error in updating Orders");
      this.showErrorNotification(
        "Some Error occured in updating  orders:",
        error.message
      );
    } finally {
      this.hideLoading();
    }
  };

  handleChange = (event) => {
    const target = event.target;
    this.setState({ [target.name]: target.value });
   
  };

 
  // renderOrderStatusDropdown=(order)=>{
  //    return (
  //       <>
  //          <select
  //           className="form-control"
  //           onChange={(e) => this.handleOrderStatus(e.target.value, order)}
  //           value={order.order_status}
  //         >
  //           {this.state.orderStatusList&&this.state.orderStatusList.map((status, index) => (
  //             <option key={index} value={status.value}>
  //               {status.text}
  //             </option>
  //           ))
  //           }

  //         </select>
  //       </>
  //    )
  // }
  renderAppoinmentDate = (order) => {
    const { appointment_time = "" } = order || {};
    const formattedDate = appointment_time
      ? formatDate(appointment_time.split(" ")[0])
      : "-";
    const time = appointment_time ? appointment_time.split(" ")[1] : "-";
    return (
      <>
        {formattedDate} {time}
      </>
    );
  };
  renderAgentDropdown = (order) => {
    const {order_status}= order || {}
    return (
      <SelectAgent
        agents={this.state.agents ?? []}
        selectedAgentId={order.agent_assigned}
        onAgentSelect={(agent_assigned) =>
          this.handleAgentSelect(order, agent_assigned)
        }
        isDisabled={
          order_status === "9" ||  order_status === "10" || !this.state.isEditable
        }
      />
    );
  };

  renderServiceDropdown = (order) => {
    return (
      <>
        <SelectAgent
          agents={this.state.homeCareServices ?? []}
          selectedAgentId={order.homecare_name}
          onAgentSelect={(agent_assigned) =>
            this.handleServiceSelect(order, agent_assigned)
          }
          isDisabled={
            order?.orderStatus === "COMPLETED" || !this.state.isEditable
          }
          isHomeCare={true}
        />
      </>
    );
  };

  getAppointmentDateAndTime = (order) => {
    if (!order) return "";

    const { appointment_time } = order;
    return <div>{appointment_time}</div>;
  };

  getConfirmedDateAndTime = (order) => {
    if (!order) return "";

    const { date_confirmed_date_time } = order;

    return (
      <div>
        <span>{date_confirmed_date_time}</span>
        <br />
      </div>
    );
  };

  renderDetailsSentToLab = (order) => {
    const { is_detail_sent_to_lab, order_status } = order || {};
    return (
      <>
        <Checkbox
          key={1}
          type="checkbox"
          checked={is_detail_sent_to_lab}
          onChange={(event, data) =>
            this.handleCheckBox(event, data, order, "is_detail_sent_to_lab")
          }
        />
      </>
    );
  };

  renderLabConfirmation = (order) => {
    const { is_lab_confirmation_done = false } = order || {};
    return (
      <>
        <Checkbox
          key={1}
          type="checkbox"
          checked={is_lab_confirmation_done}
          onChange={(event, data) =>
            this.handleCheckBox(event, data, order, "is_lab_confirmation_done")
          }
        />
      </>
    );
  };

  renderClientdetails=(order)=>{
 
    const {whatsapp_number="", client_name="", confirmed_time=""}= order || {}
    const formattedDate = confirmed_time
    ? formatDate(confirmed_time.split(" ")[0])
    : "-";
    const time = confirmed_time ? confirmed_time.split(" ")[1] : "-";
   
    return(
      <div div style={{display:"flex", flexDirection:"column", gap:"5px"}}>
      <div>{whatsapp_number}</div>
      <div>{client_name}</div>
      {confirmed_time?<div>{formatDateTime(confirmed_time)}</div>:""}
      </div>
    )
  }
  renderNurseReachedCheck = (order) => {
    const { is_nurse_msg_added = false } = order || {};
    return (
      <>
        <Checkbox
          key={1}
          type="checkbox"
          checked={is_nurse_msg_added}
          onChange={(event, data) =>
            this.handleCheckBox(event, data, order, "is_nurse_msg_added")
          }
        />
      </>
    );
  };

  renderFeedBackMsg = (order) => {
    const { is_feedback_msg_given = false } = order || {};
    return (
      <>
        <Checkbox
          key={1}
          type="checkbox"
          checked={is_feedback_msg_given}
          onChange={(event, data) =>
            this.handleCheckBox(event, data, order, "is_feedback_msg_given")
          }
        />
      </>
    );
  };

  renderFeedBackResponse = (order) => {};

  renderThankyouMsg = (order) => {
    const { is_thankyou_msg_given = false } = order || {};
    return (
      <>
        <Checkbox
          key={1}
          type="checkbox"
          checked={is_thankyou_msg_given}
          onChange={(event, data) =>
            this.handleCheckBox(event, data, order, "is_thankyou_msg_given")
          }
        />
      </>
    );
  };

  validateCnfirmDate(date,phase,oldConfirmDate,oldConfirmTime,confirmedTime) {
   // const startDate = moment();
   const today = moment().format('YYYY-MM-DD');
    const formattedDate = moment(date, "YYYY-MM-DD", true);
    const oldFormattedDate=moment(oldConfirmDate,"YYYY-MM-DD",true);
    console.log("confirmDate",date);
     
    if(phase=="Confirm"){
      if (formattedDate.isValid()) {
        this.customConfirmValidator.confirmDate.message = null;
        return true;
      }
      else{
        this.customConfirmValidator.confirmDate.message = "Invalid date.";
      return false;

      }
    }
    else if(phase=="Edit"){
    if (formattedDate.isValid()) {
      if ( formattedDate.isSameOrAfter(today) ) {
        const isSameDate = formattedDate.isSame(oldFormattedDate, 'day');
        const isDateToday= formattedDate.isSame(today);
        const currentTime = moment();
        if(isSameDate){
          const isSameTime=moment(confirmedTime, "HH:mm").isSame(moment(oldConfirmTime, "HH:mm"), 'minute');
          if(isSameTime){
            this.customConfirmValidator.confirmDate.message =
            "Date & Time should differ from previous";
          return false;
          }
          if(isDateToday){
          if(moment(confirmedTime, "HH:mm").isAfter(currentTime)){
            this.customConfirmValidator.confirmTime.message = null;
            this.customConfirmValidator.confirmDate.message =null;
            return true;   
          }
          else{
            this.customConfirmValidator.confirmTime.message =
            "Time should greater from present";
          return false;
          }
        }
        }
          

        
        
        if(isDateToday){
          const currentTime = moment();
          if(moment(confirmedTime, "HH:mm").isAfter(currentTime)){
            this.customConfirmValidator.confirmTime.message = null;
            return true;   
          }
          else{
            this.customConfirmValidator.confirmTime.message =
            "Time should greater from present";
          return false;
          }
        }

        this.customConfirmValidator.confirmDate.message = null;
        return true;

      } 
      else{
        this.customConfirmValidator.confirmDate.message = "Date should not be past date";
        return false;
      }

    }
    else {
      this.customConfirmValidator.confirmDate.message = "Invalid date.";
      return false;
    }
   } 
  }
  

  renderLabListed=(order)=>{
    const {labtListFormatted}=this.state;
   
     return (<>
      <select
            className="form-control"
            onChange={(e) => this.assignLab(e.target.value, order)}
            value={order.lab_name}
            disabled={true}
          >
            {labtListFormatted &&
              labtListFormatted.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.text}
                </option>
              ))}
          </select>
     </>)
  }

  // renderEditButton = (order) =>
  //   order && (
  //     <div>
  //       <button
  //         className="btn px-5"
  //         style={{ backgroundColor: "#2D9BF0" }}
  //         onClick={() => {
  //           // this.handleEditOrder(order);
  //         }}
  //       >
  //         Save
  //       </button>
  //     </div>
  //   );
  renderViewButton = (order) =>
    order && (
      <div>
        <Link
          className="btn px-5"
          style={{ backgroundColor: "#CEE741" }}
          to={`/dashboard/orders/newOrder/${order.id}`}
          target="_blank"
        >
          View
        </Link>
      </div>
    );

  renderBucketList = () => {
    const { bucketList, selectedBucket } = this.state;
    return (
      <div className="bucket-container">
        {bucketList.map((item, index) => {
          return (
            <div className="bucket-contentDiv" key={index}>
              <div
                className={`bucket-content-main ${
                  selectedBucket === item.status ? "selectedBucket" : ""
                }`}
                onClick={() => this.handleBucket(item)}
              >
                <div className="bucket-count">{item.count}</div>
                <div
                  className={`bucket-name ${
                    selectedBucket === item.status ? "selectedBucket" : ""
                  }`}
                >
                  {item.displayName}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  getFilteredOrders = () => {
    const { orders = [] } = this.state;
    return this.getSearchedOrders(orders);
  };

  getSearchedOrders = (orders) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return orders;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return orders.filter((order) => {
      const {
        id,
        child_order_id,
        email,
        purchaser_full_name,
        client_name,
        package_name,
      } = order;

      const name = purchaser_full_name || client_name;
      const customerName = `${name} (${email ?? ""})`;
      return (
        (id && id.toString() === newValue) ||
        (child_order_id && child_order_id.toString() === newValue) ||
        (package_name && package_name.toLowerCase().indexOf(newValue) !== -1) ||
        (customerName && customerName.toLowerCase().indexOf(newValue) !== -1) ||
        (email && email.toLowerCase().indexOf(newValue) !== -1)
      );
    });
  };
 
cancelChildOrder=async(cancelId,cancelNotes,reason)=>{
  const{order}=this.state;
  const { selectedBucket = "" } = this.state || {};
  const prevStatus=order.order_status;
  const page = 0;
    const orderStatus = selectedBucket;

const cancelIds=[];
cancelIds.push(cancelId);
  
  const userId=getUserId();
     const mokafaaSaleTransaction=order?.mokafaa_sale_transaction??null;
    if(cancelIds.length>0){
    const url="cancel-the-order";
    const payload={
      ids:cancelIds,
    cancellationNotes:cancelNotes,
    modifiedBy:userId,
    orderStatus:"CANCELLED",
    cartOrderId:order.id
    };

  const payloadAll =  {order_status: "CANCELLED",is_send_notification:false,cancellation_notes:cancelNotes,modified_by:userId,cancellation_reason:reason }
    try {
      if(prevStatus==9){
      await PhoenixAPI.patch(url, payload) ;
      if(mokafaaSaleTransaction && mokafaaSaleTransaction){
        try{
          const response=await handleMokaffaRefundReturn(cancelIds)
          console.log("Response in refund transaction", response)
        }
        catch(error){
          console.log("Error in refund transaction", error.message)
          this.showErrorNotification("Error in refund transaction", error.message)
        }
       
      }
      this.hideCancelModal();
      this.showSuccessNotification("Order Cancelled Successfully.");
      this.fetchNewOrders(page,orderStatus);
      }
     
      else if(prevStatus!==9)
      {  await updateChildOrder(cancelId, payloadAll);
        if(mokafaaSaleTransaction && mokafaaSaleTransaction){
          try{
            const response=await handleMokaffaRefundReturn(cancelIds)
            console.log("Response in refund transaction", response)
          }
          catch(error){
            console.log("Error in refund transaction", error.message)
            this.showErrorNotification("Error in refund transaction", error.message)
          }
        }
         this.hideCancelModal();
         this.showSuccessNotification("Order Cancelled Successfully.");
         this.fetchNewOrders(page,orderStatus);
        
      }
      
      
    } catch (error) {
      this.showErrorNotification(
        "Error on cancelling order: ",
        error.message
      );
    }

}
}

  hideRescheduleDateModal=()=>{
    this.setState({
      confirmDatePopup:true,
      isrescheduleDateConfirmPopup:false
    },()=>this.ConfirmDateEditPopup())
  }
  cancelRescheduleDate=()=>{
    this.setState({
      confirmDate:this.state.oldConfirmDate,
      isrescheduleDateConfirmPopup:false
    })
  }
  showRescheduleDateModal=()=>{
    this.setState({
      isrescheduleDateConfirmPopup:true
    })
  }
  calculateDifference = (endDate) => {
    const startDateMoment = moment();
    const endDateMoment = moment(endDate);
    const today=moment().format("YYYY-MM-DD");
  
   
    const endDateFormat=moment(endDate,"YYYY-MM-DD",true);
   
    if(endDateFormat.isSame(today)){

    return "same";
    }
      
    // Calculate the difference in days
  
    const differenceInDays = endDateMoment.diff(startDateMoment, 'days');
   
    return differenceInDays;
  
  };

  formManuallyAssign = () => {
    const { order, homeCareServices, nurses,errors } = this.state;
    const { child_order_id, order_status, client_name, package_name, homecare_name, professional_id, homecare_status } = order || {};
    let homeCareNursesList;
    if (homecare_name) {
      const homeCareId = homeCareServices.find((homecare) => homecare.value == homecare_name);
      const homeCareNurses = nurses.filter((nurse) => nurse.homeCareServiceId == homecare_name);
      homeCareNursesList = homeCareNurses.map((homeCareNurse) => {
        return {
          text: homeCareNurse.fullName,
          key: homeCareNurse.professionalId,
          value: homeCareNurse.professionalId,
        };
      });
      homeCareNursesList.splice(0, 0, { text: "None", value: "", key: "" });
      console.log("homecarenurselist", homeCareNursesList, homecare_name);
    }
    return (
      <div>
        <div className="home-care-popup"><div className="client-name">Id : </div>{child_order_id ?? ""}</div>
        <div className="home-care-popup"><div className="client-name">Client name : </div>{client_name ?? ""}</div>
        <div className="home-care-popup"><div className="client-name">Package name : </div>{package_name ?? ""}</div>
        <div className="row" style={{marginTop:"12px"}}>
          <div className="col">
            <label>Select Homecare Service</label>
            {/* <span className="important-asterisk">*</span> */}
          <select
            className={
              "form-control py-2" +
              (errors.homecare ? " has-error" : "")
            }
            onChange={(e) => this.handleServiceSelect(e.target.value, order)}
            value={order.homecare_name}
          >
            {this.state.homeCareServices &&
              this.state.homeCareServices.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.text}
                </option>
              ))}
          </select>
          <span className="help-block">{errors.homecare}</span>
          </div>
           <div className="col">
            <label>Assign Nurse</label>
            {/* <span className="important-asterisk">*</span> */}
           <select
       className={
        "form-control py-2" +
        (errors.nurse ? " has-error" : "")
      }
      onChange={(e) => this.handleNurseSelect(e.target.value, order)}
      value={homecare_name?order.professional_id?order.professional_id:"":""}
    >
      {homecare_name? homeCareNursesList.map((status, index) => (
          <option key={index} value={status.value}>
            {status.text}
          </option>
        )):this.state.nursesList &&
        this.state.nursesList.map((status, index) => (
          <option key={index} value={status.value}>
            {status.text}
          </option>
        ))
       
        }
    </select>
    <span className="help-block">{errors.nurse}</span>
           </div>
        </div>
        <div className="row" style={{marginTop:"12px"}}>
          <div className="col">
            <label>Homecare confirmation status</label>
            {/* <span className="important-asterisk">*</span> */}
          <select
            className={
              "form-control py-2" +
              (errors.homecarestatus ? " has-error" : "")
            }
            onChange={(e) => this.handleHomeCareStatus(e.target.value, order)}
            value={order.homecare_status}
          >
            {homeCareStatus &&
              homeCareStatus.map((status, index) => (
                <option key={index} value={status.value}>
                  {status.text}
                </option>
              ))}
          </select>
          <span className="help-block">{errors.homecarestatus}</span>
          </div>
        </div>
      </div>
    )
  }
  mappingOrdersForDownload=(newOrders)=>{
    const mappingOrders=newOrders.map((order)=>{
      const item={
         "order Id":order.order_uuid,
         "Purchased Date":formatDate(order.created_at) ??"-",
          "Purchaser":this.getUserNameForMapping(order),
          "Payment Mode":order.payment_method,
          "Total Price":order.final_price,
          "City":order.city_name,
          "Child Order Id":order.child_order_id,
          "Client Name":order.client_name,
          "Package Name":this.renderPackageNameForDownload(order),
          "Quantity":order.quantity,
           "OrderStatus":order.order_status,
           "Agent Assigned":order.agent_assigned,
           "Appointment Date & Time":order.appointment_time,
           "Confirmed Date & Time":order.confirmed_time,
           "Order Notes":order.order_notes,
           "Homecare Name":order.homecare_name,
           "Professional Name":order.professional_id,
           "Homecare Confirmation Status":order.homecare_status,
           "Lab":order.lab_name,
           "HomeCare Costs":order.home_care_cost,
           "Special Instructions":order.special_instructions,
           "Details Sent To Lab":order.is_detail_sent_to_lab,
           "Lab Confirmation":order.is_lab_confirmation_done,
           "Client Details": order.whatsapp_number+" "+order.client_name+" "+formatDateTime(order.confirmed_time),
           "Nurse Reached Check":order.is_nurse_msg_added,
           "User Response on Nurse Message":order.is_response_given ?? false,
           "Feedback msg Sent":order.is_feedback_msg_given ??false,
           "Feedback response":order.homecare_rating ?? false,
           "Thank you Msg":order.is_thankyou_msg_given ?? false,
      }
      return item;
    
    })
    return mappingOrders
  }
 
  downloadCsv=()=>{
    this.setState({downloadOption:true,csvFormat: true,
      xlsFormat: false})
  }
  hideDownloadOptionModal=()=>{
    this.setState({downloadOption:false,csvFormat: false,
      xlsFormat: false,})
  }

  downloadXls=()=>{
    this.setState({downloadOption:true,csvFormat: false,
      xlsFormat: true})
  }
  jsonToCsv = (json) => {
    const csvRows = [];
    // Get the headers
    const headers = Object.keys(json[0]);
    csvRows.push(headers.join(','));
  
    // Map over each row
    json.forEach(row => {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"');
        return `"${escaped}"`;
      });
      csvRows.push(values.join(','));
    });
  
    // Join rows with newline character
    return csvRows.join('\n');
  };

  fetchAllOrders=async( page = 0,
    orderStatus = "UNASSIGNED",
    agentId,
    sortStatus = "CREATED_AT")=>{

      const {
        rowsPerPage = DEFAULT_ROWS_PER_PAGE,
        sortField,
        sortDirection,
        searchApplied,
        searchTerm,
        selectedStartDate,
        selectedEndDate,
        userId,
        timeRangeUpperLimit,
        selectedBucket,
      } = this.state;
  
      if (orderStatus === "ASSIGNED") {
        sortStatus = "APPOINTMENT_TIME";
      } else if (orderStatus === "ALL_ORDERS") {
        sortStatus = "CREATED_AT";
      } else if (orderStatus != "UNASSIGNED") {
        sortStatus = "CONFIRMED_TIME";
      }
  
      if (sortField === "created_at") {
        sortStatus = "CREATED_AT";
        //  orderStatus=selectedBucket
      } else if (sortField === "appointment_time") {
        sortStatus = "APPOINTMENT_TIME";
        // orderStatus=selectedBucket;
      } else if (sortField === "confirmed_time") {
        sortStatus = "CONFIRMED_TIME";
        // orderStatus=selectedBucket
      }
      const params = {
        page,
        page_size: rowsPerPage,
        ordering: sortDirection == "desc" ? `-${sortStatus}` : sortStatus,
        orderStatus: orderStatus,
        agentId: agentId,
      };
      if (selectedStartDate)
        params.fromDate = moment(selectedStartDate).format("YYYY-MM-DD");
      if (selectedEndDate)
        params.toDate = moment(selectedEndDate).format("YYYY-MM-DD");
  
      if (searchApplied)
        params.search = searchTerm ? searchTerm.trim() : searchTerm;
  
      if (timeRangeUpperLimit) params.timeRangeUpperLimit = timeRangeUpperLimit;
    try{
      this.showLoading();
      const {data}=await PhoenixAPI.get("/admin/v2/cart-orders/download", {params});
      const { count, results = [] } = data;
      return results
    }
    catch(error){
      console.log("Error in Downloading Orders");
      this.showErrorNotification(
        "Some Error occured in Downloading Orders:",
        error.message
      );
    }
    finally {
      this.hideLoading();
    }
  }
  downloadAllRecords=async()=>{
    const {
      csvFormat = false,
      xlsFormat = false,
    } = this.state;
    const allOrders=await this.fetchAllOrders()
    const mappedOrders=this.mappingOrdersForDownload(allOrders);
    if (csvFormat) {
      if (mappedOrders.length > 0) {
        const csvContent = this.jsonToCsv(mappedOrders);
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      } else {
        return;
      }
    }
    else if (xlsFormat) {
      if (mappedOrders.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      }
      else {
        return;
      }
    }

  }

  downloadSelectedRecords=()=>{
    const {
      csvFormat = false,
      xlsFormat = false,
      newOrders,
    } = this.state;
    const mappedOrders=this.mappingOrdersForDownload(newOrders);
    if (csvFormat) {
      if (mappedOrders.length > 0) {
        const csvContent = this.jsonToCsv(mappedOrders);
        const blob = new Blob([csvContent], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      } else {
        return;
      }
    }
    else if (xlsFormat) {
      if (mappedOrders.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, 'data.xlsx');
        this.hideDownloadOptionModal()
        this.showSuccessNotification("File is Downloaded successfully")
      }
      else {
        return;
      }
    }
  }
  isTableRowColorSchemeApplicable=(order)=>{
    const{parent_item_id,bundle}=order;
    if(bundle && (order.order_status==1 || order.order_status==2)){
      return true;
    }
    else{
      return false;
    }
  }

  getTableRowColorScheme=(row)=>{
    const order=row.id;
    let color="#ADD8E6";
    if(order)
    {
      return {
        backgroundColor: color,
        "&:hover": {
          backgroundColor: color,
        },
      }
    }
  }

  render() {
    
    const {
      orders,
      cities,
      countries,
      coupons,
      totalRows,
      rowsPerPage,
      searchTerm,
      searchApplied,
      loadError,
      isCreateOrderViewModalVisible,
      isEditable,
      selectedStartDate,
      selectedEndDate,
      bucketList,
      newOrders,
      clientConfirm,
      rescheduleReason,
      editConfirmTime,
      confirmTimeModalError,
      selectedBucket,
      submitEnableConfirmTime,
      errors,
      order,
      fullOrder,
      isEditCustomerShippingAddressViewModalVisible,
      shippingAddressModal,
      agentId,
      notifyClient

    } = this.state;
  

    let confirmvalidation = this.confirmsubmitted
      ? this.confirmvalidator.validate(this.state)
      : this.state.confirmvalidation;

    if (this.state.accessDenied) {
      return <AccessDenied />;
    }
    const customStyles = {
      rows: {
        style: {
          minHeight: "80px", // override the row height
        },
      },
    };
  
    return (
      <div className="manage-package">
        <div className="row" style={{ gap: "20px" }}>
          <div className="" style={{ marginTop: "5px" }} >
            Start Date
          </div>
          <div className="">
            <div className="input-group user-input-container">
              <input
                value={selectedStartDate}
                onChange={this.handleDateSelect}
                // min={this.minStartDate}
                // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
                type="date"
                id="selectedStartDate"
                name="selectedStartDate"
                className="form-control py-3"
              />
            </div>
          </div>
          <div className="" style={{ marginTop: "5px" }}>
            End Date
          </div>
          <div className="">
            <input
              value={selectedEndDate}
              onChange={this.handleDateSelect}
              min={selectedStartDate ?? null}
              // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
              type="date"
              id="selectedEndDate"
              name="selectedEndDate"
              className="form-control py-3"
            />
          </div>
          <div className="">
            <button
              onClick={this.handleShowOrders}
              disabled={!isEditable}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Show Orders
            </button>
          </div>
          <div className="" style={{display:"flex", gap:"20px", justifyContent:"flex-end",marginLeft:"60px"}}>
          <div>
          <button
              onClick={(e) => this.downloadCsv()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789" , color:"white"}}
            >
              Download CSV
            </button>
          </div>
          <div>
          <button
              onClick={(e) => this.downloadXls()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789", color:"white" }}
            >
              Download Excel
            </button>
          </div>
          </div>
          
        </div>
        

        {this.renderBucketList()}
        <div className="row mb-1">
          <div className="col">
            <button
              onClick={(e) => this.handleViewMyOrders()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#CEE741" }}
            >
              View My Orders
            </button>
          </div>
          <div className="ml-auto mr-3">
            <Search
              searchExpanded={true}
              searchTerm={searchTerm}
              handleSearch={this.handleSearch}
              handleSearchEnter={this.handleSearchEnter}
              clearSearch={this.handleSearchClear}
              handleSearchExit={this.handleSearchExit}
              ref={(input) => {
                this.search = input;
              }}
            ></Search>
          </div>
          <div className="action-item" style={{ marginRight: "12px" }}>
            <button
              className="btn button-blue btn-md new-user"
              onClick={this.handleSearchEnter}
            >
              Search
            </button>
          </div>
          <div className="action-item">
            <button
              onClick={this.handleCreateOrder}
              disabled={!isEditable}
              style={{ backgroundColor: "#0CA789", color: "white" }}
              className="btn btn-md new-user"
            >
              Create Order
            </button>
          </div>
        </div>
        <div
          className=""
          style={{ display: "flex", marginLeft: "300px", marginBottom: "35px" }}
        ></div>

        <div className="sla-timers">
        <div className="sla-div">
            <input
              type="checkbox"
              id="Recollection"
              name="reCollected"
              value={true}
              checked={this.state.reCollected ?? false}
              onChange={(event, value) => this.handleTimerSlots(event, value)}
            />
            <button
              id="show_orders"
              className="btn px-2"
              style={{ backgroundColor: "#0CA789" }}
            >
              Recollected Orders
            </button>
          </div>

          <div className="sla-div">
            <input
              type="checkbox"
              id="all"
              name="allOrders"
              value={true}
              checked={this.state.allOrders ?? false}
              onChange={(event, value) => this.handleTimerSlots(event, value)}
            />
            <button
              id="show_orders"
              className="btn px-2"
              style={{ backgroundColor: "#0CA789" }}
            >
              All Orders
            </button>
          </div>
          <div className="sla-div">
            <input
              type="checkbox"
              id="red"
              name="red"
              value={true}
              checked={this.state.red ?? false}
              onChange={(event, value) => this.handleTimerSlots(event, value)}
            />
            <button
              id="show_orders"
              className="btn px-2"
              style={{ backgroundColor: "#F24726" }}
            >
              0-6 hours
            </button>
          </div>

          <div className="sla-div">
            <input
              type="checkbox"
              id="blue"
              name="blue"
              value={true}
              checked={this.state.blue ?? false}
              onChange={(event, value) => this.handleTimerSlots(event, value)}
            />
            <button
              id="show_orders"
              className="btn px-2"
              style={{ backgroundColor: "#A0EBEE" }}
            >
              7-12 hours
            </button>
          </div>

          <div className="sla-div">
            <input
              type="checkbox"
              id="green"
              name="green"
              value={true}
              checked={this.state.green ?? false}
              onChange={(event, value) => this.handleTimerSlots(event, value)}
            />
            <button
              id="show_orders"
              className="btn px-2"
              style={{ backgroundColor: "#8FD14F" }}
            >
              13-18 hours
            </button>
          </div>
        </div>
        <Card body>
          <div className="newOrders-revamp">
            <DataTable
              data={newOrders || []}
              columns={this.newOrdersColumns() || []}
              sortServer
              onSort={this.handleSort}
              defaultSortField="id"
              defaultSortAsc={false}
              sortIcon={<ArrowDownward></ArrowDownward>}
              pagination
              paginationServer
              paginationTotalRows={totalRows ?? 0}
              paginationPerPage={rowsPerPage ?? DEFAULT_ROWS_PER_PAGE}
              paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleRowsPerPageChange}
              highlightOnHover
              responsive
              noHeader={searchApplied ? false : true}
              fixedHeader
              fixedHeaderScrollHeight="1200px"
              title={
                searchApplied ? (
                  <p className="table-header">
                    {"Found " + newOrders.length + " results"}
                  </p>
                ) : (
                  ""
                )
              }
              conditionalRowStyles={[
                {
                   when: this.isTableRowColorSchemeApplicable,
                   style: this.getTableRowColorScheme,
                 },
               ]}
              noDataComponent={
                this.state.loadError ? (
                  <AlertBox message={loadError} error={true}></AlertBox>
                ) : (
                  <AlertBox message="There's nothing here."></AlertBox>
                )
              }
              customStyles={customStyles}
            />
          </div>
        </Card>
        <Modal show={this.state.showConfirmTime} onHide={this.hideConfirmTime}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row">
              <div className="col-6 form-group">
                <label>Confirm Date *</label>
                <input
                  name="confirmDate"
                  type="date"
                  placeholder="Rollout Date"
                  min={this.state.startDate}
                  disabled={!editConfirmTime}
                  className={
                    "form-control " +
                    ((confirmvalidation.confirmDate.isInvalid ||
                      this.customConfirmValidator.confirmDate.message) &&
                      "has-error")
                  }
                  onChange={this.handleChange}
                  value={this.state.confirmDate}
                ></input>
                <span className="help-block">
                  {confirmvalidation.confirmDate.message
                    ? confirmvalidation.confirmDate.message
                    : this.customConfirmValidator.confirmDate.message}
                </span>
              </div>
              <div className="col-6 form-group">
                <label>Confirm Time *</label>

                <input
                  name="confirmTime"
                  type="time"
                  placeholder="Rollout TIme"
                  min={this.state.confirmTime}
                  className={
                    "form-control " +
                    ((confirmvalidation.confirmTime.isInvalid ||
                      this.customConfirmValidator.confirmTime.message) && "has-error")
                  }
                  onChange={this.handleChange}
                  value={this.state.confirmTime}
                  disabled={!editConfirmTime}
                ></input>
                <span className="help-block">
                  {confirmvalidation.confirmTime.message? confirmvalidation.confirmTime.message
                    : this.customConfirmValidator.confirmTime.message}
                </span>
              </div>
            </div>
            <div className="form-row ">
              {editConfirmTime || rescheduleReason ? (
                <div className="form-group col">
                  Reschedule Reason *
                  <textarea
                    value={rescheduleReason ?? ""}
                    onChange={(event) => {
                      this.setState({ rescheduleReason: event.target.value });
                    }}
                    id="reScheduleNotes"
                    name="reScheduleNotes"
                    placeholder="Reschedule Notes"
                    className="form-control"
                  ></textarea>
                </div>
                
              ) : !editConfirmTime ? (
                <div className="form-group ml-2">
                  <input
                    checked={clientConfirm}
                    onChange={(event)=>{this.setState({clientConfirm:event.target.checked,submitEnableConfirmTime:true})}}
                   
                    type="checkbox"
                  /> &nbsp;
                  <label style={{ "textAlign": "center" }}>Confirmed with Client</label>
                    
                
                </div>):<></>
            }
           { !editConfirmTime?<div className="form-group col text-right">
            <span onClick={(event) => this.setState({oldConfirmDate:this.state.confirmDate,oldConfirmTime:this.state.confirmTime,editConfirmTime:true,submitEnableConfirmTime:true})}
                style={{ cursor: "pointer", color: "#2D9BF0" }}
                >
                  Edit
                </span> </div> :<></>}
            </div>
            <span className="help-block">{confirmTimeModalError}</span>

            {editConfirmTime || rescheduleReason? 
            <div className="form-group ml-2">
              <input
                checked={notifyClient}
                onChange={(event) => {
                  this.setState({
                   notifyClient : event.target.checked
                  
                  });
                }}
                type="checkbox"
              /> &nbsp;
              <label style={{ "textAlign": "center" }}>Notify Client?</label>

            
            
          </div>:<></>} 
             
            {this.state.confirm_text_bundle?<span>This is Bundle Order,please confirm with CS before making confirmed date and time</span>:<></>}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              variant="primary"
              onClick={() => {
                if (editConfirmTime) this.confirmTime("Edit");
                else {
                  this.confirmTime("Confirm");
                }
              }}
            >
              Submit
            </button>
             
            <Button variant="secondary" onClick={this.hideConfirmTime}>
              Cancel
            </Button>
           
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showNoteAssignModal}
          onHide={this.hideNoteAssignModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Add Note *</label>

                <textarea
                  type="text"
                  name="order_notes"
                  className={"form-control "}
                  placeholder="Add note"
                  onChange={this.handleChange}
                  value={this.state.order_notes}
                  maxLength={3000}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={this.state.order_notes ? false : true}
              variant="primary"
              onClick={this.addNotes}
            >
              Add Note
            </button>
            <Button variant="secondary" onClick={this.hideNoteAssignModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showPayNurseModal}
          onHide={this.hidePayNurseModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add HomeCare Costs</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Add HomeCare Costs*</label>

                <input
                  type="number"
                  name="home_care_cost"
                  className={"form-control py-2" +
                    (this.state.homecarecostErrorMessage? " has-error" : "")}
                  placeholder="Add HomeCare Costs"
                  onChange={this.handleChange}
                  value={this.state.home_care_cost}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) event.preventDefault();
                  }}
                ></input>
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Add Homecare Notes*</label>

                <textarea
                  type="text"
                  name="homecare_cost_notes"
                  className={"form-control py-2" +
                    (this.state.homecarecostNotesErrorMessage ? " has-error" : "")}
                  placeholder="Add Home care Notes"
                  onChange={(event) => {
                    this.setState({ homecare_cost_notes: event.target.value });
                  }}
                  value={this.state.homecare_cost_notes}

                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={(this.state.home_care_cost && this.state.homecare_cost_notes) ? false : true}
              variant="primary"
              onClick={this.addHomecareCosts}
            >
              Save
            </button>
            <Button variant="secondary" onClick={this.hidePayNurseModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showSpecialInstructionsModal}
          onHide={this.hideSpecialInstructionsModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Special Instructions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Add Special Instructions *</label>

                <textarea
                  type="text"
                  name="special_instructions"
                  className={"form-control "}
                  placeholder="Add Special Instructions  "
                  onChange={this.handleChange}
                  value={this.state.special_instructions}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={this.state.special_instructions ? false : true}
              variant="primary"
              onClick={this.addSpecialInstructions}
            >
              Add Special Instructions
            </button>
            <Button variant="secondary" onClick={this.hideSpecialInstructionsModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showManuallyAssignModal}
          onHide={this.hideManuallyAssignModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Assign Manually</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                {this.formManuallyAssign()}
              </div>
            </div>
          </Modal.Body>
       
          <button
                className="manually-assign-btn"
               
              variant="primary"
              onClick={this.handleManuallyAssign}
            >
              Update
            </button>
         
            <CustomLoader show={this.state.isLoading}/>
        </Modal>
        <CreateOrderViewModal
          cities={cities ?? []}
          countries={countries ?? []}
          coupons={coupons ?? []}
          isVisible={isCreateOrderViewModalVisible}
          onHide={this.hideCreateOrderViewModal}
          onSave={this.handleSaveOrder}
        />
            <Modal
            size="sm"
          show={this.state.isrescheduleDateConfirmPopup}
          //onHide={this.hideRescheduleDateModal}
        >
          <Modal.Header >
            <Modal.Title> Confirm Reschedule Date  </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          {this.calculateDifference(this.state.confirmDate)=="same"?<>Is it okay the Reschedule date is today?</>:this.calculateDifference(this.state.confirmDate)>0?<>Are you sure you want to update to this date? This reschedule is {this.calculateDifference(this.state.confirmDate)+1} days from now!</>
         :this.calculateDifference(this.state.confirmDate)==0?<>Are you sure you want to update to this date? This reschedule is {this.calculateDifference(this.state.confirmDate)+1} day from now!</>
         :this.calculateDifference(this.state.confirmDate)==-1?<>Are you sure you want to update to this date? This reschedule is  {Math.abs(this.calculateDifference(this.state.confirmDate))} day past from now !</>
         :<>Are you sure you want to update to this date? This reschedule is  {Math.abs(this.calculateDifference(this.state.confirmDate))} days past from now !</>
              }
          </Modal.Body>
          <Modal.Footer>
          <button
              onClick={() => {
               // this.rescheduleDateConfirm(this.state.confirmDate);
                this.hideRescheduleDateModal();
              }}
              className="btn btn-danger rounded-pill px-4 py-1"
            >
              Confirm
            </button>
            <button
              className="btn btn-secondary rounded-pill px-4 py-1"
              onClick={this.cancelRescheduleDate}
            >
              Cancel
            </button>
            
          </Modal.Footer>
        </Modal>
        <Modal
          size="sm"
          show={this.state.isShowErrorModal}
        >
          <Modal.Body>
            Please fill in the Service Reference field to complete the order!
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={() => this.setState({isShowErrorModal:false})}
              className="btn btn-outline-success rounded-pill px-4 py-1"
            >
              Okay
            </button>
          </Modal.Footer>
        </Modal>

        <EditCustomerShippingAddress
        title={
          "Please add Shipping Address to proceed"
        }
         shippingAddress={shippingAddressModal ?? null}
         onHide={this.handleHideEditCustomerShippingAddressViewModal}
         onSave={this.handleSaveShippingAddress}
         isVisible={isEditCustomerShippingAddressViewModalVisible}
         order={this.state.order}
         userCityAddress={this.state.userCityAddress??[]}
        />

        <Modal
          size="sm"
          show={this.state.downloadOption}
          onHide={this.hideDownloadOptionModal}
          
        >
          <Modal.Header closeButton>
            <Modal.Title>Please Select an option from below</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div style={{display:"flex", flexDirection:"column", gap:"25px",margin:"auto", alignItems:"center"}}>
          <div>
          <button
              onClick={(e) => this.downloadAllRecords()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789" , color:"white"}}
            >
              Download all records
            </button>
          </div>
          <div>
          <button
              onClick={(e) => this.downloadSelectedRecords()}
              id="show_orders"
              className="btn px-4"
              style={{ backgroundColor: "#0CA789", color:"white" }}
            >
              Download selected records
            </button>
          </div>
          </div>
          </Modal.Body>
          </Modal>
          <Modal/>

      

        <Modal

        show={this.state.showCancellationModal}
        onHide={this.hideCancelModal}
        >
          <Modal.Header  closeButton>
            <Modal.Title> Cancel Order </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div className="row mt-1 ml-1">
            <div>you are about to cancel the following order</div><br/>
            <div style={{fontSize:"16px" , fontWeight:"bold"}}> { order.package_internal_name} &nbsp;&nbsp; {order.child_order_id} </div><br/>
            </div>
            <div className="row mt-1 ">
            <div className="form-group col">
            <div className="input-group">
              <Dropdown
                value={this.state.cancelReason ?? ""}
                onChange={(event,value)=>this.setState({cancelReason: value.value,dropdownOpen:false})}
                id="cancel_reason"
                placeholder="Cancel Reason"
                search
                selection
                options={cancelReasonOptions}
                name="cancelReason"
                onClick={() => this.setState({dropdownOpen:true})}
                open={this.state.dropdownOpen}
                className={
                  "form-control" + (this.state.cancelReasonError ? " has-error" : "")
                }
              />
              
            </div>
            <span className="help-block">{this.state.cancelReasonError}</span>
            </div>
            </div>
             <div className="row">
            <div className="form-group col">
              <div>Cancel Notes *</div>
              <textarea
                value={this.state.cancelNotes ?? ""}
                onChange={this.handleChange} 
                id="CancelNotes"
                name="cancelNotes"
                placeholder="Cancel Notes"
                className={"form-control"+(this.state.cancelError?" has-error":"")}
              ></textarea>
              <span className="help-block">{this.state.cancelError}</span>
            </div>
            
            </div>
            <div className="row mt-1 ml-1">
            <button
                onClick={(event) => {if(this.CancelNotesValidation(this.state.cancelNotes,this.state.cancelReason)) this.cancelChildOrder(order.child_order_id,this.state.cancelNotes,this.state.cancelReason);
                
                }}
                type="button"
                className="btn button btn-danger"
              >
               Confirm Cancellation 
              </button>
              </div>
           
          </Modal.Body>
        </Modal>  
         

        <Modal
          show={this.state.showLabCostNotesModal}
          onHide={this.hideLabCostModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Lab Costs Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Add Lab Cost Notes*</label>

                <textarea
                  type="text"
                  name="lab_cost_notes"
                  className={"form-control "}
                  placeholder="Add Lab Costs Notes"
                  onChange={(event) => {
                    this.setState({ lab_cost_notes: event.target.value });
                  }}
                  value={this.state.lab_cost_notes}
                 
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={this.state.lab_cost_notes ? false : true}
              variant="primary"
              onClick={this.addLabCostNotes}
            >
              Save
            </button>
            <Button variant="secondary" onClick={this.hideLabCostModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>


        <Modal
          show={this.state.showHomecareCostNotesModal}
          onHide={this.hideHomecareCostNotesModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Homecare Costs Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Add Homecare Cost Notes*</label>

                <textarea
                  type="text"
                  name="lab_cost_notes"
                  className={"form-control py-2" +
                  (this.state.homecarecostNotesErrorMessage ? " has-error" : "")}
                  placeholder="Add Home care Notes"
                  onChange={(event) => {
                    this.setState({ homecare_cost_notes: event.target.value });
                  }}
                  value={this.state.homecare_cost_notes}

                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              disabled={this.state.homecare_cost_notes ? false : true}
              variant="primary"
              onClick={this.addHomecareCostNotes}
            >
              Save
            </button>
            <Button variant="secondary" onClick={this.hideHomecareCostNotesModal}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          size="sm"
          show={this.state.showSampleDetailWarning}
          onHide={this.hideSampleDetailWarning}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning :</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-row mb-3">
              <div className="form-group col">
                <label>Sample Detail Warning</label>

                <textarea
                  type="text"
                  name="special_instructions"
                  className={"form-control "}
                  
                  
                  value={this.state.SampleDetailModalData}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn button button-green"
              
              variant="primary"
              onClick={this.hideSampleDetailWarning}
            >
              OK
            </button>
            
          </Modal.Footer>
        </Modal>
        
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewOrdersRevamp);
