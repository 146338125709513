import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import FormValidator from "utils/FormValidator";
import API from "utils/API";
import { connect } from "react-redux";
import Card from "react-bootstrap/Card";
import { filterList } from "utils/List";
import ArchiveOutlinedIcon from "@material-ui/icons/ArchiveOutlined";
import UnarchiveOutlinedIcon from "@material-ui/icons/UnarchiveOutlined";
import EditIcon from "@material-ui/icons/Edit";
import ActionBar from "react-bootstrap/Dropdown";
import MoreHorizOutlinedIcon from "@material-ui/icons/MoreHorizOutlined";
import LocalCafeOutlinedIcon from "@material-ui/icons/LocalCafeOutlined";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Search from "components/Search/Search";
import { isEmpty } from "utils/FormValidator";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import CheckIcon from "@material-ui/icons/Check";
import DataTable from "react-data-table-component";
import AlertBox from "components/AlertBox/AlertBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import MUIRichTextEditor from "mui-rte";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import { ContentState, convertToRaw } from "draft-js";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import CountryDropdown from "components/Common/CountryDropdown";
import SelectSampleType from "components/Common/SelectSampleType";
import SubscriptionForm from "components/Common/SubscriptionForm";
import PackageCityDetails from "components/Common/PackageCityDetails";
import ToggleSwitch from "components/Common/ToggleSwitch";
import { compareStrings } from "utils/commons";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import { isAccessDenied, isUserSuperAdmin, isUserAdmin } from "services/aclService";
import AccessDenied from "../../components/Common/AccessDenied";
import SelectCustomPackages from "components/Common/SelectCustomPackages";
import { fetchValeoPartners } from "services/ValeoPartnersService";
import Select from "react-select"
import  PackageOrders from "components/Common/PackageOrders";

import OtherImagesPackages from "pages/Services/OtherImagesPackages";

import SelectSecondaryCategory from "components/Common/SelectSecondaryCategory"; 
import SupplementSuggestion from "components/Common/SupplementSuggestion";
import { fetchSupplementsSummaryByCountry } from "services/SupplementService";
import PackageBreaking from "components/Common/PackageBreaking";

import SelectSupplements from "../CouponCode/SelectSupplements";
import SelectCoachConsultation from "../../components/Common/SelectCoachConsultation";


import PackageSampleDetails from "../../components/Common/PackageSampleDetails";
import Logs from "components/Logs/Logs";

import SelectCustomTestAddons from "components/Common/SelectCustomTestAddons";


import internalCategoryOptions from "utils/InterenalCategory";



import RichTextEditor from "react-rte";
import {CharLimit} from "utils/CharachterLimit";


const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const BASE_URL_PACKAGE_SAMPLE_TYPES = "package-sample-type/"
const sampleDetails_url="admin/sample-details";

const validTypes = ['video/mp4','video/webm'];
const imageValidTypes = ['image/jpeg','image/png','image/gif','image/svg+xml','image/webp'];
const GENDER_OPTIONS = [
  {
    text: "Both",
    value: "B",
    key: "B",
  },
  {
    text: "Male",
    value: "M",
    key: "M",
  },
  {
    text: "Female",
    value: "F",
    key: "F",
  },
];

class ManageTests extends Component {
  constructor(props) {
    super(props);
    // this.formFields = ;
    // Commented because now we are using custom validation.
    this.validator = new FormValidator([
      // {
      //   field: "name",
      //   method: "isEmpty",
      //   validWhen: false,
      //   isRtl: false,
      //   message: "Name is required."
      // },
      // {
      //   field: "title",
      //   method: "isEmpty",
      //   validWhen: false,
      //   isRtl: false,
      //   message: "Title is required."
      // },
      // {
      //   field: "title_arabic",
      //   method: "isEmpty",
      //   validWhen: false,
      //   isRtl: true,
      //   message: "Title is required."
      // },
      // {
      //   field: "sub_heading",
      //   method: "isEmpty",
      //   validWhen: true,
      //   isRtl: false,
      //   message: "Sub Heading is required."
      // },
      // {
      //   field: "sub_heading_arabic",
      //   method: "isEmpty",
      //   validWhen: true,
      //   isRtl: true,
      //   message: "Sub Heading is required."
      // },
      // {
      //   field: "description",
      //   method: "isEmpty",
      //   validWhen: false,
      //   isRtl: false,
      //   message: "Description is required."
      // },
      // {
      //   field: "description_arabic",
      //   method: "isEmpty",
      //   validWhen: false,
      //   isRtl: true,
      //   message: "Description is required."
      // },
      // {
      //   field: "retail_price",
      //   method: "isEmpty",
      //   validWhen: false,
      //   message: "Price is required.",
      // },
      // {
      //   field: "retail_price",
      //   method: "isFloat",
      //   validWhen: true,
      //   isRtl: false,
      //   min: 0,
      //   message: "Invalid price.",
      // },
      // {
      //   field: "discount_type",
      //   method: "isEmpty",
      //   validWhen: false,
      //   message: "Discount Type is required."
      // },
      // {
      //   field: "discount_value",
      //   method: "isEmpty",
      //   validWhen: false,
      //   message: "Discount is required.",
      // },
      // {
      //   field: "discount_value",
      //   method: "isFloat",
      //   validWhen: true,
      //   isRtl: false,
      //   min: 0,
      //   message: "Invalid Discount.",
      // },
      // {
      //   field: "final_price",
      //   method: "isEmpty",
      //   validWhen: false,
      //   message: "Final Price is required.",
      // },
      // {
      //   field: "final_price",
      //   method: "isFloat",
      //   validWhen: true,
      //   isRtl: false,
      //   min: 0,
      //   message: "Invalid Price.",
      // },
      // {
      //   field: "currency",
      //   method: "isEmpty",
      //   validWhen: false,
      //   isRtl: false,
      //   message: "Currency is required."
      // },
      // {
      //   field: "infographic_thumbnail",
      //   type: "image",
      //   method: "isEmpty",
      //   validWhen: false,
      //   args: [{ min: 0 }],
      //   message: "Thumbnail is required."
      // },
      // {
      //   field: "infographic_image",
      //   type: "image",
      //   method: "isEmpty",
      //   validWhen: false,
      //   args: [{ min: 0 }],
      //   message: "Image is required."
      // },
      // {
      //   field: "rank",
      //   method: "isEmpty",
      //   validWhen: false,
      //   isRtl: false,
      //   message: "Rank is required."
      // },
      // {
      //   field: "status",
      //   method: "isEmpty",
      //   validWhen: false,
      //   isRtl: false,
      //   message: "Package Status is required.",
      // },
      // {
      //   field: "product",
      //   method: "isEmpty",
      //   validWhen: false,
      //   isRtl: false,
      //   message: "Product is required.",
      // },
    ]);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      title: '',
      name: '',
      title_arabic: '',
      sub_heading: '',
      sub_heading_arabic: '',
      description: '',
      description_arabic: '',
      what_happens_next: "",
      what_happens_next_ar: "",
      retail_price: 0,
      discount_type: '',
      discount_value: 0,
      final_price: 0,
      currency: 'AED',
      infographic_thumbnail: '',
      infographic_image: '',
      position_value: 0,
      status: '',
      
      msg_en: '',
      msg_ar: '',
      home_appoitment_msg_en: '',
      home_appoitment_msg_ar: '',
      operation_type: [],
      home_appointment_req_desc: '',
      home_appointment_req_arabic: '',
      remarks: '',
      product: '',
      validation: this.validator.valid(),
      testList: [],
      activeOrders: [],
      formHeading: "Create Package",
      test_category: 1,
      editPackageId: '',
      discountOptions: [
        {
          text: "Percentage",
          value: "percentage",
          key: "percentage",
        },
        {
          text: "Fixed",
          value: "fixed",
          key: "fixed",
        },
      ],
      bestSellerOptions:[
        {
          text: "YES",
          value: true,
          key: "yes",
        },
        {
          text: "NO",
          value: false,
          key: "no",
        },

      ],
      currencyOptions: [
        {
          text: "AED",
          value: "AED",
          key: "AED",
        }
      ],
      discountValue: "",
      packageTypeOptions: [
        {
          text: "Yes",
          value: "yes",
          key: "yes",
        },
        {
          text: "No",
          value: "no",
          key: "no",
        },
      ],
      productStatus: [
        {
          text: "Inactive",
          value: "Inactive",
          key: "inactive",
        },
        {
          text: "Active",
          value: "Active",
          key: "active",
        },
      ],

      status: "",
      homeAppointment: "",
      coachNote: "",
      pdfDownload: "",
      imgSrc: "",
      image: "",
      packageimgSrc: "",
      surveyAttached: "",
      showModal: false,
      confirmItem: null,
      confirmMessage: "",
      confirmType: null,
      confirmTitle: "",
      successMessage: "",
      searchExpanded: false,
      searchTerm: "",
      searchApplied: false,
      results: [],
      resultCount: 0,
      showItems: "Active",
      isLoaded: false,
      loadError: "",
      productList: [],
      operationList: [],
      countries: [],
      products: [],
      selectedCountry: null,
      errors: {},
      editables: {},
      isNewPackage: false,
      fasting_hours: null,
      member_applicable: null,
      member: [],
      valeoPartners:[],
      valeoPartnerOptions:[],
      customPackageIdOrders:[],
      packageIdTotalRows:0,
      is_food_intolerance_test:false,
      food_items:[],
      foodItemOptions:[],
      internal_category:"None",

      default_coach_id: null,
      defaultCoachOptions: [],

      secondaryCategoryOptions:[],

      primary_media_type:null,
      packages_media:[],
      video_s3_url:"",
      infographic_thumbnail_s3_url:"",
      secondaryCategoryOptions:[],
      is_b2b_package:false,

      default_secondary_coach_id:null,
      secondaryCoachOptions:[],

      customPackageBundles:[],
      customPackagesForPackageBreaking:[],

      memberApplicableError:"",

      package_uuid:'',
      is_eligible_for_cod:true,

       bloodPackages:[],
       

      is_vat_excluded:false,


      is_allow_coach_cons_pkg_addition:false,
      is_allow_supplement_addition:false,
      is_eligible_for_cod:true,
      pageIdForLogs : null,

      custom_addons:[],

      descriptionTxtEn: RichTextEditor.createEmptyValue(),
      descriptionTxtAr: RichTextEditor.createEmptyValue(),
      whatHappensNextTxtEn: RichTextEditor.createEmptyValue(),
      whatHappensNextTxtAr: RichTextEditor.createEmptyValue(),
      productListEdit:[],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        sortable: true,
        width: "100px",
      },
      {
        name:"UID",
        selector:"package_uuid",
        sortable:true,
        width: "150px",
      },
      {
        name: "Name",
        selector: "name",
        sortable: true,
        wrap: true,
      },
      // {
      //   name: "Product Category",
      //   selector: "product",
      //   sortable: true,
      //   wrap: true,
      //   format: (row) => this.renderProduct(row.product)
      // },
      {
        name: "Product Category",
        selector: "product_title",
        wrap: true,
        sortable: true,
      },
      {
       name:"Secondary Category",
       selector:"secondary_categories_name",
       wrap: true,
       sortable: true,
      },
      {
        name: "Type",
        selector: "operation_type",
        sortable: true,
        wrap: true,
      },
      {
        name: "Rank",
        selector: "position_value",
        sortable: true,
        width: "125px",
        center: true,
      },
      {
        name: "Status",
        selector: "status",
        sortable: true,
        center: true,
        maxWidth: "150px",
        cell: p => this.renderStatus(p.status),
      },
      {
        name: "View",
        center: true,
        cell: (p) => this.renderViewButton(p.id),
      },
    ];

    this.submitted = false;
    this.overviewMessage = "";
    this.overviewArMessage = "";
    this.home_AppointmentMessage = "";
    this.home_AppointmentArMessage = "";
    this.handleChange = this.handleChange.bind(this);
    this.saveTest = this.saveTest.bind(this);
    this.updateTest = this.updateTest.bind(this);
    this.formReset = this.formReset.bind(this);
    this.editTest = this.editTest.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.showModal = this.showModal.bind(this);
    this.hideConfirm = this.hideConfirm.bind(this);
    this.showConfirm = this.showConfirm.bind(this);
    this.toggleActiveStatus = this.toggleActiveStatus.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSearchExit = this.handleSearchExit.bind(this);
    this.setShowItems = this.setShowItems.bind(this);
    this.selecteImage = this.selecteImage.bind(this);
    this.selectePackageImage = this.selectePackageImage.bind(this);
    this.saveOverviewEng = this.saveOverviewEng.bind(this);
    this.saveOverviewAr = this.saveOverviewAr.bind(this);
    this.saveHomeAppointmentEng = this.saveHomeAppointmentEng.bind(this);
    this.saveHomeAppointmentAr = this.saveHomeAppointmentAr.bind(this);
  }

  componentWillUnmount() {
    this.props.toggleLoading({
      isLoading: false
    });
  }

  saveOverviewEng(data) {
    this.setState({
      description: JSON.stringify(convertToRaw(this.event.getCurrentContent())),
      msg_en: stateToHTML(this.event.getCurrentContent()),
    });
  }

  saveOverviewAr(data) {
    this.setState({
      description_arabic: JSON.stringify(
        convertToRaw(this.event_ar.getCurrentContent())
      ),
      msg_ar: stateToHTML(this.event_ar.getCurrentContent()),
    });
  }

  saveHomeAppointmentEng(data) {
    this.setState({
      home_appointment_req_desc: JSON.stringify(
        convertToRaw(this.event_home_en.getCurrentContent())
      ),
      home_appoitment_msg_en: stateToHTML(this.event_home_en.getCurrentContent()),
    });
  }

  saveHomeAppointmentAr(data) {
    this.setState({
      home_appointment_req_arabic: JSON.stringify(
        convertToRaw(this.event_home_ar.getCurrentContent())
      ),
      home_appoitment_msg_ar: stateToHTML(this.event_home_ar.getCurrentContent()),
    });
  }

  handleMediaChange = (event,text) => {

   
    this.setState({primary_media_type:text})
   
  };

  returnCategories(row) {
    if (this.state.productList) {
      let temp = ''
      if (this.state.productList.filter((item3) => item3.key == row.product).length) {
        temp = temp + this.state.productList.filter((item3) => item3.key == row.product)[0].text;
      }
      return temp
    }
  }

  renderProduct = (productId) => {
    const product = this.findProduct(productId);
    return product ? product.title : "";
  };

  findProduct = (id) =>
    id && this.state.products
      ? this.state.products.find((product) => product.id === id)
      : null;

  handleChange=(event)=> {
    const target = event.target;
    
    this.setState({ [target.name]: event.target.value });

    // Commented because now we are not calculating the discount value.
    // this.setState({ [target.name]: target.value }, () => {
    //   if (target.name === "retail_price" || target.name === "final_price")
    //     this.caluclateDiscount();
    // });
  }

  // Commented this code because we should not change rank options on changing product categories.
  // handleProductChange(value) {
  //   // if (value === "none") {
  //   //   this.setState({ product: null });
  //   //   return;
  //   // }
  //   this.setState({ product: value.value });
  //   let rankArray = [
  //     {
  //       text: 0,
  //       value: 0,
  //       key: 0,
  //     }
  //   ];
  //   let rankSotable = [];
  //   let rank = 1;
  //   this.state.testList.map((item) => {
  //     if (!item.is_deleted && item.product == value.value) {
  //       rankSotable[rank] = item.id;
  //       rankArray.push({
  //         text: rank,
  //         value: rank,
  //         key: rank,
  //       });
  //       rank = rank + 1;
  //     }
  //   });
  //   this.setState({
  //     rankObject: rankArray,
  //     rankSortable: rankSotable,
  //     rank: rank - 1
  //   });
  // }

  validate = () => {
    const errors = {};

    // Validate details form
    const { 
      name, 
      title, 
      title_arabic, 
      status, 
      product, 
      position_value, 
      sub_heading, 
      sub_heading_arabic, 
      what_happens_next,
      what_happens_next_ar,
      zoho_account_id,
      imgSrc, 
      packageimgSrc,
      sample_type,
      fasting,
      fasting_hours,
      member_applicable,
      display_name,
      display_name_ar,
      special_tag,
      special_tag_ar,
      customPackages,
      is_allow_custom_package_addition,
      customPackageIdOrderDetails,
      is_food_intolerance_test,
      food_items,
      internal_category,
      primary_media_type,
      packages_media,
      video_s3_url,
      infographic_thumbnail_s3_url
    } = this.state;
 
    if (!name || name.trim() === "")
      errors.name = "Internal Name is a required field!";
    else if (name.length > CharLimit.TWO_HUNDRED_LIMIT)
      errors.name = "Internal Name can not take more than 200 characters!";

    if (!title || title.trim() === "")
      errors.title = "External Name (English) is a required field!";
    else if (title.length > CharLimit.TWO_HUNDRED_LIMIT)
      errors.name = "External Name (English) can not take more than 200 characters!";

    if (!title_arabic || title_arabic.trim() === "")
      errors.title_arabic = "External Name (Arabic) is a required field!";
    else if (title_arabic.length > CharLimit.TWO_HUNDRED_LIMIT)
      errors.name = "External Name (Arabic) can not take more than 200 characters!";

    if (!status || status.trim() === "")
      errors.status = "Status is a required field!";

    if (product === null || product === undefined || product.toString().trim() === "")
      errors.product = "Product is a required field!";

    if (position_value === null || position_value === undefined)
      errors.rank = "Rank is a required field!";

    if (sub_heading && sub_heading.length > CharLimit.TWO_HUNDRED_LIMIT)
      errors.sub_heading = "Sub-Heading (English) can not take more than 200 characters!";

    if (sub_heading_arabic && sub_heading_arabic.length > CharLimit.TWO_HUNDRED_LIMIT)
      errors.sub_heading_arabic = "Sub-Heading (Arabic) can not take more than 200 characters!";

    this.validateDescriptionTextArea("description", "Description (English)", errors, true);
    this.validateDescriptionTextArea("description_arabic", "Description (Arabic)", errors, true);

    if (what_happens_next && what_happens_next.length > CharLimit.THOUSAND_LIMIT)
      errors.what_happens_next = "What happens next? (English) can not take more than 1000 characters!"

    if (what_happens_next_ar && what_happens_next_ar.length > CharLimit.THOUSAND_LIMIT)
      errors.what_happens_next_ar = "What happens next? (Arabic) can not take more than 1000 characters!"
    if(primary_media_type=="Image"){
    if (!imgSrc)
      errors.imagePrimary = " Image is a required field!";
    }
    if(primary_media_type=="Video"){
    if (!packageimgSrc)
      errors.videoThumbnailImage = "video Thumbnail Image is a required field!";
    if(!video_s3_url)
      errors.videoUrl="video is required field";
    }
    // Validate Package Type Form
    this.validateDescriptionTextArea("home_appointment_req_desc", "Description (English)", errors);
    this.validateDescriptionTextArea("home_appointment_req_arabic", "Description (Arabic)", errors);

    // Validate Location and Pricing form
    const { retail_price, final_price, discount_type, discount_value } = this.state;

    if (retail_price === null || retail_price === undefined || retail_price.toString().trim() === "")
      errors.retail_price = "Retail Price is a required field!"
    else if (Number.isNaN(Number(retail_price)))
      errors.retail_price = "Retail Price should be a valid number!";
    else if (retail_price < 0)
      errors.retail_price = "Retail Price should not be a negative number!";

    if (final_price === null || final_price === undefined || final_price.toString().trim() === "")
      errors.final_price = "Final Price is a required field!"
    else if (Number.isNaN(Number(final_price)))
      errors.final_price = "Final Price should be a valid number!";
    else if (final_price < 0)
      errors.final_price = "Final Price should not be a negative number!";

    if (discount_type === null || discount_type === undefined || discount_type.toString().trim() === "")
      errors.discount_type = "Discount Type is a required field!"

    if (discount_value === null || discount_value === undefined || discount_value.toString().trim() === "")
      errors.discount_value = "Discount Value is a required field!"
    else if (Number.isNaN(Number(discount_value)))
      errors.discount_value = "Discount Value should be a valid number!";
    else if (discount_value < 0)
      errors.discount_value = "Discount value should not be a negative number!"

    if (sample_type === null || sample_type === undefined)
      errors.sample_type = "Sample Type is a required field!";

    if (fasting && (fasting_hours === null || fasting_hours === undefined))
      errors.fasting_hours = "Fasting hours is a required field!";
    else if (fasting && Number.isNaN(Number(fasting_hours)))
      errors.fasting_hours = "Fasting hours should be a valid numbder!";
    else if (fasting && fasting_hours < 1)
      errors.fasting_hours = "Fasting hours shoule be greater than 0!";

    if (this.state.enable_subscription) {
      const subscriptionItems = this.state.subscription_items;
      const activeItemCount =
        subscriptionItems && subscriptionItems.length
          ? subscriptionItems.filter((it) => it.status === "Active").length
          : 0;
      if (activeItemCount === 0) {
        errors.subscription_items = "At least one active item is required!";
      }
    }

    if (member_applicable === null || member_applicable === undefined)
      errors.member_applicable = "Members Applicable is a required field!";
    else if (Number.isNaN(member_applicable))
      errors.member_applicable = "Members Applicable should be a valid number!"
    else if (member_applicable < 1)
      errors.member_applicable = "Members Applicable should be greater than 0!"
    
    if (!display_name || display_name.trim() === "")
      errors.display_name = "Display Name (English) is a required field!";
    else if (display_name.length > CharLimit.HUNDRED_LIMIT)
      errors.display_name = "Display Name (English) can not take more than 100 characters!";

    if (!display_name_ar || display_name_ar.trim() === "")
      errors.display_name_ar = "Display Name (Arabic) is a required field!";
    else if (display_name_ar.length > CharLimit.HUNDRED_LIMIT)
      errors.display_name_ar = "Display Name (Arabic) can not take more than 100 characters!";
    if(zoho_account_id && zoho_account_id.length > CharLimit.FOURTY_FIVE_LIMIT)
      errors.zoho_account_id="Zoho  Account Id is limited to 45 characters";
      else if(!zoho_account_id){
        errors.zoho_account_id="Zoho  Account Id is a required field!";
      }
    if (special_tag && special_tag.length > CharLimit.HUNDRED_LIMIT)
      errors.special_tag = "Special Tag (English) can not take more than 100 characters!";

    if (special_tag_ar && special_tag_ar.length > CharLimit.HUNDRED_LIMIT)
      errors.special_tag_ar = "Special Tag (Arabic) can not take more than 100 characters!";

      if(customPackages && customPackages.length>3){
        errors.customPackages="You can not add more than three items"
      }

      if(is_food_intolerance_test){
        if(food_items.length<=0){
          errors.foodLists="Select atleast 1 Food item"
        }
      }


    return Object.keys(errors).length === 0 ? null : errors;
  }

  validateDescription = (field, label, errors, isRequired = false) => {
    const MAX_LENGTH =   CharLimit.TEN_THOUSAND_LIMIT;
    const description = this.state[field];

    if (isRequired && (!description || description.trim() === ""))
      errors[field] = `${label} is a required field!`;
    else if (isRequired && description && JSON.parse(description)["blocks"][0].text === "")
      errors[field] = `${label} is a required field!`;
    else if (description && JSON.parse(description)["blocks"][0].text.length > MAX_LENGTH)
      errors[field] = `${label} can not take more than ${MAX_LENGTH} characters!`;
  }

  validateDescriptionTextArea = (field, label, errors, isRequired = false) => {
    const MAX_LENGTH = CharLimit.TEN_THOUSAND_LIMIT;
    const description = this.state[field];

    if (isRequired && (!description || description.trim() === ""))
      errors[field] = `${label} is a required field!`;
    else if (description && description.length > MAX_LENGTH)
      errors[field] = `${label} can not take more than ${MAX_LENGTH} characters!`;
  }

  async saveTest() {
    // Validation part commentted out
    const validation = this.validator.validate(this.state);
    this.submitted = true;
    this.setState({ validation });
    
    const errors =  this.validate();
    this.setState({errors: errors || {}});

    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    if (validation.isValid) {
      const {
        name,
        title,
        title_arabic,
        sub_heading,
        sub_heading_arabic,
        description,
        description_arabic,
        what_happens_next,
        what_happens_next_ar,
        retail_price,
        discount_type,
        discount_value,
        final_price,
        currency,
        zoho_account_id,
        infographic_thumbnail,
        infographic_image,
        position_value,
        status,
        is_best_seller,
        operation_type,
        home_appointment_req_desc,
        home_appointment_req_arabic,
        remarks,
        product,
        msg_en,
        msg_ar,
        home_appoitment_msg_ar,
        home_appoitment_msg_en,
        sample_type,
        fasting,
        fasting_hours,
        member_applicable,
        member,
        display_name,
        display_name_ar,
        special_tag,
        special_tag_ar,
        is_allow_custom_package_addition,
        is_paid_doctor_consultation,
        is_require_sample_collection,
        customPackages,
        custom_pkg_addons,
        partner_id,
        is_food_intolerance_test,
        food_items,
        internal_category,

        secondary_categories,



        video_s3_url,
      primary_media_type,
      infographic_thumbnail_s3_url,
      packages_media,
        is_default_suggested_supplement_required, supplementSuggestion,
        is_b2b_package,
        default_coach_id,
        default_secondary_coach_id,

        is_bundle,
        customPackageBundles,
        is_eligible_for_cod,

        package_uuid,
        is_vat_excluded,
        is_allow_coach_cons_pkg_addition,
        is_allow_supplement_addition, supplement_addons,
        coach_cons_pkg_addons,

       
        mappingPackageSamples,


        custom_addons,

        descriptionTxtEn,
        descriptionTxtAr,
        whatHappensNextTxtEn,
        whatHappensNextTxtAr,

      } = this.state;


     
      if(is_bundle && customPackageBundles.length===0) {
        this.showErrorNotification("Please add bundle packages to member")
        return 
      }
      let apiOptions = {};
      let formdata = new FormData();
      formdata.append("name", name)
      formdata.append("title", title)
      formdata.append("title_arabic", title_arabic)
      formdata.append("sub_heading", sub_heading || "")
      formdata.append("sub_heading_arabic", sub_heading_arabic || "")
      // formdata.append("description", msg_en)
      // formdata.append("description_arabic", msg_ar)
      formdata.append("description", description)
      formdata.append("description_arabic", description_arabic)
      formdata.append("what_happens_next", what_happens_next || "");
      formdata.append("what_happens_next_ar", what_happens_next_ar || "");
      formdata.append("retail_price", retail_price)
      formdata.append("discount_type", discount_type)
      formdata.append("discount_value", discount_value)
      formdata.append("final_price", final_price)
      formdata.append("currency", currency)
      if (internal_category == "None") {
        formdata.append("internal_category", "");
      }
      else {
        formdata.append("internal_category", internal_category);
      }
      if (infographic_thumbnail && infographic_thumbnail.name) {
        formdata.append("infographic_image", infographic_thumbnail, infographic_thumbnail.name)
        formdata.append("infographic_image_s3", infographic_thumbnail, infographic_thumbnail.name)
      }
      if (infographic_image && infographic_image.name) {
        formdata.append("infographic_image", infographic_image, infographic_image.image)
        formdata.append("infographic_image_s3", infographic_image, infographic_image.name)
      } 
      formdata.append("position_value", position_value)
      formdata.append("status", status)
      formdata.append("is_best_seller",is_best_seller);
      formdata.append("operation_type", operation_type)
      if (operation_type.indexOf("Home Appointment") > -1) {
        // formdata.append("home_appointment_req_desc", home_appoitment_msg_en)
        // formdata.append("home_appointment_req_arabic", home_appoitment_msg_ar)
        formdata.append("home_appointment_req_desc", home_appointment_req_desc);
        formdata.append("home_appointment_req_arabic", home_appointment_req_arabic);
      } else {
        formdata.append("home_appointment_req_desc", null)
        formdata.append("home_appointment_req_arabic", null)
      }

      formdata.append("remarks", null)
      formdata.append("product", product)
      if (this.state.selectedCountry)
        formdata.append("country", this.state.selectedCountry.id);

      formdata.append("sample_type", sample_type);
      formdata.append("fasting", fasting);
      formdata.append("fasting_hours", fasting_hours ?? 0);
      formdata.append("display_name", display_name ?? "");
      formdata.append("display_name_ar", display_name_ar ?? "");
      formdata.append("special_tag", special_tag ?? "");
      formdata.append("special_tag_ar", special_tag_ar ?? "");
      formdata.append("is_paid_doctor_consultation", is_paid_doctor_consultation || false);
     
      formdata.append("is_require_sample_collection", is_require_sample_collection || false);
      formdata.append("is_allow_custom_package_addition",is_allow_custom_package_addition||false)
      formdata.append("zoho_account_id",zoho_account_id ?? "");
      formdata.append("member_applicable", member_applicable);
      if (customPackages && customPackages.length) {
        customPackages.forEach((it) => formdata.append("custom_pkg_addons", it));
      }
      if (member && member.length) {
        for (const m of member) {
          m.package_id = null;
          formdata.append("member_gender", JSON.stringify(m));
        }
      }

         if(partner_id){
          formdata.append("partner_id",partner_id?? null);
         }

         
          formdata.append("is_food_intolerance_test",is_food_intolerance_test ??false);
         if(packages_media && packages_media.length > 0){
         packages_media.forEach((media)=> formdata.append("packages_media", JSON.stringify(media)));
         }
      formdata.append("primary_media_type", primary_media_type);
      if(primary_media_type=="Video"){
      formdata.append("video_s3_url",video_s3_url);
     // formdata.append("infographic_thumbnail_s3_url",infographic_thumbnail_s3_url);
      }
  
         if(food_items && food_items.length>0){
          food_items.forEach((it)=>formdata.append("food_items", it))
         }
          
         if (secondary_categories && secondary_categories.length>0) {
          secondary_categories.forEach((it) => formdata.append("secondary_categories", it));
        }

        formdata.append("is_default_suggested_supplement_required", is_default_suggested_supplement_required ?? false);

        
        if(supplementSuggestion && supplementSuggestion.length > 0){
          supplementSuggestion.forEach((media)=> formdata.append("package_supplements", JSON.stringify(media)));
          }

        if(mappingPackageSamples && mappingPackageSamples.length > 0){
            mappingPackageSamples.forEach((packageSample)=> formdata.append("sample_details", JSON.stringify(packageSample)));
  
        }

        formdata.append("is_b2b_package", is_b2b_package ?? false);


        if(!default_coach_id) {
          formdata.append("default_coach_id","");
        }
        else{
          formdata.append("default_coach_id", default_coach_id);
        }

        if(!default_secondary_coach_id) {
          formdata.append("default_secondary_coach_id","");
        }
        else{
          formdata.append("default_secondary_coach_id", default_secondary_coach_id);
        }
        formdata.append("is_eligible_for_cod",is_eligible_for_cod ?? true);
        formdata.append("is_vat_excluded",is_vat_excluded ?? false);


        formdata.append("is_bundle",is_bundle??false)
        const mappingCustomPackageBundles =customPackageBundles.map((item)=>{
          
          const itemObject = {
           "appointment_date_value":item.appointment_date_value,
           "id":item?.id??null,
           "is_active":item.is_active,
           "member":item.member,
            "package_id": Number(item.package_id),
            "package_name" :item.package_name,
             "package_type":item.package_type
          }
           return itemObject
        })
        if(is_bundle){
          if (mappingCustomPackageBundles && mappingCustomPackageBundles.length>0) {
            mappingCustomPackageBundles.forEach((it) => formdata.append("custom_package_bundles", JSON.stringify(it)));
          }
        }
        

        formdata.append("is_allow_coach_cons_pkg_addition", is_allow_coach_cons_pkg_addition ?? false);
        formdata.append("is_allow_supplement_addition",is_allow_supplement_addition??false)
        if (supplement_addons && supplement_addons.length>0) {
          supplement_addons.forEach((it) => formdata.append("supplement_addons", it));
        }
        if(coach_cons_pkg_addons && coach_cons_pkg_addons &&is_allow_coach_cons_pkg_addition) {
           formdata.append("coach_cons_pkg_addons",coach_cons_pkg_addons );
        }

        if (custom_addons && custom_addons.length>0) {
          custom_addons.forEach((it) => formdata.append("custom_addons", it));
        }

        formdata.append("description_richtext_en",descriptionTxtEn?.toString("html"));
        formdata.append("description_richtext_ar",descriptionTxtAr?.toString("html"))
        formdata.append("what_happens_next_richtext_en",whatHappensNextTxtEn?.toString("html"))
        formdata.append("what_happens_next_richtext_ar",whatHappensNextTxtAr?.toString("html"))
      apiOptions = {
        method: "post",
        url: "packages/",
        data: formdata,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
        },
      };
      try {
        this.showLoading();
        const { data: newCustomPackage } = await API.request(apiOptions);
        const packageId = newCustomPackage?.id ?? null;
        await this.saveSubscriptionItems(packageId, true);
        await this.saveCityPackgeDetails(packageId);
        this.hideModal();
        this.loadData();
        this.showSuccessNotification("Package successfully created.");
      } catch (error) {
        const errorData = error.response?.data;

        if (errorData) {
          // Get the field name dynamically (e.g., "subscription_description")
          const fieldName = Object.keys(errorData)?.[0];
  
          if (fieldName) {
            const errorMessage = errorData[fieldName]?.[0]; // Get the error message for the field
  
            console.error(`Field: ${fieldName}, Error: ${errorMessage}`);
            this.showErrorNotification(`${fieldName}: ${errorMessage}`); // Show field and error message
          }
        }
        else{
        console.log("Error on updating package", error);
        this.showErrorNotification("Technical Error occurred while creating/updating package. Please report the issue on #valeo-tech slack channel");
        }
      } finally {
        this.hideLoading();
      }
    }
  }

  async updateTest() {
    const errors =  this.validate();
    this.setState({errors: errors || {}});

    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }
    // Validation part commentted out
    const validation = this.validator.validate(this.state);
    console.log("UpdateTest: ", validation);
    this.submitted = true;
    this.setState({ validation });
    if (validation.isValid) {
      const {
        name,
        title,
        title_arabic,
        sub_heading,
        sub_heading_arabic,
        description,
        description_arabic,
        what_happens_next,
        what_happens_next_ar,
        retail_price,
        discount_type,
        discount_value,
        final_price,
        currency,
        infographic_thumbnail,
        infographic_image,
        position_value,
        status,
        operation_type,
        is_best_seller,
        home_appointment_req_desc,
        home_appointment_req_arabic,
        remarks,
        product,
        msg_ar,
        msg_en,
        home_appoitment_msg_ar,
        home_appoitment_msg_en,
        sample_type,
        fasting,
        fasting_hours,
        member_applicable,
        member,
        zoho_account_id,
        display_name,
        display_name_ar,
        special_tag,
        special_tag_ar,
        is_allow_custom_package_addition,
        is_paid_doctor_consultation,
        is_require_sample_collection,
        customPackages,
        custom_pkg_addons,
        partner_id,
        is_food_intolerance_test,
        food_items,
        internal_category,

        default_coach_id,
        secondary_categories,

        video_s3_url,
      primary_media_type,
      infographic_thumbnail_s3_url,
      packages_media,

        is_default_suggested_supplement_required, supplementSuggestion,
        is_b2b_package,
        default_secondary_coach_id,
        mappingPackageSamples,


        is_bundle,
        customPackageBundles,
        package_uuid,

        is_eligible_for_cod,
        is_vat_excluded,

        is_allow_coach_cons_pkg_addition,
        is_allow_supplement_addition, supplement_addons,
        coach_cons_pkg_addons,


        custom_addons,

        descriptionTxtEn,
        descriptionTxtAr,
        whatHappensNextTxtEn,
        whatHappensNextTxtAr,

      } = this.state;
      
      if(is_bundle && customPackageBundles.length===0) {
        this.showErrorNotification("Please add bundle packages to member")
        return 
      }

      let apiOptions = {};
      let formdata = new FormData();
      formdata.append("name", name)
      formdata.append("title", title)
      formdata.append("title_arabic", title_arabic)
      formdata.append("sub_heading", sub_heading || "")
      formdata.append("sub_heading_arabic", sub_heading_arabic || "")
      // formdata.append("description", msg_en)
      // formdata.append("description_arabic", msg_ar)
      formdata.append("description", description)
      formdata.append("description_arabic", description_arabic)
      formdata.append("what_happens_next", what_happens_next || "");
      formdata.append("what_happens_next_ar", what_happens_next_ar || "");
      formdata.append("zoho_account_id",zoho_account_id || "");
      formdata.append("retail_price", retail_price)
      formdata.append("discount_type", discount_type)
      formdata.append("discount_value", discount_value)
      formdata.append("final_price", final_price)
      formdata.append("currency", currency)
      if (internal_category == "None") {
        formdata.append("internal_category", "");
      }
      else{
        formdata.append("internal_category", internal_category);
      }
      if (infographic_thumbnail && infographic_thumbnail.name) {
        formdata.append("infographic_image", infographic_thumbnail, infographic_thumbnail.name);
        formdata.append("infographic_image_s3", infographic_thumbnail, infographic_thumbnail.name);
      }
      if (infographic_image && infographic_image.name) {
        formdata.append("infographic_image", infographic_image, infographic_image.name);
       formdata.append("infographic_image_s3", infographic_image, infographic_image.name);
      }
      formdata.append("position_value", position_value)
      formdata.append("status", status)
      formdata.append("is_best_seller", is_best_seller);
      formdata.append("operation_type", operation_type)
      if (operation_type.indexOf("Home Appointment") > -1) {
        // formdata.append("home_appointment_req_desc", home_appoitment_msg_en)
        // formdata.append("home_appointment_req_arabic", home_appoitment_msg_ar)
        formdata.append("home_appointment_req_desc", home_appointment_req_desc);
        formdata.append("home_appointment_req_arabic", home_appointment_req_arabic);
      } else {
        formdata.append("home_appointment_req_desc", null)
        formdata.append("home_appointment_req_arabic", null)
      }
      formdata.append("remarks", null)
      formdata.append("product", product)
      formdata.append("sample_type", sample_type);
      formdata.append("fasting", fasting);
      formdata.append("fasting_hours", fasting_hours ?? 0);
      formdata.append("member_applicable", member_applicable);
      if (member && member.length) {
        for (const m of member) {
          m.package_id = this.state.editPackageId ?? null;
          formdata.append("member_gender", JSON.stringify(m));
        }
      }
      formdata.append("display_name", display_name ?? "");
      formdata.append("display_name_ar", display_name_ar ?? "");
      formdata.append("special_tag", special_tag ?? "");
      formdata.append("special_tag_ar", special_tag_ar ?? "");
      formdata.append("is_paid_doctor_consultation", is_paid_doctor_consultation || false);
      
      formdata.append("is_require_sample_collection",is_require_sample_collection|| false);
      formdata.append("is_allow_custom_package_addition", is_allow_custom_package_addition || false);
      if(!default_coach_id) {
        formdata.append("default_coach_id","");
      }
      else{
        formdata.append("default_coach_id", default_coach_id);
      }
      if (customPackages && customPackages.length) {
        customPackages.forEach((it) => formdata.append("custom_pkg_addons", it));
      }

       if(partner_id){
        formdata.append("partner_id",partner_id ?? null);
       }
        formdata.append("package_uuid",package_uuid);
       
        formdata.append("is_food_intolerance_test",is_food_intolerance_test ??false);
       

       if(food_items && food_items.length>0){
        food_items.forEach((it)=>formdata.append("food_items", it))
       }

       if(packages_media && packages_media.length > 0){
        packages_media.forEach((media)=> formdata.append("packages_media", JSON.stringify(media)));
        }
      formdata.append("primary_media_type", primary_media_type);
      if(primary_media_type=="Video"){
      formdata.append("video_s3_url",video_s3_url);
     // formdata.append("infographic_thumbnail_s3_url",infographic_thumbnail_s3_url);
      }
        
       if (secondary_categories && secondary_categories.length>0) {
        secondary_categories.forEach((it) => formdata.append("secondary_categories", it));
      }
      formdata.append("is_default_suggested_supplement_required", is_default_suggested_supplement_required ?? false);

      if(supplementSuggestion && supplementSuggestion.length > 0){
        supplementSuggestion.forEach((media)=> formdata.append("package_supplements", JSON.stringify(media)));
        }

        formdata.append("is_b2b_package", is_b2b_package ?? false);
         console.log("package sample mapping",mappingPackageSamples);
        if(mappingPackageSamples && mappingPackageSamples.length>0){
          mappingPackageSamples.forEach((media)=> formdata.append("sample_details", JSON.stringify(media)));
        }



        if(!default_secondary_coach_id) {
          formdata.append("default_secondary_coach_id","");
        }
        else{
          formdata.append("default_secondary_coach_id", default_secondary_coach_id);
        }
        formdata.append("is_eligible_for_cod", is_eligible_for_cod ?? true);




        formdata.append("is_bundle",is_bundle??false)
        const mappingCustomPackageBundles =customPackageBundles.map((item)=>{
          
          const itemObject = {
           "appointment_date_value":item.appointment_date_value,
           "id":item?.id??null,
           "is_active":item.is_active,
           "member":item.member,
            "package_id": Number(item.package_id),
            "package_name" :item.package_name,
             "package_type":item.package_type
          }
           return itemObject
        })
        if(is_bundle){
          if (mappingCustomPackageBundles && mappingCustomPackageBundles.length>0) {
            mappingCustomPackageBundles.forEach((it) => formdata.append("custom_package_bundles", JSON.stringify(it)));
          }
        }

        formdata.append("is_vat_excluded",is_vat_excluded ?? false);

        formdata.append("is_allow_coach_cons_pkg_addition", is_allow_coach_cons_pkg_addition ?? false);
        formdata.append("is_allow_supplement_addition",is_allow_supplement_addition??false)
        if (supplement_addons && supplement_addons.length>0) {
          supplement_addons.forEach((it) => formdata.append("supplement_addons", it));
        }
        if(coach_cons_pkg_addons && coach_cons_pkg_addons) {
          formdata.append("coach_cons_pkg_addons", coach_cons_pkg_addons);
        }


        if (custom_addons && custom_addons.length>0) {
          custom_addons.forEach((it) => formdata.append("custom_addons", it));
        }

        formdata.append("description_richtext_en",descriptionTxtEn?.toString("html"));
        formdata.append("description_richtext_ar",descriptionTxtAr?.toString("html"))
        formdata.append("what_happens_next_richtext_en",whatHappensNextTxtEn?.toString("html"))
        formdata.append("what_happens_next_richtext_ar",whatHappensNextTxtAr?.toString("html"))
      const packageId = this.state.editPackageId;
      apiOptions = {
        method: "patch",
        url: "packages/" + packageId + "/",
        data: formdata,
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data; boundary=${formdata._boundary}`,
        },
      };
      
      try {
        this.showLoading();
        await API.request(apiOptions);
        await this.saveSubscriptionItems(packageId);
        console.log("Changing state after work done 868");
        this.setState({ editPackageId: null })
        this.loadData();
      
        this.hideModal();
        this.showSuccessNotification("Packages successfully updated.")
      } catch (error) {
        const errorData = error.response?.data;

        if (errorData) {
          // Get the field name dynamically (e.g., "subscription_description")
          const fieldName = Object.keys(errorData)?.[0];
  
          if (fieldName) {
            const errorMessage = errorData[fieldName]?.[0]; // Get the error message for the field
  
            console.error(`Field: ${fieldName}, Error: ${errorMessage}`);
            this.showErrorNotification(`${fieldName}: ${errorMessage}`); // Show field and error message
          }
        }
        else{
        console.log("Error on updating package", error);
        this.showErrorNotification("Technical Error occurred while creating/updating package. Please report the issue on #valeo-tech slack channel");
        }
      } finally {
        this.hideLoading();
      }
    }
  }

  hideModal() {
    this.setState({ showModal: false, pageIdForLogs: null });
    this.formReset();
  }

  showModal() {
    const rankOptions = this.createRankOptions();
    const {testList,selectedCountry, bloodPackages}= this.state
    const dropDownList= testList && testList.length>0?testList.filter(item=>item.country==selectedCountry.id):[];
    const customPackagesForPackageBreaking= dropDownList && dropDownList.map((item)=>{
      return {...item, "package_type":"Custom_Package"}
    })
    const bloodPackagesDropdown=bloodPackages.filter((item)=>item.country==selectedCountry.id);
    const bloodPackagesDropdownList=bloodPackagesDropdown.map((item)=>{
          return {...item,package_type:"Blood_Package"}
        })
    this.setState({
      showModal: true,
      rankObject: rankOptions,
      customPackagesForPackageBreaking,
      bloodPackagesDropdownList
    });
  }

  handleIconChange = (e,mediaText) => {
    e.stopPropagation();
    const file = e.target.files[0];
    if(!validTypes.includes(file.type)){
      this.showErrorNotification("please add videos");
    }
    else{
    if (file) {
      // setIcon(file);

     this.uploadIcon( file,mediaText);
    }
  }
  }
   uploadFiles=async(formData)=>{

    const response = await PhoenixAPI.post('/upload-media',formData, {
      headers: {
        accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
   }

  uploadIcon = async ( iconFile,mediaText) => {
    
    try {
   const formData=new FormData();
    formData.append("media",iconFile)
   const response= await  this.uploadFiles(formData)
      //setDeals({ ...deals, tagIconUrl: response.data.s3Url })
      console.log("response",response);
      if(mediaText=="Primary Image"){
    this.setState({infographic_image:response.data.message});
  
    this.showSuccessNotification("Image Uploaded Successfully");
      }

      if(mediaText=="Video URL"){
        //const supplement=  {...this.state.supplement, video:response.data.message}
        this.setState({video_s3_url:response.data.message})
        this.showSuccessNotification("Video Uploaded Successfully");

      }
      if(mediaText=="Video Thumbnail"){
       
        this.setState({infographic_thumbnail_s3_url:response.data.message});
        this.showSuccessNotification("Video Thumbnail  Uploaded Successfully");
      }


      
    } catch (error) {
      console.log("Error uploading image:", error);
    }
  }

  hideConfirm() {
    this.setState({
      showConfirm: false,
      confirmItem: null,
      confirmTitle: "",
      successMessage: "",
      confirmMessage: "",
      confirmType: null,
    });
  }

  showConfirm(confirmItem) {
    let confirmMessage, successMessage, confirmTitle, confirmType;

    if (confirmItem.is_active) {
      confirmTitle = "Archive Test";
      confirmMessage = "Are you sure you want to archive this?";
      successMessage = "Test successfully archived.";
      confirmType = "danger";
    } else {
      confirmTitle = "Restore Test";
      confirmMessage = "Are you sure you want to restore this?";
      successMessage = "Test successfully restored.";
      confirmType = "success";
    }

    this.setState({
      showConfirm: true,
      confirmItem: confirmItem,
      confirmMessage,
      successMessage,
      confirmType,
      confirmTitle
    });
  }

  formReset() {
    //todo
    this.submitted = false;
    this.overviewArMessage = '';
    this.overviewMessage = '';
    this.home_AppointmentArMessage = '';
    this.home_AppointmentMessage = '';
    this.setState({
      name: '',
      title: '',
      title_arabic: '',
      sub_heading: '',
      sub_heading_arabic: '',
      description: '',
      description_arabic: '',
      what_happens_next: "",
      what_happens_next_ar: "",
      retail_price: '',
      discount_type: '',
      discount_value: '',
      final_price: '',
      currency: '',
      infographic_thumbnail: '',
      infographic_image: '',
      position_value: 0,
      status: '',
      operation_type: [],
      home_appointment_req_desc: '',
      home_appointment_req_arabic: '',
      remarks: '',
      product: '',
      name: "",
      name_ar: "",
      title_english: "",
      title_arabic: "",
      sub_english: "",
      sub_arabic: "",
      description_ar: "",
      description: "",
      price: "",
      validation: this.validator.valid(),
      test_type: "",
      formHeading: "Create Test",
      imgSrc: "",
      packageimgSrc: "",
      msg_ar: '',
      msg_en: '',
      home_appoitment_msg_ar: '',
      home_appoitment_msg_en: '',
      errors: {},
      editables: {},
      isNewPackage: false,
      editPackageId: '',
      sample_type: null,
      fasting: false,
      fasting_hours: null,
      enable_subscription: false,
      subscription_items: null,
      customPackageCities:null,
      member_applicable: null,
      member: [],
      display_name: "",
      display_name_ar: "",
      zoho_account_id:"",
      special_tag: "",
      special_tag_ar: "",
      is_allow_custom_package_addition:false,
      is_paid_doctor_consultation: false,
      is_require_sample_collection:false,
      partner_id:null,
      valeoPartnerId:null,
      valeoPartnerName:"",
      is_best_seller:false,
      food_items:[],
      is_food_intolerance_test:false,
      default_coach_id:null,
      internal_category:"None",
      secondary_categories:[],
      is_b2b_package:false,


      default_secondary_coach_id:null,


      is_bundle:false,
      is_eligible_for_cod:true,

      package_uuid:"",



      is_vat_excluded:false,


      is_allow_coach_cons_pkg_addition:false,
      is_allow_supplement_addition:false,
      supplement_addons:[],
      coach_cons_pkg_addons:null,




      custom_addons:[],


      descriptionTxtEn: RichTextEditor.createEmptyValue(),
      descriptionTxtAr: RichTextEditor.createEmptyValue(),
      whatHappensNextTxtEn: RichTextEditor.createEmptyValue(),
      whatHappensNextTxtAr: RichTextEditor.createEmptyValue(),

    });
    this.props.showNotificationMessage({
      notificationMessage: "",
      successMessage: true,
      showNotification: false
    });
  }

  fetchCustomPackageIdOrders=async(packageId)=>{
    if(!packageId){
      return null;
    }
    try {
      this.showLoading();
      const url = `package-orders/Custom_Package/${packageId}?page=1&size=25&ordering=-id`;
      const { data } = await PhoenixAPI.get(url);
      console.log("fetchCustomPackageOrders:: Fetched Custom Packages: ", data);
      return {content:data.content,totalOrders:data.totalElements};
    }
    catch (error) {
      console.log("fetchCustomPackageOrders:: Fetched Custom Packages: ", error);
      this.showErrorNotification("Error on fetching CustomPackage orders! " + error.message);
      return null;
    }
    finally {
      this.hideLoading();
    }

  }
  fetchCustomPackageCities = async (packageId) => {
    if (!packageId) {
      return null;
    }
    try {
      this.showLoading();
      const url = `/custom-packages-cities/customPackageId/${packageId}`;
      const { data } = await PhoenixAPI.get(url);
      console.log("fetchCustomPackageCities:: Fetched Custom Packages: ", data);
      return data;
    }
    catch (error) {
      console.log("fetchCustomPackageCities:: Fetched Custom Packages: ", error);
      this.showErrorNotification("Error on fetching CustomPackage items! " + error.message);
      return null;
    }
    finally {
      this.hideLoading();
    }
  }

  handlePackageSampleDetailsUpdate = (sampleDetails) => { 
    console.log("sampleDetails in custom",sampleDetails);
    this.setState({mappingPackageSamples:sampleDetails})
  }
  fetchDefaultCoachDetails = async (packageId) => { 

    try {
      this.showLoading();
      const url = `nutritionist-user-name/`;
      const { data } = await PhoenixAPI.get(url);
      let coachDetails = [{ text: "None", value: null, key: "None", default_coach_id: null, is_active: false }];
      data && data.map((item) => {
        let coach = {};
        coach["text"] = item.full_name;
        coach["value"] = item.id;
        coach["key"] = item.full_name;
        coach["default_coach_id"] = item.id;
        coach["is_active"] = item.is_active;
        coachDetails.push(coach)
      })
      this.setState({ defaultCoachOptions: coachDetails})
    }
    catch (error) {
      this.showErrorNotification("Error on fetching default coach details !  " + error.message);
      return null;
    }
    finally {
      this.hideLoading();
    }
  }

  fetchDefaultSecondaryCoachDetails = async (packageId) => {
   
    try {
      const { data: externalCoaches = [] } = await PhoenixAPI.get("external/coach/users/") || [];
      let coachDetails = [{ text: "None", value: null, key: "None", default_secondary_coach_id: null }];
      externalCoaches && externalCoaches.map((item) => {
        let coach = {};
        coach["text"] = item.userName;
        coach["value"] = item.id;
        coach["key"] = item.userName;
        coach["default_secondary_coach_id"] = item.id;
        coachDetails.push(coach)
      })
     this.setState({secondaryCoachOptions:coachDetails})
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching external coaches: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    }
  }

  productNameList=()=>{
    const { products, selectedCountry } = this.state;

    const productsForCountry =
      products && selectedCountry
        ? products.filter((product) => product.country === selectedCountry.id)
        : [];

    const activeProducts = productsForCountry.filter(
      (product) => product.status === "Active"
    );
    activeProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const inactiveProducts = productsForCountry.filter(
      (product) => product.status === "Inactive"
    );
    inactiveProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));


    return activeProducts
  }

  async editTest(index) {
    
    this.setState({
      pageIdForLogs : index
    })

    const {valeoPartners, testList, selectedCountry}= this.state || {}
    // API.get("package-details/" + index + '/')
    //   .then((res) => {
    //     debugger
    //   });
    const secondaryCategoryOptions = this.productNameList()
    try {
      this.showLoading();
      const { data: foundPackage } = await API.get(`packages/${index}/`);
      // const arrEditPackage = this.state.testList.filter((e) => {
      //   if (e.id === index)
      //     return e;
      // });
      const arrEditPackage = foundPackage ? [foundPackage] : null;
      
      if (arrEditPackage && arrEditPackage.length > 0 && arrEditPackage[0].id) {
        let rankArray = [
          {
            text: 0,
            value: 0,
            key: 0,
          }
        ];
        let rankSotable = [];
        let rank = 1;
        this.state.testList.map((item) => {
          if (!item.is_deleted && item.product == arrEditPackage[0].product) {
            rankSotable[rank] = item.id;
            rankArray.push({
              text: rank,
              value: rank,
              key: rank,
            });
            rank = rank + 1;
          }
        });
        this.setState({
          rankObject: rankArray,
          rankSortable: rankSotable,
        });
        const overview_obj =
          arrEditPackage[0].description != null
            ? stateFromHTML(arrEditPackage[0].description)
            : stateFromHTML("");
        const overview_ar_obj = arrEditPackage[0].description_arabic != null
          ? stateFromHTML(arrEditPackage[0].description_arabic)
          : stateFromHTML("");
        const home_object =
          arrEditPackage[0].home_appointment_req_desc != null
            ? stateFromHTML(arrEditPackage[0].home_appointment_req_desc)
            : stateFromHTML("");
        const home_ar_object = arrEditPackage[0].home_appointment_req_arabic != null
          ? stateFromHTML(arrEditPackage[0].home_appointment_req_arabic)
          : stateFromHTML("");
  
        const subscriptionDetails = await this.fetchSubscriptionItems(arrEditPackage[0].id);
        const cityPackageDetails= await this.fetchCustomPackageCities(arrEditPackage[0].id);
        const customPackageOrderdetails= await this.fetchCustomPackageIdOrders(arrEditPackage[0].id);
        const partnerId= arrEditPackage[0].partner_id ?? null;
      const selectedPartner= valeoPartners.find((it)=>it.id===partnerId);
       const filteredSecondaryCategoryOptions= secondaryCategoryOptions &&secondaryCategoryOptions.filter((item)=>item.id!==arrEditPackage[0]?.product)
       const supplementSuggestion= arrEditPackage[0]?.package_supplements ??[];
       const customPackageBundles=arrEditPackage[0]?.custom_package_bundles??[];
       const custompackageSamples= arrEditPackage[0]?.sample_details??[];
       const filteredPrimaryCategoryOptions= this.state.productList && 
       this.state.productList.filter((item) => {
         // Check if `selectedPackage.secondary_categories` exists
         if (arrEditPackage[0].secondary_categories) {
             return !arrEditPackage[0].secondary_categories.includes(item.key);
         }
         return true; 
     });
       const descriptionTxtEn = RichTextEditor.createValueFromString(
        arrEditPackage[0].description_richtext_en  ?? "",
        "html"
      );
      const descriptionTxtAr = RichTextEditor.createValueFromString(
        arrEditPackage[0].description_richtext_ar  ?? "",
        "html"
      );
      const whatHappensNextTxtEn = RichTextEditor.createValueFromString(
        arrEditPackage[0].what_happens_next_richtext_en ?? "",
        "html"
      );
      const whatHappensNextTxtAr = RichTextEditor.createValueFromString(
        arrEditPackage[0].what_happens_next_richtext_ar  ?? "",
        "html"
      );
        this.setState(
          {
            name: arrEditPackage[0].name,
            title: arrEditPackage[0].title,
            title_arabic: arrEditPackage[0].title_arabic,
            sub_heading: arrEditPackage[0].sub_heading,
            sub_heading_arabic: arrEditPackage[0].sub_heading_arabic,
            // description: JSON.stringify(convertToRaw(overview_obj)),
            // description_arabic: JSON.stringify(convertToRaw(overview_ar_obj)),   
            description: arrEditPackage[0].description,
            description_arabic: arrEditPackage[0].description_arabic,
            msg_en:
              arrEditPackage[0].description != null
                ? arrEditPackage[0].description
                : stateToHTML(ContentState.createFromText("")),
            msg_ar: arrEditPackage[0].description_arabic != null
              ? arrEditPackage[0].description_arabic
              : stateToHTML(ContentState.createFromText("")),
            what_happens_next: arrEditPackage[0].what_happens_next,
            what_happens_next_ar: arrEditPackage[0].what_happens_next_ar,
            retail_price: arrEditPackage[0].retail_price,
            discount_type: arrEditPackage[0].discount_type,
            discount_value: arrEditPackage[0].discount_value,
            final_price: arrEditPackage[0].final_price,
            currency: arrEditPackage[0].currency,
            infographic_thumbnail: arrEditPackage[0].infographic_thumbnail,
            infographic_image: arrEditPackage[0].infographic_image,
            position_value: arrEditPackage[0].position_value,
            status: arrEditPackage[0].status,
            is_best_seller:arrEditPackage[0].is_best_seller,
            operation_type: arrEditPackage[0].operation_type ? arrEditPackage[0].operation_type.split(',') : [],
            home_appointment_req_desc: arrEditPackage[0].home_appointment_req_desc || "",
            home_appointment_req_arabic: arrEditPackage[0].home_appointment_req_arabic || "",
            // home_appointment_req_desc: JSON.stringify(convertToRaw(home_object)),
            // home_appointment_req_arabic: JSON.stringify(convertToRaw(home_ar_object)),
            home_appoitment_msg_en:
              arrEditPackage[0].home_appointment_req_desc != null
                ? arrEditPackage[0].home_appointment_req_desc
                : stateToHTML(ContentState.createFromText("")),
            home_appoitment_msg_ar:
              arrEditPackage[0].home_appointment_req_arabic != null
                ? arrEditPackage[0].home_appointment_req_arabic
                : stateToHTML(ContentState.createFromText("")),
            remarks: arrEditPackage[0].remarks,
            product: arrEditPackage[0].product,
            imgSrc: arrEditPackage[0].infographic_image,
            packageimgSrc: arrEditPackage[0].infographic_image_s3_url,
            formHeading: "Edit Package",
            title_name: arrEditPackage[0].title,
            location: arrEditPackage[0].location,
            editPackageId: arrEditPackage[0].id,
            country: arrEditPackage[0].country,
            sample_type: arrEditPackage[0].sample_type,
            fasting: arrEditPackage[0].fasting,
            fasting_hours: arrEditPackage[0].fasting_hours,
            enable_subscription: subscriptionDetails?.enableSubscription ?? false,
            subscription_items: subscriptionDetails?.subscriptionItems ?? null,
            customPackageCities:cityPackageDetails,
            member_applicable: arrEditPackage[0].member_applicable,
            member:  arrEditPackage[0].member ? [...arrEditPackage[0].member] : [],
            display_name: arrEditPackage[0].display_name,
            display_name_ar: arrEditPackage[0].display_name_ar,
            zoho_account_id:arrEditPackage[0].zoho_account_id,
            special_tag: arrEditPackage[0].special_tag,
            special_tag_ar: arrEditPackage[0].special_tag_ar,
            customPackagesDropdownList:this.state.testList.filter((item)=>item.name!==arrEditPackage[0].name).filter(item=>item.country==arrEditPackage[0].country),
            customPackages:arrEditPackage[0].custom_pkg_addons,
            is_allow_custom_package_addition:arrEditPackage[0].custom_pkg_addons.length>0??false,
            is_paid_doctor_consultation: arrEditPackage[0].is_paid_doctor_consultation,
            is_require_sample_collection:arrEditPackage[0].is_require_sample_collection,
            partner_id:arrEditPackage[0].partner_id,
            valeoPartnerId: selectedPartner?.id || null,
            valeoPartnerName:selectedPartner?.name || "",
            customPackageIdOrders:customPackageOrderdetails.content,
            packageIdTotalRows:customPackageOrderdetails.totalOrders,
            is_food_intolerance_test:arrEditPackage[0].is_food_intolerance_test ?? false,
            food_items:arrEditPackage[0].food_items ?? [],
            default_coach_id: arrEditPackage[0].default_coach_id,
            internal_category: !arrEditPackage[0].internal_category ? "None" : arrEditPackage[0].internal_category,

            video_s3_url:arrEditPackage[0].video_s3_url,
            primary_media_type:arrEditPackage[0].primary_media_type,
            infographic_thumbnail_s3_url:arrEditPackage[0].infographic_thumbnail_s3_url,
          packages_media:arrEditPackage[0].packages_media??[],

            secondary_categories:arrEditPackage[0].secondary_categories,
            secondaryCategoryOptions:filteredSecondaryCategoryOptions,
            is_default_suggested_supplement_required:arrEditPackage[0].is_default_suggested_supplement_required,
            supplementSuggestion,
            is_b2b_package:arrEditPackage[0].is_b2b_package,

            default_secondary_coach_id:arrEditPackage[0].default_secondary_coach_id,
            package_uuid:arrEditPackage[0].package_uuid,

            is_eligible_for_cod:arrEditPackage[0].is_eligible_for_cod,
            is_vat_excluded:arrEditPackage[0].is_vat_excluded,
           
            customPackageBundles,
            is_bundle:arrEditPackage[0].is_bundle,



            is_allow_coach_cons_pkg_addition:arrEditPackage[0].is_allow_coach_cons_pkg_addition,
            is_allow_supplement_addition:arrEditPackage[0].is_allow_supplement_addition,
            supplement_addons:arrEditPackage[0].supplement_addons,

            coach_cons_pkg_addons:arrEditPackage[0].coach_cons_pkg_addons[0]??"",
            is_eligible_for_cod:arrEditPackage[0].is_eligible_for_cod,

            mappingPackageSamples:custompackageSamples,
            productListEdit:filteredPrimaryCategoryOptions,
            custom_addons:arrEditPackage[0].custom_addons??[],

            descriptionTxtEn,
            descriptionTxtAr,
            whatHappensNextTxtEn,
            whatHappensNextTxtAr

          },
          () => this.showModal()
        );
      }
    } catch (error) {
      console.log("editTest:: Error on fetching package!")
    } finally {
      this.hideLoading();
    }
  }

  selecteImage(e) {
    if (e.target.files && e.target.files.length) {
      let infographic_thumbnail = e.target.files[0];
      if(!imageValidTypes.includes(infographic_thumbnail.type)){
        this.showErrorNotification("upload image file");
      }
      else{
      this.setState({ infographic_thumbnail });
      let reader = new FileReader();

      reader.onloadend = function () {
        this.setState({
          imgSrc: [reader.result],
        });
      }.bind(this);
      reader.readAsDataURL(infographic_thumbnail);
    }
  }
  }

  selectePackageImage(e) {
    if (e.target.files && e.target.files.length) {

      let infographic_image = e.target.files[0];
      if(!imageValidTypes.includes(infographic_image.type)){
        this.showErrorNotification("upload image file");
      }
      else{
      this.setState({ infographic_image });
      let reader = new FileReader();

      reader.onloadend = function () {
        this.setState({
          packageimgSrc: [reader.result]
        });
      }.bind(this);
      reader.readAsDataURL(infographic_image);
    }
  }
  }

  toggleActiveStatus(item) {
    const { successMessage } = this.state;

    if (!item) return;
    this.hideConfirm();

    this.props.toggleLoading({
      isLoading: true
    });

    API.patch("packages/" + item.id + "/", {
      is_active: !item.is_active
    })
      .then(response => {
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: successMessage,
          successMessage: true,
          showNotification: true
        });
        this.loadData();
      })
      .catch(error => {
        console.log("Error on updating status", error);
        this.props.toggleLoading({
          isLoading: false
        });
        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });
      });
  }

  loadData() {
    let activeOrders = [];
    const { searchApplied, searchTerm } = this.state;

    this.props.toggleLoading({
      isLoading: true
    });

    // API.get("packages/")
    PhoenixAPI.get("/custom/packages")
      .then(response => {
        let testList = response.data || [];

        const ranks = testList.filter(p => p.rank).map(p => p.rank);
        const minRank = Math.min(...ranks) || 0;
        const maxRank = Math.max(...ranks) || 0;
      //  let  customPackagesDropdownListData=testList.filter(item=>item.country==this.state.selectedCountry.id);
       console.log("min rank max rank",minRank,maxRank);
       
        const activeList = filterList(testList, "active");
        const archivedList = filterList(testList, "archived");
        let newList = response.data;
        newList.sort((a, b) => {
          return a.rank - b.rank;
        });
        let rankArray = [
          {
            text: 0,
            value: 0,
            key: 0,
          }
        ];
        let rankSotable = [];
        let rank = 1;
        newList.map((item) => {
          if (!item.is_deleted) {
            rankSotable[rank] = item.id;
            rankArray.push({
              text: rank,
              value: rank,
              key: rank,
            });
            rank = rank + 1;
          }
        });
        this.setState({
          rankObject: rankArray,
          rankSortable: rankSotable,
          position_value: rank - 1,
          minRank, 
          maxRank,
        });
        this.setState({
          testList: testList.map(p => {
            const product = this.findProduct(p.product);
            return {...p, product_title: product ? product.title : ""};
          }),
          customPackagesDropdownList:testList || [],
          customList:testList || [],
          activeList,
          archivedList,
          isLoaded: true
        });

        if (searchApplied) this.handleSearch(searchTerm);

        this.props.toggleLoading({
          isLoading: false
        });
        // this.getTestCategories();
      })
      .catch(error => {
        console.log("Error on fetching packages", error);
        this.setState({
          loadError: "Some error has occured. Please try again"
        });

        this.props.showNotificationMessage({
          notificationMessage: "Some error has occured. Please try again.",
          successMessage: false,
          showNotification: true
        });

        this.props.toggleLoading({
          isLoading: false
        });
      });
  }

  componentDidMount() {
    this.fetchAclUser();
    this.loadCountries();
    this.loadPackageSampleTypes();
    this.loadOperations();
    this.loadProducts();
    this.fetchValeoPartners()
    this.fetchDefaultCoachDetails();
    this.fetchDefaultSecondaryCoachDetails();
    this.fetchSampleDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedCountry } = this.state;
    if (selectedCountry !== prevState.selectedCountry)
      this.createProductList();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if(!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  }

  fetchSampleDetails=async()=>{
    try {
      this.showLoading();
      const { data: sampleDetails = [] } = await PhoenixAPI.get(`${sampleDetails_url}`);
      if(sampleDetails.length>0){
        const activeOptions = sampleDetails
  .filter(sample => sample.isActive)
  .map(sample => ({
    key: sample.id,
    value: sample.id,
    text: sample.sampleName,
    group: "Active Samples"  // Custom attribute for logical grouping
  }));

const inactiveOptions = sampleDetails
  .filter(sample => !sample.isActive)
  .map(sample => ({
    key: sample.id,
    value: sample.id,
    text: sample.sampleName,
    group: "Inactive Samples"  // Custom attribute for logical grouping
  }));

// Combine active and inactive options
const sampleDetailsOptions = [
  { key: 'active-header', text: 'Active Samples', disabled: true },
  ...activeOptions,
  { key: 'inactive-header', text: 'Inactive Samples', disabled: true },
  ...inactiveOptions
];
      this.setState({ sampleDetails,sampleDetailsOptions});

      }
      else{
        this.setState({sampleDetails: [], sampleDetailOptions: []});
      }
      
    } catch (error) {
      console.log(
        "fetchCorporatePartners:: Error on fetching corporate partners!",
        error
      );
      this.showErrorNotification(
        "Error on fetching corporate partners! " + error.message ?? ""
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
   }

  fetchAclUser = async () => {
    const userId = getUserId();
      if (!userId) {
        console.log("fetchAclUser:: Invalid user id!", {userId});
        return;
      }
  
      try {
        const pageName = Page.CUSTOM_PACKAGES;
        const { data: aclUser } = await PhoenixAPI.get(`/api/v1/acl/users/userId/${userId}`);
        const pagePermission = aclUser?.pagePermissions?.find(it => pageName?.toLowerCase() === it.pageName?.toLowerCase()) ?? null;
        const pageAccessRights = pagePermission?.pageAccessRights?.filter(it => it.isActive ?? false)?.map(it => it.accessRightName?.toLowerCase()) ?? null;
        const accessDenied = isAccessDenied(aclUser, pageAccessRights);
        this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
          const isEditable = this.hasPageAccessRight("edit");
          this.setState({ isEditable });
        });
      } catch (error) {
        console.log("fetchAclUser:: Error on fetching acl user!", error);
      }
  }

  fetchSubscriptionItems = async(packageId) => {
    if (!packageId) {
      console.log("fetchSubscriptionItems:: Invalid package id: ", packageId);
      return null;
    }

    try {
      this.showLoading();
      const url = `/subscription-items/Custom_Package/${packageId}`;
      const { data } = await PhoenixAPI.get(url);
      console.log("fetchSubscriptionItems:: Fetched subscriptions items: ", data);
      return data;
    } catch (error) {
      console.log("fetchSubscriptionItems:: Error on fetching subscription items!", error);
      this.showErrorNotification("Error on fetching subscription items! " + error.message);
      return null;
    } finally {
      this.hideLoading();
    }
  }

  saveSubscriptionItems = async (packageId, isNewPackage = false) => {
    if (!packageId) {
      console.log("saveSubscriptionItems:: Invalid package id: ", packageId);
      return null;
    }

    const { enable_subscription, subscription_items } = this.state;
    if (subscription_items && subscription_items.length) {
      subscription_items.forEach(it => it.customPackageId = packageId)
    }
    const url = (isNewPackage || enable_subscription)
      ? `/subscription-items/`
      : `/disable-subscription/Custom_Package/${packageId}`
    const payload = url.startsWith("/disable-subscription")
      ? subscription_items ?? []
      : { 
          enableSubscription: enable_subscription, 
          packageType: "Custom_Package",
          packageId,
          subscriptionItems: subscription_items ?? []
      };
    try {
      const apiOptions = {
        method: isNewPackage ? "POST" : "PATCH",
        url,
        data: payload,
      };
      await PhoenixAPI.request(apiOptions);
    } catch (error) {
      this.showErrorNotification("Error on saving subscription items!" + error.message);
    }
  }

  saveCityPackgeDetails = async (packageId) => {
    if (!packageId) {
      console.log("saveCityPackageDetails:: Invalid package id: ", packageId);
      return null
    }
    const { customPackageCities, isNewPackage } = this.state;
    if (isNewPackage) {
      if (customPackageCities && customPackageCities.length) {
        customPackageCities.forEach(it => it.customPackageId = packageId)
      }
      const url = `list-custom-packages-cities`
      const payload = {
        customPackageId: packageId,
        customPackagesCities: customPackageCities ?? []
      };
      try {
        const apiOptions = {
          method: "POST",
          url,
          data: payload,
        };
        await PhoenixAPI.request(apiOptions);
      } catch (error) {
        this.showErrorNotification("Error on saving city prices of custom packages!" + error.message);
      }
    }

  }

  fetchValeoPartners = async () => {
    try {
      this.showLoading();
      const partners = (await fetchValeoPartners()) ?? [];
        const partnerOptions=partners.map((it)=>{
          return {key:it.id, value:it.id, label:it.name}
        })
        partnerOptions.push({key:"select", value:"select", label:"Select"})
        this.setState({valeoPartners:partners,valeoPartnerOptions:partnerOptions})
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching valeo partners: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchFoodItems=async()=>{
   try{
     const {data:foodItems=[]}= await PhoenixAPI.get("admin/get-all-food-items")
     const {selectedCountry={}}= this.state || {}
     const selectedCountryFoodItems=foodItems.filter((item)=>item.country===selectedCountry.id)
     const foodItemOptions=selectedCountryFoodItems.map((value)=>{
      return {
        text: value.food_name,
        value: value.id,
        key: value.id,
      }
     })
     this.setState({foodItemOptions})
   }
   catch(error){
   console.log("Error on fetching food items", error)
   }
  }

  fetchSupplements=async()=>{
    const { selectedCountry } = this.state;
    const countryId = selectedCountry?.id ?? null;
    if (!countryId) {
      console.log("fetchSupplements:: Invalid country!", { selectedCountry });
      return;
    }
    try {
      this.showLoading();
      const { data: supplements = [] } = await fetchSupplementsSummaryByCountry(
        countryId
      );
      this.setState({supplements});
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching supplements: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  }

  fetchBloodPackages=async()=>{
    const {selectedCountry}= this.state
     try{
       const response=await PhoenixAPI.get("/bloods/packages")
       let newList = response.data || [];
        // const dropDownList = newList && newList.length>0 && newList.filter((item)=>item.country==selectedCountry.id);
        // const bloodPackagesDropdownList=dropDownList.map((item)=>{
        //   return {...item,package_type:"Blood_Package"}
        // })
      this.setState({bloodPackages:newList})
     }
     catch(error){
       console.log("Error on fetching blood packages", error)
     }
  }

  loadProducts() {
    API.get("products/")
      .then((res) => {
        let products = res.data;
        this.setState({ products }, () => {
          this.createProductList();
          this.loadData();
        });
      })
      .catch((error) => {
        console.log("Error on fetching products", error);
      });
  }

  createProductList = () => {
    const { products, selectedCountry } = this.state;

    const productsForCountry =
      products && selectedCountry
        ? products.filter((product) => product.country === selectedCountry.id)
        : [];

    const activeProducts = productsForCountry.filter(
      (product) => product.status === "Active"
    );
    activeProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const inactiveProducts = productsForCountry.filter(
      (product) => product.status === "Inactive"
    );
    inactiveProducts.sort((p1, p2) => compareStrings(p1.name, p2.name, true));

    const productOptions = [...activeProducts, ...inactiveProducts].map(
      (product) => ({
        key: product.id,
        value: product.id,
        text: `${product.name}${product.status === "Inactive" ? " (Inactive)" : ""
          }`,
      })
    );

    this.setState({ productList: productOptions ,productListEdit:productOptions, secondaryCategoryOptions:activeProducts});
  }

  loadOperations() {
    API.get("operation-types/")
      .then((res) => {
        let data = res.data;
        this.setState({ operationList: data });
      })
      .catch((error) => {
        console.log("Error on fetching operation types", error);
      });
  }

  loadCountries = async () => {
    const url = "countries/";
    const isDefaultCountry = (country) => country.is_default;
    const countryMapper = (country) => ({
      id: country.id,
      country_name: country.country_name,
      currency: country.currency,
      is_active: country.is_active,
      is_default: country.is_default,
    });

    try {
      const { data: allCountries } = await API.get(url);
      const countries = allCountries ? allCountries.map(countryMapper) : [];
      const defaultCountry = countries.find(isDefaultCountry);

      this.setState({
        countries,
        selectedCountry: defaultCountry,
      },()=>{this.fetchFoodItems();this.fetchSupplements(); this.fetchBloodPackages();});
    } catch (error) {
      console.error("Error on fetching countries from this URL: ", url);
      console.error("Error on fetching countries", error);
    }
  };

  loadPackageSampleTypes = async () => {
    try {
      const { data: packageSampleTypes = [] } = await API.get(BASE_URL_PACKAGE_SAMPLE_TYPES);
      this.setState({ packageSampleTypes });
    } catch (error) {
      console.error("Error on fetching package sample types", error);
      throw error;
    }
  };

  handleSearch(value) {
    let newValue,
      list = [];

    this.setState({
      searchTerm: value
    });

    // if (e.key !== "Enter") {
    //   return;
    // }

    newValue = value
      .slice(0)
      .trim()
      .toLowerCase();

    if (isEmpty(value)) {
      this.setState({
        searchApplied: false,
        searchExpanded: false
      });

      return;
    }

    // Filter Code commentted out
    // const { showItems } = this.state;

    // if (showItems === "Active") list = this.state.activeList;
    // else if (showItems === "Archived") list = this.state.archivedList;
    // else 
    list = this.state.testList;

    if (!list) return;

    let newList = [];

    for (let item of list) {
      if (item.name.toLowerCase().indexOf(newValue) !== -1) {
        newList.push(item);
      }
    }

    this.setState({
      searchExpanded: true,
      searchApplied: true,
      results: newList,
      resultCount: newList.length
    });
  }

  clearSearch() {
    this.setState({
      searchTerm: "",
      searchExpanded: true,
      searchApplied: false
    });

    this.search.searchInput.focus();
  }

  handleSearchExit(e) {
    const value = e.target.value;

    if (!value.length) {
      this.setState({
        searchExpanded: false,
        searchApplied: false
      });

      return;
    } else {
      this.setState({
        searchExpanded: true
      });
    }
  }

  setShowItems(mode) {
    let showItems;
    const { searchApplied, searchTerm } = this.state;
    // Filter Code commentted out

    // if (mode === "active") showItems = "Active";
    // else if (mode === "archived") showItems = "Archived";
    // else showItems = "All";

    // this.setState({ showItems: showItems }, () => {
    if (searchApplied) this.handleSearch(searchTerm);
    // });
  }

  renderStatus = (status) => {
    if (status === "Active")
      return (
        <div className="badge badge-success pt-1">
          <CheckCircleOutlinedIcon></CheckCircleOutlinedIcon>
          Active
        </div>
      );
    else
      return (
        <div className="badge badge-warning pt-1">
          <ArchiveOutlinedIcon></ArchiveOutlinedIcon>
          Inactive
        </div>
      );
  };

  renderViewButton = (packageId) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.editTest(packageId);
        }}
      >
        View
      </button>
    </div>
  );

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewPackage ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  handleCountrySelection = (country) =>
    this.setState({ selectedCountry: country },()=>this.fetchFoodItems());

  handleSubscriptionsItemsUpdated = (subscriptionItems = []) => {
    this.setState({ subscription_items: subscriptionItems });
  };

  handleCityPackageItemsUpdated = (cityPackageItems = []) => {
    this.setState({ customPackageCities: cityPackageItems })
  }

  getFilteredPackages = () => {
    const {testList: packages, selectedCountry} = this.state;
    console.log("All Packages", packages);
    console.log("SelectedCountry: ", selectedCountry);
    const packagesForCountry = (packages && selectedCountry) 
      ? packages.filter(p => p.country === selectedCountry.id)
      : [];

    return this.getSearchedPackages(packagesForCountry);
  }

  getSearchedPackages = (packages) => {
    const {searchApplied, searchTerm} = this.state;
    if (!searchApplied || !searchTerm)
      return packages;

    let newValue, list = packages;

    newValue = searchTerm.slice(0).trim().toLowerCase();

    let newList = [];

    for (let item of list) {
      if (
        (item.name && item.name.toLowerCase().indexOf(newValue) !== -1) ||
        (item.product_title && item.product_title.toLowerCase().indexOf(newValue) !== -1)
      ) {
        newList.push(item);
      }
    }
    return newList;
  }

  getCountry = (id) => {
    const { countries } = this.state;
    return countries && countries.find((country) => country.id === id);
  };

  handleSelectPartner=(partnerId)=>{
  
    const {valeoPartnerOptions, valeoPartners}=this.state || {}
    if(partnerId==="select"){
   
      this.setState({...this.state,partner_id:null, valeoPartnerId: null,valeoPartnerName:"SELECT"})
    }
    else{
      const selectedId = valeoPartners.find(
        (it) => it.id === partnerId 
      ) ?? {};
      this.setState({...this.state,partner_id:selectedId.id ?? null, valeoPartnerId:selectedId.id ?? null,valeoPartnerName:selectedId.name ?? ""})
      }
      
    }
  

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  caluclateDiscount = () => {
    const { retail_price, final_price, discount_type } = this.state;
    const errors = {};

    if (discount_type === null || discount_type === undefined || discount_type.toString() === "")
      errors.discount_type = "Discount Type is a required field!";

    if (retail_price === null || retail_price === undefined || retail_price.toString() === "")
      errors.retail_price = "Retail Price is a required field!";
    else if (Number.isNaN(Number(retail_price)))
      errors.retail_price = "Retail Price should be a valid number!";

    if (final_price === null || final_price === undefined || final_price.toString() === "")
      errors.final_price = "Final Price is a required field!";
    else if (Number.isNaN(Number(final_price)))
      errors.final_price = "Final Price should be a valid number!";
    else if (Number(final_price) > Number(retail_price)) {
      console.log("Final price is greater then retail price", final_price, retail_price);
      errors.final_price = "Final Price can not be greater then Retail Price!"
    }

    const previousErrors = Object.keys(this.state.errors)
      .filter(key => key !== "retail_price" && key !== "final_price" && key !== "discount_type")
      .reduce((obj, key) => {
        obj[key] = this.state.errors[key];
        return obj;
      }, {});

    this.setState({ errors: { ...previousErrors, ...errors } });
    if (Object.keys(errors).length !== 0) return;

    const retailPrice = Number(retail_price);
    const finalPrice = Number(final_price);      
    if (discount_type === "percentage") {
      this.setState({ discount_value: Math.round(100 * (retailPrice - finalPrice) / retailPrice) });
    } else if (discount_type === "fixed") {
      this.setState({ discount_value: Math.round(Math.abs(finalPrice - retailPrice)) });
    } else {
      console.log("Unknown discount type: ", discount_type);
    }
  }

  createRankOptions = () => {
    const { minRank, maxRank, isNewPackage } = this.state;
    if (!minRank || !maxRank) return;

    const until = isNewPackage ? maxRank + 1 : maxRank;
    let rankOptions = [];
    for (let i = minRank; i <= until; i++)
      rankOptions.push({ key: i, value: i, text: i });
    
    console.log("Rank Options: ", rankOptions);
    return rankOptions;
  }

  updateFastingHours = () => {
    if (!this.state.fasting)
      this.setState({ fasting_hours: null });
  }

  getDefaultMemberGender = (index = 1) => {
    return { member_index: index, gender: "B", is_active: true }
  }

  createPartnerOption=(customPackage)=>{
    return {
     key:customPackage.valeoPartnerId,
     value:customPackage.valeoPartnerId ,
     label:customPackage.valeoPartnerName ,
    }
   }

   handleOtherImagesUpdated = (otherImages = []) => {
    
    this.setState({ packages_media:otherImages });
  };
   
  handleSupplementSuggestions=(supplements)=>{
    this.setState({supplementSuggestion:supplements})
   } 

   handlePackagesUpdated=(packages)=>{
    this.setState({customPackageBundles:packages})
  }

  handleRichText = (value, name) => {
    this.setState({ [name]: value });
  };

  render() {
    let finalList = [];
    const packageCountry = this.getCountry(this.state.country);
    
    const {
      testList,
      isLoaded,
      loadError,
      searchApplied,
      results,
      resultCount,
      showItems,
      activeList,
      archivedList,
      countries,
      selectedCountry,
      isNewPackage,
      errors,
      editables,
      isEditable,
      member,
      valeoPartnerOptions,
      customPackageIdOrders,
      is_food_intolerance_test,
      foodItemOptions,
      food_items,
      secondary_categories,
      secondaryCategoryOptions,
      is_default_suggested_supplement_required, supplements,
      customPackagesBundles,
      member_applicable,
      bloodPackagesDropdownList,
      customPackagesForPackageBreaking,
      customPackageBundles,
      sampleDetails,
      sampleDetailsOptions,
    } = this.state;

    const  selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    let validation = this.submitted
      ? this.validator.validate(this.state)
      : this.state.validation;

    if (isLoaded) {
      if (searchApplied) finalList = results;
      else {
        // Filter Code commentted out

        // if (showItems === "Active") {
        //   finalList = activeList;
        //   if (categoryFilter != 'All categories') {
        //     finalList = finalList.filter((item) => item.test_category.includes(categoryFilter));
        //   }
        // }
        // else if (showItems === "Archived") {
        //   finalList = archivedList;
        //   if (categoryFilter != 'All categories') {
        //     finalList = finalList.filter((item) => item.test_category == categoryFilter);
        //   }
        // }
        // else {
        //all
        finalList = testList;
      }
      finalList = this.getFilteredPackages();
    }

    const genderInputs = member && member.length
      ? member.map((it1, index) => {
        return <div className="row">
          <div className="form-group col-6">
            <label htmlFor={`gender_${index}`}>{`Member ${index + 1} Gender*`}</label>
            <div className="input-group">
              <Dropdown
                value={it1.gender}
                onChange={(event, value) => {
                  const updatedMember = member.map((it2, itIndex) => {
                    if (index === itIndex) return { ...it2, gender: value.value };
                    return it2;
                  })
                  this.setState({ member: updatedMember });
                }}
                id={`gender_${index}`}
                placeholder="Gender"
                search
                selection
                options={GENDER_OPTIONS}
                disabled={!isNewPackage && !editables[`gender_${index}`]}
                className={
                  "form-control" + (errors[`gender_${index}`] ? " has-error" : "")
                }
              />
              {this.renderPencilIcon(`gender_${index}`)}
            </div>
            <span className="help-block">{errors[`gender_${index}`]}</span>
          </div>
        </div>
      })
      : null;
    const memberGenders = (
      <>
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="member_applicable">Members Applicable*</label>
            <div className="input-group">
              <input
                value={this.state.member_applicable ?? ""}
                onChange={(event) => {
                  let numberOfMembers = event.target.valueAsNumber;
                  if (numberOfMembers < 1) numberOfMembers = 1;
                  const newMember = [...member];
                  if (numberOfMembers > newMember.length) {
                    while (numberOfMembers > newMember.length) {
                      newMember.push(this.getDefaultMemberGender(newMember.length + 1));
                    }
                  }
                  if (numberOfMembers < newMember.length) {
                    while (numberOfMembers < newMember.length) {
                      newMember.pop();
                    }
                  }
                  this.setState({
                    member_applicable: numberOfMembers,
                    member: newMember,
                  })
                }}
                id="member_applicable"
                name="member_applicable"
                type="number"
                min="1"
                placeholder="Members Applicable"
                disabled={!isNewPackage && !editables.member_applicable}
                className={
                  "form-control py-2" +
                  (errors.member_applicable ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("member_applicable")}
            </div>
            <span className="help-block">{errors.member_applicable}</span>
          </div>
        </div>
        {genderInputs}
      </>
    );

    // const operationType = this.state.operationList.map((item, index) =>
    //   <>
    //     <div className="form-group col-6">
    //       <label>
    //         <b>{item.name}</b>
    //         <input
    //           type="checkbox"
    //           className="ml-2 mr-1"
    //           name={item.name}
    //           value='yes'
    //           // onChange={this.handleChange}
    //           onChange={(event, value) => {
    //             if (this.state.operation_type.indexOf(item.name) == -1) {
    //               let operationType = this.state.operation_type;
    //               operationType.push(item.name);
    //               this.setState({ operation_type: operationType });
    //             }
    //           }}
    //           checked={this.state.operation_type.indexOf(item.name) > -1} />
    //         YES
    //       </label>
    //       <label>
    //         <input
    //           type="checkbox"
    //           className="ml-2 mr-1"
    //           name={item.name}
    //           value='no'
    //           // onChange={this.handleChange}
    //           onChange={(event, value) => {
    //             if (this.state.operation_type.indexOf(item.name) > -1) {
    //               let operationType = this.state.operation_type;
    //               let index = operationType.findIndex(x => x == item.name);
    //               operationType.splice(index, 1);
    //               this.setState({ operation_type: operationType });
    //             }

    //           }}
    //           checked={this.state.operation_type.indexOf(item.name) < 0} />

    //         NO
    //       </label>
    //       {/* <Dropdown
    //         value={this.state.operation_type.indexOf(item.name) > -1 ? 'yes' : 'no'}
    //         className={
    //           "form-control "
    //           //  + (validation.operation_type.isInvalid && "has-error")
    //         }
    //         // placeholder="rank"
    //         name={item.name}
    //         onChange={(event, value) => {
    //           if (value.value == 'yes' && this.state.operation_type.indexOf(item.name) == -1) {
    //             let operationType = this.state.operation_type;
    //             operationType.push(item.name);
    //             this.setState({ operation_type: operationType });
    //           } else {
    //             let operationType = this.state.operation_type;
    //             let index = operationType.findIndex(x => x == item.name);
    //             operationType.splice(index, 1);
    //             this.setState({ operation_type: operationType });
    //           }
    //         }}
    //         search
    //         selection
    //         options={this.state.packageTypeOptions}
    //       /> */}
    //     </div>
    //   </>
    // );

    const operationType = this.state.operationList.map((item, index) =>
      <div className="row">
        <div className="form-group col">
          {item.name}
        </div> 
        <div className="custom-control custom-checkbox col-6">    
          <input
            type="checkbox"
            className="custom-control-input"
            name={item.name}
            id={"yes" + index}
            value='yes'
            // onChange={this.handleChange}
            onChange={(event, value) => {
              if (this.state.operation_type.indexOf(item.name) === -1) {
                let operationType = this.state.operation_type;
                operationType.push(item.name);
                this.setState({ operation_type: operationType });
              }
            }}  
            disabled={!isNewPackage && !editables[item.name]}
            checked={this.state.operation_type.indexOf(item.name) > -1} />
          <label htmlFor={"yes" + index} className="custom-control-label mr-5">YES</label>
        </div>
        <div className="custom-control custom-checkbox"> 
          <input
            type="checkbox"
            className="custom-control-input"
            name={item.name}
            id={"no" + index}
            value='no'
            // onChange={this.handleChange}
            onChange={(event, value) => {
              if (this.state.operation_type.indexOf(item.name) > -1) {
                let operationType = this.state.operation_type;
                let index = operationType.findIndex(x => x === item.name);
                operationType.splice(index, 1);
                this.setState({ operation_type: operationType });
              }
            }}
            disabled={!isNewPackage && !editables[item.name]}
            checked={this.state.operation_type.indexOf(item.name) < 0} />
          <label htmlFor={"no" + index} className="custom-control-label">NO</label>
        </div>
        <div className="form-group col ml-4">
          {this.renderPencilIcon(item.name)}
        </div>
        <div className="form-group col"></div>
      </div>
    );

    const paidDoctorConsultation = (  //removed paid doctor consultation.In we dont capture client information
      <div className="row">
        <div className="form-group col">Paid Doctor Consultation</div> 
        <div className="custom-control custom-checkbox">    
          <input
            type="checkbox"
            className="custom-control-input"
            name="is_paid_doctor_consultation_yes"
            id="is_paid_doctor_consultation_yes"
            value={this.state.is_paid_doctor_consultation}
            onChange={(event, value) => {
              this.setState({ is_paid_doctor_consultation: event.target.checked })
            }}  
            disabled={!isNewPackage && !editables.is_paid_doctor_consultation}
            checked={this.state.is_paid_doctor_consultation || false} />
          <label htmlFor="is_paid_doctor_consultation_yes" className="custom-control-label mr-5">YES</label>
        </div>
        <div className="custom-control custom-checkbox"> 
          <input
            type="checkbox"
            className="custom-control-input"
            name="is_paid_doctor_consultation_no"
            id="is_paid_doctor_consultation_no"
            value={this.state.is_paid_doctor_consultation}
            // onChange={this.handleChange}
            onChange={(event, value) => {
              this.setState({ is_paid_doctor_consultation: !event.target.checked })
            }}
            disabled={!isNewPackage && !editables.is_paid_doctor_consultation}
            checked={!this.state.is_paid_doctor_consultation} />
          <label htmlFor="is_paid_doctor_consultation_no" className="custom-control-label">NO</label>
        </div>
        <div className="form-group col ml-4">
          {this.renderPencilIcon("is_paid_doctor_consultation")}
        </div>
        <div className="form-group col"></div>
      </div>
    );


    const sampleRequiredForm=(
      <div className="row">
        <div className="form-group col">Does this require sample collection?</div> 
        <div className="custom-control custom-checkbox">    
          <input
            type="checkbox"
            className="custom-control-input"
            name="is_require_sample_collection_yes"
            id="is_require_sample_collection_yes"
            value={this.state.is_require_sample_collection}
            onChange={(event, value) => {
              this.setState({ is_require_sample_collection: event.target.checked })
            }}  
            disabled={!isNewPackage && !editables.is_require_sample_collection}
            checked={this.state.is_require_sample_collection || false} />
          <label htmlFor="is_require_sample_collection_yes" className="custom-control-label mr-5">YES</label>
        </div>
        <div className="custom-control custom-checkbox"> 
          <input
            type="checkbox"
            className="custom-control-input"
            name="is_require_sample_collection_no"
            id="is_require_sample_collection_no"
            value={this.state.is_require_sample_collection}
            // onChange={this.handleChange}
            onChange={(event, value) => {
              this.setState({ is_require_sample_collection: !event.target.checked })
            }}
            disabled={!isNewPackage && !editables.is_require_sample_collection}
            checked={!this.state.is_require_sample_collection} />
          <label htmlFor="is_require_sample_collection_no" className="custom-control-label">NO</label>
        </div>
        <div className="form-group col ml-4">
          {this.renderPencilIcon("is_require_sample_collection")}
        </div>
        <div className="form-group col"></div>
      </div>
    );

    const homeAppointmentForm = <>
      <div className="row mt-3 mb-3">
        <div className="col separator">
          <span>Home Appointment</span>
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="home_appointment_req_desc">
            Description (English)
          </label>
          <div className="input-group mb-4">
            <textarea
              value={this.state.home_appointment_req_desc || ""}
              onChange={this.handleChange}
              id="home_appointment_req_desc"
              name="home_appointment_req_desc"
              type="text"
              placeholder="Description in English"
              readOnly={!isNewPackage && !editables.home_appointment_req_desc}
              className={
                "form-control" +
                (errors.home_appointment_req_desc ? " has-error" : "")
              }
            ></textarea>
            {this.renderPencilIcon("home_appointment_req_desc")}
          </div>
          <span className="help-block">{errors.home_appointment_req_desc}</span>
        </div>
        <div className="form-group col">
          <label htmlFor="home_appointment_req_arabic">
            Description (Arabic)
          </label>
          <div className="input-group mb-4">
            <textarea
              value={this.state.home_appointment_req_arabic || ""}
              onChange={this.handleChange}
              id="home_appointment_req_arabic"
              name="home_appointment_req_arabic"
              type="text"
              placeholder="Description in Arabic"
              readOnly={!isNewPackage && !editables.home_appointment_req_arabic}
              className={
                "form-control input-arabic" +
                (errors.home_appointment_req_arabic ? " has-error" : "")
              }
            ></textarea>
            {this.renderPencilIcon("home_appointment_req_arabic")}
          </div>
          <span className="help-block">{errors.home_appointment_req_arabic}</span>
        </div>
      </div>
    </>;

    // Home appointment form with rich text edtitor.
    // const homeAppointmentForm = <>
    //   <div className="row mt-3 mb-3">
    //     <div className="col separator">
    //       <span>Home Appointment</span>
    //     </div>
    //   </div>
    //   <div className="row">
    //     <div className="form-group col">
    //       <label htmlFor="home_appointment_req_desc">
    //         Description (English)
    //         <span> (click on save icon to save your data)</span>
    //       </label>
    //       <div className="input-group mb-4">
    //         <MUIRichTextEditor
    //           defaultValue={
    //             this.state.home_appointment_req_desc ? this.state.home_appointment_req_desc : ""
    //           }
    //           onChange={(e) => { this.event_home_en = e; }}
    //           onSave={this.saveHomeAppointmentEng}
    //           id="home_appointment_req_desc"
    //           name="home_appointment_req_desc"
    //           label="Description in English"
    //           inheritFontSize={true}
    //           toolbarButtonSize="small"
    //           readOnly={!isNewPackage && !editables.home_appointment_req_desc}
    //           className={
    //             "form-control" +
    //             (errors.home_appointment_req_desc ? " has-error" : "")
    //           }
    //         />
    //         {this.renderPencilIcon("home_appointment_req_desc")}
    //       </div>
    //       <span className="help-block">{errors.home_appointment_req_desc}</span>
    //     </div>
    //     <div className="form-group col">
    //       <label htmlFor="home_appointment_req_arabic">
    //         Description (Arabic)
    //         <span> (click on save icon to save your data)</span>
    //       </label>
    //       <div className="input-group mb-4">
    //         <MUIRichTextEditor
    //           value={
    //             this.state.home_appointment_req_arabic ? this.state.home_appointment_req_arabic : ""
    //           }
    //           onChange={(e) => { this.event_home_ar = e; }}
    //           id="home_appointment_req_arabic"
    //           name="home_appointment_req_arabic"
    //           label="Description in Arabic"
    //           onSave={this.saveHomeAppointmentAr}
    //           inheritFontSize={true}
    //           toolbarButtonSize="small"
    //           readOnly={!isNewPackage && !editables.home_appointment_req_arabic}
    //           className={
    //             "form-control " +
    //             (errors.home_appointment_req_arabic ? " has-error" : "")
    //           }
    //         />
    //         {this.renderPencilIcon("home_appointment_req_arabic")}
    //       </div>
    //       <span className="help-block">{errors.home_appointment_req_arabic}</span>
    //     </div>
    //   </div>
    // </>;

    // This form is not in use currently.
    // const biomarkerTestsForm = <>
    //   <div className="row mt-3 mb-3">
    //       <div className="col separator">
    //         <span>Media</span>
    //       </div>
    //     </div>
    //     <div className="row">
    //       <div className="form-group col">
    //         <label htmlFor="biomarker_tests">Tests</label>
    //         <Dropdown
    //           value={this.state.tests}
    //           onChange={(event, value) => { this.setState({ tests: value.value }); }}
    //           id="biomarker_tests"
    //           placeholder="Biomarker Tests"
    //           search
    //           selection
    //           options={this.state.biomarkerTestOptions || []}
    //           className={
    //             "form-control" +
    //             (errors.discount_type ? " has-error" : "")
    //           }
    //         />
    //       </div>
    //       <div className="form-group col"></div>
    //     </div>
    // </>

    const detailsForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="name">Internal Name*</label>
            <div className="input-group">
              <input
                value={this.state.name}
                onChange={this.handleChange}
                id="name"
                name="name"
                type="text"
                placeholder="Internal Name in English"
                disabled={!isNewPackage && !editables.name}
                className={
                  "form-control py-2" +
                  (errors.name ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("name")}
            </div>
            {/* <span className="help-block">{validation.name.message}</span> */}
            <span className="help-block">{errors.name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Status*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.status}
                onChange={(event, value) => {
                  this.setState({ status: value.value });
                }}
                id="status"
                placeholder="Status"
                search
                selection
                options={this.state.productStatus}
                disabled={!isNewPackage && !editables.status}
                className={
                  "form-control" +
                  (errors.status ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="product">Product Category*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.product}
                onChange={(event, value) => {
                  const updatedSecondaryCategoryOptions = value.value === "none"
                    ? secondaryCategoryOptions // If "none" is selected, keep original options
                    : this.productNameList().filter((catOption) => catOption.id !== value.value)
                  this.setState({
                    product: value.value === "none" ? null : value.value,
                    secondaryCategoryOptions: updatedSecondaryCategoryOptions
                  })
                }}
                id="product"
                placeholder="Product"
                // onChange={(event, value) => {
                //   this.setState({ product: value.value });
                // }}
                search
                selection
                options={this.state.productListEdit}
                disabled={!isNewPackage && !editables.product}
                className={
                  "form-control" +
                  (errors.product ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("product")}
            </div>
            <span className="help-block">{errors.product}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="rank">Rank*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.position_value}
                onChange={(event, value) => {
                  this.setState({ position_value: value.value });
                }}
                id="rank"
                placeholder="Rank"
                search
                selection
                options={this.state.rankObject}
                disabled={!isNewPackage && !editables.rank}
                className={
                  "form-control" +
                  (errors.subscription_rank ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("rank")}
            </div>
            <span className="help-block">{errors.subscription_rank}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="title">External Name (English)*</label>
            <div className="input-group">
              <input
                value={this.state.title}
                onChange={this.handleChange}
                id="title"
                name="title"
                type="text"
                maxLength="50"
                placeholder="External Name in English"
                disabled={!isNewPackage && !editables.title}
                className={
                  "form-control py-2" +
                  (errors.title ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("title")}
            </div>
            <span className="help-block">{errors.title}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="title_arabic">External Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={this.state.title_arabic}
                onChange={this.handleChange}
                id="title_arabic"
                name="title_arabic"
                type="text"
                maxLength="50"
                placeholder="External Name in Arabic"
                disabled={!isNewPackage && !editables.title_arabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.title_arabic ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("title_arabic")}
            </div>
            <span className="help-block">{errors.title_arabic}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="display_name">Display Name (English)*</label>
            <div className="input-group">
              <input
                value={this.state.display_name ?? ""}
                onChange={this.handleChange}
                id="display_name"
                name="display_name"
                type="text"
                placeholder="Display in English"
                className={
                  "form-control py-2" + (errors.display_name ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.display_name}
              />
              {this.renderPencilIcon("display_name")}
            </div>
            <span className="help-block">{errors.display_name}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="display_name_ar">Display Name (Arabic)*</label>
            <div className="input-group">
              <input
                value={this.state.display_name_ar ?? ""}
                onChange={this.handleChange}
                id="display_name_ar"
                name="display_name_ar"
                type="text"
                placeholder="Display Name in Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.display_name_ar ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.display_name_ar}
              />
              {this.renderPencilIcon("display_name_ar")}
            </div>
            <span className="help-block">{errors.display_name_ar}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="sub_heading">Sub-Heading (English)</label>
            <div className="input-group">
              <input
                value={this.state.sub_heading || ""}
                onChange={this.handleChange}
                id="sub_heading"
                name="sub_heading"
                type="text"
                placeholder="Sub-Heading in English"
                disabled={!isNewPackage && !editables.sub_heading}
                className={
                  "form-control py-2" +
                  (errors.sub_heading ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("sub_heading")}
            </div>
            <span className="help-block">{errors.sub_heading}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="sub_heading_arabic">Sub-Heading (Arabic)</label>
            <div className="input-group">
              <input
                value={this.state.sub_heading_arabic || ""}
                onChange={this.handleChange}
                id="sub_heading_arabic"
                name="sub_heading_arabic"
                type="text"
                maxLength="50"
                placeholder="Sub-Heading in Arabic"
                disabled={!isNewPackage && !editables.sub_heading_arabic}
                className={
                  "form-control input-arabic py-2" +
                  (errors.sub_heading_arabic ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("sub_heading_arabic")}
            </div>
            <span className="help-block">{errors.sub_heading_arabic}</span>
          </div>
        </div>

        <div className="row">
          <div className="form-group col">
            <label htmlFor="special_tag">Special Tag (English)</label>
            <div className="input-group">
              <input
                value={this.state.special_tag ?? ""}
                onChange={this.handleChange}
                id="special_tag"
                name="special_tag"
                type="text"
                placeholder="Special Tag in English"
                className={
                  "form-control py-2" + (errors.special_tag ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.special_tag}
              />
              {this.renderPencilIcon("special_tag")}
            </div>
            <span className="help-block">{errors.special_tag}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="special_tag_ar">Special Tag (Arabic)</label>
            <div className="input-group">
              <input
                value={this.state.special_tag_ar ?? ""}
                onChange={this.handleChange}
                id="special_tag_ar"
                name="special_tag_ar"
                type="text"
                placeholder="Special Tag in Arabic"
                className={
                  "form-control input-arabic py-2" + (errors.special_tag_ar ? " has-error" : "")
                }
                disabled={!isNewPackage && !editables.special_tag_ar}
              />
              {this.renderPencilIcon("special_tag_ar")}
            </div>
            <span className="help-block">{errors.special_tag_ar}</span>
          </div>
        </div>

        
        <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Description (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.descriptionTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "descriptionTxtEn")
                  }
                  disabled={!isNewPackage && !editables.descriptionTxtEn}
                />
                {this.renderPencilIcon("descriptionTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>Description (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.descriptionTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "descriptionTxtAr")
                  }
                  disabled={!isNewPackage && !editables.descriptionTxtAr}
                />
                {this.renderPencilIcon("descriptionTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>What happens next ? (with RichText English) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.whatHappensNextTxtEn}
                  onChange={(value) =>
                    this.handleRichText(value, "whatHappensNextTxtEn")
                  }
                  disabled={!isNewPackage && !editables.whatHappensNextTxtEn}
                />
                {this.renderPencilIcon("whatHappensNextTxtEn")}
              </div>
            </div>

            <div className="form-group col" style={{ maxWidth: "50%" }}>
              <label>What happens next ? (with RichText Arabic) *</label>
              <div className="input-group">
                <RichTextEditor
                  rootStyle={{
                    position: "relative",
                    flex: "1 1 auto",
                    width: "1%",
                    marginBottom: 0,
                  }}
                  editorStyle={{ height: "90px", overflow: "hidden" }}
                  value={this.state.whatHappensNextTxtAr}
                  onChange={(value) =>
                    this.handleRichText(value, "whatHappensNextTxtAr")
                  }
                  disabled={!isNewPackage && !editables.whatHappensNextTxtAr}
                />
                {this.renderPencilIcon("whatHappensNextTxtAr")}
              </div>
            </div>
          </div>
          <div className="row">
          <div className="form-group col">
            <label htmlFor="description">
              Description (English)*

            </label>
            <div className="input-group">
              <textarea
                value={this.state.description || ""}
                onChange={this.handleChange}
                id="description"
                name="description"
                type="text"
                placeholder="Description in English"
                readOnly={!isNewPackage && !editables.description}
                className={
                  "form-control" +
                  (errors.description ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("description")}
            </div>
            <span className="help-block">{errors.description}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="description_arabic">
              Description (Arabic)*
              {/* <span> (click on save icon to save your data)</span> */}
            </label>
            <div className="input-group">
              <textarea
                value={this.state.description_arabic || ""}
                onChange={this.handleChange}
                id="description_arabic"
                name="description_arabic"
                type="text"
                placeholder="Description in Arabic"
                readOnly={!isNewPackage && !editables.description_arabic}
                className={
                  "form-control input-arabic" +
                  (errors.description_arabic ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("description_arabic")}
            </div>
            <span className="help-block">{errors.description_arabic}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
          <label htmlFor="what_happens_next">What happens next? (English)</label>
            <div className="input-group">
              <textarea
                value={this.state.what_happens_next}
                onChange={this.handleChange}
                id="what_happens_next"
                name="what_happens_next"
                type="text"
                placeholder="What happens next? in English"
                readOnly={!isNewPackage && !editables.what_happens_next}
                className={
                  "form-control" +
                  (errors.what_happens_next ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("what_happens_next")}
              </div>
              <span className="help-block">{errors.what_happens_next}</span>
          </div>
          <div className="form-group col">
          <label htmlFor="what_happens_next_ar">What happens next? (Arabic)</label>
            <div className="input-group">
              <textarea
                value={this.state.what_happens_next_ar}
                onChange={this.handleChange}
                id="what_happens_next_ar"
                name="what_happens_next_ar"
                type="text"
                placeholder="What happens next? in Arabic"
                readOnly={!isNewPackage && !editables.what_happens_next_ar}
                className={
                  "form-control input-arabic" +
                  (errors.what_happens_next_ar ? " has-error" : "")
                }
              ></textarea>
              {this.renderPencilIcon("what_happens_next_ar")}
              </div>
              <span className="help-block">{errors.what_happens_next_ar}</span>
          </div>
        </div>
        <div className="row">
        <div className="form-group col">
            <label htmlFor="zoho_account_id">Zoho Account Id *</label>
            <div className="input-group">
              <input
                value={this.state.zoho_account_id}
                onChange={this.handleChange}
                id="zoho_account_id"
                name="zoho_account_id"
                type="number"
                maxLength="45"
                placeholder="Zoho account Id"
                disabled={!isNewPackage && !editables.zoho_account_id }
                className={
                  "form-control py-2" +
                  (errors.zoho_account_id  ? " has-error" : "")
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) event.preventDefault();
                }}
              />
              {this.renderPencilIcon("zoho_account_id")}
            </div>
            <span className="help-block">{errors.zoho_account_id }</span>
          </div>
          <div className="form-group col-6">
            <label htmlFor="partnerId">Select Partner Id</label>
            <Select
                key={`my_unique_select_key__${this.state.partner_id}`}
                value={this.state.partner_id===null?this.createPartnerOption(this.state):this.createPartnerOption(this.state)}
                onChange={(event) => {
                  this.handleSelectPartner(event.value);
                }}
                options={valeoPartnerOptions || []}
                style={selectStyles}
                placeholder="Select Parnter"
                searchable
                lazyLoad
              />
              {/* {this.renderPencilIcon("partner_id")} */}
            
            <span className="help-block">{errors.partner_id}</span>
          </div>
          </div>
          <div className="row">
          <div className="form-group col-6">
            
            <label htmlFor="is_best_seller"> Is Bestseller</label>
            <div className="input-group">
              <Dropdown
                value={this.state.is_best_seller}
                onChange={(event, value) => {
                  this.setState({ is_best_seller: value.value });
                }}
                id="is_best_seller"
                placeholder="Best Seller"
                search
                selection
                options={this.state.bestSellerOptions}
                disabled={!isNewPackage && !editables.is_best_seller}
                className={
                  "form-control" + (errors.is_best_seller ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("is_best_seller")}
            </div>
              
            
            <span className="help-block">{errors.is_best_seller}</span>
          </div>

         {
         <div className="form-group col-6">

            <label htmlFor="default_coach_id"> Default Coach</label>
            <div className="input-group">
              <Dropdown
                value={this.state.default_coach_id||null}
                onChange={(event, value) => {
                  this.setState({ default_coach_id: value.value });
                }}
                id="default_coach_id"
                placeholder="None"
                search
                selection
                options={this.state.defaultCoachOptions}
                disabled={!isNewPackage && !editables.default_coach_id}
                className={
                  "form-control" + (errors.default_coach_id ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("default_coach_id")}
            </div>


            <span className="help-block">{errors.default_coach_id}</span>
          </div>
         }

          <div className="form-group col-6">

            <label htmlFor="internal_category"> Internal Category</label>
            <div className="input-group">
              <Dropdown
                value={this.state.internal_category||"None"}
                onChange={(event, value) => {
                  this.setState({ internal_category: value.value });
                }}
                id="internal_category"
                placeholder="Internal Category"
                search
                selection
                options={internalCategoryOptions}
                disabled={!isNewPackage && !editables.internal_category}
                className={
                  "form-control" + (errors.internal_category ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("internal_category")}
            </div>


            <span className="help-block">{errors.internal_category}</span>
          </div>
         
          </div>
          <div className="row">
          <div className="form-group col">
             <label>Select Secondary Category</label>
             <div className="input-group">
             <SelectSecondaryCategory
              categories={secondaryCategoryOptions ?? []}
              selectedCategories={secondary_categories??[]}
              onSelect={(values) => {
                const filteredProductOptions = values.length > 0
                  ? this.state.productList.filter((item) => {
                    // Filter out items whose id matches any value in `values`
                    return !values.some((value) => value.value === item.key);
                  })
                  : this.state.productList;
                this.setState({secondary_categories: values.map((it) => it.value),
                    productListEdit: filteredProductOptions
                    
                  });
              }}
              isDisabled={!isNewPackage && !editables.secondary_categories}
                className={
                  "form-control" + (errors.secondary_categories ? " has-error" : "")
                }
                menuPlacement={"bottom"}
             />
               {this.renderPencilIcon("secondary_categories")}
             </div>
             <span className="help-block">{errors.secondary_categories}</span>
          </div>
          
          <div className="form-group col">
            <label></label>
            <div className="input-group">
              <input
                value={true}
                checked={this.state.is_b2b_package}
                onChange={(event, value) => {
                  this.setState({
                    is_b2b_package: event.target.checked
                  }
                  );
                }}
                id="is_b2b_package"
                name="is_b2b_package"
                type="checkbox"
                className="larger-checkbox"
              />
              &nbsp;&nbsp; is this a B2B Package?
            </div>

          </div>
       
          </div>
        {
          <div className="row">
            <div className="form-group col-6">
              <label htmlFor="default_secondary_coach_id"> Default Secondary Coach</label>
              <div className="input-group">
                <Dropdown
                  value={this.state.default_secondary_coach_id || null}
                  onChange={(event, value) => {
                    this.setState({ default_secondary_coach_id: value.value });
                  }}
                  id="default_secondary_coach_id"
                  placeholder="None"
                  search
                  selection
                  options={this.state.secondaryCoachOptions}
                  disabled={!isNewPackage && !editables.default_secondary_coach_id}
                  className={
                    "form-control" + (errors.default_secondary_coach_id ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("default_secondary_coach_id")}
              </div>
              <span className="help-block">{errors.default_secondary_coach_id}</span>
            </div>
          </div>
         }

        <div className="row mt-3 mb-3">
          <div className="col separator">
            <span>Media</span>
          </div>
        </div>


        <div className="row">
        <div className="form-group col">
        <h6>Primary Image/Video:</h6>
        
          <div className="row">
          <div className="col-3">
            <label>
              
                <input
                    type="radio"
                    name="media"
                    value="Video"
                    checked={this.state.primary_media_type === "Video"}
                    onChange={(e)=>this.handleMediaChange(e,"Video")}
                />  Video 
          </label>  </div>
          <div className="col-3">
            <label>
         
                <input
                    type="radio"
                    name="media"
                    value="Image"
                    checked={this.state.primary_media_type === "Image"}
                    onChange={(e)=>this.handleMediaChange(e,"Image")}
                /> Image 
            </label> </div>
            </div>


          
        </div></div>
        
       { this.state.primary_media_type=="Image" || this.state.image?(<div className="row">
          <div className="form-group col">
            <label htmlFor="imageInputPrimary">Primary Image*</label>
            <div className="input-group">
              <input
               
                onChange={this.selecteImage}
                id="imagePrimary"
                name="imagePrimary"
                type="file"
                accept="image/*"
                disabled={!isNewPackage && !editables.imagePrimary }
                className={"form-control" + (errors.imagePrimary ? " has-error" : "")}
              />
              {this.renderPencilIcon("imagePrimary")}
            </div>
            <span className="help-block">{errors.imagePrimary}</span>
          </div>
          <div className="form-group col">
            {this.state.imgSrc && (
              <>
                <label htmlFor="selected_package_image">
                  Selected Thumbnail Image
                </label>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={this.state.imgSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>):
        (<div  className="row">
           <div className="form-group col">
           <label htmlFor="Video URL">Video File*</label>
           <div className="input-group">
              <input
              
                onChange={(e) => this.handleIconChange(e, 'Video URL')}
                id="videoUrl"
                name="videoUrl"
                type="file"
                accept="video/*"
                disabled={!isNewPackage && !editables.videoUrl}
                className={"form-control" + (errors.videoUrl ? " has-error" : "")}
              />
              {this.renderPencilIcon("videoUrl")}
            </div>
            <span className="help-block">{errors.videoUrl}</span>
            <br/>
            <label htmlFor="videoThumbnailImage">Video Thumbnail*</label>
           <div className="input-group">
              <input
                onChange={this.selectePackageImage}
                id="videoThumbnailImage"
                name="videoThumbnailImage"
                type="file"
                accept="image/*"
                disabled={!isNewPackage && !editables.videoThumbnailImage }
                className={"form-control" + (errors.image ? " has-error" : "")}
              />
              {this.renderPencilIcon("videoThumbnailImage")}
            </div>
             <span className="help-block">{errors.videoThumbnailImage}</span>
          

       </div>
       <div className="form-group col">
       {this.state.video_s3_url? (<><div>
                <label htmlFor="selected_package_image">
                  Selected Video
                </label></div><video controls width="100%" height="150" key={this.state.video_s3_url}>
          <source src={this.state.video_s3_url} type="video/mp4" />
          </video></>):null}
       {this.state.packageimgSrc && (<>
              <div>
                <label htmlFor="selected_package_image">
                  Selected Thumbnail Image
                </label></div>
                <div>
                  <img
                    id="selected_package_image"
                    name="selected_package_image"
                    src={this.state.packageimgSrc}
                    alt="Selected"
                    className="img-thumbnail mx-auto"
                    style={{
                      width: "100%",
                      maxHeight: "150px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                </>)}

       </div>
       </div>

          )}

<div className="row">
          <div className="col">
            <OtherImagesPackages
              id="english"
              label="Other Images"
              images={this.state.packages_media ?? []}
              onImagesUpdated={this.handleOtherImagesUpdated}
              isEditable={isEditable}
              selectedMedia={"Image"}
              packageId={this.state.edit_id}
            />
          </div>
        </div>
       {/* <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Thumbnail Image*</label>
            <div className="input-group">
              <input
                onChange={this.selecteImage}
                id="imageInput"
                name="infographic_thumbnail"
                type="file"
                accept="image/*"
                disabled={!isNewPackage && !editables.package_selection_thumbnail}
                className={
                  "form-control" +
                  (errors.infographic_thumbnail ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("package_selection_thumbnail")}
            </div>
            <span className="help-block">{errors.infographic_thumbnail}</span>
          </div>
          <div className="form-group col">
            {this.state.imgSrc && (
              <>  
                <label>Selected Thumbnail Image</label>
                <div className="form-image-container">
                  <img
                    src={this.state.imgSrc}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
        </div>*/}
       {/* <div className="row">
          <div className="form-group col">
            <label htmlFor="imageInput">Package Description Image*</label>
            <div className="input-group">
              <input
                onChange={this.selectePackageImage}
                id="imageInput"
                name="infographic_image"
                type="file"
                accept="image/*"
                disabled={!isNewPackage && !editables.package_detail_image}
                className={
                  "form-control" +
                  (errors.infographic_image ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("package_detail_image")}
            </div>
            <span className="help-block">{errors.infographic_image}</span>
          </div>
          <div className="form-group col">
            {this.state.packageimgSrc && (
              <>
                <label>Selected Description Image</label>
                <div className="form-image-container">
                  <img
                    src={this.state.packageimgSrc}
                    className="form-image"
                    alt="selected"
                  />
                </div>
              </>
            )}
          </div>
        </div>*/}
      </div>;
 const bloodPackageBundleSections = (
  <>
    <div className="row mt-2">
      <div className="form-group col-4">Is Bundle?</div>
      <div className="form-group col-2">
        <ToggleSwitch
          id="is_bundle"
          toggled={this.state.is_bundle ?? false}
          onToggle={(isToggled) =>
            this.setState({ is_bundle: isToggled })
          }
          disabled={!isEditable}
        />
      </div>
      {this.state.is_bundle && (
        <div  className="container mt-4">
              <PackageBreaking packages={customPackageBundles} onPackageUpdated={this.handlePackagesUpdated} isEditable={isEditable} bloodPackages={bloodPackagesDropdownList} customPackages={customPackagesForPackageBreaking} membersApplicable={member_applicable} />
            </div>
      )}
    </div>
  </>
);

    const packageTypeForm =
      <div className="container mt-4">
        {operationType}
        {/* {paidDoctorConsultation} */} 
        {sampleRequiredForm}
        {memberGenders}
        {this.state.member_applicable && this.state.member_applicable >= 1 ? bloodPackageBundleSections : null}
        {this.state.operation_type.indexOf("Home Appointment") > -1 && homeAppointmentForm}
        {/* {this.state.operation_type.indexOf("Home Appointment") > -1 && homeAppointmentForm} */}
      </div>;

    const formPricingOneTimeContent = (
      <>
      
        <div className="row">
          <div className="form-group col">
            <label htmlFor="retail_price">Retail Price*</label>
            <div className="input-group">
              <input
                value={this.state.retail_price}
                onChange={this.handleChange}
                id="retail_price"
                name="retail_price"
                type="text"
                placeholder="Enter Retail Price"
                disabled={!isNewPackage && !editables.retail_price}
                className={
                  "form-control py-2" + 
                  (errors.retail_price ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("retail_price")}
            </div>
            <span className="help-block">{errors.retail_price}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="final_price">Final Price*</label>
            <div className="input-group">
              <input
                value={this.state.final_price}
                onChange={this.handleChange}
                id="final_price"
                name="final_price"
                type="text"
                placeholder="Enter Discounted Final Price"
                disabled={!isNewPackage && !editables.final_price}
                className={
                  "form-control py-2" + 
                  (errors.final_price ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("final_price")}
            </div>
            <span className="help-block">{errors.final_price}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="discount_type">Discount Type*</label>
            <div className="input-group">
              <Dropdown
                value={this.state.discount_type}
                onChange={(event, value) => { this.setState({ discount_type: value.value }); }}
                id="discount_type"
                placeholder="Percentage or Fixed"
                search
                selection
                options={this.state.discountOptions}
                disabled={!isNewPackage && !editables.discount_type}
                className={
                  "form-control" +
                  (errors.discount_type ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discount_type")}
            </div>
            <span className="help-block">{errors.discount_type}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="discount_value">Discount Value*</label>
            <div className="input-group">
              <input
                value={this.state.discount_value}
                onChange={this.handleChange}
                id="discount_value"
                name="discount_value"
                type="number"
                placeholder="Discount Value"
                disabled={!isNewPackage && !editables.discount_value}
                className={
                  "form-control " +
                  (errors.discount_value ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("discount_value")}
            </div>
            <span className="help-block">{errors.discount_value}</span>
          </div>
        </div>
      </>
    );

    // Currently one time pricing is always enabled so we are not using this.
    // But keeping it here for future purpose.
    const formPricingOneTime = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>One Time</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">One Time Pricing</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enable_one_time"
              toggled={this.state.enable_one_time ?? false}
              onToggle={(isToggled) =>
                this.setState({ enable_one_time: isToggled })
              }
              disabled={!isEditable}
            />
          </div>
        </div>
        {this.state.enable_one_time && formPricingOneTimeContent}
      </>
    );

    const formPricingSubscriptionContent = (
      <div className="container mt-2">
        <span className="help-block">{errors.subscription_items}</span>
        <SubscriptionForm
          subscriptions={this.state.subscription_items ?? []}
          onSubscriptionUpdated={this.handleSubscriptionsItemsUpdated}
          isEditable={isEditable}
        />
      </div>
    );

    const formPricingSubscription = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>Subscription</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-4">Subscription</div>
          <div className="form-group col-2">
            <ToggleSwitch
              id="enableOneTime"
              toggled={this.state.enable_subscription ?? false}
              onToggle={(isToggled) =>
                this.setState({ enable_subscription: isToggled })
              }
              disabled={!isEditable}
            />
          </div>
        </div>
        {this.state.enable_subscription && formPricingSubscriptionContent}
      </>
    );

    const customPackageCitiesDetails = (
      <>
        <div className="row mt-2">
          <div className="col separator">
            <span>City Prices</span>
          </div>
        </div>
        <PackageCityDetails
          cityPackageDetails={this.state.customPackageCities || []}
          onCityPackageDetailsUpdated={this.handleCityPackageItemsUpdated}
          packageType={"customPackage"}
          packageId={this.state.editPackageId || null}
          selectedCountry={selectedCountry}
          isEditable={isEditable}
        />
      </>
    )


    const inclusionsForm=<div className="container mt-4">
           <div className="row">
            <div className="form-group col" style={{display:"flex", justifyContent: "space-between",}}>
              <div style={{width:"100%"}}>Is this a Food Intolerance Test</div>
              <div className="input-group">
              <input
                value={true}
                checked={is_food_intolerance_test && is_food_intolerance_test || false}
                onChange={(event, value) => {
                  this.setState({
                    is_food_intolerance_test:event.target.checked
                  });
                }}
                id="isRequired"
                name="isRequired"
                type="checkbox"
                className="larger-checkbox"
              />
            </div>
            </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="foodLists">Food List</label>
            <div className="input-group">
              <Dropdown
                value={this.state.food_items}
                onChange={(event, value) => {
                  this.setState({ food_items: value.value });
                }}
                id="foodLists"
                placeholder="Select Food"
                search
                fluid
                multiple
                selection
                options={
                  this.state.foodItemOptions
                }
                disabled={!isNewPackage && !editables.foodLists}
                className={
                  "form-control" + (errors.foodLists ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("foodLists")}
            </div>
            <span className="help-block">{errors.foodLists}</span>
          </div>
        </div>
      <div className="row">
        <div className="form-group col-6">
        <label>Custom Test AddOns</label>
        <div className="input-group">
        <SelectCustomTestAddons
        selectedCustomPackageIds={this.state.custom_addons??[]}
        id="select custom Test Addons"
        name="select custom Test Addons"
        countryId={selectedCountry?.id}
        onSelect={(options) => {
          this.setState({
            custom_addons: options.map((it) => it.value),
          });
        }}
        showOnlyActive={true}
        isDisabled={!isNewPackage && !editables.custom_addons}
        className={
          "form-control" +
          (errors.custom_addons ? " has-error" : "")
        }
        />
          {this.renderPencilIcon("custom_addons")}
        </div>
        </div>
     
       
      </div>
      <span className="help-block">{this.state.customAddonsErrorMessage}</span>
    </div>

    const locationAndPricingForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <div className="input-group">
              <input
                value={true}
                checked={!this.state.is_eligible_for_cod || false}
                onChange={(event, value) => {
                  this.setState({
                    is_eligible_for_cod: !event.target.checked
                  });
                }}
                id="isCODRemove"
                name="isCODRemove"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Remove COD for this Product
               </div> 
            </div>
            <div className="form-group col-6">
            <div className="input-group">
              <input
                value={true}
                checked={this.state.is_vat_excluded || false}
                onChange={(event, value) => {
                  this.setState({
                    is_vat_excluded: event.target.checked
                  });
                }}
                id="isVATRemove"
                name="isVATRemove"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; No VAT for this product
               </div> 
            </div>
          </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewPackage 
                ? selectedCountry && selectedCountry.country_name
                : packageCountry && packageCountry.country_name 
              }
              type="text"
              id="country"
              name="country"
              readOnly
              className="form-control"
            />
          </div>
          <div className="form-group col">
            <label htmlFor="currency">Currency</label>
            <input
              value={isNewPackage
                ? selectedCountry && selectedCountry.currency
                : packageCountry && packageCountry.currency 
              }
              type="text"
              id="currency"
              name="currency"
              readOnly
              className="form-control"
            />
          </div>
        </div>
        {formPricingOneTimeContent}
        {customPackageCitiesDetails}
        {formPricingSubscription}
      </div>;

    const fastingHoursForm =
      <div className={isNewPackage ? "row mt-2" : "row"}>
        <div className="form-group col-6">
          <label htmlFor="fasting_hours">Fasting Hours*</label>
          <div className="input-group">
            <input
              value={this.state.fasting_hours ?? null}
              onChange={(event) => {
                this.setState({ fasting_hours: event.currentTarget.value ? Number(event.currentTarget.value) : null });
              }}
              id="fasting_hours"
              name="fasting_hours"
              type="number"
              placeholder="Fasting Hours"
              disabled={!isNewPackage && !editables.fasting_hours}
              className={
                "form-control py-2" +
                (errors.fasting_hours ? " has-error" : "")
              }
            />
            {this.renderPencilIcon("fasting_hours")}
          </div>
          <span className="help-block">{errors.fasting_hours}</span>
        </div>
      </div>;

    const CustomPackageIdForm=<>
    <PackageOrders
    packageOrderDetails={customPackageIdOrders}
    packageType={"Custom_Package"}
    packageId={this.state.editPackageId}
    totalRows={this.state.packageIdTotalRows}
    />
    </>

const coachSupplementSuggestionsForm=(
  <>
    <div className="row mt-2">
        <div className="col separator">
          <span>Coach Suggested Supplements</span>
        </div>
      </div>
         <div className="input-group">
            <input
              value={true}
              checked={is_default_suggested_supplement_required && is_default_suggested_supplement_required || false}
              onChange={(event, value) => {
                this.setState({
                  is_default_suggested_supplement_required:event.target.checked
                });
              }}
              id="isRequired"
              name="isRequired"
              type="checkbox"
              className="larger-checkbox"
            />
            <label style={{marginLeft:"10px"}}>is Default Suggested Supplements Required</label>
          </div>
          <SupplementSuggestion
              suggestions={this.state.supplementSuggestion??[]}
              countryId={selectedCountry?.id ?? null}
              suggestedSupplements={supplements}
              suggestionsUpdate={this.handleSupplementSuggestions}
              isEditable={isEditable}
              
            />

  </>
)
    const sampleTypeForm =
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col-6">
            <label htmlFor="sample_type">Sample Type*</label>
            <div className="input-group">
              <SelectSampleType
                sampleTypes={this.state.packageSampleTypes ?? []}
                selectedSampleType={this.state.sample_type}
                onSampleTypeSelect={(selectedOption) => {
                  this.setState({ sample_type: selectedOption.value });
                }}
                id="sample_type"
                name="sample_type"
                isDisabled={!isNewPackage && !editables.sample_type}
                className={
                  "form-control" + (errors.sample_type ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("sample_type")}
            </div>
            <span className="help-block">{errors.sample_type}</span>
          </div>
        </div>
        <div className="row mt-1">
          <span style={{ marginLeft: "1rem", marginRight: "0.5rem" }}>Fasting Required*</span>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="yes"
              name="yes"
              value='yes'
              onChange={(event, value) => this.setState({ fasting: event.target.checked }, () => this.updateFastingHours())}
              disabled={!isNewPackage && !editables.fasting}
              checked={this.state.fasting} />
            <label htmlFor="yes" className="custom-control-label mr-5">Yes</label>
          </div>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id="no"
              name="no"
              value='no'
              onChange={(event, value) => this.setState({ fasting: !event.target.checked }, () => this.updateFastingHours())}
              disabled={!isNewPackage && !editables.fasting}
              checked={!this.state.fasting} />
            <label htmlFor="no" className="custom-control-label">No</label>
          </div>
          <div className="form-group col ml-4">
            {this.renderPencilIcon("fasting")}
          </div>
          <div className="form-group col"></div>
        </div>
        {this.state.fasting && fastingHoursForm}
        <div className="" style={{marginTop:"130px", display:"flex"}}>
          <div className="form-group col-4">Allow Custom Package Addition</div>
          <div className="form-group col-2">
          <ToggleSwitch
              id="enableSubscription"
              toggled={this.state.is_allow_custom_package_addition ?? false}
              onToggle={(isToggled) =>
                this.setState({ is_allow_custom_package_addition: isToggled })
              }
              disabled={!isEditable}
            />
          </div>
          {this.state.is_allow_custom_package_addition && (
            <div className="form-group col-6">
              <label>Popular Custom Package List</label>
              <div className="input-group">
              <SelectCustomPackages
                  customPackages={this.state.customPackagesDropdownList ?? []}
                  selectedCustomPackageIds={this.state.customPackages?? []}
                  onSelect={(options) => {
                  
                    this.setState({
                      customPackages: options.map((it) => it.value),
                    });
                  }}
                  menuPlacement={this.state.tests && this.state.tests.length > 8 ? "top" : "bottom"}
                  showOnlyActive={true}
                  isDisabled={!isNewPackage && !editables.custompackages}
                  className={
                    "form-control" +
                    (errors.customPackages ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("custompackages")}
              </div>
              <span className="help-block">{errors.customPackages}</span>
            </div>
          )}
        </div>
        <div className="" style={{marginTop:"130px", display:"flex"}}>
          <div className="form-group col-4">Allow Supplement Addition</div>
          <div className="form-group col-2">
          <ToggleSwitch
              id="supplementAddition"
              toggled={this.state.is_allow_supplement_addition ?? false}
              onToggle={(isToggled) =>
                this.setState({ is_allow_supplement_addition: isToggled })
              }
              disabled={!isEditable}
            />
          </div>
          {this.state.is_allow_supplement_addition && (
            <div className="form-group col-6">
              <label>Popular Supplement List</label>
              <div className="input-group">
                <SelectSupplements
                  id="suggestedSupplements"
                  name="suggestedSupplements"
                  countryId={selectedCountry?.id}
                  selectedSupplementIds={this.state.supplement_addons ?? []}
                  onSupplementSelect={(options) => {
                    this.setState({
                      supplement_addons: options.map((it) => it.value),
                    });
                  }}

                  showOnlyActive={true}
                  isDisabled={!isNewPackage && !editables.supplements}
                  className={
                    "form-control" +
                    (errors.supplements ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("supplements")}
              </div>
              <span className="help-block">{this.state.supplementsErrorMessage}</span>
            </div>
          )}
        </div>
        <div className="" style={{marginTop:"130px", display:"flex"}}>
          <div className="form-group col-4">Allow Coach Consultation Package Addition</div>
          <div className="form-group col-2">
          <ToggleSwitch
              id="coachConsultationAddition"
              toggled={this.state.is_allow_coach_cons_pkg_addition ?? false}
              onToggle={(isToggled) =>
                this.setState({ is_allow_coach_cons_pkg_addition: isToggled })
              }
              disabled={!isEditable}
            />
          </div>
          {this.state.is_allow_coach_cons_pkg_addition && (
            <div className="form-group col-6">
              <label>Popular Coach Consultation List</label>
              <div className="input-group">
                <SelectCoachConsultation
                 countryId={selectedCountry?.id}
                  selectedCoachConsultationIds={this.state.coach_cons_pkg_addons ?? ""}
                  onCoachConsultationSelect={(event) => {
                    this.setState({
                      coach_cons_pkg_addons: event.value,
                    });
                  }}

                  showOnlyActive={true}
                  isDisabled={!isNewPackage && !editables.coachConsultationpackages}
                  className={
                    "form-control" +
                    (errors.coachConsultationpackages ? " has-error" : "")
                  }
                />
                {this.renderPencilIcon("coachConsultationpackages")}
              </div>
              <span className="help-block">{this.state.coachConsultationError}</span>
            </div>
          )}
        </div>
        {coachSupplementSuggestionsForm}
        <PackageSampleDetails
              packageSamples={this.state.mappingPackageSamples?? []}
              countryId={selectedCountry?.id ?? null}
              allSamples={sampleDetails}
              packageSamplesUpdate={this.handlePackageSampleDetailsUpdate} 
              isEditable={isEditable}
              sampleOptions={sampleDetailsOptions}
            />

      </div>;

    if (this.state.accessDenied) {
      return <AccessDenied />
    }

    return (
      <div className="manage-test" >
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          {/* <h5>Manage Packages</h5> */}
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={this.state.searchTerm}
                clearSearch={this.clearSearch}
                handleSearch={this.handleSearch}
                handleSearchExit={this.handleSearchExit}
                ref={input => {
                  this.search = input;
                }}
              ></Search>
            </div>
            {/* <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.showItems}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={showItems === "All" ? "active" : ""}
                    onClick={() => this.setShowItems("all")}
                  >
                    {showItems === "All" ? <CheckIcon></CheckIcon> : null}All
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Active" ? "active" : ""}
                    onClick={() => this.setShowItems("active")}
                  >
                    {showItems === "Active" ? <CheckIcon></CheckIcon> : null}
                    Active
                  </ActionBar.Item>
                  <ActionBar.Item
                    className={showItems === "Archived" ? "active" : ""}
                    onClick={() => this.setShowItems("archived")}
                  >
                    {showItems === "Archived" ? <CheckIcon></CheckIcon> : null}
                    Archived
                  </ActionBar.Item>
                </ActionBar.Menu>
              </ActionBar>
            </div> */}
            {/* <div className="action-item select-menu">
              <ActionBar alignRight className="valeo-dropdown">
                <ActionBar.Toggle variant="">
                  {this.state.categoryFilter == 'All categories' ? 'All Categories' : this.state.testCategortList.filter((item) => item.value == this.state.categoryFilter)[0].text}
                </ActionBar.Toggle>
                <ActionBar.Menu>
                  <ActionBar.Header>Show</ActionBar.Header>
                  <ActionBar.Item
                    className={categoryFilter === "All categories" ? "active" : ""}
                    onClick={() => this.filterCategory("All categories")}
                  >
                    {categoryFilter === "All categories" ? <CheckIcon></CheckIcon> : null}All categories
                  </ActionBar.Item>
                  {this.state.testCategortList && this.state.testCategortList.map((item, index) => {
                    return (
                      <ActionBar.Item key={index}
                        className={categoryFilter === item.value ? "active" : ""}
                        onClick={() => this.filterCategory(item.key)}
                      >
                        {categoryFilter === item.value ? <CheckIcon></CheckIcon> : null}
                        {item.text}
                      </ActionBar.Item>
                    )
                  })}
                </ActionBar.Menu>
              </ActionBar>
            </div> */}
            <div className="action-item">
              <button
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
                onClick={() => {
                  this.setState({ 
                    isNewPackage: true, 
                    status: "Inactive",
                    is_best_seller:false, 
                    retail_price: 0, 
                    final_price: 0, 
                    discount_type: "percentage",
                    discount_value: 0,
                    position_value: this.state.maxRank + 1,
                    sample_type: null,
                    fasting: false,
                    member_applicable: 1,
                    customPackages:[],
                    customPackagesDropdownList:this.state.customList && this.state.customList.length>0 && this.state.customList.filter(item=>item.country==this.state.selectedCountry.id),
                    member: new Array(1).fill(this.getDefaultMemberGender()),
                    partner_id:null,

                    internal_category: "None",
                    is_paid_doctor_consultation:false,
                    is_require_sample_collection:false,
                    primary_media_type:"Image",
                  }, () => this.showModal());
                }}
                disabled={!isEditable}
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card>
          <DataTable
            highlightOnHover
            columns={this.columns}
            data={finalList}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + finalList.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.showModal}
          onHide={this.hideModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.state.editPackageId ? this.updateTest : this.saveTest}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="key_details"
            >
              <Nav variant="tabs" className="justify-content-left">
                <Nav.Item>
                  <Nav.Link eventKey="key_details">
                    Details
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_package_type">
                    Package Type
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_inclusion_form">
                   Inclusions
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_sample_type_form">Sample Type</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="key_location_pricing">
                    Location & Pricing
                  </Nav.Link>
                </Nav.Item>
                {! isNewPackage &&<Nav.Item>
               <Nav.Link eventKey="key_custom_package_form">
                  Orders
                  </Nav.Link>
                </Nav.Item>}
                {this.state.pageIdForLogs!=null? 
                  <Nav.Item>
                    <Nav.Link eventKey="logs-custom-package">
                      Logs
                    </Nav.Link>
                  </Nav.Item>
                :""}
              </Nav>
              <Row>
                <Col>
                  <Tab.Content>
                    <Tab.Pane eventKey="key_details">
                      {detailsForm}
                    </Tab.Pane>
                    <Tab.Pane eventKey="key_package_type">
                      {packageTypeForm}
                    </Tab.Pane> 
                    <Tab.Pane eventKey="key_inclusion_form">
                      {inclusionsForm}
                    </Tab.Pane>
                    <Tab.Pane eventKey="key_sample_type_form">{sampleTypeForm}</Tab.Pane>
                    <Tab.Pane eventKey="key_location_pricing">
                      {locationAndPricingForm}
                    </Tab.Pane>
                    {!isNewPackage && <Tab.Pane eventKey="key_custom_package_form">
                      {CustomPackageIdForm}
                    </Tab.Pane>}
                    {this.state.pageIdForLogs!=null?  <Tab.Pane eventKey="logs-custom-package"> <Logs pageId={this.state.pageIdForLogs} pageType='CUSTOM_PACKAGE' /> </Tab.Pane> :""}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showConfirm} onHide={this.hideConfirm}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.confirmTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.confirmMessage}</Modal.Body>
          <Modal.Footer>
            {this.state.confirmType === "danger" ? (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-danger"
              >
                Archive
              </button>
            ) : (
              <button
                onClick={() => this.toggleActiveStatus(this.state.confirmItem)}
                className="btn button btn-success"
              >
                Restore
              </button>
            )}
            <button
              className="btn btn-secondary button"
              onClick={this.hideConfirm}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}
function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: value =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: value => dispatch({ type: "TOGGLE_LOADING", value })
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageTests);
