import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import SelectCoupon from "./SelectCoupon";
import PhoenixAPI from "utils/PhoenixAPI";
import { Dropdown } from "semantic-ui-react";


const PAYMENT_MODE_OPTIONS = [
  {
    key: "CARD",
    value: "CARD",
    text: "Card",
  },
  {
    key: "COD",
    value: "COD",
    text: "COD",
  },
  {
    key: "TAMARA",
    value: "TAMARA",
    text: "Tamara",
  },
  {
    key: "APPLE_PAY",
    value: "APPLE_PAY",
    text: "Apple Pay",
  },
  {
    key: "wallet",
    value: "wallet",
    text: "Wallet",
  },
  {
    key:"TABBY",
    value:"TABBY",
    text:"Tabby"
  },
  /*{
    key: "COD_MAMOPAY",
    value: "COD_MAMOPAY",
    text: "COD+MamoPay", 
    
  },*/
];
const PAYMENT_GATEWAY_OPTIONS = [
  {
    key: "CHECKOUT",
    value: "CHECKOUT",
    text: "Checkout",
  },
  {
    key: "PAYTABS",
    value: "PAYTABS",
    text: "Paytab",
  },
  {
    key: "MAMOPAY",
    value: "MAMOPAY",
    text: "Mamopay",
  },
  {
    key: "COD",
    value: "COD",
    text: "COD",
  },
  {
    key: "TAMARA",
    value: "TAMARA",
    text: "Tamara",
  },
  {
    key: "wallet",
    value: "wallet",
    text: "Wallet",
  },
  {
    key:"TABBY",
    value:"TABBY",
    text:"Tabby"
  },
  {
    key: "OTHERS",
    value: "OTHERS",
    text: "Others",
  },
];
function PaymentInfoForm(props) {
  const {
    cartItems = [],
    coupons = [],
    country,
    city,
    userId,
    onDiscountDetailsUpdated,
    errors = {},
    nationality=""
  } = props;

  const [discountDetails, setDiscountDetails] = useState(props.discountDetails);
  console.log("discountDetails", discountDetails);
  const [walletDetails, setWalletDetails] = useState(null);
  const isCartHasAnySubscriptionOrder = cartItems.some((it) => it.isSubscriptionOrder || false);
  console.log("cartItems are",cartItems);
  const showCod = cartItems.some(item => {
    if (item.itemType === "Blood_Package" || item.itemType === "Custom_Package" || item.itemType === "Supplement") {
      return !item.isCoddisabled;
    } 
    return false;
  });
  useEffect(() => {
    const fetchWalletDetails = async() => {
      try {
        props.toggleLoading({ isLoading: true });
        const { data: walletDetails } = await PhoenixAPI.get(`/api/v1/wallets/customer/${userId}?country=${country.id}`)
        setWalletDetails(walletDetails);
        
        const newDiscountDetails = { ...discountDetails };
        newDiscountDetails.walletDetails = walletDetails;
        setDiscountDetails(newDiscountDetails);
        onDiscountDetailsUpdated(newDiscountDetails);
      } catch (error) {
        console.log("fetchWalletDetails:: Error on fetching wallet details!", error);
      } finally {
        props.toggleLoading({ isLoading: false });
      }
    }
    if (userId && country && country.id) {
      fetchWalletDetails();
    }
  }, [userId, country]);

  const showNotification = (notificationMessage, isSuccessMessage) =>
    props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });


  const showSuccessNotification = (notificationMessage) =>
    showNotification(notificationMessage, true);

  const showErrorNotification = (notificationMessage) =>
    showNotification(notificationMessage, false);

  const handleChange = ({ currentTarget: input }) => {
    const newDiscountDetails = { ...discountDetails };
  
    newDiscountDetails[input.name] = input.value;
    if(input.name=="walletAmount")
    {newDiscountDetails.finalAmount =

    newDiscountDetails.totalAmount-Number(newDiscountDetails.discount) + Number(newDiscountDetails.deliveryCharges)+ Number(newDiscountDetails.nurseVisitFee)-Number(newDiscountDetails.additionalDiscount?newDiscountDetails.additionalDiscount:0);
     //removing adding vat amount to final amount
    newDiscountDetails.finalAmount=newDiscountDetails.finalAmount<0?0:Number(newDiscountDetails.finalAmount.toFixed(2));
     

  }
    setDiscountDetails(newDiscountDetails);
    onDiscountDetailsUpdated(newDiscountDetails);
  };

  const handleAdditionalDiscount=({ currentTarget: input })=>{
    const newDiscountDetails={...discountDetails};
    console.log("newDiscountDetails", newDiscountDetails)
    console.log("type of additional discount",typeof(input.value))
    newDiscountDetails[input.name] =input.value? Number(input.value):null;
    if(Number(input.value)>=0){
     
      newDiscountDetails.finalAmount =

      newDiscountDetails.totalAmount-newDiscountDetails.discount + Number(newDiscountDetails.deliveryCharges)+Number(newDiscountDetails.nurseVisitFee)-Number(newDiscountDetails.additionalDiscount);
        //removing adding vat amount to final amount
      newDiscountDetails.finalAmount=Number(newDiscountDetails.finalAmount)<0?0:Number(newDiscountDetails.finalAmount.toFixed(2));
      setDiscountDetails(newDiscountDetails);
      onDiscountDetailsUpdated(newDiscountDetails);
    }
    
  }

   
   
  


  const handlePaymentModeChange = (paymentMode) => {
    const newDiscountDetails = { ...discountDetails };
    newDiscountDetails.paymentMode = paymentMode;
    if(paymentMode=="COD_MAMOPAY"){
      newDiscountDetails.paymentGateway = PAYMENT_GATEWAY_OPTIONS.filter((it) =>it.value=="MAMOPAY")[0].value;
    }
    setDiscountDetails(newDiscountDetails);
    onDiscountDetailsUpdated(newDiscountDetails);
   
   
    
  };

  const handlePaymentGatewayChange = (paymentGateway) => {
    const newDiscountDetails = { ...discountDetails };
    newDiscountDetails.paymentGateway = paymentGateway;
    setDiscountDetails(newDiscountDetails);
    onDiscountDetailsUpdated(newDiscountDetails);
  };

  const applyCoupon = async (couponCode) => {
    const itemMapper = (item) => ({ id: item.itemId, quantity: item.quantity });
    const bloodPackages = cartItems
      .filter((it) => it.itemType === "Blood_Package")
      .map(itemMapper);
    const coachPackages = cartItems
      .filter((it) => it.itemType === "Coach_Package")
      .map(itemMapper);
    const customPackages = cartItems
      .filter((it) => it.itemType === "Custom_Package")
      .map(itemMapper);
    const bundles = cartItems
      .filter((it) => it.itemType === "Bundle")
      .map(itemMapper);
    const medicines = cartItems
      .filter((it) => it.itemType === "Medicine")
      .map(itemMapper);
    const supplements = cartItems
      .filter((it) => it.itemType === "Supplement")
      .map(itemMapper);
    const cartItemMiniPackage = cartItems.find(it => it.itemType === "Mini_Package");
    const miniPackage = cartItemMiniPackage && cartItemMiniPackage.miniPackages
      ? cartItemMiniPackage.miniPackages.map((item) => ({ id: item, quantity: 1 }))
      : [];
        console.log("cartItems: Apply Coupon", cartItems);
        const totalCartPrice = cartItems
        // .map((cartItem) => {
        //   const { quantity, price } = cartItem;
        //   return quantity * price;
        // })
        .map((cartItem) => cartItem.totalPrice ?? 0)
        .reduce((total, current) => total + current, 0)
        const couponCodeOrders = cartItems.map(item => ({
          orderItemType: item.itemType,
          orderItemId: item.itemId,
          packagePrice: item.price,
          addOnPrice: item?.miniPackagePrice ??0,
          couponCode: couponCode, // Assuming this is a constant value
          countryId: country.id, // Assuming this is a constant value
          totalCartPrice,
          quantity: item.quantity,
          miniPackageIds:item.miniPackages ? item.miniPackages.length>0?item.miniPackages:null:null,
      }));

//     const combinedMiniPackages = cartItems.reduce((acc, item) => {
//       if (item.miniPackages.length > 0) {
//         acc.push(...item.miniPackages);
//     }
//     return acc;
// }, []);

  const addOnMiniPackageIds = [...new Set(cartItems.flatMap(item => item.miniPackages ?? []))];
  
  if(addOnMiniPackageIds.length>0){
    addOnMiniPackageIds.forEach((item)=>{
      miniPackage.push({id:item, quantity:1})
    })
  }

  const uniqueIDsSet = new Set();

  // Create a  new MinPackages array with unique IDs
  const newMiniPackagesArray = miniPackage.length>0 && miniPackage.filter(item => {
      if (!uniqueIDsSet.has(item.id)) {
          uniqueIDsSet.add(item.id);
          return true;
      }
      return false;
  });
  
  console.log(newMiniPackagesArray);
    const payload = {
      couponCode,
      bloodPackages:
        bloodPackages && bloodPackages.length ? bloodPackages : null,
      coachPackages:
        coachPackages && coachPackages.length ? coachPackages : null,
      customPackages:
        customPackages && customPackages.length ? customPackages : null,
      bundles: bundles && bundles.length ? bundles : null,
      medicines: medicines && medicines.length ? medicines : null,
      supplements: supplements && supplements.length ? supplements : null,
      miniPackage: (newMiniPackagesArray && newMiniPackagesArray.length>0? newMiniPackagesArray:null),
      userId,
      country: country.id,
      cityId:city.id,
      isCityLevelPricing:true,
      couponOrders :couponCodeOrders.length>0?couponCodeOrders:[]
    };
    try {
      props.toggleLoading(true);
      // const response = await PhoenixAPI.post("apply-coupon-cart/", payload);  changing the api for coupon code logic updation
      const response = await PhoenixAPI.patch("v2/referal-and-coupon", payload);
      const { data } = response;


     

    const {couponResponseDto={}}= data
      const { couponApplied, couponCode, discount, reason ,cashback, childCouponDiscount} = couponResponseDto;


      if (discount>0) {
        const newDiscountDetails = { ...discountDetails };
        newDiscountDetails.couponApplied = true;
        newDiscountDetails.discount = discount;
        newDiscountDetails.couponCode = couponCode;
     
        newDiscountDetails.finalAmount =

          newDiscountDetails.totalAmount - discount + newDiscountDetails.deliveryCharges+newDiscountDetails.nurseVisitFee-Number(newDiscountDetails.additionalDiscount?newDiscountDetails.additionalDiscount:0);

          newDiscountDetails.finalAmount=newDiscountDetails.finalAmount<0?0:Number(newDiscountDetails.finalAmount.toFixed(2));
          newDiscountDetails.childCouponDiscount=childCouponDiscount ??[];
          let modifiedCartItems=cartItems.map(itemH => {
            const matchingGItem = childCouponDiscount.length>0 ?childCouponDiscount.find(itemG => itemG.orderItemId === itemH.itemId):[];
            if (matchingGItem) {
                return {
                    ...itemH,
                    price:itemH.price-matchingGItem.childOrderDiscount,
                    couponDiscount: matchingGItem.childOrderDiscount ??0
                };
            } else {
                return itemH;
            }
        });
        let modifiedWithoutSupplementsAndMedicineItems=modifiedCartItems.filter((it) => (it.itemType!== "Medicine") &&( it.itemType !== "Supplement"))
        let modifiedAllCartItemsPrice=modifiedCartItems.map((cartItem) => cartItem.price ?? 0)
        .reduce((total, current) => total + current, 0)

        const totalModifiedCartPrice = modifiedWithoutSupplementsAndMedicineItems
        // .map((cartItem) => {
        //   const { quantity, price } = cartItem;
        //   return quantity * price;
        // })
        .map((cartItem) => cartItem.price ?? 0)
        .reduce((total, current) => total + current, 0)

        
          let nationalityTaxAmount=0;
           const vatPercentage = country
           ? Number(country.vat_percentage)
           : null;
           console.log("nationality",nationality,country.country_name);
           if(nationality==="expats" && country.country_name==="Kingdom of Saudi Arabia"){
            

           nationalityTaxAmount=((newDiscountDetails.finalAmount)*vatPercentage/100)??0;
            
           newDiscountDetails.vatAmount=Number(nationalityTaxAmount.toFixed(2));
           newDiscountDetails.finalAmount= newDiscountDetails.finalAmount<0?0:(Number(newDiscountDetails.finalAmount.toFixed(2))+newDiscountDetails.vatAmount);
            console.log("nationality expats :", newDiscountDetails, totalModifiedCartPrice, newDiscountDetails.finalAmount );
           }
           else if(nationality==="saudi" && country.country_name==="Kingdom of Saudi Arabia"){
            nationalityTaxAmount=0
            newDiscountDetails.vatAmount=Number(nationalityTaxAmount.toFixed(2));
            console.log("nationality saudi:", newDiscountDetails);
           }
           else{
            

            nationalityTaxAmount=((newDiscountDetails.finalAmount)*vatPercentage/100)??0;
            newDiscountDetails.vatAmount =Number(nationalityTaxAmount.toFixed(2));
           }
           
         
          setDiscountDetails(newDiscountDetails);

        onDiscountDetailsUpdated(newDiscountDetails);
        showSuccessNotification("Coupon Applied Successfully!");
      } 
      else if(cashback>0){
        console.log("cashback",cashback);
        const newDiscountDetails = { ...discountDetails };
        newDiscountDetails.couponApplied = true;
        newDiscountDetails.discount=discount;
        newDiscountDetails.cashbackAmount=cashback;
        newDiscountDetails.couponCode = couponCode;
        newDiscountDetails.finalAmount =

          newDiscountDetails.totalAmount - discount + newDiscountDetails.deliveryCharges+newDiscountDetails.nurseVisitFee-Number(newDiscountDetails.additionalDiscount?newDiscountDetails.additionalDiscount:0);


          newDiscountDetails.finalAmount=newDiscountDetails.finalAmount<0?0:Number(newDiscountDetails.finalAmount.toFixed(2));
          newDiscountDetails.childCouponDiscount=childCouponDiscount ??[]
        setDiscountDetails(newDiscountDetails);
        onDiscountDetailsUpdated(newDiscountDetails);
        showSuccessNotification("Coupon Applied Successfully!");
           
      }
      else  {
        showErrorNotification("Coupon not applied " + reason);
      }
      console.log("Response: ", response);
    } catch (error) {
      console.log("Error on applying coupon", error);
      showErrorNotification("Error on applying coupon " + error.message);
    } finally {
      props.toggleLoading(false);
    }
  };

  const handleCouponSelect = (couponCode) => {
    applyCoupon(couponCode);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="form-group col">
          <label htmlFor="coupon">Coupon</label>
          <SelectCoupon
            coupons={coupons ?? []}
            selectedCouponCode={discountDetails?.couponCode ?? null}
            onCouponSelect={handleCouponSelect}
          />
        </div>
        <div className="form-group col">
          <label htmlFor="discount">Discount</label>
          <input
            value={`${country?.currency} ${discountDetails?.discount}`}
            type="text"
            id="discount"
            name="discount"
            className="form-control py-2"
            readOnly={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="walletAmount">Wallet Balance</label>
          <div className="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{walletDetails?.country?.currency ?? ""}</span>
            </div>
            <input
              value={walletDetails?.balance}
              type="number"
              id="walletAmount"
              name="walletBalance"
              className="form-control py-2"
              readOnly={true}
            />
          </div>
        </div>
        <div className="form-group col">
          <label htmlFor="discount">Additional Discount</label>
          <div className="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{country?.currency ?? ""}</span>
            </div>
          <input
            value={discountDetails.additionalDiscount??null}
            onChange={handleAdditionalDiscount}
            type="number"
            id="additionalDiscount"
            name="additionalDiscount"
            min={0}
            className="form-control py-2"
            onKeyPress={(event) => {
              if (!/[0-9,.]*$/.test(event.key)) event.preventDefault();
            }}
            
          />
        </div>
        <span className="help-block">{errors.additionalDiscount ?? ""}</span>
      </div>

      </div>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="walletAmount">Wallet Amount</label>
          <div className="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{country?.currency ?? ""}</span>
            </div>
            <input
              value={discountDetails?.walletAmount}
              onChange={handleChange}
              type="number"
              id="walletAmount"
              name="walletAmount"
              className="form-control py-2"
              min={0}
              max={walletDetails?.balance ?? 0}
              disabled={!walletDetails || walletDetails.balance === 0}
            />
          </div>
          <span className="help-block">{errors.walletAmount ?? ""}</span>
        </div>
        <div className="form-group col">
          <label htmlFor="deliveryCharges">Delivery Charges</label>
          <input
            value={`${country?.currency} ${discountDetails?.deliveryCharges}`}
            type="text"
            id="deliveryCharges"
            name="deliveryCharges"
            className="form-control py-2"
            readOnly={true}
          />
        </div>
      </div>
      <div className="row">
        {nationality==="expats" && country.country_name==="Kingdom of Saudi Arabia" && discountDetails?.vatAmount?<div className="form-group col">
        <label htmlFor="vatAmount">Total Vat Amount Included</label>
          <input
            value={`${country?.currency} ${discountDetails?.vatAmount}`}
            type="text"
            id="vatAmount"
            name="vatAmount"
            className="form-control py-2"
            readOnly={true}
          />
        </div>: <div className="form-group col-6"></div>}
        <div className="form-group col">
          <label htmlFor="nurseVisitFee">Nurse Visit Fee</label>
          <input
            value={`${country?.currency} ${discountDetails?.nurseVisitFee}`}
            type="text"
            id="nurseVisitFee"
            name="nurseVisitFee"
            className="form-control py-2"
            readOnly={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="totalAmount">Subtotal</label>
          <input
            value={`${country?.currency} ${discountDetails?.totalAmount}`}
            type="text"
            id="totalAmount"
            name="totalAmount"
            className="form-control py-2"
            readOnly={true}
          />
        </div>
        <div className="form-group col-6">
          <label htmlFor="finalAmount">Total</label>
          <input
            value={`${country?.currency} ${discountDetails?.finalAmount}`}
            type="text"
            id="finalAmount"
            name="finalAmount"
            className="form-control py-2"
            readOnly={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <label htmlFor="paymentMode">Payment Mode*</label>
          <Dropdown
            value={discountDetails?.paymentMode}
            onChange={(event, value) => handlePaymentModeChange(value.value)}
            id="paymentMode"
            name="paymentMode"
            placeholder="Payment Mode"
            search
            selection
            options={isCartHasAnySubscriptionOrder || showCod 
              ? PAYMENT_MODE_OPTIONS.filter((it) => it.value !== "COD")
              : PAYMENT_MODE_OPTIONS
            }
            className={"form-control" + (errors.paymentMode ? " has-error" : "")}
          />
          <span className="help-block">{errors.paymentMode}</span>
        </div>
        <div className="form-group col">
          <label htmlFor="paymentGateway">Payment Gateway*</label>
          <Dropdown
            value={discountDetails?.paymentGateway}
            onChange={(event, value) => handlePaymentGatewayChange(value.value)}
            id="paymentGateway"
            name="paymentGateway"
            placeholder="Payment Gateway"
            search
            selection
            options={isCartHasAnySubscriptionOrder  || showCod
              ? PAYMENT_GATEWAY_OPTIONS.filter((it) => it.value !== "COD") 
              : PAYMENT_GATEWAY_OPTIONS
            }
           disabled={discountDetails?.paymentMode=="COD_MAMOPAY"}
            className={"form-control" + (errors.paymentGateway ? " has-error" : "")}
          />
          <span className="help-block">{errors.paymentGateway}</span>
        </div>
      </div>
      <div className="row">
        <div className="form-group col-6">
          <label htmlFor="transactionReference">{discountDetails.paymentMode=="COD_MAMOPAY"?"Service Reference":"Service Reference*"}</label>
          <input
            value={discountDetails?.transactionReference ?? ""}
            onChange={handleChange}
            type="text"
            id="transactionReference"
            name="transactionReference"
            className={"form-control py-2" + (errors.transactionReference ? " has-error" : "")}
          />
          <span className="help-block">{errors.transactionReference}</span>
        </div>
       {discountDetails.additionalDiscount>0? <div className="form-group col-6">
          <label htmlFor="transactionReference">Additional Discount Notes*</label>
          <input
            value={discountDetails?.additionalDicountNote ?? ""}
            onChange={handleChange}
            type="text"
            id="transactionReference"
            name="additionalDicountNote"
            className={"form-control py-2" + (errors.additionalDicountNote ? " has-error" : "")}
          />
          <span className="help-block">{errors.additionalDicountNote}</span>
        </div>:<></>}

      </div>
      <div className="row mt-2">
          <div className="form-group col-6">
          <div className="input-group">
              <input
                value={true}
                checked={discountDetails?.isNotifyClientEmail || false}
                onChange={(event, value) => {
                  const newDiscountDetails = { ...discountDetails };
                  newDiscountDetails.isNotifyClientEmail = event.target.checked;
                  setDiscountDetails(newDiscountDetails);
                  onDiscountDetailsUpdated(newDiscountDetails);
                }}
                id="isNotifyClientEmail"
                name="isNotifyClientEmail"
                type="checkbox"
                className="larger-checkbox"
              />
               &nbsp;&nbsp; Notify Client?
               </div> 

          </div>
          </div>

    </div>
  );
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfoForm);
