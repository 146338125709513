import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Modal, Tabs, Tab } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Dropdown } from "semantic-ui-react";
import { ArrowDownward } from "@material-ui/icons";
import AlertBox from "components/AlertBox/AlertBox";
import CountryDropdown from "components/Common/CountryDropdown";
import Search from "components/Search/Search";
import Status from "components/Common/Status";
import { fetchCountries } from "services/RegionService";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId } from "services/UserService";
import { Page } from "utils/constant";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AccessDenied from "../../components/Common/AccessDenied";
import Select from "react-select";

const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];
const STATUS_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

const HOMECARE_COSTING_NEW_ENABLE_OPTIONS = [
  {
    key: "Active",
    value: true,
    text: "Active",
  },
  {
    key: "Inactive",
    value: false,
    text: "Inactive",
  },
];

class HomecareUsers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      countries: [],
      selectedCountry: null,
      searchTerm: null,
      homecareUsers: [],
      homecareUser: {},
      errors: {},
      editables: {},
      isHomecareServiceViewModalVisible: false,
      homecareServiceOptions: [],
      homecaresForCountry: [],
    };

    this.columns = [
      {
        name: "ID",
        selector: "id",
        width: "100px",
        sortable: true,
      },
      {
        name: "Name",
        selector: "userName",
        wrap: true,
        sortable: true,
      },
      {
        name: "Status",
        selector: "isActive",
        maxWidth: "150px",
        center: true,
        cell: (homecareService) => (
          <Status isActive={homecareService.isActive} />
        ),
        sortable: true,
      },
      {
        name: "View",
        center: true,
        cell: (homecareService) => this.renderViewButton(homecareService.id),
      },
    ];
  }
  componentDidMount() {
    this.fetchAclUser();
    this.fetchCountries();
    this.fetchHomeCareUsers();
  }

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.HOMECARE_USERS;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchHomeCareUsers = async () => {
    this.showLoading();
    try {
      const { data: homecareUsers = [] } =
        (await PhoenixAPI.get("homecare-user")) || [];
      console.log("homecareUsers: ", homecareUsers);
      this.setState({ homecareUsers });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching homecareusers: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchHomecareServices = async () => {
    this.showLoading();
    const selectedCountry = this.state.selectedCountry;
    if (!selectedCountry) {
      console.log("fetchHomecareServices:: Invalid country!", {
        selectedCountry,
      });
      return;
    }
    try {
      const { data: homecareServices = [] } =
        (await PhoenixAPI.get("home-care-service/")) || [];
      const filteredCountryHomecareServices = homecareServices.filter(
        (it) => it.countryId === selectedCountry.id
      );
      const homecareServiceOptions = filteredCountryHomecareServices.map(
        (it) => {
          return { key: it.id, value: it.id, label: it.name };
        }
      );
      this.setState({ homecareServiceOptions });
    } catch (error) {
      this.showErrorNotification(
        "Error on fetching homecare services: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchCountries = async () => {
    try {
      const countries = (await fetchCountries()) || [];
      const defaultCountry = countries.find((country) => country.is_default);
      this.setState({ countries, selectedCountry: defaultCountry }, () =>
        this.fetchHomecareServices()
      );
    } catch (error) { }
  };

  findCountry = (id) => {
    const countries = this.state.countries || [];
    return countries.find((country) => country.id === id);
  };

  handleCountrySelection = (selectedCountry) =>
    this.setState({ selectedCountry }, () => this.fetchHomecareServices());

  handleSearch = (queryString) => {
    const searchTerm = queryString ? queryString : "";
    this.setState({ searchApplied: true, searchTerm });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleChange = ({ currentTarget: input }) => {
    const homecareUser = { ...this.state.homecareUser };
    homecareUser[input.name] = input.value;
    this.setState({ homecareUser });
  };

  resetForm = () => {
    this.setState({
      homecareUser: {},
      errors: {},
      editables: {},
    });
  };

  renderViewButton = (id) => (
    <div>
      <button
        className="btn px-5"
        style={{ backgroundColor: "#CEE741" }}
        onClick={() => {
          this.handleViewHomecareUser(id);
        }}
      >
        View
      </button>
    </div>
  );

  handleAddNewHomecareUser = () => {
    this.setState({ isNewHomecareUser: true }, () =>
      this.showHomeCareViewModal()
    );
  };

  handleViewHomecareUser = (homecareUserId) => {
    const { homecareUsers, homecareServiceOptions } = this.state ?? [];
    const homecareUser = homecareUsers.find((it) => it.id === homecareUserId);
    if (!homecareUser) {
      console.log(`Homecare User with id ${homecareUserId} is not found!`);
      return;
    }
    // const homecareName = homecareServiceOptions.filter((it)=>(it.value == homecareUser.homeCareServiceId))
    // homecareUser.homeCareName = homecareName.length>0 && homecareName[0].label;
    this.setState({ homecareUser, isNewHomecareUser: false }, () =>
      this.showHomeCareViewModal()
    );
  };

  validate = () => {
    const { homecareUser, isNewHomecareUser } = this.state;
    const errors = {};
    const {
      firstName,
      lastName,
      homeCareServiceId,
      isActive,
      password,
      userName,
      phoneNo,
      whatsappNumber,
      email,
      whatsappNumber2,
    } = homecareUser;

    const ARABIC_TEXT = /[\u0600-\u06FF\u0750-\u077F]/;

    if (!firstName || firstName.trim() === "") {
      errors.firstName = "Firstname is a required field!";
    } else if (ARABIC_TEXT.test(firstName)) {
      errors.firstName = "";
    } else if (
      !firstName.split(/[ .]+/).every((name) => /^[a-zA-Z]*$/g.test(name))
    ) {
      errors.firstName = "Invalid Firstname!";
    } else if (firstName.length > 50) {
      errors.firstName =
        "Invalid Firstname! (can not be longer than 50 characters)";
    }

    if (!lastName || lastName.trim() === "") {
      errors.lastName = "Lastname is a required field!";
    } else if (ARABIC_TEXT.test(lastName)) {
      errors.lastName = "";
    } else if (
      !lastName.split(/[ .]+/).every((name) => /^[a-zA-Z]*$/g.test(name))
    ) {
      errors.lastName = "Invalid Lastname!";
    } else if (lastName.length > 50) {
      errors.lastName =
        "Invalid Lastname! (can not be longer than 50 characters)";
    }

    if (!userName || userName.trim() === "")
      errors.userName = "User Name cannot be blank";
    else if (userName.length > 100)
      errors.userName = "User Name is limited to a 100 characters";

    const pattern = new RegExp(
      /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/
    );
    if (!email || email.trim() === "") {
      errors.email = "Email is a required field!";
    } else if (!pattern.test(email)) {
      errors.email = "Invalid email address!";
    }

    if (!phoneNo || phoneNo.trim() === "") {
      errors.phoneNo = "Phone Number is a required field";
    } else if (phoneNo && phoneNo.length <= 11) {
      errors.phoneNo = "Invalid Phone number!";
    }
    if (!whatsappNumber || whatsappNumber.trim() === "") {
      errors.whatsappNumber = "WhatsApp Number is a required field";
    } else if (whatsappNumber && whatsappNumber.length <= 11) {
      errors.whatsappNumber = "Invalid Phone number!";
    }

    // if (!whatsappNumber2 || whatsappNumber2.trim() === "") {
    //   errors.whatsappNumber2="WhatsApp Number is a required field"
    // }
    if (whatsappNumber2 && whatsappNumber2.length <= 11) {
      errors.whatsappNumber2 = "Invalid Phone number!";
    }

    if (isNewHomecareUser) {
      if (!password || password.trim() === "") {
        errors.password = "Password is a required field!";
      } else if (!/^[a-zA-Z0-9_@./#&+-]*$/g.test(password)) {
        errors.password = "Invalid Password!";
      } else if (password.length < 8) {
        errors.password =
          "Invalid Password! (can not be smaller than 8 characters).";
      }
    }
    if (!homeCareServiceId) {
      errors.homeCareName = "Home Care Service is a required field!";
    }

    return Object.keys(errors).length === 0 ? null : errors;
  };
  updateHomecareUser = async (homecareUser) => {
    const payload = {
      id: homecareUser.id,
      firstName: homecareUser.firstName,
      lastName: homecareUser.lastName,
      userName: homecareUser.userName,
      phoneNo: homecareUser.phoneNo,
      whatsappNumber: homecareUser.whatsappNumber,
      isActive: homecareUser.isActive,
      whatsappNumber2: homecareUser.whatsappNumber2,
      isCostingOnHomecare: homecareUser?.isCostingOnHomecare ?? false,
    };
    try {
      const response = await PhoenixAPI.patch(
        `update-homecare-user/${homecareUser.id}`,
        payload
      );
      return response;
    } catch (error) {
      console.log("Error in updating homecare user", error.message);
    }
  };

  createApiOptions = (method, url, formData) => ({
    method,
    url,
    data: formData,
    headers: {
      accept: "application/json",
      "Accept-Language": "en-US,en;q=0.8",
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    },
  });

  createFormData = () => {
    const { homecareUser, selectedCountry } = this.state;
    const formData = new FormData();
    formData.append("email", homecareUser.email);
    formData.append("first_name", homecareUser.firstName);
    formData.append("last_name", homecareUser.lastName);
    formData.append("username", homecareUser.userName);
    formData.append("password", homecareUser.password);
    formData.append("phone_no", homecareUser.phoneNo);
    formData.append("homecare_service_id", homecareUser.homeCareServiceId);
    formData.append("is_homecare_user", true);
    formData.append("is_active", homecareUser.isActive);
    formData.append("whatsapp_number", homecareUser.whatsappNumber);
    formData.append("country_id", selectedCountry.id);
    formData.append("whatsapp_number_2", homecareUser.whatsappNumber2);
    formData.append("is_costing_on_homecare", homecareUser?.isCostingOnHomecare ?? false);
    return formData;
  };

  saveHomecareUser = async (homecareUser) => {
    const formData = this.createFormData();
    const apiOptions = this.createApiOptions(
      "post",
      "create-homecare-user/",
      formData
    );
    console.log("repsossas", apiOptions);
    try {
      const response = await API.request(apiOptions);
      console.log("response", response);
      return response;
    } catch (error) {
      console.log("Error in saving homecare user", error.message);
    }
  };

  handlesaveHomecareUser = async () => {
    const { selectedCountry, homecareUser } = this.state;
    if (!selectedCountry) {
      this.showErrorNotification("Please select a country!");
      return;
    }

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) {
      this.showErrorNotification("There are some invalid form fields!");
      return;
    }

    try {
      this.showLoading();
      const isValidId =
        homecareUser.id !== null && homecareUser.id !== undefined;

      const response = isValidId
        ? await this.updateHomecareUser(homecareUser)
        : await this.saveHomecareUser(homecareUser);

      this.hideHomecareUserViewModal();
      console.log("Response: ", response);
      this.showSuccessNotification(
        isValidId
          ? "Homecare user Updated Successfully."
          : "Homecare user Created Successfully."
      );
      this.fetchHomeCareUsers();
    } catch (error) {
      this.showErrorNotification("Some error occurred: " + error.message);
    } finally {
      this.hideLoading();
    }
  };

  showHomeCareViewModal = async () => {
    const { selectedCountry, isNewHomecareUser, homecareServiceOptions } =
      this.state;
    const { data: homecaresForCountry = [] } = await PhoenixAPI.get(
      `homecare-user-panel-homecare-dropdown/country/${selectedCountry.id}`
    );

    const homecaresForCountryOptions = homecaresForCountry.map((it) => {
      return { key: it.id, value: it.id, label: it.name };
    });

    if (isNewHomecareUser) {
      const homecareUser = {
        countryId: selectedCountry?.id,
        isActive: false,
      };
      this.setState({
        homecareUser,
        isHomecareServiceViewModalVisible: true,
        homecaresForCountry: homecaresForCountryOptions,
      });
    } else {
      const homecareUser = this.state.homecareUser;
      const findHomeCareOption =
        homecareServiceOptions &&
        homecareServiceOptions.filter(
          (item) => item.value == homecareUser?.homeCareServiceId
        );
      homecareUser.homeCareName =
        findHomeCareOption.length > 0 && findHomeCareOption[0].label;
      this.setState({
        isHomecareServiceViewModalVisible: true,
        homecareUser,
        homecaresForCountry: homecaresForCountryOptions,
      });
    }
  };

  hideHomecareUserViewModal = () => {
    this.setState({ isHomecareServiceViewModalVisible: false });
    this.resetForm();
  };

  setEditable = (field) => {
    const editables = { ...this.state.editables };
    editables[field] = true;
    this.setState({ editables });
  };

  renderPencilIcon = (fieldName) =>
    this.state.isEditable && !this.state.isNewHomecareUser ? (
      <div
        onClick={() => this.setEditable(fieldName)}
        style={{ cursor: "pointer" }}
        className="input-group-append"
      >
        <i className="input-group-text fa fa-pencil"></i>
      </div>
    ) : (
      ""
    );

  numberCheck = async (value, data) => {
    if (data.dialCode !== undefined && data.dialCode !== null && value !== "") {
      if (value.slice(data.dialCode.length).length > 6) {
        this.setState({
          homecareUser: {
            ...this.state.homecareUser,
            phoneNo: `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
          contactNoErrorMessage: "",
        });
      } else {
        this.setState({
          homecareUser: {
            ...this.state.homecareUser,
            phoneNo: `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
        });
      }
    } else {
      this.setState({
        homecareUser: {
          ...this.state.homecareUser,
          phoneNo: "",
        },
        contactNoErrorMessage: "Invalid Phone Number",
      });
    }
  };

  numberCheckForWhatsApp = async (value, data) => {
    if (data.dialCode !== undefined && data.dialCode !== null && value !== "") {
      if (value.slice(data.dialCode.length).length > 6) {
        this.setState({
          homecareUser: {
            ...this.state.homecareUser,
            whatsappNumber:
              `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
          whatsAppNoErrorMessage: "",
        });
      } else {
        this.setState({
          homecareUser: {
            ...this.state.homecareUser,
            whatsappNumber:
              `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
        });
      }
    } else {
      this.setState({
        homecareUser: {
          ...this.state.homecareUser,
          whatsappNumber: "",
        },
        whatsAppNoErrorMessage: "Invalid Phone Number",
      });
    }
  };

  numberCheckForWhatsApp2 = async (value, data) => {
    if (data.dialCode !== undefined && data.dialCode !== null && value !== "") {
      if (value.slice(data.dialCode.length).length > 6) {
        this.setState({
          homecareUser: {
            ...this.state.homecareUser,
            whatsappNumber2:
              `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
          whatsAppNoErrorMessage2: "",
        });
      } else {
        this.setState({
          homecareUser: {
            ...this.state.homecareUser,
            whatsappNumber2:
              `+${data.dialCode}` + value.slice(data.dialCode.length),
          },
        });
      }
    } else {
      this.setState({
        homecareUser: {
          ...this.state.homecareUser,
          whatsappNumber2: "",
        },
        whatsAppNoErrorMessage2: "Invalid Phone Number",
      });
    }
  };

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  getFilteredHomecareUsers = () => {
    const homecareUsers = this.getHomecareUsersForCurrentCountry();
    return this.getSearchedHomecareUsers(homecareUsers);
  };

  getHomecareUsersForCurrentCountry = () => {
    const { homecareUsers, selectedCountry } = this.state;
    return homecareUsers && selectedCountry
      ? homecareUsers.filter(
        (homecareUser) => homecareUser.countryId === selectedCountry.id
      )
      : [];
  };

  getSearchedHomecareUsers = (homecareUsers) => {
    const { searchApplied, searchTerm } = this.state;

    if (!searchApplied || !searchTerm) return homecareUsers;

    const newValue = searchTerm.slice(0).trim().toLowerCase();
    return homecareUsers.filter((homecareUser) => {
      const { id, userName } = homecareUser;
      return (
        (userName && userName.toLowerCase().indexOf(newValue) !== -1) ||
        (id && id.toString() === newValue)
      );
    });
  };

  createHomeCareServiceOption = (homecare) => {
    return {
      key: homecare.homeCareServiceId,
      value: homecare.homeCareServiceId,
      label: homecare.homeCareName,
    };
  };

  render() {
    const {
      homecareUsers,
      homecareUser,
      isNewHomecareUser,
      countries,
      selectedCountry,
      searchTerm,
      searchApplied,
      loadError,
      errors,
      editables,
      isEditable,
    } = this.state;

    const selectStyles = {
      container: (base) => ({
        ...base,
        flex: 1,
      }),
    };

    const filteredHomecareUsers =
      this.getFilteredHomecareUsers(homecareUsers) ?? [];
    const formBasicInformation = (
      <div className="container mt-4">
        <div className="row">
          <div className="form-group col">
            <label htmlFor="firstName">First Name *</label>
            <div className="input-group">
              <input
                value={homecareUser?.firstName || ""}
                onChange={this.handleChange}
                id="firstName"
                name="firstName"
                type="text"
                placeholder="First Name"
                className={
                  "form-control py-2" + (errors.firstName ? " has-error" : "")
                }
                disabled={!isNewHomecareUser && !editables.firstName}
              />
              {this.renderPencilIcon("firstName")}
            </div>
            <span className="help-block">{errors.firstName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="lastName">Last Name *</label>
            <div className="input-group">
              <input
                value={homecareUser?.lastName || ""}
                onChange={this.handleChange}
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Last Name"
                className={
                  "form-control py-2" + (errors.lastName ? " has-error" : "")
                }
                disabled={!isNewHomecareUser && !editables.lastName}
              />
              {this.renderPencilIcon("lastName")}
            </div>
            <span className="help-block">{errors.lastName}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="userName">User Name *</label>
            <div className="input-group">
              <input
                value={homecareUser?.userName || ""}
                onChange={this.handleChange}
                id="userName"
                name="userName"
                type="text"
                placeholder="User Name"
                className={
                  "form-control py-2" + (errors.userName ? " has-error" : "")
                }
                disabled={!isNewHomecareUser && !editables.userName}
              />
              {this.renderPencilIcon("userName")}
            </div>
            <span className="help-block">{errors.userName}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="email">Email *</label>
            <div className="input-group">
              <input
                value={homecareUser?.email || ""}
                onChange={this.handleChange}
                id="email"
                name="email"
                type="text"
                placeholder="Enter email address"
                className={
                  "form-control py-2" + (errors.email ? " has-error" : "")
                }
                disabled={!isNewHomecareUser && !editables.email}
              />
              {this.renderPencilIcon("email")}
            </div>
            <span className="help-block">{errors.email}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="password">Password *</label>
            <div className="input-group">
              <input
                value={isNewHomecareUser ? homecareUser.password : ""}
                onChange={this.handleChange}
                id="password"
                name="password"
                type="text"
                placeholder="Enter your password"
                className={
                  "form-control py-2" + (errors.password ? " has-error" : "")
                }
                disabled={!isNewHomecareUser && !editables.password}
                readOnly={this.state.isNewHomecareUser ? false : true}
                autoComplete="off"
              />
            </div>
            <span className="help-block">{errors.password}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="homeCareName">HomeCare Service *</label>
            <div className="input-group">
              <Select
                value={
                  homecareUser && this.createHomeCareServiceOption(homecareUser)
                }
                onChange={(option) =>
                  this.setState({
                    homecareUser: {
                      ...homecareUser,
                      homeCareServiceId: option.value,
                      homeCareName: option.label,
                    },
                  })
                }
                options={this.state.homecaresForCountry}
                styles={selectStyles}
                placeholder={"Select HomeCare service"}
                isDisabled={!isNewHomecareUser && !editables.homeCareName}
              />
              {this.renderPencilIcon("homeCareName")}
            </div>
            <span className="help-block">{errors.homeCareName}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="country">Country</label>
            <input
              value={
                isNewHomecareUser
                  ? selectedCountry && selectedCountry.country_name
                  : this.findCountry(homecareUser?.countryId)?.country_name ||
                  ""
              }
              type="text"
              id="country"
              name="country"
              disabled={true}
              className={
                "form-control py-2" + (errors.country ? " has-error" : "")
              }
            />
            <span className="help-block">{errors.country}</span>
          </div>
          <div className="form-group col">
            <label>Phone Number *</label>
            <div className="user-input-container">
              <div
                className={`${editables.phone_no || isNewHomecareUser
                    ? `non-editable-phone`
                    : `editable-phone`
                  } `}
              >
                <PhoneInput
                  // country={"ae"}
                  autocompleteSearch
                  autoFormat
                  className={`form-cotrol user-data-input contact-no ${editables.phoneNo || isNewHomecareUser
                      ? `non-editable`
                      : `editable`
                    } `}
                  value={homecareUser.phoneNo || ""}
                  onChange={(value, data) => {
                    this.numberCheck(value, data);
                  }}
                  disabled={!isNewHomecareUser && !editables.phoneNo}
                  inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                />
                {this.renderPencilIcon("phoneNo")}
              </div>
              <span className="help-block">{errors.phoneNo}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label htmlFor="status">Status *</label>
            <div className="input-group">
              <Dropdown
                value={homecareUser?.isActive}
                onChange={(event, value) => {
                  this.setState({
                    homecareUser: {
                      ...homecareUser,
                      isActive: value.value,
                    },
                  });
                }}
                id="status"
                name="status"
                placeholder="Status"
                search
                selection
                options={STATUS_OPTIONS}
                disabled={!isNewHomecareUser && !editables.status}
                className={"form-control" + (errors.status ? " has-error" : "")}
              />
              {this.renderPencilIcon("status")}
            </div>
            <span className="help-block">{errors.status}</span>
          </div>
          <div className="form-group col">
            <label htmlFor="status">Is Homecare Costing Enabled</label>
            <div className="input-group">
              <Dropdown
                value={homecareUser?.isCostingOnHomecare}
                onChange={(event, value) => {
                  this.setState({
                    homecareUser: {
                      ...homecareUser,

                      isCostingOnHomecare: value.value,
                    },
                  });
                }}
                id="isCostingOnHomecare"
                name="isCostingOnHomecare"
                placeholder="isCostingOnHomecare"
                search
                selection
                options={HOMECARE_COSTING_NEW_ENABLE_OPTIONS}
                disabled={!isNewHomecareUser && !editables.isCostingOnHomecare}
                className={
                  "form-control" +
                  (errors.isCostingOnHomecare ? " has-error" : "")
                }
              />
              {this.renderPencilIcon("isCostingOnHomecare")}
            </div>
            <span className="help-block">{errors.isCostingOnHomecare}</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col">
            <label>WhatsApp Number *</label>
            <div className="user-input-container">
              <div
                className={`${editables.whatsappNumber || isNewHomecareUser
                    ? `non-editable-phone`
                    : `editable-phone`
                  } `}
              >
                <PhoneInput
                  // country={"ae"}
                  autocompleteSearch
                  autoFormat
                  className={`form-cotrol user-data-input contact-no ${editables.whatsappNumber || isNewHomecareUser
                      ? `non-editable`
                      : `editable`
                    } `}
                  value={homecareUser.whatsappNumber || ""}
                  onChange={(value, data) => {
                    this.numberCheckForWhatsApp(value, data);
                  }}
                  disabled={!isNewHomecareUser && !editables.whatsappNumber}
                  inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                />
                {this.renderPencilIcon("whatsappNumber")}
              </div>

              <span className="help-block">{errors.whatsappNumber}</span>
            </div>
          </div>
          <div className="form-group col">
            <label>WhatsApp Number 2 </label>
            <div className="user-input-container">
              <div
                className={`${editables.whatsappNumber2 || isNewHomecareUser
                    ? `non-editable-phone`
                    : `editable-phone`
                  } `}
              >
                <PhoneInput
                  // country={"ae"}
                  autocompleteSearch
                  autoFormat
                  className={`form-cotrol user-data-input contact-no ${editables.whatsappNumber2 || isNewHomecareUser
                      ? `non-editable`
                      : `editable`
                    } `}
                  value={homecareUser.whatsappNumber2 || ""}
                  onChange={(value, data) => {
                    this.numberCheckForWhatsApp2(value, data);
                  }}
                  disabled={!isNewHomecareUser && !editables.whatsappNumber2}
                  inputStyle={{ paddingTop: "18px", paddingBottom: "18px" }}
                />
                {this.renderPencilIcon("whatsappNumber2")}
              </div>

              <span className="help-block">{errors.whatsappNumber2}</span>
            </div>
          </div>
        </div>
      </div>
    );
    if (this.state.accessDenied) {
      return <AccessDenied />;
    }

    return (
      <div className="manage-package">
        <CountryDropdown
          countries={countries || []}
          selectedCountry={selectedCountry}
          onSelect={this.handleCountrySelection}
        />
        <div className="page-header">
          <div className="actions">
            <div className="action-item">
              <Search
                searchExpanded={true}
                searchTerm={searchTerm}
                handleSearch={this.handleSearch}
                clearSearch={this.handleSearchClear}
                handleSearchExit={this.handleSearchExit}
                ref={(input) => {
                  this.search = input;
                }}
              ></Search>
            </div>
            <div className="action-item">
              <button
                onClick={this.handleAddNewHomecareUser}
                disabled={!isEditable}
                style={{ backgroundColor: "#8FD14F" }}
                className="btn btn-md new-user"
              >
                <i className="fa fa-plus-circle"></i>Add New
              </button>
            </div>
          </div>
        </div>
        <Card body>
          <DataTable
            data={filteredHomecareUsers ?? []}
            columns={this.columns}
            defaultSortField="status"
            sortIcon={<ArrowDownward></ArrowDownward>}
            paginationPerPage={DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            highlightOnHover
            pagination
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">
                  {"Found " + filteredHomecareUsers.length + " results"}
                </p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
          />
        </Card>
        <Modal
          size="lg"
          show={this.state.isHomecareServiceViewModalVisible}
          onHide={this.hideHomecareUserViewModal}
          className="user-data-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p style={{ fontWeight: "normal", fontSize: "1rem" }}>
                <span style={{ fontWeight: "bold" }}>HomeCare: </span>
                {homecareUser?.userName || ""}
              </p>
            </Modal.Title>
            <div className="user-title"></div>
            <button
              onClick={this.handlesaveHomecareUser}
              disabled={!isEditable}
              className="btn modal-save-btn"
            >
              Save
            </button>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="tabs_otheritems">
              <Tab
                eventKey="key_form_basic_information"
                title="Basic Information"
              >
                {formBasicInformation}
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomecareUsers);
