import React, { useEffect, useState } from "react";
import "./clientLabDetails.scss";
import AlertModal from "./AlertModal";
import PhoenixAPI from "../../utils/PhoenixAPI";
import PdfPreview from "components/Common/PdfPreview";
import Notification from "./Notification";
import API from "utils/API";
import { bookingStatus, findBookingStatusByValue } from "./bookingStatus";
import { fetchBookings } from "./Services";
import NurseModal from "./NurseModal";
import moment from "moment";
import { getUserId } from "services/UserService";
import { connect } from "react-redux";
export default function ClientLabDetails(props) {
  const {
    clientDetails,
    professionals,
    setOrders,
    setIsShowOrder,
    setIsShowClient,
    user,
    orders,
    selectedStartDate,
    selectedEndDate,
  } = props;
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [imageURL1, setImageURL1] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [imageURL2, setImageURL2] = useState("");
  const [biomarkerData, setBiomarkerData] = useState({});
  const [biomarkerList, setBiomarkerList] = useState([]);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [labs, setLabs] = useState([]);
  const [lab1Name, setLab1Name] = useState("");
  const [lab2Name, setLab2Name] = useState("");
  const [isShowBiomarkerModal, setIsShowBiomarkerModal] = useState(false);
  const [enableCompeleteOrderStatus, setEnableCompeleteOrderStatus] = useState(false)
  
  useEffect(()=>{
    const {is_require_sample_collection=false, nurse_appointment_status=""}= clientDetails??{}
    console.log("is_require_sample_collection", is_require_sample_collection,clientDetails )
    const enableCompleteStatus=!((is_require_sample_collection??false) || (is_require_sample_collection==null)) && nurse_appointment_status!=="Completed";
    setEnableCompeleteOrderStatus(enableCompleteStatus)
  },[clientDetails])
  const validTypes = [
    "image/jpeg",
    "image/png",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "image/svg+xml",
  ];
  const errMsg = "Please upload only images, docs or pdf ";
  const BASE_URL_LABS = "/create-laboratory";
  const [appointmentDate, appointmentTime] =
    clientDetails && clientDetails.confirmed_date_time
      ? clientDetails.confirmed_date_time.split(" ")
      : [null, null];
  function handleBackButton(data) {
    if (data === "clientLabDetails") {
      setIsShowClient(false);
      // setIsShowUploadSample(false);
      setIsShowOrder(true);
    }
  }

  const handleImageChange = (e, data) => {
    const fileName = e.target.files[0];
    const imageURL = URL.createObjectURL(fileName);
    if (!validTypes.includes(fileName.type)) {
      setIsError(true);
      setMessage(errMsg);
    } else {
      if (data === "frontImage") {
        setFrontImage(fileName);
        setImageURL1(imageURL);
      } else {
        setBackImage(fileName);
        setImageURL2(imageURL);
      }
    }
  };
  useEffect(() => {
    const getBiomarkerList = async () => {
      // console.log("client details", clientDetails);
      try {
        const res = await PhoenixAPI.get(
          `/nurse/biomarkers/${clientDetails?.id}`
        );
        if (res.data) {
          const keys = Object.keys(res.data) || [];
          setBiomarkerList([...keys]);
          setBiomarkerData({ ...res.data });
        }
      } catch (error) {
        console.log("Error while getting biomarkerList data", error);
      }
    };

    const fetchLabs = async () => {
      try {
        //   showLoading();
        const { data: labs = [] } = await API.get(BASE_URL_LABS);
        setLabs([...labs]);
        
        if (clientDetails.lab_1_id) {
          const { name } = findLabById(clientDetails.lab_1_id, labs);
          
          setLab1Name(name);
        }
        if (clientDetails.lab_2_id) {
          const { name } = findLabById(clientDetails.lab_2_id, labs);
       
          setLab2Name(name);
        }
      } catch (error) {
        // showErrorNotification("Error on fetching labs!" + error.message);
      } finally {
        // hideLoading();
      }
    };
    fetchLabs();

    getBiomarkerList();
  }, []);
  const showLoading = () => props.toggleLoading({ isLoading: true });

  const hideLoading = () => props.toggleLoading({ isLoading: false });
  const handleAssignProfessional = async (payload) => {


    if (!user) {
      console.log("handleAssignProfessional:: Invalid user or bookings!");
      // this.showErrorNotification("Invalid user or bookings!");
      return;
    }
    if (isError) {
      setMessage("Invalid Fields");
    } else {
      setMessage("");
      setIsError(false);

      const { id, homecare_service_id } = user?.user;
      const booking = orders?.find((it) => it.id === payload.orderId);
      payload.userId = id;
      payload.homeCareId = homecare_service_id;
      payload.nurseAppointmentStatus = bookingStatus.NURSE_APPOINTED.value;
      try {
        //showLoading();
        if (booking.professional_id) {
          await PhoenixAPI.patch("/assign-nurse", payload);
        } else {
          await PhoenixAPI.post("/assign-nurse", payload);
        }

        //   this.showSuccessNotification("Assigned successfully.");
        // const ordersAssign = await fetchBookings(
        //   selectedStartDate,
        //   selectedEndDate,
        //   homecare_service_id
        // );
        
        // setOrders([...ordersAssign]);
        setIsShowClient(false);

        setIsShowOrder(true);
        hideModal();
      } catch (error) {
        //this.showErrorNotification("Some error occurred: " + error.message);
      } finally {
        //hideLoading();
      }
    }
  };
  const hideModal = () => {
    setIsShowModal(false);
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      let formData = new FormData();
      let selectedFrontId = frontImage || clientDetails.userEmiratesFrontUrl;
      let selectedBackId = backImage || clientDetails.userEmiratesBackUrl;
      if (!frontImage && !backImage && (selectedFrontId || selectedBackId)) {
        setIsError(false);
        setMessage("Image Uploaded succesfully");
        return;
      }

      formData.append("frontId", selectedFrontId);
      formData.append("backId", selectedBackId);
      const res = await PhoenixAPI.patch(
        `/nurse/emirates-id/${clientDetails.id}`,
        formData
      );
      if (res.status === 200) {
        setIsError(false);
        setMessage("Image Uploaded succesfully");
      } else {
        const errorMessage =
          (res && res.message) || "Error while uploading images";
        setIsError(true);
        setMessage(errorMessage);
      }
    } catch (error) {
      const errorMessage =
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.errorMessage) ||
        "Error while uploading images";
      setIsError(true);
      setMessage(errorMessage);
    }
  }
  const isAppointmentSameDay = () => {
    const today = moment().format("YYYY-MM-DD");
    const formattedDate = moment(appointmentDate, "YYYY-MM-DD", true);
    return formattedDate.isSame(today, "day");
  };
  const getCurrentTime24Format = () => {
    const currentTime = new Date();
    let hours = currentTime.getHours();
    let minutes = currentTime.getMinutes();

    // Add leading zero if necessary
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Concatenate hours and minutes
    const timeIn24Format = hours + ":" + minutes;

    return timeIn24Format;
  };

  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
  };

  // Function to find if the difference between two times is less than 30 minutes
  const isTimeDifferenceLessThan30Minutes = (time1, time2) => {
    const time1Minutes = timeToMinutes(time1);
    const time2Minutes = timeToMinutes(time2);
    const difference = Math.abs(time1Minutes - time2Minutes);
    return difference < 30;
  };

  const diffTime = () => {
    const sameDay = isAppointmentSameDay();
    const currentTime = getCurrentTime24Format();
    if (sameDay) {

      return isTimeDifferenceLessThan30Minutes(currentTime, appointmentTime);
    }
  };

  const findLabById = (labId, labs) => {
    const labsvar = labs;
  
    return labsvar.find((lab) => lab.id === labId);
  };

  const getDob = (dob) => {
    let userDob = (dob && dob.split("-")) || [];
    return (
      (userDob[2] ? userDob[2] : "NA") +
      "/" +
      (userDob[1] ? userDob[1] : "NA") +
      "/" +
      (userDob[0] ? userDob[0] : "NA")
    );
  };
  const renderFrontIdPreview = (userEmiratesFrontUrl) => {
    // const { front_id: fileSource, modalData } = this.state;
    const fileSource = imageURL1 || userEmiratesFrontUrl;
    if (!fileSource) return null;

    const file = frontImage;
    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof fileSource === "string" &&
        fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource}
      />
    ) : (
      <img className="user-emirates-img" src={fileSource} alt="" />
    );

    return <>{preview}</>;
  };
  const renderBackIdPreview = (userEmiratesBackUrl) => {
    // const { front_id: fileSource, modalData } = this.state;
    const fileSource = imageURL2 || userEmiratesBackUrl;
    if (!fileSource) return null;

    const file = backImage;
    const isPdfFile =
      file?.type === "application/pdf" ||
      (typeof fileSource === "string" &&
        fileSource.toLowerCase().endsWith(".pdf"));

    const preview = isPdfFile ? (
      <PdfPreview
        pdfFile={isPdfFile ? fileSource : null}
        pdfFileUrl={isPdfFile ? null : fileSource}
      />
    ) : (
      <img className="user-emirates-img" src={fileSource} alt="" />
    );

    return <>{preview}</>;
  };
  let countOfBiomarker = 0;
  if (biomarkerList && biomarkerList.length > 0) {
    biomarkerList &&
      biomarkerList.length > 0 &&
      biomarkerList.map((key, index) => {
        Object.keys(biomarkerData) &&
          Object.keys(biomarkerData).length > 0 &&
          biomarkerData[key] &&
          biomarkerData[key].length > 0 &&
          biomarkerData[key].map((item, index) => {
            countOfBiomarker++;
          });
      });
  }

  const handleCompleteorder=async ()=>{
    const orderId=clientDetails.id??null
    const userId=getUserId();
    const professional_id=clientDetails.professional_id??null;
    if(!professional_id){
      setMessage("Please Assign a Nurse to complete the order")
      setIsError(true);
      return;
    }
    if(orderId){
      try{
        const url = `/update-nurse-appointment-status/${orderId}`;
       await PhoenixAPI.patch(url, { nurseAppointmentStatus: "Completed" });
      //   await PhoenixAPI.post(
      //    `/admin/check-and-complete-parent-order/${orderId}/`
      //  );
      //  await API.patch("order-list/" + orderId + "/", { order_status: "COMPLETED" ,modified_by:parseInt(userId)
      //  });  
          setMessage("Order completed successfully");
          setIsShowClient(false);
          setIsShowOrder(true);
      }
     catch(error){
      console.log("Error in completing order", error.message)
      setMessage(error.message)
      setIsError(true);
     }
    }
   
   
  }
  return (
    <>
      <div className="client-lab-details">
        <div className="client-details-div">
          <div className="client-details">
            <div className="client-detail-row">
              <div className="client-detal-1">
                <div className="name">Client Name</div>{" "}
                <div className= {`${clientDetails?.is_show_client_information ?"subttitle":"subttitle hide-client-info"}`} >
                  {clientDetails.user_name}{" "}
                  <span className="gender">
                    {clientDetails.gender == "M" ? "Male" : "Female"}
                  </span>
                </div>
              </div>

              <div className="client-detal-1">
                <div className="dob">Date of Birth</div>{" "}
                <div className={`${clientDetails?.is_show_client_information ?"subttitle":"subttitle hide-client-info"}`}>{getDob(clientDetails.dob)}</div>
              </div>
            </div>
            <div className="client-detail-row">
              <div className="client-detal-1">
              <div className="name">Phone Number</div>{" "}
              <div className={`${clientDetails?.is_show_client_information ?"subttitle":"subttitle hide-client-info"}`}>
                <a className='mob-no' href={`tel:${clientDetails.phone_no} `}>{(clientDetails.phone_no ?? "")}</a></div>
              </div>
              <div className="client-detal-1">
              <div className="name">PIN</div>{" "}
              <div className={`${clientDetails?.is_show_client_information ?"subttitle":"subttitle hide-client-info"}`}>{(clientDetails.maqsamUniqueCode ?? "")}</div>
              </div>
            </div>
          </div>
          {
            <div className="upload-id">
              <div className="id-front">
                <div className="id-text">Emirates ID Front</div>
                <div className= {`${clientDetails?.is_show_client_information ?"upload-file-box":"upload-file-box hide-client-info"}`}>
                  <input
                    accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                    type="file"
                    onChange={(e) => handleImageChange(e, "frontImage")}
                  />
                  <div className="choose-fileand-icon">
                    <img
                      className="uplooad-icon"
                      src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                      alt=""
                    />
                    <span className="choose-file">Choose File</span>
                  </div>
                  {renderFrontIdPreview(clientDetails.userEmiratesFrontUrl)}
                </div>
              </div>
              <div className="id-front">
                <div className="id-text">Emirates ID Back</div>
                <div className={`${clientDetails?.is_show_client_information ?"upload-file-box":"upload-file-box hide-client-info"}`}>
                  <input
                    accept="image/*, .pdf, .doc, .docx, .xls, .xlsx .svg"
                    type="file"
                    onChange={(e) => handleImageChange(e, "backImage")}
                  />
                  <div className="choose-fileand-icon">
                    <img
                      className="uplooad-icon"
                      src="https://d25uasl7utydze.cloudfront.net/assets/download%20(1).svg"
                      alt=""
                    />
                    <span className="choose-file">Choose File</span>
                  </div>
                  {renderBackIdPreview(clientDetails.userEmiratesBackUrl)}
                </div>
              </div>
            </div>
         }
          {/* <div className='save-button' onClick={(e) => handleSubmit(e)}>Save</div>*/}
        </div>
        <hr
          style={{ height: "4px", background: "rgba(224, 228, 238, 0.50)" }}
        />
        <div className="lab-information-div">
          <div className="lab-title-text">
            <img
              className="lab-icon"
              src="https://d25uasl7utydze.cloudfront.net/assets/labs.svg"
              alt=""
            />
            <span className="lab-info-text">Lab Information</span>
          </div>
          <div className="lab-info-wrapper">
            {(clientDetails.lab_1_id || clientDetails?.is_require_sample_collection === false) && (
              <div className="lab-info-card">
                {clientDetails?.is_require_sample_collection === false?<></>:<div className="lab-no">
                  <span>Lab</span> <span>1 :</span>{" "}
                  <span className="lab-name">{clientDetails?.is_require_sample_collection==false?"N/A":lab1Name || "null"}</span>
                </div>}
                <div className="package-detail">
                  <div className="package-txt">
                    Package Details{" "}
                    <span
                      className="no-of-bio"
                      onClick={() => setIsShowBiomarkerModal(true)}
                    >
                      {countOfBiomarker} Biomarkers
                    </span>
                  </div>
                  <div className="package-name">
                    {clientDetails.package_name || "null"}{" "}
                  </div>
                  {clientDetails.addons && (
                    <div className="add-on-div">
                      <span className="add-on-title">Adds ons : </span>
                      <span className="add-on-name">
                        {clientDetails.addons || "null"}
                      </span>
                    </div>
                  )}
                     {clientDetails.custom_addons && (
                    <div className="add-on-div">
                      <span className="add-on-title">Adds ons : </span>
                      <span className="add-on-name">
                        {clientDetails.custom_addons || "null"}
                      </span>
                    </div>
                  )}
                </div>
                <div className="sample-detail">
                  <div className="sample-txt">Sample Details</div>
                  <div className="sample-para-txt">
                    {clientDetails.sample_details_lab1 || "null"}
                  </div>
                </div>
                <div className="special-instruction">
                  <div className="instruction-txt">Special Instructions</div>
                  <div className="instruction-para-txt" >
                    {clientDetails.special_instructions || "null"}
                  </div>
                </div>
                {clientDetails?.is_require_sample_collection === false?<></>:<div className="lab-location">
                  <div className="lab-location-txt">Lab Location</div>
                  <div className="lab-location">
                    {clientDetails.lab1Location || "null"}
                  </div>
                </div>}
              </div>
            )}
            {clientDetails.lab2Name && (
              <div className="lab-info-card">
                <div className="lab-no">
                  <span>Lab</span> <span>2 :</span>{" "}
                  <span className="lab-name">
                    {clientDetails.lab2Name || "null"}
                  </span>
                </div>
                <div className="package-detail">
                  <div className="add-on-div">
                    <span className="add-on-title"> Lab2 Contact: </span>
                    <span className="add-on-name">
                      {clientDetails.lab2Contact || "null"}
                    </span>
                  </div>
                  <div className="package-txt">
                    Package Details{" "}
                    <span
                      className="no-of-bio"
                      onClick={() => setIsShowModal(true)}
                    >
                      {countOfBiomarker} Biomarkers
                    </span>
                  </div>
                  <div className="package-name">
                    {clientDetails.packageName || "null"}{" "}
                  </div>
                  {clientDetails.addOns && (
                    <div className="add-on-div">
                      <span className="add-on-title">Adds ons : </span>
                      <span className="add-on-name">
                        {clientDetails.addOns || "null"}
                      </span>
                    </div>
                  )}
                </div>
                <div className="sample-detail">
                  <div className="sample-txt">Sample Details</div>
                  <div className="sample-para-txt">
                    {clientDetails.lab2SampleDetails || "null"}
                  </div>
                </div>
                <div className="special-instruction">
                  <div className="instruction-txt">Special Instructions</div>
                  <div className="instruction-para-txt">
                    {clientDetails.specialInstructions || "null"}
                  </div>
                </div>
                <div className="lab-location">
                  <div className="lab-location-txt">Lab Location</div>
                  <div className="lab-location">
                    {clientDetails.lab2Location || "null"}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {clientDetails.nurse_appointment_status !== "Nurse Appointed" &&
        clientDetails.nurse_appointment_status !== "Completed" &&
        clientDetails.nurse_appointment_status == null ? (
          <div
          className="assign-nurse-client-div"
           style={{marginBottom:"50px"}}
            onClick={() => setIsShowModal(true)}
          >
            Assign Nurse
          </div>
        ) : (
          <></>
        )}
      </div>
      {isShowBiomarkerModal && (
        <AlertModal
          count={countOfBiomarker}
          biomarkerList={biomarkerList}
          biomarkerData={biomarkerData}
          isShowModal={isShowBiomarkerModal}
          setIsShowModal={setIsShowBiomarkerModal}
        />
      )}
      {isShowModal && (
        <NurseModal
          orderId={clientDetails.id}
          nurseData={clientDetails}
          setIsShowModal={setIsShowModal}
          professionals={professionals}
          onSave={handleAssignProfessional}
          nurseModalText={"assign"}
          setIsShowClient={setIsShowClient}
          setIsError={setIsError}
          setMessage={setMessage}
        />
      )}
      {message && (
        <Notification
          setMessage={setMessage}
          message={message}
          isError={isError}
        />
      )}
      {enableCompeleteOrderStatus && <div className="complete-order-btn" onClick={()=>handleCompleteorder()}>
        Complete Order
        </div>}
    </>
  );
}
