import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Card, Modal, Tab, Tabs } from "react-bootstrap";
import { formatDate, formatSingleTimeslot } from "utils/commons";
import SearchOrders from "../../pages/HomecareMwebPanelNew/Search";
import { getUserEmail, getUserId, getUserName } from "services/UserService";
import CustomLoader from "components/CustomLoader/CustomLoader";
import Notification from "../../pages/HomecareMwebPortal/Notification";
import Select from "react-select";
import API from "utils/API";
import PhoenixAPI from "utils/PhoenixAPI";
import "./homecareCostingTotalOrders.scss";

export default function HomecareCostingTotalOrders(props) {
  const [filteredStartDate, setFilteredStartDate] = useState("");
  const [filteredEndDate, setFilteredEndDate] = useState("");
  const [user, setUser] = useState({});
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchFlag, setSearchFlag] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [orders, setOrders] = useState([]);
  const [searchOrder, setSearchOrders] = [];

  useEffect(() => {
    try {
      fetchUser();
    } catch (error) {
      console.error("Error fetching user:", error);
    }
  }, []);

  const fetchUser = async () => {
    try {
      const response = await API.get("/users/");
      const { data: users = [] } = response;
      console.log("fetchUser:: Response: ", { response });
      setUser({ user: users && users.length ? users[0] : null });
    } catch (error) {
      console.log("fetchUser:: Error on fetching users!", error);
    }
  };

  const handleShowOrders = async () => {
    const params = {};
    if (filteredStartDate)
      params.fromDate = moment(filteredStartDate).format("YYYY-MM-DD");
    if (filteredEndDate)
      params.toDate = moment(filteredEndDate).format("YYYY-MM-DD");
    console.log("USer:", user);
    try {
      const { data: allHomeCareCosting = [] } = await PhoenixAPI.get(
        `homecare-cost-orders/${user.user?.homecare_service_id}`,
        {
          params,
        }
      );
      setOrders(allHomeCareCosting);
      console.log("allHomeCareCosting", allHomeCareCosting);
    } catch (error) {
      console.log("Error in Fetching  homecare costing", error);
    }
  };

  const handleDateSelect = ({ target: input }) => {
    input.classList.add("has-value");
    if (input.name === "selectedStartDate" && filteredEndDate) {
      if (moment(input.value).isAfter(filteredEndDate)) {
        setFilteredStartDate(input.value);
        setFilteredEndDate(input.value);
      }
    }

    if (input.name == "selectedStartDate") {
      setFilteredStartDate(input.value);
    } else if (input.name == "selectedEndDate") {
      setFilteredEndDate(input.value);
    }
  };

  const handleSearchFlag = (item) => {
    setSearchFlag(item);
  };

  const handleSearchValue = (value) => {
    setSearchValue(value);
  };

  return (
    <>
      <SearchOrders
        searchFlag={(item) => handleSearchFlag(item)}
        searchValue={(value) => handleSearchValue(value)}
      />
      <div className="date-main-cont">
        <div className="date-container">
          <div className="del-date-filter">
            <input
              value={filteredStartDate}
              onChange={handleDateSelect}
              type="date"
              id="selectedStartDate"
              name="selectedStartDate"
            />
          </div>
          <div>-</div>
          <div className="del-date-filter">
            <input
              value={filteredEndDate}
              onChange={handleDateSelect}
              min={filteredStartDate ?? null}
              type="date"
              id="selectedEndDate"
              name="selectedEndDate"
            />
          </div>
        </div>

        <button
          onClick={handleShowOrders}
          // disabled={!isEditable}
          className="showbtn"
        >
          Show Orders
        </button>
      </div>

      <div className="clients-wrapper-home-care">
      {orders && orders.map((item, index)=>{
        const [appointmentDate, appointmentTime] =
                      item && item.confirmedDateTime
                        ? item.confirmedDateTime.split(" ")
                        : [null, null];
        
                    const formattedDate = appointmentDate
                      ? formatDate(appointmentDate)
                      : "";
                    const formattedTimeslot = appointmentTime
                      ? formatSingleTimeslot(appointmentTime)
                      : "";
                      let labDetails="";
                      const {lab1Name = "", lab2Name = ""}=item;
                      if (lab1Name && lab2Name) {
                         labDetails=`${lab1Name} + ${lab2Name}`;
                      } else if (lab1Name) {
                         labDetails=lab1Name;
                      } else if (lab2Name) {
                        labDetails=lab2Name;
                      }
        return (
          <div key={item.index} className={`${item.homecareCost<0?"clients-card-costing":"clients-card"}`}>
            <div>Order Id: {item.orderId}</div>
            <div>Client Name: {item.userName}</div>
            <div>Order Date & Time: {formattedDate} {formattedTimeslot}</div>
            <div>Package Name: {item.packageName}</div>
            <div>Lab Details: {labDetails}</div>
            <div>Status: {item.homecareStatus}</div>
            <div>Nurse Name: {item.professionalName}</div>
            <div>Nurse Visit Fee/Cost : {item.homecareCost}</div>
            <div>Payment Reciever : {item.paymentReceiver}</div>
            <div>Payment Mode: {item.paymentStatus}</div>
            <div>Remarks by Valeo: {item.remarks}</div>
            <div></div>
            <div></div>
          </div>
        )
      })}
       
    </div>
    </>
  );
}
