import React, { Component } from "react";
import { connect } from "react-redux";
import { Card,} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ArrowDownward } from "@material-ui/icons";
import { Page } from "utils/constant";
import PhoenixAPI from "utils/PhoenixAPI";
import { getUserId, getUserType } from "services/UserService";
import AlertBox from "components/AlertBox/AlertBox";
import {
  isAccessDenied,
  isUserSuperAdmin,
  isUserAdmin,
} from "services/aclService";
import moment from "moment";
import AccessDenied from "../../components/Common/AccessDenied";
import { formatDateTime } from "utils/commons";
import Select from "react-select";
import API from "utils/API";
import CustomLoader from "components/CustomLoader/CustomLoader";
import * as XLSX from 'xlsx';
const DEFAULT_ROWS_PER_PAGE = 25;
const ROWS_PER_PAGE_OPTIONS = [15, 25, 50, 100, 200];

const BASE_URL_HOMECARE_SERVICES = "home-care-service/";

class HomecareCostingNew extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      aclUser: null,
      pageAccessRights: null,
      accessDenied: false,
      isEditable: true,
      cities: [],
      countries: [],
      loadError: null,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "",
      sortDirection: "desc",
      searchTerm: null,
      searchApplied: false,
      homeCareServices: [],
      errors: {},
      isLoading: false,
      loadError: null,
      totalRows: 0,
      rowsPerPage: DEFAULT_ROWS_PER_PAGE,
      sortField: "",
      sortDirection: "desc",
      searchTerm: null,
      searchApplied: false,
      selectedHomecareService: null,
      isCustomLoader: false,
    };
  }
  componentDidMount() {
    if(getUserType()!=="homecare"){
      this.fetchAclUser();
      this.fetchHomecareServices();
    }
    else{
      this.fetchUser()
    }
    
  }

  costingColumns = () => {
    return [
      {
        name: "Order Id",
        selector: "orderId",
        sortField: "orderId",
        sortable: true,
        wrap: true,
        center: true,
      },
      {
        name: "Client Name",
        selector: "userName",
        sortField: "userName",
        sortable: true,
        wrap: true,
        center: true,
      },
      {
        name: "Date",
        selector: "dateConfirmedDateTime",
        sortField: "dateConfirmedDateTime",
        sortable: true,
        wrap: true,
        center: true,
        format: (row) => {
          return formatDateTime(row.dateConfirmedDateTime);
        },
      },
      {
        name: "Package Name",
        selector: "packageName",
        sortField: "packageName",
        sortable: true,
        wrap: true,
        center: true,
      },
      {
        name: "Lab Details",
        selector: "homecare_name",
        sortField: "id",
        sortable: true,
        wrap: true,
        center: true,
        cell: (row) => this.renderLabDetails(row),
      },
      {
        name: "Status",
        selector: "homecareStatus",
        sortField: "id",
        sortable: true,
        wrap: true,
        center: true,
      },
      {
        name: "Nurse Name",
        selector: "professionalName",
        sortField: "professionalName",
        wrap: true,
        sortable: false,
        center: true,
      },
      {
        name: "Nurse Visit Fee/Cost",
        selector: "homecareCost",
        sortField: "homecareCost",
        wrap: true,
        sortable: false,
        center: true,
      },
      {
        name: "Payment Reciever",
        selector: "paymentReceiver",
        sortField: "paymentReceiver",
        wrap: true,
        sortable: false,
        center: true,
      },
      {
        name: "Payment Mode",
        selector: "paymentStatus",
        sortField: "paymentStatus",
        wrap: true,
        sortable: false,
        center: true,
      },
      {
        name: "Remarks by Valeo",
        selector: "remarks",
        sortField: "remarks",
        wrap: true,
        sortable: false,
        center: true,
      },
    ];
  };

  hasPageAccessRight = (accessRightName) => {
    const { aclUser, pageAccessRights } = this.state;
    // If user is not configured as an acl user then he has all the rights as previous.
    if (!aclUser || isUserSuperAdmin(aclUser)) return true;
    if (!aclUser || isUserAdmin(aclUser)) return true;
    if (pageAccessRights && pageAccessRights.length && accessRightName)
      return pageAccessRights.includes(accessRightName.toLowerCase());
    return false;
  };

  fetchAclUser = async () => {
    const userId = getUserId();
    if (!userId) {
      console.log("fetchAclUser:: Invalid user id!", { userId });
      return;
    }

    try {
      const pageName = Page.HOMECARE_COSTING_NEW;
      const { data: aclUser } = await PhoenixAPI.get(
        `/api/v1/acl/users/userId/${userId}`
      );
      const pagePermission =
        aclUser?.pagePermissions?.find(
          (it) => pageName?.toLowerCase() === it.pageName?.toLowerCase()
        ) ?? null;
      const pageAccessRights =
        pagePermission?.pageAccessRights
          ?.filter((it) => it.isActive ?? false)
          ?.map((it) => it.accessRightName?.toLowerCase()) ?? null;
      const accessDenied = isAccessDenied(aclUser, pageAccessRights);
      this.setState({ aclUser, pageAccessRights, accessDenied }, () => {
        const isEditable = this.hasPageAccessRight("edit");
        this.setState({ isEditable });
      });
    } catch (error) {
      console.log("fetchAclUser:: Error on fetching acl user!", error);
    }
  };

  fetchUser = async () => {
    try {
      this.showLoading();
      const response = await API.get("/users/");
      const { data: users = [] } = response;
      console.log("fetchUser:: Response: ", { response });
      this.setState({ user: users && users.length ? users[0] : null });
    } catch (error) {
      console.log("fetchUser:: Error on fetching users!", error);
      this.showErrorNotification("Error on fetching user!" + error.message);
    } finally {
      this.hideLoading();
    }
  };

  fetchAllCosting = async (homecareServId) => {
    const { selectedStartDate, selectedEndDate } = this.state;
    const params = {};
    if (selectedStartDate)
      params.fromDate = moment(selectedStartDate).format("YYYY/MM/DD");
    if (selectedEndDate)
      params.toDate = moment(selectedEndDate).format("YYYY/MM/DD");
    try {
      this.showLoading();
      const { data: allHomeCareCosting = [] } = await PhoenixAPI.get(
        `homecare-cost-orders/${homecareServId}`,
        {
          params,
        }
      );
      console.log("allHomeCareCosting", allHomeCareCosting);
      this.setState({ allHomeCareCosting });
    } catch (error) {
      console.log("Error in Fetching  homecare costing", error);
      this.showErrorNotification(
        "Some Error occured in fetching Homecare Costing:",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  fetchHomecareServices = async () => {
    try {
      this.showLoading();
      const { data: services = [] } = await PhoenixAPI.get(
        BASE_URL_HOMECARE_SERVICES
      );

      const homecareServiceOptions = services.map((service) => {
        return {
          key: service.id,
          value: service.id,
          label: service.name,
        };
      });

      this.setState({ homecareServices: homecareServiceOptions });
    } catch (error) {
      console.log("Error on fetching homecare services", error);
      this.showErrorNotification(
        "Error on fetching homecare services: ",
        error.message
      );
      this.setState({ loadError: "Some error has occured. Please try again" });
    } finally {
      this.hideLoading();
    }
  };

  resetForm = () => {
    this.setState({ errors: {} });
  };

  handleShowOrders = () => {
    const { selectedHomecareService, user } = this.state;
    const userType=getUserType();
    if(userType!=="homecare"){
      this.fetchAllCosting(selectedHomecareService.value);
    }
    else{
      this.fetchAllCosting(user.homecare_service_id);
    }
    
  };

  renderLabDetails = (row) => {
    const { lab1Name = "", lab2Name = "" } = row ?? {};
    if (lab1Name && lab2Name) {
      return `${lab1Name} + ${lab2Name}`;
    } else if (lab1Name) {
      return lab1Name;
    } else if (lab2Name) {
      return lab2Name;
    }
  };
  handleSearch = (queryString) => {
    const searchTerm = queryString ?? "";
    this.setState({ searchTerm });
  };

  handleSearchEnter = () => {
    const page = 0;
    this.setState({ searchApplied: true });
  };

  handleSearchClear = () => {
    this.setState({ searchTerm: "", searchApplied: false });
    this.search.searchInput.focus();
  };

  handleSearchExit = (e) => {
    const value = e.target.value;
    if (!value || !value.length)
      this.setState({ searchTerm: "", searchApplied: false });
  };

  handleDateSelect = ({ target: input }) => {
    if (input.name === "selectedStartDate" && this.state.selectedEndDate) {
      if (moment(input.value).isAfter(this.state.selectedEndDate)) {
        this.setState({
          selectedStartDate: input.value,
          selectedEndDate: input.value,
        });
      }
    }
    this.setState({ [input.name]: input.value });
  };
  mappingOrdersForDownload = (orders) => {
    const mappedOrders = orders.map((order) => {
      const item = {
        "Order Id": order.orderId,
        "Client Name": order.userName,
        "Date": formatDateTime(order.dateConfirmedDateTime),
        "Package Name": order.packageName,
        "Lab Details": this.renderLabDetails(order),
        "Status": order.orderStatus,
        "Nurse Name": order.professionalName,
        "Nurse Visit Fee/Cost": order.homecareCost,
       "Payment Reciever": order.paymentReceiver,
        "Payment Mode": order.paymentStatus,
        "Remarks by Valeo": order.remarks,

      }

      return item;
    })
    return mappedOrders
  }
  handleDownloadCostingOrders=()=>{
    const { allHomeCareCosting=[] } = this.state || {}
  const mappedOrders=this.mappingOrdersForDownload(allHomeCareCosting);
    if (mappedOrders.length > 0) {
          const worksheet = XLSX.utils.json_to_sheet(mappedOrders);
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
          XLSX.writeFile(workbook, 'data.xlsx');
          this.hideDownloadOptionModal()
          this.showSuccessNotification("File is Downloaded successfully")
        }
        else {
          this.showErrorNotification("Please select Orders to Download")
          return;
        }
  }
  isTableRowColorSchemeApplicable=(order)=>{

    const {homecareCost}=order;
    if(homecareCost<0){
     return true
    }
   }
 
   getTableRowColorScheme=(row)=>{
     const order=row.id;
     let color="#FFCCCB";
     if(order)
     {
       return {
         backgroundColor: color,
         "&:hover": {
           backgroundColor: color,
         },
       }
     }
   }
  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  showLoading = () => this.props.toggleLoading({ isLoading: true });

  hideLoading = () => this.props.toggleLoading({ isLoading: false });

  render() {
    const {
      searchTerm,
      totalRows,
      rowsPerPage,
      allHomeCareCosting,
      searchApplied,
      loadError,
      homecareServices,
      selectedHomecareService,
      selectedStartDate,
      selectedEndDate,
      isEditable,
    } = this.state;
    if (this.state.accessDenied) {
      return <AccessDenied />;
    }
    const selectStyles = {
      container: (base) => ({ ...base, flex: 1 }),
    };

    return (
      <div className="manage-package">
       {
       <>
        <div className="page-header">
          {getUserType()!=="homecare" && <div className="col-3 ml-0 pl-0">
            <Select
              key={`my_unique_select_key__${
                selectedHomecareService && selectedHomecareService.id
              }`}
              value={selectedHomecareService ?? null}
              onChange={(option) =>
                this.setState({
                  selectedHomecareService: {
                    key: option.value,
                    value: option.value,
                    label: option.label,
                  },
                })
              }
              options={homecareServices}
              styles={selectStyles}
              menuPlacement={"bottom"}
              placeholder={"Select Homecare Service"}
              isDisabled={!this.state.isEditable}
            />
          </div>}
          <div className="row">
        <div className="form-group col-4">
           <button
             onClick={this.handleDownloadCostingOrders}
             disabled={!isEditable}
             style={{ backgroundColor: "#0CA789", color: "white"}}
             className="btn btn-md new-user"
           >
             Download
           </button>
         </div>
        </div>
        </div>
         
       </>
      
        }
       
  
        <div className="row" style={{ marginTop: "20px" }}>
          <div className="form-group col-4">
            <label htmlFor="selectedStartDate">Start Date</label>
            <div className="input-group user-input-container">
              <input
                value={selectedStartDate}
                onChange={this.handleDateSelect}
                // min={this.minStartDate}
                // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
                type="date"
                id="selectedStartDate"
                name="selectedStartDate"
                className="form-control py-3"
              />
            </div>
          </div>
          <div className="form-group col-4">
            <label htmlFor="selectedEndDate">End Date</label>
            <input
              value={selectedEndDate}
              onChange={this.handleDateSelect}
              min={selectedStartDate ?? null}
              // max={moment(this.todaysDateString).add(1, "M").format("YYYY-MM-DD")}
              type="date"
              id="selectedEndDate"
              name="selectedEndDate"
              className="form-control py-3"
            />
          </div>
          <div className="form-group col-4">
            <button
              onClick={this.handleShowOrders}
              disabled={!isEditable}
              style={{ backgroundColor: "#CEE741" , marginTop:"30px"}}
              className="btn btn-md new-user"
            >
              Show Orders
            </button>
          </div>
         
        </div>

        <Card body>
          <DataTable
            data={allHomeCareCosting ?? []}
            columns={this.costingColumns() || []}
            sortServer
            onSort={this.handleSort}
            defaultSortField="dateConfirmedDateTime"
            defaultSortAsc={false}
            sortIcon={<ArrowDownward></ArrowDownward>}
            pagination
            paginationServer
            paginationTotalRows={totalRows ?? 0}
            paginationPerPage={rowsPerPage ?? DEFAULT_ROWS_PER_PAGE}
            paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            onChangePage={this.handlePageChange}
            highlightOnHover
            responsive
            noHeader={searchApplied ? false : true}
            title={
              searchApplied ? (
                <p className="table-header">{"Found " + " results"}</p>
              ) : (
                ""
              )
            }
            noDataComponent={
              this.state.loadError ? (
                <AlertBox message={loadError} error={true}></AlertBox>
              ) : (
                <AlertBox message="There's nothing here."></AlertBox>
              )
            }
            conditionalRowStyles={[
              {
                 when: this.isTableRowColorSchemeApplicable,
                 style: this.getTableRowColorScheme,
               },
             ]}
          />
        </Card>

        <CustomLoader show={this.state.isCustomLoader} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomecareCostingNew);
