import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DataTable from "react-data-table-component";
import ReactDragListView from "react-drag-listview/lib/index.js";
import Drag from "@material-ui/icons/DragIndicatorOutlined";
import Card from "react-bootstrap/Card";
import ArrowDownward from "@material-ui/icons/ArrowDownward";

import _ from "lodash";
const DEFAULT_ROWS_PER_PAGE = 10;
const ROWS_PER_PAGE_OPTIONS = [100, 200, 300];
class MultiFacilityQauntityData extends Component {
  constructor(props) {
    super(props);

    const { supplementData = [] } = this.props;

    this.state = {
      supplementData,
    };
    this.columns = [
      {
        name: "Facility",
        selector: "facilityName",
        sortable: true,
        wrap: true,
      },
      {
        name: "Quanity",
        selector: "quantity",

        center: true,
        sortable: "true",
      },
    ];
  }

  showSuccessNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, true);

  showErrorNotification = (notificationMessage) =>
    this.showNotification(notificationMessage, false);

  showNotification = (notificationMessage, isSuccessMessage) =>
    this.props.showNotificationMessage({
      notificationMessage,
      successMessage: isSuccessMessage,
      showNotification: true,
    });

  render() {
    const { supplementData = [] } = this.state;
    return (
      <div className="container mt-4 px-0">
        <div className="row">
          <div className="form-group col">
            <Card>
              <DataTable
                highlightOnHover
                columns={this.columns}
                data={supplementData || []}
                pagination
                paginationPerPage={DEFAULT_ROWS_PER_PAGE}
                paginationRowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                responsive
                sortIcon={<ArrowDownward></ArrowDownward>}
              />
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

MultiFacilityQauntityData.propTypes = {
  packages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

MultiFacilityQauntityData.defaultProps = {
  packages: [],
};

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    showNotificationMessage: (value) =>
      dispatch({ type: "SHOW_NOTIFICATION", value }),
    toggleLoading: (value) => dispatch({ type: "TOGGLE_LOADING", value }),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiFacilityQauntityData);
